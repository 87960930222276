<template>
  <div style="width: 260px;height: 562px;" class="hide bd-radius-20 bd-w-1 bd-style-solid bd-dark-9 bg-light">
    <div style="height: 30px;background: #496be6;line-height: 30px;" class="hide cl light pd-l-r-10">
      <div class="z f12">{{$moment().format('H:m')}}</div>
      <div class="y f12">80%</div>
    </div>
    <div style="height: 30px;background: #496be6;line-height: 30px;" class="hide cl rel pd-l-r-10">
      <i class="el-icon-arrow-left z light" style="line-height: 30px;"></i>
      <div class="y cl bd-radius bd-w-1 bd-light bd-style-solid" style="line-height:14px;background-color:#354ea7;padding:3px 5px;height: 22px;margin-top:4px;">
        <i class="el-icon-more light z" style="line-height: 14px;"></i>
        <span class="pipe z"></span>
        <span class="bd-radius bg-light hide inline-block z" style="height: 10px;width:10px;margin-top:2px;"></span>
      </div>
      <div class="abs text-center light abs-pos" style="line-height: 30px;height: 30px;" v-if="title">{{title}}</div>
    </div>
    <div class="rel hide" style="width: 260px;height: 502px;">
      <div style="width: 260px;height: 502px;overflow-x: hidden;overflow-y: auto">
        <slot></slot>
      </div>
      <div class="abs bd-radius" style="height: 6px;width: 80px;bottom:5px;left:90px;z-index: 2;background-color:rgba(0,0,0,.7)"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Simulation",
  data() {
    return {}
  },
  props:{
    title:{
      type:String,
      default:'标题'
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {}
}
</script>
