export default {
    '/setting/mini':{
        name:'小程序设置',
        default: '/setting/mini',
        listRouter:'/setting/mini'
    },
    '/setting/upload':{
        name:'上传设置',
        default: '/setting/upload',
        listRouter:'/setting/upload'
    },
    '/setting/sms':{
        name:'短信设置',
        default: '/setting/sms',
        listRouter:'/setting/sms'
    },
    '/setting/h5':{
        name:'H5设置',
        default: '/setting/h5',
        listRouter:'/setting/h5'
    },
    '/setting/aboutus':{
        name:'关于我们',
        default: '/setting/about',
        listRouter:'/setting/aboutus'
    },
    '/setting/service':{
        name:'平台服务协议',
        default: '/setting/service',
        listRouter:'/setting/service'
    },
    '/setting/base':{
        name:'基础设置',
        default: '/setting/base',
        listRouter:'/setting/base'
    },
    '/setting/lianlian':{
        name:'产业联链介绍',
        default: '/setting/lianlian',
        listRouter:'/setting/lianlian'
    },
    '/setting/qihuwoying':{
        name:'企呼我应',
        default: '/setting/qihuwoying',
        listRouter:'/setting/qihuwoying'
    },
    '/setting/blackip':{
        name: 'IP黑名单',
        listRouter: '/setting/blackip',
        editRouter: '/setting/blackip/edit',
        addRouter: '/setting/blackip/add',
        detailRouter: '',
        editRole: 'setting:black:edit',
        addRole: 'setting:black:add',
        delRole: 'setting:black:delete',
        list: {
            com: '',
            options: {
                listUrl: '/BlackIp/listPage',
                statusUrl: '/BlackIp/changeStatus',
                delUrl: '/BlackIp/del',
                method: 'post',
                delMethod: 'post',
                pk:'id',
                data: null,
                showDept: false,
                showAdd: true,
                showDel: true,
                showEdit: true,
                showDetail: false,
                pageKey:'page',
                pageSizeKey:'pageSize',
                listKey:'list',
                optionsWidth:300
            },
            params: [
                {name: 'IP', value: 'ip'},
                {name: '添加时间', value: 'create_time'},
            ]
        },
        edit: {
            com: '',
            options: {
                editUrl: '/BlackIp/edit',
                detailUrl: '/BlackIp/detail',
                addUrl: '/BlackIp/add',
                editMethod:'post',
                addMethod:'post',
                detailMethod:'post',
                pk: 'id',
                data: null,
                copyData:false
            },
            params: [
                {name: 'IP', value: 'ip',edit:true,rq:true,max:30},
            ]
        },
        add: {
            com: '',
        },
        detail: {
            com: '',
        }
    },
    
    '/setting/friendlink': {
        name: '友情链接',
        // default:'/system/menu/list'
        listRouter: '/setting/friendlink',
        editRouter: '/setting/friendlink/edit',
        addRouter: '/setting/friendlink/add',
        detailRouter: '',
        editRole: 'setting:friend:edit',
        addRole: 'setting:friend:add',
        delRole: 'setting:friend:delete',
        delDisabled:null,
        editDisabled:null,
        list: {
            com: '',
            options: {
                listUrl: '/FriendLink/listTree',
                statusUrl: '/FriendLink/changeStatus',
                delUrl: '/FriendLink/del',
                method: 'post',
                delMethod: 'post',
                pk:'id',
                data: {orderBy:'sort',sortBy:'asc'},
                showDept: false,
                showAdd: true,
                showDel: true,
                showEdit: true,
                showDetail: false,
                pageKey:'page',
                pageSizeKey:'pageSize',
                listKey:null,
                listTree:false,
                expandAll:true,
                showPage:false,
                optionsWidth:300
            },
            params: [
                {name: '标题', value: 'title',width:300},
                {name: '网址', value: 'url',type:'download'},
                // {name: '路径', value: 'path',width:200},
                // {name: '权限标识', value:'rule',width:200},
                // {name: '类型', value: 'type',type:'custom-select',options:[{label:'菜单',val:'page',color:'success'},{label:'按钮',val:'button',color:'warning'}]},
                // {name: 'ID路径(仅开发用)', value: 'id_path',width: 250},
                {name: '排序', value: 'sort',width: 50},
                {name: '状态', value: 'state', type: 'changeStatus'},
            ],
            extButtons: [
                //  label 按钮文字 ，role :权限标识  icon:图标，title：弹框的标题，type：按钮交互方式，可选值 fun(调用某个接口)，url(跳转路由)，dialog(已弹窗形式打开某一组件)，默认url，com：组件路径，path：组件路径,路由 或 接口地址
                // {label:'添加子级',role:'special:list:child',icon:'el-icon-key',type:'add',isPid:true,title:'添加子级',path:'/special',color:'orange-1',btnType:'text',rules:null},
            ]
        },
        edit: {
            com: '',
            options: {
                editUrl: '/FriendLink/edit',
                detailUrl: '/FriendLink/detail',
                addUrl: '/FriendLink/add',
                editMethod:'post',
                addMethod:'post',
                detailMethod:'post',
                pk: 'id',
                data: null,
                copyData:false
            },
            params: [
                // {name: '上级', value: 'pid', edit: true, rq: true,type:'baseTreeSelect',url:'/Special/listTree',method:'post',valLabel:'title',isTop:true,def:0,datas:{orderBy:'sort',sortBy:'asc'}},
                {name: '名称', value: 'title', edit: true, rq: true,max:25},
                {name: '网址', value: 'url', edit: true, rq: true,max:100},
                // {name: '类型', value: 'type', edit: true, rq: true,type:'radio',options:[{label:'菜单',value:'page'},{label:'按钮',value:'button'}]},
                // {name: '图标', value: 'icon',edit: true,rq:true,type:'iconChoose',rules:{key:'type',val:'page'}},
                // {name: '路径', value: 'path', edit: true, rq: true,max:50,rules:{key:'type',val:'page'}},
                // {name: '权限标识', value: 'rule', edit: true, rq: false,max:30},
                {name: '排序', value: 'sort', def: '0', edit: true, rq: true, type: 'sort'},
                {name: '状态', value: 'state', type: 'state', edit: true, rq: true},
            ]
        },
        add: {
            com: '',
        },
        detail: {
            com: '',
            options: {
                method: 'get'
            },
        }
    },
}
