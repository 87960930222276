export default {
    '/dept': {
        name: '部门管理',
        // default:'/system/menu/list'
        listRouter: '/dept',
        editRouter: '/dept/edit',
        addRouter: '/dept/add',
        detailRouter: '',
        editRole: 'setting:dept:edit',
        addRole: 'setting:dept:add',
        delRole: 'setting:dept:delete',
        // delDisabled:{rules:{key:'is_system',val:1}},
        // editDisabled:{rules:{key:'is_system',val:1}},
        //顶部右侧按钮
        topRightButtons: [
            {label:'同步部门/人员',role:'setting:dept:sync',icon:'el-icon-refresh',type:'api',showConfirm:true,title:'编辑按钮权限',path:'/Dept/syncRemote',size:'mini',btnType:'danger',showLoading:true},
        ],
        headerTip:'部门信息数据来自于钉钉<br/>=重要提示=：钉钉官方接口中，无法获取下级部门信息，所以在钉钉端的部门设置中，一定要把所有部门都设置成同一级！！这样才能获取到所有部门；至于部门的上下级关系，由本系统维护。',
        list: {
            com: '',
            options: {
                listUrl: '/Dept/listTree',
                statusUrl: '/Dept/changeStatus',
                delUrl: '/Dept/del',
                method: 'post',
                delMethod: 'post',
                pk:'id',
                data: {pageSize:15,orderBy:'sort',sortBy:'asc'},
                showDept: false,
                showAdd: false,
                showDel: false,
                showEdit: true,
                showDetail: false,
                pageKey:'page',
                pageSizeKey:'pageSize',
                listKey:'',
                listTree:true,
                showPage:false,
                expandAll:true
                // optionsWidth:300
            },
            params: [
                {name: '部门名称', value: 'name',width:200},
                {name: '是否牵头单位', value: 'is_qtdw',type:'changeStatus',comProp:{key:'is_qtdw'}},
                {name: '是否责任单位', value: 'is_zrdw',type:'changeStatus',comProp:{key:'is_zrdw'}},
                {name: 'ID路径(开发用,无其他作用)', value: 'id_path',width:200},
                // {name: '图标', value: 'img',type:'image'},
                {name: '排序', value: 'sort'},
                // {name: '状态', value: 'state', type: 'changeStatus'},
                {name: '创建时间', value: 'create_time',width: 170},
            ],
            extButtons: []
        },
        edit: {
            com: '',
            options: {
                editUrl: '/Dept/edit',
                detailUrl: '/Dept/detail',
                addUrl: '/Dept/add',
                editMethod:'post',
                addMethod:'post',
                detailMethod:'post',
                pk: 'id',
                data: {type:'program',akey:'qzf'},
                copyData:false
            },
            params: [
                {name:'部门名称',value:'name',edit:false,rq:false},
                {name:'上级部门',value:'pid',edit:true,rq:true,type:'baseTreeSelect',url:'/Dept/listTree',valKey:'dept_id',valLabel:'name',isTop:true,topValue:'1'},
                {name: '是否牵头单位', value: 'is_qtdw', def: 0, edit: true, rq: true, type: 'bool'},
                {name: '是否责任单位', value: 'is_zrdw', def: 0, edit: true, rq: true, type: 'bool'},
                {name: '排序', value: 'sort',def:'0', edit: true, rq: true},
                // {name: '状态', value: 'state', type: 'state', edit: true, rq: true},
            ]
        },
        add: {
            com: '',
        },
        detail: {
            com: '',
            options: {
                method: 'get'
            },
        }
    },
}
