<template>
  <div>{{values}}</div>
</template>

<script>
export default {
  name: "TimeDown",
  data() {
    return {
      time:10,
      task:null,
      values:''
    }
  },
  props:{
    value:{
      type:[Number,String],
      default:0
    }
  },
  created() {
    this.time = this.value;
  },
  mounted() {
    if(this.time > 0) {
      this.start()
    }else{
      this.values = '00:00:00';
    }
  },
  methods: {
    start(){
      this.task = setInterval(this.toDown,1000)
    },
    toDown(){
      if(this.time > 0){
        this.time--;
        this.formatValues();
      }else{
        this.time = 0;
        this.cleanTime();
        this.formatValues();
        this.$emit('finish');
      }
    },
    formatValues(){
      this.values = this.time;

      let _hh = this.$utils.paddingZero(Math.floor(this.time / 3600),2);

      let _mm = this.$utils.paddingZero((Math.floor(this.time / 60) % 60),2);

      let _ss = this.$utils.paddingZero(this.time % 60,2);

      this.values = _hh+':'+_mm+':'+_ss;
    },
    cleanTime(){
      if(this.task){
        clearInterval(this.task);
        this.task = null;
      }
    }
  },
  destroyed() {
    this.cleanTime();
  }
}
</script>
