export default {
  '/program/export': {
    name: '导出模版管理',
    listRouter: '/program/export',
    editRouter: '',
    addRouter: '',
    delRouter: '',
    detailRouter: '',
    editRole: 'program:export:edit',
    addRole: 'program:export:add',
    delRole: 'program:export:delete',
    detailRole: 'program:export:detail',
    addProp: {title: '新增模版'},
    list: {
      com: '',
      options: {
        listUrl: '/DownloadTemplate/listPage',
        statusUrl: '/DownloadTemplate/changeStatus',
        delUrl: '/DownloadTemplate/del',
        method: 'post',
        delMethod: 'post',
        data: null,
        showDept: false,
        showAdd: true,
        showDel: true,
        showEdit: true,
        showDetail: false,
        showListIndex: true,
        showSearch: true,
        showSideTree: false,
        showListExport:false,
        showListShenpi:false,
        showListReShenpi:false,
        listKey: 'list',
        listTree:false,
        optionsWidth:280
      },
      params: [
        {name: '名称', value: 'title',width:100},
        {name: '发布部门', value: 'dept.name',width: 80},
        {name: '发布人', value: 'user.name',width: 80},
        {name: '状态', value: 'state', type: 'changeStatus',width: 80},
        {name: '创建时间', value: 'create_time', width: 150},
        // {name: '最后修改时间', value: 'update_time', width: 150},
      ]
    },
    edit: {
      com: '',
      options: {
        editUrl: '/DownloadTemplate/edit',
        detailUrl: '/DownloadTemplate/detail',
        addUrl: '/DownloadTemplate/add',
        editMethod: 'post',
        addMethod: 'post',
        detailMethod: 'post',
        pk: 'id',
        data: null,
        copyData: false
      },
      params: [
        {name: '标题', value: 'title', edit: true, rq: true, max: 50},
        {name: '模版文件', value: 'url', edit: true, rq: true,type:'oneFile',comProp:{accept:'.xls,.xlsx'}},
        {name: '状态', value: 'state', edit: true, rq: true, type: 'state'},
      ]
    },
    add: {
      com: '',
    },
    detail: {
      com: '',
    },
    search: {
      params: [
        {name: '事项内容', value: 'title'},
        {name: '状态', value: 'state', type: 'select', options: [{name: '禁用', id: 0}, {name: '可用', id: 1}]},
      ]
    }
  },
}
