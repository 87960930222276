export default {
    '/dict/program/qzfsx': {
        name: '区政府事项类型',
        // default:'/system/menu/list'
        listRouter: '/dict/program/qzfsx',
        editRouter: '/dict/program/qzfsx/edit',
        addRouter: '/dict/program/qzfsx/add',
        detailRouter: '',
        editRole: 'dict:qzfsx:edit',
        addRole: 'dict:qzfsx:add',
        delRole: 'dict:qzfsx:delete',
        // delDisabled:{rules:{key:'is_system',val:1}},
        // editDisabled:{rules:{key:'is_system',val:1}},
        list: {
            com: '',
            options: {
                listUrl: '/SystemDict/listPage',
                statusUrl: '/SystemDict/changeStatus',
                delUrl: '/SystemDict/del',
                method: 'post',
                delMethod: 'post',
                pk:'id',
                data: {orderBy:'sort',sortBy:'asc',type:'program',akey:'qzfsx'},
                showDept: false,
                showAdd: true,
                showDel: true,
                showEdit: true,
                showDetail: false,
                pageKey:'page',
                pageSizeKey:'pageSize',
                listKey:'list',
                listTree:false,
                // optionsWidth:300
            },
            params: [
                {name: '分类名称', value: 'name',width:200},
                {name: '图标', value: 'img',type:'image'},
                {name: '排序', value: 'sort'},
                {name: '状态', value: 'state', type: 'changeStatus'},
                {name: '创建时间', value: 'create_time',width: 170},
            ],
            extButtons: []
        },
        edit: {
            com: '',
            options: {
                editUrl: '/SystemDict/edit',
                detailUrl: '/SystemDict/detail',
                addUrl: '/SystemDict/add',
                editMethod:'post',
                addMethod:'post',
                detailMethod:'post',
                pk: 'id',
                data: {type:'program',akey:'qzfsx'},
                copyData:false
            },
            params: [
                {name:'分类名称',value:'name',edit:true,rq:true,max:50},
                {name:'图标',value:'img',edit:true,rq:false,type:'image'},
                {name: '排序', value: 'sort', def: '0', edit: true, rq: true, type: 'sort'},
                {name: '状态', value: 'state', type: 'state', edit: true, rq: true},
            ]
        },
        add: {
            com: '',
        },
        detail: {
            com: '',
            options: {
                method: 'get'
            },
        }
    },
    '/dict/program/zysx': {
        name: '重要事项类型',
        // default:'/system/menu/list'
        listRouter: '/dict/program/zysx',
        editRouter: '/dict/program/zysx/edit',
        addRouter: '/dict/program/zysx/add',
        detailRouter: '',
        editRole: 'dict:zysx:edit',
        addRole: 'dict:zysx:add',
        delRole: 'dict:zysx:delete',
        // delDisabled:{rules:{key:'is_system',val:1}},
        // editDisabled:{rules:{key:'is_system',val:1}},
        list: {
            com: '',
            options: {
                listUrl: '/SystemDict/listPage',
                statusUrl: '/SystemDict/changeStatus',
                delUrl: '/SystemDict/del',
                method: 'post',
                delMethod: 'post',
                pk:'id',
                data: {orderBy:'sort',sortBy:'asc',type:'program',akey:'zysx'},
                showDept: false,
                showAdd: true,
                showDel: true,
                showEdit: true,
                showDetail: false,
                pageKey:'page',
                pageSizeKey:'pageSize',
                listKey:'list',
                listTree:false,
                // optionsWidth:300
            },
            params: [
                {name: '分类名称', value: 'name',width:200},
                {name: '图标', value: 'img',type:'image'},
                {name: '排序', value: 'sort'},
                {name: '状态', value: 'state', type: 'changeStatus'},
                {name: '创建时间', value: 'create_time',width: 170},
            ],
            extButtons: []
        },
        edit: {
            com: '',
            options: {
                editUrl: '/SystemDict/edit',
                detailUrl: '/SystemDict/detail',
                addUrl: '/SystemDict/add',
                editMethod:'post',
                addMethod:'post',
                detailMethod:'post',
                pk: 'id',
                data: {type:'program',akey:'zysx'},
                copyData:false
            },
            params: [
                {name:'分类名称',value:'name',edit:true,rq:true,max:50},
                {name:'图标',value:'img',edit:true,rq:false,type:'image'},
                {name: '排序', value: 'sort', def: '0', edit: true, rq: true, type: 'sort'},
                {name: '状态', value: 'state', type: 'state', edit: true, rq: true},
            ]
        },
        add: {
            com: '',
        },
        detail: {
            com: '',
            options: {
                method: 'get'
            },
        }
    },
    '/dict/program/hysx': {
        name: '会议事项类型',
        // default:'/system/menu/list'
        listRouter: '/dict/program/hysx',
        editRouter: '/dict/program/hysx/edit',
        addRouter: '/dict/program/hysx/add',
        detailRouter: '',
        editRole: 'dict:hysx:edit',
        addRole: 'dict:hysx:add',
        delRole: 'dict:hysx:delete',
        // delDisabled:{rules:{key:'is_system',val:1}},
        // editDisabled:{rules:{key:'is_system',val:1}},
        list: {
            com: '',
            options: {
                listUrl: '/SystemDict/listPage',
                statusUrl: '/SystemDict/changeStatus',
                delUrl: '/SystemDict/del',
                method: 'post',
                delMethod: 'post',
                pk:'id',
                data: {orderBy:'sort',sortBy:'asc',type:'program',akey:'hysx'},
                showDept: false,
                showAdd: true,
                showDel: true,
                showEdit: true,
                showDetail: false,
                pageKey:'page',
                pageSizeKey:'pageSize',
                listKey:'list',
                listTree:false,
                // optionsWidth:300
            },
            params: [
                {name: '分类名称', value: 'name',width:200},
                {name: '图标', value: 'img',type:'image'},
                {name: '排序', value: 'sort'},
                {name: '状态', value: 'state', type: 'changeStatus'},
                {name: '创建时间', value: 'create_time',width: 170},
            ],
            extButtons: []
        },
        edit: {
            com: '',
            options: {
                editUrl: '/SystemDict/edit',
                detailUrl: '/SystemDict/detail',
                addUrl: '/SystemDict/add',
                editMethod:'post',
                addMethod:'post',
                detailMethod:'post',
                pk: 'id',
                data: {type:'program',akey:'hysx'},
                copyData:false
            },
            params: [
                {name:'分类名称',value:'name',edit:true,rq:true,max:50},
                {name:'图标',value:'img',edit:true,rq:false,type:'image'},
                {name: '排序', value: 'sort', def: '0', edit: true, rq: true, type: 'sort'},
                {name: '状态', value: 'state', type: 'state', edit: true, rq: true},
            ]
        },
        add: {
            com: '',
        },
        detail: {
            com: '',
            options: {
                method: 'get'
            },
        }
    },
    '/dict/program/ldjb': {
        name: '领导交办事项类型',
        // default:'/system/menu/list'
        listRouter: '/dict/program/ldjb',
        editRouter: '/dict/program/ldjb/edit',
        addRouter: '/dict/program/ldjb/add',
        detailRouter: '',
        editRole: 'dict:ldjb:edit',
        addRole: 'dict:ldjb:add',
        delRole: 'dict:ldjb:delete',
        // delDisabled:{rules:{key:'is_system',val:1}},
        // editDisabled:{rules:{key:'is_system',val:1}},
        list: {
            com: '',
            options: {
                listUrl: '/SystemDict/listPage',
                statusUrl: '/SystemDict/changeStatus',
                delUrl: '/SystemDict/del',
                method: 'post',
                delMethod: 'post',
                pk:'id',
                data: {orderBy:'sort',sortBy:'asc',type:'program',akey:'ldjb'},
                showDept: false,
                showAdd: true,
                showDel: true,
                showEdit: true,
                showDetail: false,
                pageKey:'page',
                pageSizeKey:'pageSize',
                listKey:'list',
                listTree:false,
                // optionsWidth:300
            },
            params: [
                {name: '分类名称', value: 'name',width:200},
                // {name: '所属产业', value: 'chanye.name'},
                {name: '图标', value: 'img',type:'image'},
                {name: '排序', value: 'sort'},
                {name: '状态', value: 'state', type: 'changeStatus'},
                {name: '创建时间', value: 'create_time',width: 170},
            ],
            extButtons: [
                //  label 按钮文字 ，role :权限标识  icon:图标，title：弹框的标题，type：按钮交互方式，可选值 fun(调用某个接口)，url(跳转路由)，dialog(已弹窗形式打开某一组件)，默认url，com：组件路径，path：组件路径,路由 或 接口地址
                // {label:'添加子级',role:'system:group:rule',icon:'el-icon-key',type:'add',isPid:true,title:'添加子级',path:'/dict/program',color:'orange-1',btnType:'text',rules:{key:'pid',val:'0'}},
            ]
        },
        edit: {
            com: '',
            options: {
                editUrl: '/SystemDict/edit',
                detailUrl: '/SystemDict/detail',
                addUrl: '/SystemDict/add',
                editMethod:'post',
                addMethod:'post',
                detailMethod:'post',
                pk: 'id',
                data: {type:'program',akey:'ldjb'},
                copyData:false
            },
            params: [
                // {name: '上级', value: 'pid', edit: true, rq: true,type:'baseTreeSelect',url:'/SystemDict/listTree',method:'post',valLabel:'name',isTop:true,def:0,datas:{type:'program'}},
                {name:'分类名称',value:'name',edit:true,rq:true,max:50},
                // {name:'所属产业',value:'pid',edit:true,rq:true,type:'select',url:'/SystemDict/list',data:{type:'chanye',orderBy:'sort',sortBy:'asc'},method:'post'},
                {name:'图标',value:'img',edit:true,rq:false,type:'image'},
                // {name:'定位图标',value:'s_img',edit:true,rq:false,type:'image'},
                {name: '排序', value: 'sort', def: '0', edit: true, rq: true, type: 'sort'},
                {name: '状态', value: 'state', type: 'state', edit: true, rq: true},
            ]
        },
        add: {
            com: '',
        },
        detail: {
            com: '',
            options: {
                method: 'get'
            },
        }
    },
    '/dict/industry': {
        name: '行业管理',
        listRouter: '/dict/industry',
        editRouter: '',
        addRouter: '',
        delRouter: '',
        detailRouter: '',
        editRole: 'dict:industry:edit',
        addRole: 'dict:industry:add',
        delRole: 'dict:industry:delete',
        addProp:{title:'新增行业'},
        editProp:{title:'编辑行业'},
        list: {
            com: '',
            options: {
                listUrl: '/SystemDict/listPage',
                statusUrl: '/SystemDict/changeStatus',
                delUrl: '/SystemDict/del',
                method: 'post',
                delMethod:'post',
                data: {type:'industry',orderBy:'sort',sortBy:'asc'},
                showDept: false,
                showAdd: true,
                showDel: true,
                showEdit: true,
                showDetail: false,
                showListIndex:true,
                showSearch:true,
                showSideTree:false,
                listKey:'list',
            },
            params: [
                {name: '名称', value: 'name',width:200},
                {name: '所属产业', value: 'chanye.name'},
                {name: '图标', value: 'img',type:'image'},
                {name: '定位图标', value: 's_img',type:'image'},
                {name: '排序', value: 'sort'},
                {name: '状态', value: 'state', type: 'changeStatus'},
                {name: '创建时间', value: 'create_time',width: 170},
            ]
        },
        edit: {
            com: '',
            options: {
                editUrl: '/SystemDict/edit',
                detailUrl: '/SystemDict/detail',
                addUrl: '/SystemDict/add',
                editMethod:'post',
                addMethod:'post',
                detailMethod:'post',
                pk: 'id',
                data: {type:'industry'},
                copyData:false
            },
            params: [
                {name:'名称',value:'name',edit:true,rq:true,max:50},
                {name:'所属产业',value:'pid',edit:true,rq:true,type:'select',url:'/SystemDict/list',data:{type:'chanye',orderBy:'sort',sortBy:'asc'},method:'post'},
                {name:'图标',value:'img',edit:true,rq:false,type:'image'},
                {name:'定位图标',value:'s_img',edit:true,rq:false,type:'image'},
                {name: '排序', value: 'sort', def: '0', edit: true, rq: true, type: 'sort'},
                {name: '状态', value: 'state', type: 'state', edit: true, rq: true},
            ]
        },
        add: {
            com: '',
        },
        detail: {
            com: '',
        },
        search: {
            params: [
                {name: '所属产业', value: 'pid',type:'select',url:'/SystemDict/list',data:{type:'chanye',orderBy:'sort',sortBy:'asc'}},
                {name: '状态', value: 'state',type:'select',options:[{name:'启用',id:'1'},{name:'禁用',id:'0'}]},
            ]
        }
    },
    '/dict/chanye': {
        name: '产业管理',
        listRouter: '/dict/chanye',
        editRouter: '',
        addRouter: '',
        delRouter: '',
        detailRouter: '',
        editRole: 'dict:chanye:edit',
        addRole: 'dict:chanye:add',
        delRole: 'dict:chanye:delete',
        addProp:{title:'新增产业'},
        editProp:{type:'url',path:'/chanye/edit',target:'_self'},
        topLeftButtons: [
            {label:'添加产业',role:'dict:chanye:add',icon:'el-icon-plus',type:'url',title:'编辑按钮权限',path:'/chanye/edit',size:'mini',btnType:'primary'},
        ],
        list: {
            com: '',
            options: {
                listUrl: '/SystemDict/listPage',
                statusUrl: '/SystemDict/changeStatus',
                delUrl: '/SystemDict/del',
                method: 'post',
                delMethod:'post',
                data: {type:'chanye',orderBy:'sort',sortBy:'asc'},
                showDept: false,
                showAdd: false,
                showDel: true,
                showEdit: true,
                showDetail: false,
                showListIndex:true,
                showSearch:false,
                showSideTree:false,
                listKey:'list',
                optionsWidth:300
            },
            params: [
                {name: '简称', value: 'name',width:150},
                {name: '全称', value: 'full_name',width:200},
                {name: '封面图', value: 'head_img',type:'image'},
                {name: '图标', value: 'img',type:'image'},
                {name:'定位图标',value:'s_img',type:'image'},
                {name: '排序', value: 'sort'},
                {name: '状态', value: 'state', type: 'changeStatus'},
                {name: '创建时间', value: 'create_time',width: 170},
            ],
        },
        edit: {
            com: '',
            options: {
                editUrl: '/SystemDict/edit',
                detailUrl: '/SystemDict/detail',
                addUrl: '/SystemDict/add',
                editMethod:'post',
                addMethod:'post',
                detailMethod:'post',
                pk: 'id',
                data: {type:'chanye'},
                copyData:false
            },
            params: [
                {name:'名称',value:'name',edit:true,rq:true,max:50},
                {name:'标签',value:'tags',edit:true,rq:false,type:'tags',def:[]},
                {name:'图标',value:'img',edit:true,rq:true,type:'image'},
                {name:'小图标',value:'s_img',edit:true,rq:true,type:'image'},
                {name:'封面图',value:'head_img',edit:true,rq:true,type:'image'},
                {name:'简介',value:'des',edit:true,rq:false,type:'textarea'},
                {name: '排序', value: 'sort', def: '0', edit: true, rq: true, type: 'sort'},
                {name: '状态', value: 'state', type: 'state', edit: true, rq: true},
            ]
        },
        add: {
            com: '',
        },
        detail: {
            com: '',
        },
        search: {
            params: []
        }
    },

    '/chanye/edit':{
        name: '编辑产业内容',
        default:'/chanye/edit',
        listRouter: '/chanye/edit',
        editRouter: '/chanye/edit',
        addRouter: '/chanye/add',
        detailRouter: '',
        editRole: 'dict:chanye:edit',
        addRole: 'dict:chanye:add',
        delRole: 'dict:chanye:delete',
    },

    '/dict/policy': {
        name: '政策分类',
        listRouter: '/dict/policy',
        editRouter: '',
        addRouter: '',
        delRouter: '',
        detailRouter: '',
        editRole: 'dict:chanye:edit',
        addRole: 'dict:chanye:add',
        delRole: 'dict:chanye:delete',
        addProp:{title:'新增政策分类'},
        editProp:{title:'编辑政策分类'},
        list: {
            com: '',
            options: {
                listUrl: '/SystemDict/listPage',
                statusUrl: '/SystemDict/changeStatus',
                delUrl: '/SystemDict/del',
                method: 'post',
                delMethod:'post',
                data: {type:'policy',orderBy:'sort',sortBy:'asc'},
                showDept: false,
                showAdd: true,
                showDel: true,
                showEdit: true,
                showDetail: false,
                showListIndex:true,
                showSearch:false,
                showSideTree:false,
                listKey:'list',
            },
            params: [
                {name: '名称', value: 'name',width:200},
                {name: '简介', value: 'des',width:200},
                {name:'图标',value:'img',type:'image'},
                {name:'小图标',value:'s_img',type:'image'},
                {name: '排序', value: 'sort'},
                {name: '状态', value: 'state', type: 'changeStatus'},
                {name: '创建时间', value: 'create_time'},
            ]
        },
        edit: {
            com: '',
            options: {
                editUrl: '/SystemDict/edit',
                detailUrl: '/SystemDict/detail',
                addUrl: '/SystemDict/add',
                editMethod:'post',
                addMethod:'post',
                detailMethod:'post',
                pk: 'id',
                data: {type:'policy'},
                copyData:false
            },
            params: [
                {name:'名称',value:'name',edit:true,rq:true,max:50},
                {name:'简介',value:'des',edit:true,rq:true,max:30},
                {name:'图标',value:'img',edit:true,rq:true,type:'image'},
                {name:'小图标',value:'s_img',edit:true,rq:false,type:'image'},
                {name: '排序', value: 'sort', def: '0', edit: true, rq: true, type: 'sort'},
                {name: '状态', value: 'state', type: 'state', edit: true, rq: true},
            ]
        },
        add: {
            com: '',
        },
        detail: {
            com: '',
        },
        search: {
            params: []
        }
    },

    '/dict/company': {
        name: '企业分类',
        listRouter: '/dict/company',
        editRouter: '',
        addRouter: '',
        delRouter: '',
        detailRouter: '',
        editRole: 'dict:company:edit',
        addRole: 'dict:company:add',
        delRole: 'dict:company:delete',
        addProp:{title:'新增企业分类'},
        editProp:{title:'编辑企业分类'},
        list: {
            com: '',
            options: {
                listUrl: '/SystemDict/listPage',
                statusUrl: '/SystemDict/changeStatus',
                delUrl: '/SystemDict/del',
                method: 'post',
                delMethod:'post',
                data: {type:'company',orderBy:'sort',sortBy:'asc'},
                showDept: false,
                showAdd: true,
                showDel: true,
                showEdit: true,
                showDetail: false,
                showListIndex:true,
                showSearch:false,
                showSideTree:false,
                listKey:'list',
            },
            params: [
                {name: '名称', value: 'name',width:200},
                {name: '排序', value: 'sort'},
                {name: '状态', value: 'state', type: 'changeStatus'},
                {name: '创建时间', value: 'create_time'},
            ]
        },
        edit: {
            com: '',
            options: {
                editUrl: '/SystemDict/edit',
                detailUrl: '/SystemDict/detail',
                addUrl: '/SystemDict/add',
                editMethod:'post',
                addMethod:'post',
                detailMethod:'post',
                pk: 'id',
                data: {type:'company'},
                copyData:false
            },
            params: [
                {name:'名称',value:'name',edit:true,rq:true,max:50},
                {name: '排序', value: 'sort', def: '0', edit: true, rq: true, type: 'sort'},
                {name: '状态', value: 'state', type: 'state', edit: true, rq: true},
            ]
        },
        add: {
            com: '',
        },
        detail: {
            com: '',
        },
        search: {
            params: []
        }
    },

    '/dict/question': {
        name: '问答分类',
        listRouter: '/dict/question',
        editRouter: '',
        addRouter: '',
        delRouter: '',
        detailRouter: '',
        editRole: 'dict:question:edit',
        addRole: 'dict:question:add',
        delRole: 'dict:question:delete',
        addProp:{title:'新增问答分类'},
        editProp:{title:'编辑问答分类'},
        list: {
            com: '',
            options: {
                listUrl: '/SystemDict/listPage',
                statusUrl: '/SystemDict/changeStatus',
                delUrl: '/SystemDict/del',
                method: 'post',
                delMethod:'post',
                data: {type:'question',orderBy:'sort',sortBy:'asc'},
                showDept: false,
                showAdd: true,
                showDel: true,
                showEdit: true,
                showDetail: false,
                showListIndex:true,
                showSearch:false,
                showSideTree:false,
                listKey:'list',
            },
            params: [
                {name: '名称', value: 'name',width:200},
                {name:'图标',value:'img',type:'image'},
                {name: '排序', value: 'sort'},
                {name: '状态', value: 'state', type: 'changeStatus'},
                {name: '创建时间', value: 'create_time'},
            ]
        },
        edit: {
            com: '',
            options: {
                editUrl: '/SystemDict/edit',
                detailUrl: '/SystemDict/detail',
                addUrl: '/SystemDict/add',
                editMethod:'post',
                addMethod:'post',
                detailMethod:'post',
                pk: 'id',
                data: {type:'question'},
                copyData:false
            },
            params: [
                {name:'名称',value:'name',edit:true,rq:true,max:50},
                {name:'图标',value:'img',edit:true,rq:true,type:'image'},
                {name: '排序', value: 'sort', def: '0', edit: true, rq: true, type: 'sort'},
                {name: '状态', value: 'state', type: 'state', edit: true, rq: true},
            ]
        },
        add: {
            com: '',
        },
        detail: {
            com: '',
        },
        search: {
            params: []
        }
    },

    '/dict/resources': {
        name: '资源分类',
        listRouter: '/dict/resources',
        editRouter: '',
        addRouter: '',
        delRouter: '',
        detailRouter: '',
        editRole: 'dict:resources:edit',
        addRole: 'dict:resources:add',
        delRole: 'dict:resources:delete',
        addProp:{title:'新增资源分类'},
        editProp:{title:'编辑资源分类'},
        list: {
            com: '',
            options: {
                listUrl: '/SystemDict/listPage',
                statusUrl: '/SystemDict/changeStatus',
                delUrl: '/SystemDict/del',
                method: 'post',
                delMethod:'post',
                data: {type:'resources',orderBy:'sort',sortBy:'asc'},
                showDept: false,
                showAdd: true,
                showDel: true,
                showEdit: true,
                showDetail: false,
                showListIndex:true,
                showSearch:false,
                showSideTree:false,
                listKey:'list',
            },
            params: [
                {name: '名称', value: 'name',width:200},
                {name:'图标',value:'img',type:'image'},
                {name:'小图标',value:'s_img',type:'image'},
                {name: '排序', value: 'sort'},
                {name: '状态', value: 'state', type: 'changeStatus'},
                {name: '创建时间', value: 'create_time'},
            ]
        },
        edit: {
            com: '',
            options: {
                editUrl: '/SystemDict/edit',
                detailUrl: '/SystemDict/detail',
                addUrl: '/SystemDict/add',
                editMethod:'post',
                addMethod:'post',
                detailMethod:'post',
                pk: 'id',
                data: {type:'resources'},
                copyData:false
            },
            params: [
                {name:'名称',value:'name',edit:true,rq:true,max:50},
                {name:'图标',value:'img',edit:true,rq:true,type:'image'},
                {name:'小图标',value:'s_img',edit:true,rq:true,type:'image'},
                {name: '排序', value: 'sort', def: '0', edit: true, rq: true, type: 'sort'},
                {name: '状态', value: 'state', type: 'state', edit: true, rq: true},
            ]
        },
        add: {
            com: '',
        },
        detail: {
            com: '',
        },
        search: {
            params: []
        }
    },

    '/dict/kaocha': {
        name: '考察分类',
        listRouter: '/dict/kaocha',
        editRouter: '',
        addRouter: '',
        delRouter: '',
        detailRouter: '',
        editRole: 'dict:kaocha:edit',
        addRole: 'dict:kaocha:add',
        delRole: 'dict:kaocha:delete',
        addProp:{title:'新增考察分类'},
        editProp:{title:'编辑考察分类'},
        list: {
            com: '',
            options: {
                listUrl: '/SystemDict/listPage',
                statusUrl: '/SystemDict/changeStatus',
                delUrl: '/SystemDict/del',
                method: 'post',
                delMethod:'post',
                data: {type:'kaocha',orderBy:'sort',sortBy:'asc'},
                showDept: false,
                showAdd: true,
                showDel: true,
                showEdit: true,
                showDetail: false,
                showListIndex:true,
                showSearch:false,
                showSideTree:false,
                listKey:'list',
            },
            params: [
                {name: '名称', value: 'name',width:200},
                {name:'图标',value:'img',type:'image'},
                {name:'小图标',value:'s_img',type:'image'},
                {name: '排序', value: 'sort'},
                {name: '状态', value: 'state', type: 'changeStatus'},
                {name: '创建时间', value: 'create_time'},
            ]
        },
        edit: {
            com: '',
            options: {
                editUrl: '/SystemDict/edit',
                detailUrl: '/SystemDict/detail',
                addUrl: '/SystemDict/add',
                editMethod:'post',
                addMethod:'post',
                detailMethod:'post',
                pk: 'id',
                data: {type:'kaocha'},
                copyData:false
            },
            params: [
                {name:'名称',value:'name',edit:true,rq:true,max:50},
                {name:'图标',value:'img',edit:true,rq:true,type:'image'},
                {name:'小图标',value:'s_img',edit:true,rq:true,type:'image'},
                {name: '排序', value: 'sort', def: '0', edit: true, rq: true, type: 'sort'},
                {name: '状态', value: 'state', type: 'state', edit: true, rq: true},
            ]
        },
        add: {
            com: '',
        },
        detail: {
            com: '',
        },
        search: {
            params: []
        }
    },
}
