<template>
    <span class="inline-block">
        <el-button size="mini" type="primary" @click="openDialog">{{label}}</el-button>
        <el-dialog title="知识选择" :visible.sync="visible" @close="onClose" width="80%" custom-class="dialog-h-500" append-to-body>
          <el-row :gutter="10">
            <el-col :span="6">
              <base-tree-side ref="side" @change="onChangeSideTree" url="/api-train/kn_category/tree" :datas="{level:'3'}" val-label="label" :show-top="false" show-first></base-tree-side>
            </el-col>
            <el-col :span="18">
              <div style="margin-bottom:20px;">
                <el-input placeholder="知识标题模糊搜索" class="mg-r-10" v-model="searchForm.title" maxlength="100" show-word-limit style="width: 300px;"></el-input>
                <el-button type="primary" @click="toSearch">搜索</el-button>
                <el-button @click="toCancel">重置</el-button>
              </div>
              <el-table ref="list" :data="list" row-key="id" @selection-change="onSelectionChange">
                <el-table-column type="selection" width="55" reserve-selection></el-table-column>
                <el-table-column label="序号" type="index" width="55"></el-table-column>
                <el-table-column label="知识标题" prop="title"></el-table-column>
                <el-table-column label="知识分类" prop="categoryName">
                  <template slot-scope="scope">
                    {{scope.row.categoryParentName}} / {{scope.row.categoryName}}
                  </template>
                </el-table-column>
                <el-table-column label="创建人" prop="createBy"></el-table-column>
                <el-table-column label="创建时间" prop="createTime"></el-table-column>
              </el-table>
              <div class="text-center mg-t-20" style="flex:1" v-if="list.length > 0">
                <el-pagination
                  background
                  layout="total,prev, pager, next"
                  :page-size="searchForm.limit"
                  :current-page.sync="searchForm.page"
                  :total="total"
                  @current-change="pageChange">
                </el-pagination>
                </div>
            </el-col>
          </el-row>
            <div slot="footer" class="dialog-footer">
                <el-button @click="visible = false">关闭</el-button>
                <el-button type="primary" @click="toChoose">确定</el-button>
            </div>
        </el-dialog>
    </span>
</template>

<script>
export default {
  name: "KnowledgeDialog",
  data() {
    return {
      visible: false,
      dataList: [],
      list: [],
      searchForm:{
        page:1,
        limit:10,
        categoryId:'',
        title:'',
        queryMode:'page',
        sortField: 'CREATE_TIME',
        sortIsAsc: true,
        status:1
      },
      total:0,
      values:[],
      ids:[]
    }
  },
  model:{
    prop:'value',
    event:'change'
  },
  props: {
    value: {
      type: Array,
      default: function () {
        return [];
      }
    },
    label:{
      type:String,
      default:'新增'
    }
  },
  created() {
    this.values = this.value;
    this.initIds();
  },
  watch:{
    value:{
      handler(n,o) {
        console.log(n);

        this.values = n;

        this.initIds();

        // this.getList();
      },
      deep:true
      // this.initChecked();
    }
  },
  mounted() {
    // this.getList();
  },
  methods: {
    initIds(){
      this.ids = [];
      for(let i=0;i < this.values.length;i++){
        this.ids.push(this.values[i].id);
      }
    },
    initChecked() {
      this.$nextTick(()=>{
        // this.$refs.list.clearSelection();

        // console.log(this.ids);

        if (this.ids.length > 0) {
          this.list.forEach(row => {
            if(this.ids.indexOf(row.id) >= 0) {
              this.$refs.list.toggleRowSelection(row, true);
            }else{
              this.$refs.list.toggleRowSelection(row, false);
            }
          });
        }
      })
    },
    toSearch() {
      this.searchForm.page = 1;
      this.getList();
    },
    toCancel(){
      this.searchForm.page = 1;
      this.searchForm.categoryId = '';
      this.searchForm.title = '';
      this.getList();
    },
    toChoose() {

      this.initIds();

      this.$emit('change', this.values);
      this.onClose();
    },
    openDialog() {
      this.visible = true;

      // this.initChecked();
      this.getList();
    },
    onClose() {
      this.visible = false;
      // this.imageList = [];
    },
    onExceed() {
      this.$message.error('数量最多不能超过' + this.limit + '个');
    },
    getList() {
      let _this = this;
      this.dataList = [];

      let _datas = this.$utils.deepCopy(this.searchForm);

      if(!_datas.categoryId){
        delete _datas.categoryId;
      }

      this.$http.request({
        url: '/api-train/knowledge/list',
        method:'post',
        datas: _datas,
        success: (res) => {
          _this.list = res.records;

          _this.total = res.total;

          _this.initChecked();
        }
      });
    },
    pageChange(page) {
      this.page = page;
      this.getList();
    },
    onChangeSideTree(e){
      console.log(e);
      this.searchForm.categoryId = e;
      this.searchForm.page = 1;

      this.getList();
    },
    onSelectionChange(e){
      // console.log(e);
      this.values = e;

      // this.initIds();
    },
  }
}
</script>
<style scoped>
.img-item {
  width: 140px;
  height: 140px !important;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  overflow: hidden;
  margin-left: 0 !important;
  margin-bottom: 10px !important;
}

/*.img-item .el-checkbox__label{width: 100px !important;}*/
</style>
