export default {
    '/user': {
        name: '人员管理',
        // default:'/system/menu/list'
        listRouter: '/user',
        editRouter: '/user/edit',
        addRouter: '/user/add',
        detailRouter: '',
        editRole: 'system:user:edit',
        addRole: 'system:user:add',
        delRole: 'system:user:delete',
        // delDisabled:{rules:{key:'is_system',val:1}},
        // editDisabled:{rules:{key:'is_system',val:1}},
        //顶部右侧按钮
        // topRightButtons: [
        //     {label:'同步部门/人员',role:'system:menu:delete',icon:'el-icon-refresh',type:'api',showConfirm:true,title:'编辑按钮权限',path:'/Dept/syncRemote',size:'mini',btnType:'danger',showLoading:true},
        // ],
        list: {
            com: '',
            options: {
                sideSpan:4,
                listUrl: '/User/listPage',
                statusUrl: '/User/changeStatus',
                delUrl: '/User/del',
                method: 'post',
                delMethod: 'post',
                pk:'id',
                data: {orderBy:'dept_id_list',sortBy:'asc'},
                showDept: true,
                showAdd: false,
                showDel: false,
                showEdit: true,
                showDetail: false,
                showSearch: true,
                pageKey:'page',
                pageSizeKey:'pageSize',
                listKey:'list',
                listTree:false,
                optionsWidth:150
            },
            params: [
                {name: '姓名', value: 'name',width:100},
                {name: '头像', value: 'avatar',type:'image',width:100},
                {name: '手机号', value: 'mobile',width:120},
                {name: '部门', value: 'dept_txt',width:100},
                {name: '职位标识', value: 'job',type:'custom-select',options:[{val:'lingdao',label:'领导',color:'danger'},{val:'bsy',label:'联络员',color:'success'},{val:'dby',label:'督办员',color:'warning'}],width:100},
                {name: '用户分组', value: 'group.name',width:100},
                // {name: '图标', value: 'img',type:'image'},
                // {name: '排序', value: 'sort'},
                {name: '状态', value: 'state', type: 'changeStatus'},
                {name: '创建时间', value: 'create_time',width: 170},
                {name: '最后登录时间', value: 'last_time',width: 170},
                {name: '最后登录IP', value: 'last_ip',width: 170},
            ],
            extButtons: [
                // {label:'审核',role:'program:all:shenpi',icon:'el-icon-circle-check',type:'program',path:'shenpi',target:'_self',btnType:'text',checkDisabled:true,rules:{key:'can_clean',val:1}},
                {label:'可清理',role:'tools:cron:todo',icon:'el-icon-delete',type:'api',title:'计划执行日志',path:'/User/clean',target:'_self',btnType:'text',showConfirm:true,rules:{key:'can_clean',val:1}},
            ]
        },
        edit: {
            com: '',
            options: {
                editUrl: '/User/edit',
                detailUrl: '/User/detail',
                addUrl: '/User/add',
                editMethod:'post',
                addMethod:'post',
                detailMethod:'post',
                pk: 'id',
                data: null,
                copyData:false
            },
            params: [
                {name: '姓名', value: 'name', edit: false, rq: false},
                {name: '手机号', value: 'mobile', edit: false, rq: false},
                {name: '部门', value: 'dept_txt', edit: false, rq: false},
                // {name: '部门', value: 'deptId', type: 'deptSelect', edit: true, rq: true},
                // {name: '部门', value: 'deptId', type: 'select',url:'/api-admin/dept?queryMode=list&parentId=0', edit: true, rq: true},
                // {name: '岗位', value: 'postId', type: 'select',url:'/SystemGroup/listAll', edit: true, rq: true},
                {name: '职位分组', value: 'job', type: 'select',url:'/SystemGroup/listAll',data:{type:'program'},valKey:'akey', edit: true, rq: true},
                {name: '用户分组', value: 'system_group_id', type: 'select',url:'/SystemGroup/listAll',data:{type:'user'}, edit: true, rq: true},
                // {name: '用户分组', value: 'system_group_id', type: 'roleSelect', edit: true, rq: true},
                // {name: '手机号', value: 'mobile', rule: {patt:'mobile'}, edit: true, rq: true,max:25},
                // {name: '邮箱', value: 'email', rule: {patt:'email'}, edit: true, rq: false,max:25},
                {name: '备注', value: 'remark',type:'textarea', edit: true, rq: false,max:100},
                {name: '状态', value: 'state', type: 'state', edit: true, rq: true},
            ]
        },
        add: {
            com: '',
        },
        detail: {
            com: '',
            options: {
                method: 'get'
            },
        },
        search: {
            params: [
                {name: '姓名', value: 'name',},
                {name: '手机号', value: 'mobile'},
                {name: '职务分组', value: 'job', type: 'select',url:'/SystemGroup/listAll',data:{state:1,type:'program'},valKey:'akey',filterable:true,isTop:true},
                {name: '用户分组', value: 'system_group_id', type: 'select',url:'/SystemGroup/listAll',data:{state:1,type:'user'},valKey:'id',filterable:true,isTop:true},
                {name: '状态', value: 'state', type: 'select', options: [{name: '已失效', id: 0}, {name: '正常', id: 1}]},
                {name: '可清理', value: 'can_clean', type: 'select', options: [{name: '否', id: 0}, {name: '是', id: 1}]},
            ]
        }
    },
}
