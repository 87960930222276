<template>
	<el-dialog title="事项审批" :visible.sync="isShow" width="700px" append-to-body @close="onClose" @open="onOpen">
<!--		{{formData}}-->
		<el-form :model="formData" :rules="rules" ref="form" label-width="100px">
			<el-form-item label="审批状态" prop="state">
				<type-select :is-top="false" :options="[{id:2,name:'通过'},{id:6,name:'拒绝'}]" v-model="formData.state"></type-select>
			</el-form-item>
			<el-form-item label="拒绝理由" prop="remark" :rules="[{required:true,message:'拒绝理由必填'}]" v-if="formData.state === 6">
				<el-input type="textarea" v-model="formData.remark"></el-input>
			</el-form-item>
			<el-form-item label="批示意见" prop="remark" v-if="formData.state === 2">
				<el-input type="textarea" v-model="formData.remark"></el-input>
			</el-form-item>
			<el-form-item label="是否发送短信" prop="send_sms">
				<one-checkbox v-model="formData.send_sms" @change="onChangeSms">是</one-checkbox>
			</el-form-item>
			<el-form-item label="接收短信人员" prop="sms_ids" v-if="personList.length > 0">
<!--				{{formData.sms_ids}}-->
				<el-table :data="personList" border style="width: 100%" size="mini" ref="list" @selection-change="onSelectionChange" row-key="id">
					<el-table-column type="selection" width="55px"></el-table-column>
					<el-table-column prop="name" label="姓名"></el-table-column>
					<el-table-column prop="dept_name" label="部门"></el-table-column>
					<el-table-column prop="mobile" label="手机号"></el-table-column>
					<el-table-column prop="type_str" label="职责单位"></el-table-column>
					<el-table-column prop="job_str" label="事项职责"></el-table-column>
				</el-table>
			</el-form-item>
		</el-form>
		<span slot="footer" class="dialog-footer">
			<el-button @click="isShow = false">关闭</el-button>
	    <el-button type="primary" @click="toSubmit">确定</el-button>
	  </span>
	</el-dialog>
</template>

<script>
export default {
	name: "ShenpiDialog",
	data() {
		return {
			isShow:false,
			formData:{
				id:'',
				state:'',
				remark:'',
				send_sms:0,
				sms_ids:[]
			},
			personList:[],
			rules:{
				state:[{required: true,message:'审批状态必选'}]
			}
		}
	},
	props:{
		visible:{
			type:Boolean,
			default:false
		},
		id:{
			type:String,
			default:''
		}
	},
	model:{
		prop:'visible',
		event:'change'
	},
	created() {
		this.isShow = this.visible;
		this.formData.id = this.id ? this.id : ''
	},
	mounted() {

	},
	methods: {
		onSelectionChange(e){
			this.formData.sms_ids = e;
		},
		onChangeSms(e){
			if(e === 1){
				this.getPerson();
			}else{
				this.personList = [];
				this.formData.sms_ids = [];
			}
		},
		onOpen(){
			let _this = this;
			this.$nextTick(()=>{
				_this.formData.id = this.id ? this.id : '';
				_this.$refs['form'].clearValidate()
			})
		},
		onClose(){
			this.formData.id = '';
			this.formData.state = '';
			this.formData.remark = '';
			this.formData.sms_ids = [];
			this.formData.send_sms = 0;
			this.personList = [];
			this.$emit('update:visible', false);
		},
		getPerson(){
			let _this = this;
			_this.$http.request({
				url:'/Program/getProgramPerson',
				datas:{id:this.formData.id},
				success(res){
					_this.personList = res;

					if(res && res.length > 0) {
						_this.$nextTick(() => {
							_this.$refs.list.toggleAllSelection();
						})
					}
				}
			})
		},
		toSubmit(){
			let _this = this;
			this.$refs['form'].validate((valid) => {
				if (valid) {
					if(_this.formData.send_sms === 1 && _this.formData.sms_ids.length <= 0){
						_this.$message.error('请选择需要接受短信的人');
						return;
					}
					_this.$http.request({
						url:'/Program/toShenpi',
						datas:_this.formData,
						success(res){
							_this.$message.success('操作成功');

							_this.$emit('success');

							_this.isShow = false;
						}
					})
				} else {
					_this.$message.error('请填写完整表单')
					return false;
				}
			});
		}
	},
	watch:{
		visible(n,o){
			this.isShow = n;
		}
	}
}
</script>
