<template>
  <div>
    <template v-if="info">
      <el-card class="mg-b-20" shadow="never" v-for="(item,index) in detailParams" :class="item.title ? '' : 'bd-w-0'" :body-style="{padding:item.title ? '20px' : '0px'}">
        <div slot="header" class="cl" v-if="item.title">
          <span>{{item.title}}</span>
          <span v-if="item && item.slot && item.slot.title" v-html="$utils.callString(info,item.slot.title)"></span>
<!--          <el-button class="y">2121</el-button>-->
<!--          <template v-if="item && item.slot && item.slot.right">{{$utils.callString(info,item.slot.right)}}</template>-->
<!--          <span v-if="item && item.slot && item.slot.right">$utils.callString(info,item.slot.right)</span>-->
<!--          <template v-if="item && item.slot && item.slot.right" :is="$utils.callString(info,item.slot.right)"></template>-->
<!--          <component v-if="item && item.slot && item.slot.right" is="right"></component>-->
        </div>
        <template v-if="['list','edit'].indexOf(item.type) >= 0">
          <component :ref="$utils.randomWords()+'_'+item.type" :is="item.type" :path="path" :comParams="item" :comListParams="item" :comListOptions="item.options" :comTableList="item.options.listKey ? info[item.options.listKey] : []" :info="info" @toApi="toApi" @toEdit="toEdit" @toDetail="toDetail" @toList="toList" @toDiy="toDiy"></component>
        </template>
        <component :ref="com_t.ref" :is="com_t.component" :path="path" :datas="searchForm" :info="info" v-else-if="item.type === 'diy'" v-for="(com_t,com_i) in componentList" :key="'com_'+com_i"></component>
        <template v-else>
          <el-descriptions :border="$utils.isUndefined(item.border) ? true : item.border" title="" :column="item.cols" :labelStyle="{textAlign:'right',width:'150px !important'}" v-if="$utils.checkButtonRules(true,info,item.comProp ? item.comProp.rules : null)">
            <el-descriptions-item :label="s_item.name" v-for="(s_item,s_index) in item.params" :span="s_item.span ? s_item.span : 1">
              <template v-if="s_item.type === 'loop'">
                <template v-for="(l_t,l_i) in info[s_item.value]">{{l_t[s_item.comProp.key]}}、</template>
              </template>
              <template v-else-if="s_item.type === 'custom-select'">
                <el-tag :type="st.color" disable-transitions v-for="(st,si) in s_item.options" v-if="info[s_item.value] === st.val">{{ st.label }}</el-tag>
              </template>
              <template v-else-if="s_item.type === 'province'">
                {{$utils.areaCodeToName($utils.evalGet(info,s_item.value))}}
              </template>
              <template v-else-if="s_item.type === 'link'">
                <span class="a-link hand f-u" @click="s_item.url && $utils.evalGet(info,s_item.linkKey ? s_item.linkKey : 'id') ? $router.push({path:s_item.url,query:{id:$utils.evalGet(info,s_item.linkKey ? s_item.linkKey : 'id')}}) : null">{{$utils.evalGet(info,s_item.value)}}</span>
              </template>
              <div style="white-space: pre-line;" v-html="$utils.evalGet(info,s_item.value) ? $utils.evalGet(info,s_item.value) : '-'" v-else>{{$utils.evalGet(info,s_item.value) ? $utils.evalGet(info,s_item.value) : '-'}}</div>
            </el-descriptions-item>
          </el-descriptions>
          <div v-else>
            <template v-if="item.comProp">
            {{item.comProp.nullText}}
            </template>
          </div>
        </template>
      </el-card>
    </template>
  </div>
</template>

<script>
import list from '@/views/base/list';
import edit from '@/views/base/edit';

export default {
  name: "detail",
  components: {edit,list},
  data() {
      return {
        pk: 'id',
        id:'',
        method:'get',
        detailParams: [],
        detailOptions: {},
        config: null,
        searchForm:{},
        info:null,
        componentList:[]
      };
  },
  props: {
    datas: {
      type: [Array,Object],
      default(){
        return null
      }
    },
    path: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    width:{
      type: String,
      default:'900px'
    },
  },
  created() {
    this.initSetting();
  },
  mounted() {
  },
  methods: {
    initSetting() {
      let _config = this.$baseConfig

      this.config = _config[this.path];

      this.pk = this.config && this.config.detail && this.config.detail.options && this.config.detail.options.pk ? this.config.detail.options.pk : 'id';

      this.detailParams = this.config.detail.params;

      this.detailOptions = this.config.detail.options;

      this.id = this.datas ? this.datas[this.pk] : (this.$route.query[this.pk] ? this.$route.query[this.pk] : '');

      if(this.id){
        this.$set(this.searchForm,this.pk,this.id);
      }

      for(let i = 0;i < this.detailParams.length;i++){
        if(this.detailParams[i].type === 'diy' && this.detailParams[i].path){
          let _com = {
            ref:this.$utils.randomWords(),
            component: () => import('@/views'+this.detailParams[i].path)
          }

          this.componentList.push(_com);
        }
      }

      //获取表单提交url和method
      if (this.searchForm[this.pk]) {
        this.url = this.detailOptions.detailUrl.replace('{id}',this.searchForm[this.pk]);
        this.method = this.detailOptions.detailMethod;
      }

      console.log(this.searchForm);

      //如果有主键id，则获取详情
      if (this.searchForm[this.pk]) {
        this.getDetail();
      }
    },
    getDetail() {
      let _this = this;

      this.$http.request({
        url: this.detailOptions.detailUrl.replace('{id}',this.searchForm[this.pk]),
        method: this.detailOptions.detailMethod ? this.detailOptions.detailMethod : 'get',
        datas: {[this.pk]: this.searchForm[this.pk]},
        // datas:null,
        success(res) {
          _this.info = res ? JSON.parse(JSON.stringify(res)) : null;
        }
      });
    },

    //自定义请求接口
    toApi(option, scopeRow, data,tableData) {
      this.$emit('toApi',option,scopeRow, data,tableData)
    },

    //查看详情
    toDetail(option, scopeRow, data,tableData) {
      this.$emit('toDetail',option, scopeRow, data,tableData)
    },

    toList(option, scopeRow, data,tableData){
      this.$emit('toList',option, scopeRow, data,tableData)
    },

    toDiy(option, scopeRow, data,tableData){
      this.$emit('toDiy',option, scopeRow, data,tableData)
    },

    //编辑
    toEdit(option, scopeRow, data,tableData) {
      this.$emit('toEdit',option, scopeRow, data,tableData);
    },
  }
};
</script>
