<template>
	<td :colspan="colspan" class="el-descriptions-item__cell el-descriptions-item__content"><slot></slot></td>
</template>

<script>
export default {
	name: "ZhbTd",
	data() {
		return {}
	},
	props:{
		colspan:{
			type:Number,
			default:1
		}
	},
	created() {
	},
	mounted() {
	},
	methods: {}
}
</script>
