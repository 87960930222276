<template>
    <el-button :type="type" :size="bSize" v-if="isShow" icon="el-icon-plus">{{ sLabel }}</el-button>
</template>

<script>
export default {
    name: "AddButton",
    data() {
        return {
            sLabel: '添加',
            bSize:''
        }
    },
    props: {
        role: '',
        label: {
            type: String,
            default: '添加'
        },
        type: {
            type: String,
            default: 'primary'
        },
        size: {
            type: String,
            default: ''
        },
	    permission: {
		    type:Array,
		    default(){
			    return null
		    },
	    },
    },
    created() {
        this.sLabel = this.label;
        this.bSize = this.size;
    },
    watch: {
        label(n, o) {
            this.sLabel = n;
        },
        size(n, o) {
            this.bSize = n;
        }
    },
    computed: {
        isShow() {
	        if(this.role) {
		        return this.$rule.checkRule(this.role,this.permission ? this.permission : null);
	        }else{
		        return true;
	        }
        }
    }
}
</script>
