import Vue from 'vue';

let vm = new Vue();

import Config from './config'
import {CodeToText} from "element-china-area-data";

//获取数组交集
Array.intersect = function (arr1, arr2) {
  if (Object.prototype.toString.call(arr1) === "[object Array]" && Object.prototype.toString.call(arr2) === "[object Array]") {
    return arr1.filter(function (v) {
      return arr2.indexOf(v) !== -1
    })
  }
}

//数组并集
Array.union = function (arr1, arr2) {
  if (Object.prototype.toString.call(arr1) === "[object Array]" && Object.prototype.toString.call(arr2) === "[object Array]") {
    return arr1.concat(arr2).unique()
  }
}

//数组差集
Array.prototype.minus = function (arr) {
  if (Object.prototype.toString.call(arr) === "[object Array]") {
    var interArr = Array.intersect(this, arr);// 交集数组
    return this.filter(function (v) {
      return interArr.indexOf(v) === -1
    })
  }
}

//数组查找key，并返回index
Array.prototype.objIndexOf = function (key, val) {
  if (typeof key === 'string' && (typeof val === 'string' || typeof val === 'number')) {
    for (let i = 0; i < this.length; i++) {
      if (this[i][key] === val) {
        return i;
      }
    }
  }
  return -1;
}

export default {
  formatUrl(url) {
    if (url) {
      return url.indexOf('http') >= 0 ? url : Config.api.assetsUrl + url;
    } else {
      return ''
    }
  },
  loadJs(src) {
    return new Promise((resolve, reject) => {
      let script = document.createElement('script');
      script.type = "text/javascript";
      script.src = src;
      document.head.appendChild(script);
      
      script.onload = () => {
        resolve();
      }
      script.onerror = () => {
        reject();
      }
    })
  },
  //执行字符串函数
  callString(scope, fn) {
    // var tmp = arguments.callee.toString();
    // var re = /function\s*(\w*)/i;
    // var matches = re.exec(tmp);
    // alert(matches[1]);
    // console.log(scope);
    let _key = this.randomWords();
    
    window[_key] = fn
    
    // console.log(fn);
    // console.log(window[_key])
    // eval('')
    // console.log(window[fn]);
    return window[_key](scope)
    // console.log(fn);
    // return eval(fn(...args))
  },
  
  //省市区code 转 name
  areaCodeToName(codeStr) {
    
    let _codes = [];
    
    let _names = [];
    
    if (!codeStr) {
      return '';
    }
    
    if (typeof codeStr === 'object' && codeStr.length <= 0) {
      return '';
    }
    
    if (typeof codeStr === 'string') {
      _codes = codeStr.split(',');
    } else {
      _codes = codeStr && codeStr.length > 0 ? codeStr : []
    }
    
    for (let i = 0; i < _codes.length; i++) {
      _names.push(CodeToText[_codes[i]]);
    }
    
    return _names.join(' / ');
    
  },
  
  //前面补0
  paddingZero(num, length = 2) {
    //这里用slice和substr均可
    return (Array(length).join("0") + num).slice(-length);
  },
  
  //将scope的数组转str
  scopeArrToStr(scope, arr) {
    let _list = [];
    
    for (let i = 0; i < arr.length; i++) {
      if(i === arr.length - 1){
        if(scope['is_long'] === 1){
          _list.push('长期');
        }else{
          _list.push(this.evalGet(scope, arr[i]));
        }
      }else {
        _list.push(this.evalGet(scope, arr[i]));
      }
    }
    
    return _list.join(' / ');
  },
  
  //控件分割至变量，支持无限个
  splitToParams(context, str, toValue) {
    // console.log(context,str,toValue);
    
    let _arr = str.split(',');
    
    if (toValue && toValue.length > 0) {
      for (let i = 0; i < toValue.length; i++) {
        context[toValue[i]] = _arr[i];
      }
    }
  },
  
  //判断按钮是否显示
  checkButtonRules(optionShow, scopeRow, rules) {
    // console.log(optionShow,scopeRow,rules);
    if (!this.isUndefined(optionShow)) {
      if (optionShow) {
        if (!this.isUndefined(rules)) {
          // console.log(Object.prototype.toString.call(rules));
          if (Object.prototype.toString.call(rules) === "[object Array]") {
            let _out = [];
            
            for (let i = 0; i < rules.length; i++) {
              let _check = this.callOperator(scopeRow[rules[i].key], rules[i].val, rules[i].operator ? rules[i].operator : '===');
              _out.push(_check);
            }
            
            // console.log(rules);
            
            for(let j=0;j < _out.length;j++){
              if(!_out[j]){
                return false;
              }
            }
            return true;
          } else {
            return this.callOperator(scopeRow[rules.key], rules.val, rules.operator ? rules.operator : '===');
          }
        }
        return true;
      }
      return false;
    } else {
      if (!this.isUndefined(rules)) {
        if (typeof rules === "[object Array]") {
          let _out = true;
          
          for (let i = 0; i < rules.length; i++) {
            _out = this.callOperator(scopeRow[rules[i].key], rules[i].val, rules[i].operator ? rules[i].operator : '===');
          }
          
          return _out;
        } else {
          return this.callOperator(scopeRow[rules.key], rules.val, rules.operator ? rules.operator : '===');
        }
      }
      return true;
    }
  },
  
  //是否空对象
  isNullObject(obj) {
    let _len = Object.keys(obj);
    
    return _len.length === 0
  },
  
  //首字母转大写
  firstToUpper(str) {
    return str.toLowerCase().replace(/( |^)[a-z]/g, (L) => L.toUpperCase());
  },
  
  //逻辑判断
  callOperator(arg1, arg2, operator) {
    // console.log(arg1,arg2,operator);
    // console.log(arg1 === arg2);
    
    operator = this.isUndefined(operator) ? '===' : operator;
    
    switch (operator) {
      case '<':
        return arg1 < arg2;
      case '<=':
        return arg1 <= arg2;
      case '>':
        return arg1 > arg2;
      case '>=':
        return arg1 >= arg2;
      case '!==':
        return arg1 !== arg2;
      case 'in':
        return arg2.indexOf(arg1) >= 0;
      case 'not in':
        return arg2.indexOf(arg1) < 0;
      default:
        return arg1 === arg2;
    }
  },
  
  //对象转query字符串
  obj2str(obj) {
    if (typeof obj === 'object') {
      let _arr = [];
      
      for (let i in obj) {
        _arr.push(i + '=' + obj[i]);
      }
      
      return _arr.join('&');
      
    } else {
      throw new Error("parameter must be an object");
    }
  },
  
  //深拷贝
  deepCopy(data) {
    if (typeof data !== 'string') {
      return JSON.parse(JSON.stringify(data));
    }
    
    return data;
  },
  
  // 点 链接符，获取对象值，如：let _obj = {aaa:{bbb:{ccc:{ddd:'==='}}}}   evalGet(_obj,'aaa.bbb.ccc.ddd');
  evalGet(obj, target) {
    let scope = obj;
    
    let _arr = target.split('.');
    
    if (_arr.length > 1) {
      for (let i = 0; i < _arr.length; i++) {
        if (scope) {
          scope = scope[_arr[i]] || scope[_arr[i]] === 0 ? scope[_arr[i]] : '';
        }
      }
    } else {
      scope = scope[_arr[0]];
    }
    
    return scope;
  },
  
  //随机字母，大小写
  randomWords(n = 6) {
    //创建26个字母数组
    let arr = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
    let idvalue = '';
    for (let i = 0; i < n; i++) {
      idvalue += arr[Math.floor(Math.random() * 26)];
    }
    return idvalue;
  },
  success(title) {
    vm.$message({
      message: title,
      type: 'success'
    });
  },
  warning(title) {
    vm.$message({
      message: title,
      type: 'warning'
    });
  },
  error(title) {
    vm.$message({
      message: title,
      type: 'error'
    });
  },
  
  //判断是否微信头像，微信头像地址无文件后缀
  isWxImg(img) {
    if (img) {
      if (!/\.(gif|jpg|jpeg|png|GIF|JPEG|JPG|PNG)$/.test(img)) {
        return true;
      }
      
      return false;
    } else {
      return false;
    }
  },
  
  //笛卡尔积，用于sku，array的格式忘了，2年前封装的，已找不到使用例子
  calcDescartes(array) {
    
    let res = [];
    let _arr = [];
    let skus = [];
    
    for (let i = 0; i < array.length; i++) {
      if (array[i].spec_value.length > 0) {
        _arr.push(array[i].spec_value);
      }
    }
    
    if (_arr.length > 0) {
      if (_arr.length < 2) {
        
        for (let i = 0; i < _arr[0].length; i++) {
          res.push([_arr[0][i]]);
        }
      } else {
        res = [].reduce.call(_arr, function (col, set) {
          let _res = [];
          
          col.forEach(function (c) {
            set.forEach(function (s) {
              let t = [].concat(Array.isArray(c) ? c : [c]);
              t.push(s);
              _res.push(t);
              
            })
          });
          
          return _res;
        });
      }
      
      for (let k = 0; k < res.length; k++) {
        let _sku = [];
        for (let j = 0; j < res[k].length; j++) {
          _sku.push({
            id: array[j].id,
            name: array[j].name,
            value_id: res[k][j].id,
            value_name: res[k][j].name
          });
        }
        skus.push({spec: _sku, price: 0.00, stock: 0, code: '', img: ''});
      }
      
      return skus;
    }
    
    return skus;
  },
  
  
  checkParams(rule, val) {
    for (let item in rule) {
      if (rule[item].check === 'require') {
        if (this.isUndefined(val[item].trim())) {
          console.error('param ' + item + ' must be require!');
          return false;
        }
      }
      
      if (typeof val[item] !== rule[item].type && !this.isUndefined(val[item])) {
        console.error('param ' + item + ' must be ' + rule[item].type + '!');
        return false;
      }
    }
    return true;
  },
  formatPrice(price) {
    return (Math.floor(Number(price) * 100) / 100).toFixed(2);
  },
  
  //判断是否为空
  isUndefined(variable) {
    return (typeof variable == 'undefined' || variable === null || variable === '');
  },
  
  sessionSet(key, val) {
    if ((typeof val === 'string' || typeof val === 'number') && typeof key === 'string') {
      return sessionStorage.setItem(key, val);
    } else {
      console.error('value & key must be a string!');
    }
    return false;
  },
  sessionGet(key) {
    if (typeof key === 'string') {
      return sessionStorage.getItem(key);
    } else {
      console.error('key must be a string!');
    }
    return false;
  },
  sessionDel(key) {
    if (typeof key === 'string') {
      return sessionStorage.removeItem(key);
    } else {
      console.error('key must be a string!');
    }
    return false;
  },
  sessionClear() {
    return sessionStorage.clear();
  },
  
  localSet(key, val) {
    if ((typeof val === 'string' || typeof val === 'number') && typeof key === 'string') {
      return localStorage.setItem(key, val);
    } else {
      console.error('value & key must be a string!');
    }
    return false;
  },
  localGet(key) {
    if (typeof key === 'string') {
      return localStorage.getItem(key);
    } else {
      console.error('key must be a string!');
    }
    return false;
  },
  localDel(key) {
    if (typeof key === 'string') {
      return localStorage.removeItem(key);
    } else {
      console.error('key must be a string!');
    }
    return false;
  },
  localClear() {
    return localStorage.clear();
  },
  
  //复制，不依赖第三方
  copy(data) {
    let oInput = document.createElement('input');
    oInput.value = data;
    oInput.classList.add('copy-input');
    document.body.appendChild(oInput);
    vm.$nextTick(() => {
      oInput.select(); // 选择对象;
      console.log(oInput.value);
      document.execCommand("Copy"); // 执行浏览器复制命令
      vm.$message({
        message: '复制成功',
        type: 'success'
      });
      oInput.remove();
    });
  },
  
  //判断是否手机浏览器
  isMobile() {
    let u = navigator.userAgent, app = navigator.appVersion;
    let out = {
      versions: {
        trident: u.indexOf('Trident') > -1, //IE内核
        presto: u.indexOf('Presto') > -1, //opera内核
        webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
        gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') === -1, //火狐内核
        mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
        ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
        android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //android终端或uc浏览器
        iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
        iPad: u.indexOf('iPad') > -1, //是否iPad
        webApp: u.indexOf('Safari') === -1 //是否web应该程序，没有头部与底部
      }, language: (navigator.browserLanguage || navigator.language).toLowerCase()
    }
    return out;
  },
  
  removal(array) {
    let _arr = [];
    
    for (let i = 0; i < array.length; i++) {
      if (_arr.indexOf(array[i]) < 0) {
        _arr.push(array[i]);
      }
    }
    
    return _arr;
  }
}
