<template>
    <el-row :gutter="0">
        <el-col :xl="12" :lg="14" :md="24">
            <el-radio-group v-model="radio" @change="changeRadio">
                <el-radio label="">全部</el-radio>
                <el-radio :label="1">本月</el-radio>
                <el-radio :label="2">近30天</el-radio>
                <el-radio :label="3">自定义</el-radio>
            </el-radio-group>
        </el-col>
        <el-col :xl="12" :lg="10" :md="24">
            <el-date-picker
                style="width: 100%;"
                v-model="values"
                type="daterange"
                align="right"
                unlink-panels
                :clearable="false"
                :editable="false"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="pickerOptions"
                @change="change" v-if="radio === 3">
            </el-date-picker>
        </el-col>
    </el-row>
</template>

<script>
export default {
    name: "PushTime",
    data(){
        return{
            radio:'',
            values:'',
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            radioList:[
                {
                    start:this.$moment().startOf('month').format('YYYY-MM-DD 00:00:00'),
                    end:this.$moment().format('YYYY-MM-DD 23:59:59')
                },
                {
                    start:this.$moment().subtract(29, 'days').format('YYYY-MM-DD 00:00:00'),
                    end:this.$moment().format('YYYY-MM-DD 23:59:59')
                }
            ]
        }
    },
    model:{
        prop:'value',
        event:'change'
    },
    props: {
        value: {
            type:String,
            default: ''
        },
    },
    created() {
        console.log(this.radioList);
        if(this.value){
            let _times = this.value.split(',');
            let _timeArr = [];
            _timeArr.push(this.$moment(_times[0]).toDate());
            _timeArr.push(this.$moment(_times[1]).toDate());

            this.values = _timeArr;

            this.radio = 3;

            for(let i=0;i < this.radioList.length;i++){
                if(this.radioList[i].start === _times[0] && this.radioList[i].end === _times[1]){
                    this.radio = i+1;
                    break;
                }
            }

        }else{
            this.radio = '';
            this.values = ''
        }
        // this.values = this.value ? this.$moment(this.value).toDate() : '';
    },
    watch:{
        value(n,o){
            // this.values = n ? this.$moment(n).toDate() : '';

            console.log(n);
            if(n){
                let _times = n.split(',');
                let _timeArr = [];
                _timeArr.push(this.$moment(_times[0]).toDate());
                _timeArr.push(this.$moment(_times[1]).toDate());

                this.values = _timeArr;

            }else{
                this.values = '';
                this.radio = '';

                this.$emit('change','');
            }
        },
    },
    methods:{
        change(e){
            let _start = this.$moment(e[0]).format('YYYY-MM-DD 00:00:00');
            let _end = this.$moment(e[1]).format('YYYY-MM-DD 23:59:59');

            this.$emit('change',_start+','+_end);
        },
        changeRadio(e){
            let _start = '';
            let _end = '';
            if(e === 1) {
                _start = this.radioList[0].start;
                _end = this.radioList[0].end;
            }else if(e === 2){
                _start = this.radioList[1].start;
                _end = this.radioList[1].end;
            }

            this.$emit('change',_start+','+_end);
        }
    }
}
</script>
