export default {
  '/space': {
    name: '钉钉存储空间',
    // default:'/system/menu/list'
    listRouter: '/space',
    editRouter: '/space/edit',
    addRouter: '/space/add',
    detailRouter: '',
    editRole: 'dingding:space:edit',
    addRole: 'dingding:space:add',
    delRole: 'dingding:space:delete',
    // delDisabled:{rules:{key:'is_system',val:1}},
    // editDisabled:{rules:{key:'is_system',val:1}},
    //顶部右侧按钮
    topRightButtons: [
      {label:'同步权限',role:'dingding:folder:edit',icon:'el-icon-refresh',type:'api',showConfirm:true,title:'编辑按钮权限',path:'/SpaceFolder/syncFolder',size:'mini',btnType:'danger',showLoading:true},
    ],
    // headerTip:'部门信息数据来自于钉钉<br/>=重要提示=：钉钉官方接口中，无法获取下级部门信息，所以在钉钉端的部门设置中，一定要把所有部门都设置成同一级！！这样才能获取到所有部门；至于部门的上下级关系，由本系统维护。',
    list: {
      com: '',
      options: {
        listUrl: '/Space/listPage',
        statusUrl: '/Space/changeStatus',
        delUrl: '/Space/del',
        method: 'post',
        delMethod: 'post',
        pk: 'id',
        data: {pageSize: 15, orderBy: 'create_time', sortBy: 'desc'},
        showDept: false,
        showAdd: true,
        showDel: false,
        showEdit: true,
        showDetail: false,
        pageKey: 'page',
        pageSizeKey: 'pageSize',
        listKey: 'list',
        listTree: true,
        showPage: false,
        expandAll: true
        // optionsWidth:300
      },
      params: [
        {name: '空间/文件夹名称', value: 'name', width: 200},
        {name: '文件夹ID', value: 'folderId'},
        {name: '空间ID', value: 'spaceId'},
        // {name: '是否支持被列入最近使用列表', value: 'can_record_recent_file', type: 'bool'},
        {name: '创建时间', value: 'create_time', width: 170},
      ],
      extButtons: [
        {label:'添加文件夹',role:'dingding:folder:add',icon:'el-icon-key',type:'add',isPid:true,title:'添加文件夹',path:'/space/folder',color:'orange-1',btnType:'text',rules:{key:'type',val:'FOLDER',operator:'!=='},pidKey:'space_id'},
        {label:'编辑',role:'dingding:folder:edit',icon:'el-icon-key',type:'edit',isPid:true,title:'添加文件夹',path:'/space/folder',color:'orange-1',btnType:'text',rules:{key:'type',val:'FOLDER',operator:'==='},pidKey:'space_id'},
      ]
    },
    edit: {
      com: '',
      options: {
        editUrl: '/Space/edit',
        detailUrl: '/Space/detail',
        addUrl: '/Space/add',
        editMethod: 'post',
        addMethod: 'post',
        detailMethod: 'post',
        pk: 'id',
        data:null,
        copyData: false
      },
      params: [
        {name: '空间名称', value: 'name', edit: true, rq: true},
        {name: '是否支持搜索', value: 'can_search', def: 0, edit: true, rq: true, type: 'bool'},
        {name: '是否支持重命名', value: 'can_rename', def: 0, edit: true, rq: true, type: 'bool'},
        {name: '是否列入最近使用', value: 'can_record_recent_file', def: 0, edit: true, rq: true, type: 'bool'},
        {name: '类型',value:'owner_type',def:'USER',edit: true, rq: true,type:'radio',options:[{label:'USER',value:'USER'},{label:'APP',value:'APP'}]},
        {name: '状态', value: 'state', type: 'state', edit: true, rq: true},
      ]
    },
    add: {
      com: '',
    },
    detail: {
      com: '',
      options: {
        method: 'get'
      },
    }
  },
  '/space/folder': {
    name: '存储空间文件夹',
    // default:'/system/menu/list'
    listRouter: '/space/folder',
    editRouter: '/space/folder/edit',
    addRouter: '/space/folder/add',
    detailRouter: '',
    editRole: 'dingding:space:edit',
    addRole: 'dingding:space:add',
    delRole: 'dingding:space:delete',
    // delDisabled:{rules:{key:'is_system',val:1}},
    // editDisabled:{rules:{key:'is_system',val:1}},
    //顶部右侧按钮
    topRightButtons: [
      // {label:'同步部门/人员',role:'setting:dept:sync',icon:'el-icon-refresh',type:'api',showConfirm:true,title:'编辑按钮权限',path:'/Dept/syncRemote',size:'mini',btnType:'danger',showLoading:true},
    ],
    // headerTip:'部门信息数据来自于钉钉<br/>=重要提示=：钉钉官方接口中，无法获取下级部门信息，所以在钉钉端的部门设置中，一定要把所有部门都设置成同一级！！这样才能获取到所有部门；至于部门的上下级关系，由本系统维护。',
    list: {
      com: '',
      options: {
        listUrl: '/SpaceFolder/listPage',
        statusUrl: '/SpaceFolder/changeStatus',
        delUrl: '/SpaceFolder/del',
        method: 'post',
        delMethod: 'post',
        pk: 'id',
        data: {pageSize: 15, orderBy: 'create_time', sortBy: 'desc'},
        showDept: false,
        showAdd: true,
        showDel: false,
        showEdit: true,
        showDetail: false,
        pageKey: 'page',
        pageSizeKey: 'pageSize',
        listKey: 'list',
        listTree: true,
        showPage: false,
        expandAll: true
        // optionsWidth:300
      },
      params: [
        {name: '空间名称', value: 'name', width: 200},
        {name: '是否支持搜索', value: 'can_search', type: 'bool'},
        {name: '是否支持重命名空间名称', value: 'can_rename', type: 'bool'},
        {name: '是否支持被列入最近使用列表', value: 'can_record_recent_file', type: 'bool'},
        {name: '创建时间', value: 'create_time', width: 170},
      ],
      extButtons: [
      ]
    },
    edit: {
      com: '',
      options: {
        editUrl: '/SpaceFolder/edit',
        detailUrl: '/SpaceFolder/detail',
        addUrl: '/SpaceFolder/add',
        editMethod: 'post',
        addMethod: 'post',
        detailMethod: 'post',
        pk: 'id',
        data: {conflictStrategy:'AUTO_RENAME'},
        copyData: false
      },
      params: [
        // {name: '空间Id', value: 'pid', edit: true, rq: true,readonly:true},
        {name: '文件夹名称', value: 'name', edit: true, rq: true},
        {name: '是否可写', value: 'is_write', edit: true, rq: true,type:'bool'},
      ]
    },
    add: {
      com: '',
      options: {
        editUrl: '/SpaceFolder/edit',
        detailUrl: '/SpaceFolder/detail',
        addUrl: '/SpaceFolder/add',
        editMethod: 'post',
        addMethod: 'post',
        detailMethod: 'post',
        pk: 'id',
        data: {conflictStrategy:'AUTO_RENAME'},
        copyData: false
      },
      params: [
        {name: '空间Id', value: 'pid', edit: true, rq: true,readonly:true},
        {name: '文件夹名称', value: 'name', edit: true, rq: true},
        {name: '是否可写', value: 'is_write', edit: true, rq: true,type:'bool'},
      ]
    },
    detail: {
      com: '',
      options: {
        method: 'get'
      },
    }
  },
}
