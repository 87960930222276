<template>
    <el-select v-model="values" clearable placeholder="请选择" @change="change">
        <el-option
            v-for="item in list"
            :key="item.id"
            :label="item.name"
            :value="item.id"></el-option>
    </el-select>
</template>

<script>
export default {
    name: "SortSelect",
    data(){
        return{
            list:[
                {name:'1级置顶',id:3},
                {name:'2级置顶',id:2},
                {name:'3级置顶',id:1},
            ],
            values:'',
            name:''
        }
    },
    model:{
        prop:'value',
        event:'change'
    },
    props:{
        value:{
            type:[String,Number],
            default:''
        },
    },
    created() {
        this.values = this.value === 0 ? '' : this.value;
    },
    watch:{
        value(n,o){
            this.values = n === 0 ? '' : n;
        }
    },
    mounted() {
    },
    methods:{
        change(e){
            this.$emit("change", e);
        },
    }
}
</script>
