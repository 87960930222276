<template>
  <div class="h100 hide flex flex-col">
		<slot name="tip"></slot>
    <div class="flex1 hide">
      <main-table-list ref="tableList" :datas="{...listSearchForm,...datas}" @toApi="toApi" @toEdit="toEdit" @toDetail="toDetail" @toList="toList" @toDiy="toDiy" @toProgram="toProgram" @toListExport="toListExport" @toListShenpi="toListShenpi" @toListReShenpi="toListReShenpi" :path="comPath" :com-list-options="comListOptions" :com-params="comParams" :com-table-list="comTableList" :com-list-params="comListParams" :info="info">
				<div class="margin-bottom-20 pd-b-5 bd-b-w-1 bd-style-solid bd-dark-9" v-if="listOptions.showSearch" slot="search">
					<top-search @toSearch="toSearch" @toReset="toReset" :path="comPath" :com-list-options="comListOptions" :com-params="comParams" :com-table-list="comTableList" :com-list-params="comListParams"></top-search>
				</div>
			</main-table-list>
    </div>
  </div>
</template>

<script>

export default {
  name: "list",
  data() {
    return {
      showAddDialog: false,
      showDetailDialog: false,
      showExtDialog:false,

      comPath: '',
      params: null,
      listParams: null,
      listOptions: null,
      listSearchForm:{},

      tableList:[],
    };
  },
  props:{
    datas: {
      type: [Array,Object],
      default(){
        return {}
      }
    },
    info:{
      type:[Object,Array],
      default(){
        return {}
      }
    },
    path:{
      type:String,
      default:''
    },
    comParams: null,
    comListParams: null,
    comListOptions: null,

    comTableList:[],
  },
  created() {

    //获取配置
    let _config = this.$baseConfig

    //获取当前路径
    this.comPath = this.path ? this.path : this.$route.path;

    //获取路径全部配置
    this.params = this.comParams ? this.comParams : (this.comPath ? _config[this.comPath] : null);

    //获取列表参数
    this.listParams = this.comListParams ? this.comListParams : (this.params ? this.params.list : null);
    //获取列表配置
    this.listOptions = this.comListOptions ? this.comListOptions : (this.params && this.params.list ? this.params.list.options : null);
  },
  mounted() {

  },
  methods: {
    //搜索
    toSearch(data) {
      this.listSearchForm = data;
    },

    //重置搜索
    toReset(data) {
      this.listSearchForm = data;
    },

    success(){
      this.getList();

      this.$refs.tableList.getList();
      this.$refs.tableList.getSide();
      this.$refs.tableList.cleanSelect();
    },

    //获取列表
    getList(){
      this.$refs.tableList.getList();
    },

    //自定义请求接口
    toApi(option, scopeRow, data,tableData) {
      this.$emit('toApi',option,scopeRow, data,tableData)
    },

	  toListExport(option, scopeRow, data,tableData,ids,listExportUrl) {
      this.$emit('toListExport',option, scopeRow, data,tableData,ids,listExportUrl)
    },

		toListShenpi(option, scopeRow, data,tableData,ids,listShenpiUrl) {
			this.$emit('toListShenpi',option, scopeRow, data,tableData,ids,listShenpiUrl)
		},

		toListReShenpi(option, scopeRow, data,tableData,ids,listReShenpiUrl) {
			this.$emit('toListReShenpi',option, scopeRow, data,tableData,ids,listReShenpiUrl)
		},

    //查看详情
    toDetail(option, scopeRow, data,tableData) {
      this.$emit('toDetail',option, scopeRow, data,tableData)
    },

    toList(option, scopeRow, data,tableData){
      this.$emit('toList',option, scopeRow, data,tableData)
    },

    toDiy(option, scopeRow, data,tableData){
      this.$emit('toDiy',option, scopeRow, data,tableData)
    },

	  toProgram(option, scopeRow, data,tableData){
      this.$emit('toProgram',option, scopeRow, data,tableData)
    },

    //编辑
    toEdit(option, scopeRow, data,tableData) {
      this.$emit('toEdit',option, scopeRow, data,tableData);
    },
  },
  destroyed() {
    // if (this.time) {
    //   clearInterval(this.time);
    //   this.time = null;
    // }
  }
};
</script>
