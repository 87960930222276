<template>
	<el-dialog title="事项延期" :visible.sync="isShow" width="700px" append-to-body @close="onClose" @open="onOpen">
		<el-form :model="formData" :rules="rules" ref="form" label-width="100px">
			<el-form-item label="办理状态" prop="end_time">
				<push-time v-model="formData.end_time" :start-time="info.end_time"></push-time>
			</el-form-item>
			<el-form-item label="情况说明" prop="remark" :rules="[{required:true,message:'情况说明必填'}]">
				<el-input type="textarea" v-model="formData.remark"></el-input>
			</el-form-item>
		</el-form>
		<span slot="footer" class="dialog-footer">
			<el-button @click="isShow = false">关闭</el-button>
	    <el-button type="primary" @click="toSubmit">确定</el-button>
	  </span>
	</el-dialog>
</template>

<script>
export default {
	name: "DelayDialog",
	data() {
		return {
			isShow:false,
			formData:{
				id:'',
				end_time:'',
				remark:''
			},
			rules:{
				end_time:[{required: true,message:'时间不能为空'}]
			}
		}
	},
	props:{
		visible:{
			type:Boolean,
			default:false
		},
		info:{
			type:[Object,Array],
			default(){
				return null
			}
		},
		id:{
			type:String,
			default:''
		}
	},
	model:{
		prop:'visible',
		event:'change'
	},
	created() {
		this.isShow = this.visible;
		this.formData.id = this.id ? this.id : ''
		this.formData.end_time = this.info.end_time ? this.info.end_time : ''
	},
	mounted() {

	},
	methods: {
		onChange(){
			this.formData.remark = '';
		},
		onOpen(){
			let _this = this;
			this.$nextTick(()=>{
				_this.formData.id = this.id ? this.id : '';
				_this.formData.end_time = _this.info.end_time ? _this.info.end_time : ''
				_this.$refs['form'].clearValidate()
			})
		},
		onClose(){
			this.formData.id = '';
			this.formData.end_time = '';
			this.formData.remark = '';
			this.$emit('update:visible', false);
		},
		toSubmit(){
			let _this = this;
			this.$refs['form'].validate((valid) => {
				if (valid) {
					_this.$http.request({
						url:'/Program/toDelay',
						datas:_this.formData,
						success(res){
							_this.$message.success('操作成功');

							_this.$emit('success');

							_this.isShow = false;
						}
					})
				} else {
					_this.$message.error('请填写完整表单')
					return false;
				}
			});
		}
	},
	watch:{
		visible(n,o){
			this.isShow = n;
		}
	}
}
</script>
