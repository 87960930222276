let _week = [
    {id:-1,name:'不限制'},
    {id:0,name:'周日'},
    {id:1,name:'周一'},
    {id:2,name:'周二'},
    {id:3,name:'周三'},
    {id:4,name:'周四'},
    {id:5,name:'周五'},
    {id:6,name:'周六'},
];

let _day = [{id:-1,name:'不限制'}];
for(let i=1;i <= 31;i++){
    _day.push({id:i,name:i+' 号'});
}

let _hour = [{id:-1,name:'不限制'}];
for(let i=0;i <= 23;i++){
    _hour.push({id:i,name:i+' 时'});
}
export default {
    '/tools/backup':{
        name: '数据备份',
        listRouter: '/tools/backup',
        editRouter: '/tools/backup/edit',
        addRouter: '/tools/backup/add',
        detailRouter: '',
        editRole: 'system:menu:edit',
        addRole: 'system:menu:add',
        delRole: 'system:menu:delete',
        topRightButtons:[
            {size:'mini',btnType:'primary',icon:'el-icon-refresh',label:'立即备份数据',type:'api',path:'/Backup/add',data:null},
            {size:'mini',btnType:'danger',icon:'el-icon-delete',label:'清空备份数据',type:'api',path:'/Backup/delAll',data:null,showConfirm:true,confirmContent:'是否要清空所有备份文件？'}
        ],
        headerTip:'数据备份的基础由计划任务定时执行，也可以手动备份<br/>[手动备份]会存在显示延时，需要等待一会儿才能在列表中看到最新的备份文件，具体延时时间由数据量大小决定<br/>[删除备份]请谨慎操作，可以选择性的删除时间较久的备份文件',
        list: {
            com: '',
            options: {
                listUrl: '/Backup/listAll',
                statusUrl: '/SystemRole/changeStatus',
                delUrl: '/Backup/del',
                method: 'post',
                delMethod: 'post',
                pk:'id',
                delPk:'name',
                data: {orderBy:'sort',sortBy:'asc'},
                showDept: false,
                showAdd: false,
                showDel: true,
                showEdit: false,
                showDetail: false,
                showPage:false,
                pageKey:'page',
                pageSizeKey:'pageSize',
                listKey:null,
                listTree:false,
                optionsWidth:100
            },
            params: [
                {name: '文件名', value: 'name'},
                {name: '文件地址', value: 'url',type:'download',linkUrl:'http://api.yldc.miihe.com/static'},
                {name: '文件大小', value: 'size',append:'KB'},
                {name: '备份时间', value: 'file_time'},
            ]
        },
        edit: {
            com: '',
            options: {
                editUrl: '/SystemRole/edit',
                detailUrl: '/SystemRole/detail',
                addUrl: '/SystemRole/add',
                editMethod:'post',
                addMethod:'post',
                detailMethod:'post',
                pk: 'id',
                data: null,
                copyData:false
            },
            params: [
                {name: '内容介绍', value: 'path',type:'editor', edit: true, rq: true,max:50},
            ]
        },
        add: {
            com: '',
        },
        detail: {
            com: '',
        }
    },
    '/tools/cron':{
        name: '计划任务',
        listRouter: '/tools/cron',
        editRouter: '/tools/cron/edit',
        addRouter: '/tools/cron/add',
        detailRouter: '',
        editRole: 'tools:cron:edit',
        addRole: 'tools:cron:add',
        delRole: 'tools:cron:delete',
        headerTip:'计划任务是系统按照一定周期自动执行程序的功能，此功能非常重要，如需修改，请联系开发人员',
        list: {
            com: '',
            options: {
                listUrl: '/Cron/listPage',
                statusUrl: '/Cron/changeStatus',
                delUrl: '/Cron/del',
                method: 'post',
                delMethod: 'post',
                pk:'id',
                // delPk:'name',
                data: {orderBy:'create_time',sortBy:'asc'},
                showDept: false,
                showAdd: true,
                showDel: true,
                showEdit: true,
                showDetail: false,
                showBack:true,
                showPage:true,
                pageKey:'page',
                pageSizeKey:'pageSize',
                listKey:'list',
                listTree:false,
                optionsWidth:350
            },
            params: [
                {name: '计划名称', value: 'name'},
                {name: '文件名称', value: 'filename'},
                {name: '执行周期', value: 'time_des'},
                {name: '备注', value: 'remark'},
                {name: '最后执行时间', value: 'last_time'},
                {name: '下次执行时间', value: 'next_time',type:'time'},
                {name: '状态', value: 'state', type: 'state'},
            ],
            extButtons: [
                {label:'执行日志',role:'tools:cron:log',icon:'el-icon-view',type:'url',title:'计划执行日志',path:'/tools/cron/log',target:'_self',btnType:'text'},
                {label:'立即执行',role:'tools:cron:todo',icon:'el-icon-s-promotion',type:'api',title:'计划执行日志',path:'/Cron/todo',target:'_self',btnType:'text',showConfirm:true},
            ],
        },
        edit: {
            com: '',
            options: {
                editUrl: '/Cron/edit',
                detailUrl: '/Cron/detail',
                addUrl: '/Cron/add',
                editMethod:'post',
                addMethod:'post',
                detailMethod:'post',
                pk: 'id',
                data: null,
                copyData:false
            },
            params: [
                {name: '任务名称', value: 'name',edit: true, rq: true,max:50},
                {name: '类型', value: 'type',type:'radio',options:[{value:'system',label:'系统'},{value:'user',label:'自定义'}],edit: true, rq: true,max:50},
                {name: '文件名称', value: 'filename',edit: true, rq: true,max:50},
                // {name: '执行周期', value: 'filename',type:'driver',edit: false, rq: false},
                {name: '每周几', value: 'weekday',type:'select',def:-1,edit: true, rq: true,options:_week,tip: '设置星期几执行本任务，本设置会覆盖下面的“日”设定',},
                {name: '每月几号', value: 'day',type:'select',def:-1,edit: true, rq: true,options:_day,tip: '设置哪一日执行本任务'},
                {name: '每天几时', value: 'hour',type:'select',def:-1,edit: true, rq: true,options:_hour,tip: '设置哪一小时执行本任务'},
                {name: '每几分', value: 'minute',def:'0',edit: true, rq: true,max:50,lineTip:'设置哪些分钟执行本任务，至多可以设置 12 个分钟值，多个值之间用半角逗号 "," 隔开，留空为不限制',rule:{patt:'minute',message:'只能是整数或","'}},
                {name: '备注', value: 'remark',edit: true, rq: false,max:50},
                {name: '状态', value: 'state', type: 'state', edit: true, rq: true},
            ]
        },
        add: {
            com: '',
        },
        detail: {
            com: '',
        }
    },
    '/tools/cron/log':{
        name: '计划执行日志',
        listRouter: '/tools/cron/log',
        editRouter: '/tools/cron/log/edit',
        addRouter: '/tools/cron/log/add',
        detailRouter: '',
        editRole: 'tools:cron:edit',
        addRole: 'tools:cron:add',
        delRole: 'tools:cron:delete',
        // topRightButtons:[
        //     {size:'mini',btnType:'primary',icon:'el-icon-refresh',label:'立即备份数据',type:'api',path:'/Backup/add',data:null},
        //     {size:'mini',btnType:'danger',icon:'el-icon-delete',label:'清空备份数据',type:'api',path:'/Backup/delAll',data:null,showConfirm:true,confirmContent:'是否要清空所有备份文件？'}
        // ],
        list: {
            com: '',
            options: {
                listUrl: '/CronLog/listPage',
                statusUrl: '/CronLog/changeStatus',
                delUrl: '/CronLog/del',
                method: 'post',
                delMethod: 'post',
                pk:'id',
                // delPk:'name',
                data: {orderBy:'create_time',sortBy:'desc'},
                showBack:true,
                showDept: false,
                showAdd: false,
                showDel: false,
                showEdit: false,
                showDetail: false,
                showPage:true,
                pageKey:'page',
                pageSizeKey:'pageSize',
                pageSize:20,
                listKey:'list',
                listTree:false,
                copyQueryData:[{from:'id',to:'cid'}]
            },
            params: [
                {name: '计划名称', value: 'name'},
                {name: '文件名称', value: 'filename'},
                {name: '返回信息', value: 'remark'},
                {name: '执行时间', value: 'create_time'},
                {name: '执行结果', value: 'state',type:'custom-select',options:[{val:0,label:'失败',color:'danger'},{val:1,label:'成功',color:'success'}]},
            ],
        },
        edit: {
            com: '',
            params: []
        },
        add: {
            com: '',
        },
        detail: {
            com: '',
        }
    },
    '/tools/update':{
        name:'更新日志',
        default: '/tools/update',
        listRouter:'/tools/update'
    },
}
