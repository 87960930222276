<template>
  <el-card class="box-card">
    <div slot="header" class="cl">
      <el-tag class="z" size="small" :type="title ? 'primary' : 'info'">{{title ? title : '请选择角色'}}</el-tag>
      <add-button label="保存" size="mini" class="y" rule="system:role:add" @click.native="toSave"></add-button>
    </div>
    <div style="max-height: 500px;overflow-y: auto;">
      <el-tree
        check-strictly
        highlight-current
        :data="list"
        ref="tree"
        show-checkbox
        node-key="id"
        :default-checked-keys="ids"
        :props="defaultProps"
        :default-expand-all="defaultExpandAll"
        @node-click="onChoose"
        @check="onCheck">
      </el-tree>
    </div>
  </el-card>
</template>

<script>
export default {
  name: "MenuTreeSelect",
  data() {
    return {
      list:[],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      ids:[]
    }
  },
  props:{
    labelKey:'label',
    valueKey:'id',
    childKey:'children',
    defaultExpandAll:{
      type:Boolean,
      default:true
    },
    title:'',
    id:{
      type:[String,Number],
      default:''
    }
  },

  model:{
    prop:'value',
    event:'change'
  },

  created() {
    this.defaultProps.children = this.childKey;
    this.defaultProps.label = this.labelKey;
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList(){
      let _this = this;

      this.$http.request({
        url:'/api-admin/api.admin/v1/menus',
        method:'get',
        datas:{queryMode:'allTree'},
        success(res){
          _this.list = res;
        }
      })
    },

    getMenuIds(){
      let _this = this;

      if(this.id) {
        this.$http.request({
          url: '/api-admin/api.admin/v1/roles/' + this.id + '/menu_ids',
          method: 'get',
          success(res) {
            _this.ids = res;
          }
        })
      }
    },

    onCheck(e,b){
      this.ids = b.checkedKeys;
    },

    onChoose(e){
      if(!this.id){
        this.$message.error('请先选择角色');
        return;
      }
      this.$emit('change',e.id);
    },

    toSave(){
      let _this = this;

      if(!this.id){
        this.$message.error('请选择角色');
        return;
      }

      if(this.id) {
        this.$http.request({
          url: '/api-admin/api.admin/v1/roles/' + this.id + '/menu_ids',
          method: 'put',
          datas: {menuIds: this.ids},
          success(res) {
            _this.$message.success('保存成功');
          }
        })
      }
    }
  },
  watch:{
    id(n,o){
      this.$refs.tree.setCheckedKeys([]);
      this.ids = [];
      this.getMenuIds();
    }
  }
}
</script>
