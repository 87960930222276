import Utils from './utils'
import Router from '../router'
import Vue from 'vue';
let vm = new Vue();


export default {
  error(msg,buttonText = '关闭',cb){
    vm.$alert(msg, '提示', {
      confirmButtonText: buttonText,
      showClose:false,
      type:'error',
      callback: action => {
        if(cb){
          cb()
        }
      }
    });
  },
  success(msg,buttonText = '确定',cb){
    vm.$alert(msg, '提示', {
      confirmButtonText: buttonText,
      showClose:false,
      type:'success',
      callback: action => {
        if(cb){
          cb()
        }
      }
    });
  },
  warning(msg,buttonText = '确定',cb){
    vm.$alert(msg, '提示', {
      confirmButtonText: buttonText,
      showClose:false,
      type:'warning',
      callback: action => {
        if(cb){
          cb()
        }
      }
    });
  },
  base(msg,buttonText = '确定',cb){
    vm.$alert(msg, '提示', {
      confirmButtonText: buttonText,
      showClose:false,
      callback: action => {
        if(cb){
          cb()
        }
      }
    });
  }
}
