<template>
  <el-dialog :title="title" :visible.sync="isShow" @closed="closed">
    <el-form :model="formData" :rules="rules" ref="form" label-width="150px">
<!--      <el-form-item label="ID" v-if="formData.id">-->
<!--        {{ formData.id }}-->
<!--      </el-form-item>-->
      <el-row :gutter="10">
        <el-col :span="24 / (item.cols ? item.cols : (editOptions.cols ? editOptions.cols : 1))" v-for="(item,index) in editParams">
          <el-form-item :label="item.name" :prop="item.value" :class="item.type ==='editor' ? 'no-line-height' : ''">
            <template v-if="item.edit">
              <type-select v-if="item.type === 'select'" v-model="formData[item.value]"
                           @change="(e)=>$set(formData,item.value,e)" :is-top="item.isTop ? item.isTop : false"
                           :options="item.options ? item.options : null" :data="item.data ? item.data : null"
                           style="max-width: 500px;" :url="item.url ? item.url : null"
                           :method="item.method ? item.method : 'get'" :val-key="item.valKey ? item.valKey : 'id'"
                           :val-label="item.valLabel ? item.valLabel : 'name'"></type-select>
              <company-select v-else-if="item.type === 'company'" v-model="formData[item.value]"
                              @change="(e)=>$set(formData,item.value,e)" style="max-width: 500px;"></company-select>
              <el-input type="textarea" :rows="4" placeholder="请输入内容" v-model="formData[item.value]" v-else-if="item.type === 'textarea'" style="max-width: 500px;"></el-input>
              <head-img-upload v-model="formData.head_img"
                               :img="formData.head_img ? $config.assetsUrl + formData.head_img : ''"
                               v-else-if="item.value === 'head_img'"
                               @change="(e)=>$set(formData,'head_img',e ? e : '')"></head-img-upload>
              <head-img-upload v-model="formData[item.value]"
                               :img="formData[item.value] ? $config.assetsUrl + formData[item.value] : ''"
                               v-else-if="item.type === 'image'"
                               @change="(e)=>$set(formData,item.value,e ? e : '')"></head-img-upload>
              <push-time v-model="formData.todo_time" style="max-width: 500px;"
                         v-else-if="item.value === 'todo_time'"></push-time>
              <push-time v-model="formData.push_time" style="max-width: 500px;"
                         v-else-if="item.value === 'push_time'"></push-time>
              <push-time v-model="formData[item.value]" style="max-width: 500px;" v-else-if="item.type === 'time'"></push-time>
              <year-select v-model="formData.year" style="max-width: 500px;" v-else-if="item.value === 'year'"></year-select>
              <file-upload v-model="formData[item.value]" v-else-if="item.type === 'file'" :files="formData[item.value]"></file-upload>
              <el-switch
                v-model="formData[item.value]"
                active-color="#13ce66"
                :active-value="1"
                :inactive-value="0"
                active-text="启用"
                inactive-text="禁用" v-else-if="item.type === 'state'">
              </el-switch>
              <template v-else-if="item.type === 'totime'">
                <el-input v-model="formData[item.value]" style="max-width: 400px;"></el-input>
                <price-time-select class="margin-left" v-model="formData.time_type" style="width: 90px;"></price-time-select>
              </template>
              <group-select style="max-width: 500px;" v-else-if="item.type === 'group'" v-model="formData[item.value]"></group-select>
              <dept-tree-select style="max-width: 500px;" v-else-if="item.type === 'deptSelect'" v-model="formData[item.value]" :id="formData[item.value]"></dept-tree-select>
              <role-tree-select v-else-if="item.type === 'roleTreeSelect'" v-model="formData[item.value]" :id="formData[pk]"></role-tree-select>
              <role-select v-else-if="item.type === 'roleSelect'" v-model="formData[item.value]"></role-select>
              <base-tree-select :is-top="item.isTop ? item.isTop : false" :top-label="item.topLabel" :url="item.url" v-model="formData[item.value]" v-else-if="item.type === 'baseTreeSelect'" :val-key="item.valKey ? item.valKey : 'id'" :val-label="item.valLabel ? item.valLabel : 'label'" :val-child="item.valChild ? item.valChild : 'children'" style="max-width:500px;"></base-tree-select>
              <template v-else-if="item.type === 'password'">
                <el-input v-model="formData[item.value]" type="password" show-password style="width: 500px;"></el-input>
              </template>
              <icon-dialog v-model="formData[item.value]" v-else-if="item.type === 'iconChoose'"></icon-dialog>
              <el-input v-model="formData[item.value]" style="max-width: 500px;" :maxlength="item.max ? item.max : null" :show-word-limit="item.max" v-else>
                <template slot="prepend" v-if="item.prepend">{{ item.prepend }}</template>
                <template slot="append" v-if="item.append">{{ item.append }}</template>
              </el-input>
              <span class="margin-left dark-7" v-if="item.value === 'sort'">排序数字越小越靠前</span>
              <span class="margin-left" v-else-if="item.tip">{{ item.tip }}</span>
            </template>
            <template v-else>
              {{ formData[item.value] }}
            </template>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer">
      <el-button type="primary" @click="submitForm('form')">保存</el-button>
      <el-button @click="closeDialog">关闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "EditForm",
  data() {
    return {
      formData: {},

      rules: {},

      isShow: false,

      options: null,

      pk: 'id',
      pdid: '',
      mode:'add',
      method:'post',

      editParams: [],
      editOptions: {},
      detailOptions: {},
      editData: {},
      config: null,
    }
  },
  model: {
    prop: 'showDialog',
    event: 'change'
  },
  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    id: {
      type: [Number, String],
      default: ''
    },
    path: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  },
  created() {
    console.log(this.path);
    this.initSetting();

    this.isShow = this.showDialog;
  },
  mounted() {
    this.init();
    this.initRules();
  },
  methods: {
    starchange(e){
      console.log(e)
      this.starGradeArr =e;
    },
    initSetting() {
      let _config = this.$baseConfig

      this.config = _config[this.path];

      this.editParams = this.id ? this.config.edit.params : (this.config.add.params ? this.config.add.params : this.config.edit.params);

      this.editOptions = this.id ? this.config.edit.options : (this.config.add.options ? this.config.add.options : this.config.edit.options);

      this.detailOptions = this.id ? this.config.detail.options : (this.config.add.options ? this.config.add.options : this.config.detail.options);

      this.pk = this.editOptions && this.editOptions.pk ? this.editOptions.pk : 'id';

      this.formData[this.pk] = this.id ? this.id : '';

      this.editData = this.editOptions.data ? this.editOptions.data : {};

      if (this.formData[this.pk]) {
        this.url = this.editOptions.editUrl.replace('{id}',this.formData[this.pk]);
        this.method = this.editOptions.editMethod;
      } else {
        this.url = this.editOptions.addUrl;
        this.method = this.editOptions.addMethod;
      }

      if (this.formData[this.pk]) {
        this.getDetail();
      }
    },
    closeDialog() {
      this.isShow = false;
      this.$emit('change', this.isShow);
    },
    //初始化校验规则
    initRules() {
      for (let i = 0; i < this.editParams.length; i++) {
        if (this.editParams[i].type === 'price') {
          this.rules[this.editParams[i].value].push({
            message: '请填写正确的' + this.editParams[i].name,
            pattern: this.$regexp.money
          });
        } else if (this.editParams[i].type === 'totime') {
          this.rules[this.editParams[i].value].push({
            message: '请填写正确的' + this.editParams[i].name,
            pattern: this.$regexp.integer
          });
        } else if (this.editParams[i].type === 'sort') {
          this.rules[this.editParams[i].value].push({
            message: '请填写正确的' + this.editParams[i].name,
            pattern: this.$regexp.positiveInteger
          });
        } else if (this.editParams[i].type === 'mobile') {
          this.rules[this.editParams[i].value].push({
            message: '请填写正确的' + this.editParams[i].name,
            pattern: this.$regexp.mobile
          });
        } else if (this.editParams[i].type === 'email') {
          this.rules[this.editParams[i].value].push({
            message: '请填写正确的' + this.editParams[i].name,
            pattern: this.$regexp.email
          });
        }else{
          if(this.editParams[i].rule) {
            this.rules[this.editParams[i].value].push({
              message: '请填写正确的' + this.editParams[i].name,
              pattern: this.$regexp[this.editParams[i].rule]
            });
          }
        }

        if (this.editParams[i].rq) {
          this.rules[this.editParams[i].value].push({required: true, message: this.editParams[i].name + '必填'});
        }
      }
    },
    //初始化表单数据
    init() {
      for (let i = 0; i < this.editParams.length; i++) {
        this.rules[this.editParams[i].value] = [];

        if (this.editParams[i].type === 'state') {
          this.$set(this.formData, this.editParams[i].value, 1);
        }
        // else if (this.editParams[i].value === 'status') {
        //   this.$set(this.formData, this.editParams[i].value, '0');
        // } else if (this.editParams[i].value === 'sort') {
        //   this.$set(this.formData, this.editParams[i].value, 0);
        // }
        else {
          //def 为默认值参数
          if (this.editParams[i].edit) {
            this.$set(this.formData, this.editParams[i].value, (this.editParams[i].def === 0 || this.editParams[i].def ? this.editParams[i].def : ''));
          }
        }
      }
    },
    //表单保存
    create() {
      let _this = this;

      let _data = this.$utils.deepCopy(this.formData);

      if(!this.formData.id){
        delete _data.id;
      }

      this.$http.request({
        url: this.url,
        datas: {..._data, ...this.editData},
        method:this.method,
        success(res) {
          _this.$utils.success('保存成功！');

          _this.isShow = false;

          _this.$emit('change', this.isShow);

          _this.$emit('success');
          // _this.$router.back();
        }
      });
    },
    //表单提交
    submitForm(formName) {
      let _this = this;
      if(this.starList){  //starGradeArr 传值
        this.formData.starList = this.starGradeArr;
      }
      this.$refs[formName].validate(valid => {
        if (valid) {
          _this.create();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //获取详情
    getDetail() {
      let _this = this;

      this.$http.request({
        url: this.editOptions.detailUrl.replace('{id}',this.formData[this.pk]),
        method: this.editOptions.detailMethod ? this.editOptions.detailMethod : 'get',
        datas: {[this.pk]: this.formData[this.pk]},
        success(res) {
          _this.formData = res ? JSON.parse(JSON.stringify(res)) : null;

          for (let i = 0; i < _this.editParams.length; i++) {
            if (_this.editParams[i].isNone) {
              _this.$set(_this.formData, _this.editParams[i].value, '');
            } else if (_this.editParams[i].isNull) {
              // _this.formData[_this.editParams[i].value] = null;
              _this.$set(_this.formData, _this.editParams[i].value, null);
            }
          }
        }
      });
    },
    closed() {
      this.isShow = false;

      this.$emit('change', this.isShow);
    }
  },
  watch: {
    // params:{
    //     handler(n,o){
    //         console.log(n);
    //     },
    //     deep:true
    // },
    path(n, o) {
      this.path = n;
      this.initSetting();
      this.init();
      this.initRules();
    },
    id(n, o) {
      this.pkid = n ? n : '';
      this.initSetting();
      this.init();
      this.initRules();
    },
    showDialog(n, o) {
      this.isShow = n;

      // if(n) {
      //     if(this.pkid){
      //         this.formData[this.pk] = this.pkid;
      //
      //         this.getDetail();
      //         this.url = this.setting.editUrl;
      //
      //         // if (this.formData[this.pk]) {
      //         //     this.getDetail();
      //         //     this.url = this.setting.editUrl;
      //         // } else {
      //         //     this.url = this.setting.addUrl;
      //         // }
      //     }else{
      //         this.url = this.setting.addUrl;
      //     }
      // }else{
      //     this.init();
      // }
    }
  }
}
</script>
