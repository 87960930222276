<template>
  <el-form ref="topic-type-form" :model="formData">
    <div>
      <div v-if="type === 1">
        <div class="mg-b-20" v-for="(item,index) in formData.answerList">
          <el-radio v-model="formData.radioCheck" :label="index" @change="changeAnswer">
            {{String.fromCharCode(64 + index + 1)}}
          </el-radio>
          <el-form-item class="inline-block" :prop="'answerList.' + index + '.content'" :rules="[{required:true,message:'选项内容必填'}]">
            <el-input clearable v-model="item.content" class="mg-l-10" style="width: 400px !important;" placeholder="请输入选项内容，500个字以内" maxlength="500" show-word-limit @input="onInput"></el-input>
          </el-form-item>
        </div>
      </div>
      <div v-else-if="type === 2">
        <el-button size="mini" type="primary" @click="toAddAnswer">新增选项</el-button>
        <div class="mg-b-10" v-for="(item,index) in formData.answerList">
          <zero-checkbox v-model="item.isRight">{{String.fromCharCode(64 + index + 1)}}</zero-checkbox>
          <el-form-item class="inline-block" :prop="'answerList.' + index + '.content'" :rules="[{required:true,message:'选项内容必填'}]">
            <el-input clearable v-model="item.content" class="mg-l-10" style="width: 400px !important;" placeholder="请输入选项内容，500个字以内" maxlength="500" show-word-limit @input="onInput"></el-input>
            <el-button type="primary" class="mg-l-10" @click="delAnswer(index)">删除</el-button>
          </el-form-item>
        </div>
      </div>
      <div v-else>
        <div class="mg-b-10" v-for="(item,index) in formData.answerList">
          <el-radio v-model="formData.radioCheck" :label="index" @change="changeAnswer">{{item.content}}</el-radio>
        </div>
      </div>
    </div>
  </el-form>
</template>

<script>
export default {
  name: "TopicTypeForm",
  data() {
    return {
      type:1,
      formData:{
        radioCheck:'',
        answerList:[
          {
            content:'',
            isRight:0
          },
          {
            content:'',
            isRight:0
          },
          {
            content:'',
            isRight:0
          },
          {
            content:'',
            isRight:0
          }
        ]
      },

      isEdit:false
    }
  },
  props:{
    pid:{
      type:[Number,String],
      default:1
    },
    value:{
      type:[Array,Object,String],
      default(){
        return ''
      }
    }
  },
  model:{
    prop:'value',
    event:'change'
  },
  created() {
    console.log('=========create==========')
    console.log(this.value);

    this.type = this.pid ? this.pid : 1;

    this.formData.answerList = this.value ? this.value : [
      {
        content:'',
        isRight:0
      },
      {
        content:'',
        isRight:0
      },
      {
        content:'',
        isRight:0
      },
      {
        content:'',
        isRight:0
      }
    ];

    this.initChecked();
  },
  mounted() {
    // this.$emit('change',this.formData.answerList);
  },
  methods: {
    validate(callback){
      this.$refs["topic-type-form"].validate(res=>{

        if(res){
          if(this.type === 1 || this.type === 3) {
            if (!this.formData.radioCheck && this.formData.radioCheck !== 0) {
              this.$message.error('请选择一个选项');
              return;
            }
          }else if(this.type === 2){
            let _count = 0;

            for(let i=0;i < this.formData.answerList.length;i++){
              if(this.formData.answerList[i].isRight === 1){
                _count++
              }
            }

            if(_count <= 0){
              this.$message.error('至少选择一个选项');
              return;
            }
          }
        }

        if (callback) {
          callback(res);
        }
      });
    },
    initChecked(){
      if(this.type === 1 || this.type === 3) {
        for (let i = 0; i < this.formData.answerList.length; i++) {
          if (this.formData.answerList[i].isRight) {
            this.formData.radioCheck = i;
            return;
          }
        }
      }
    },
    onInput(e){
      this.$emit('change',this.formData.answerList);
    },
    changeAnswer(e){
      for(let i = 0;i < this.formData.answerList.length;i++){
        if(i === e){
          this.formData.answerList[i].isRight = 1;
        }else{
          this.formData.answerList[i].isRight = 0;
        }
      }

      this.$emit('change',this.formData.answerList);
    },
    toAddAnswer(){
      let _data = {
        content:'',
        isRight:0
      };

      this.formData.answerList.push(_data);

      this.$emit('change',this.formData.answerList);
    },
    initAnswerList(type){
      if(type === 1){
        this.formData.answerList = [
          {
            content: '',
            isRight: 0
          },
          {
            content: '',
            isRight: 0
          },
          {
            content: '',
            isRight: 0
          },
          {
            content: '',
            isRight: 0
          }
        ]
      }else if(type === 2){
        this.formData.answerList = []
      }else if(type === 3){
        this.formData.answerList = [
          {
            content: '是',
            isRight: 0
          },
          {
            content: '否',
            isRight: 0
          }
        ]
      }
    },

    //删除题目
    delAnswer(index){
      if(this.type !== 2){
        return;
      }

      this.formData.answerList.splice(index,1);
    }
  },
  watch:{
    value(n,o){
      console.log('=========value==========')
      console.log(n);
      console.log(o);

      this.formData.answerList = n ? n : [];

      this.initChecked();
    },
    pid(n,o){
      console.log('=========pid==========')
      console.log(n);
      this.type = n;

      console.log(this.value);

      // if(this.value && this.value.length > 0){
      //   this.initChecked();
      // }else {
        this.formData.radioCheck = '';
        this.initAnswerList(this.type);
      // }

      this.$emit('change',this.formData.answerList);
    }
  }
}
</script>
