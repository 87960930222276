const env = process.server ? process.env : global.window && window.__NUXT__ && window.__NUXT__.state ? window.__NUXT__.state.env : {};

// 开发环境
let dev = {
    // apiUrl:'http://127.0.0.1:9501/Admin',
    // uploadUrl:'http://127.0.0.1:9501/Admin/Upload/upload',
    // assetsUrl:'http://127.0.0.1:9501',
    // editorUrl:'http://127.0.0.1:9501/UEditor/',

    apiUrl: 'https://api.yldc.miihe.com/Admin',
    uploadUrl: 'https://api.yldc.miihe.com/Admin/Upload/upload',
    assetsUrl: 'https://api.yldc.miihe.com',
    editorUrl:'https://api.yldc.miihe/UEditor/',
};

// 生产环境
let pro = {
    apiUrl: 'https://api.yldc.miihe.com/Admin',
    uploadUrl: 'https://api.yldc.miihe.com/Admin/Upload/upload',
    assetsUrl: 'https://api.yldc.miihe.com',
    editorUrl:'https://api.yldc.miihe/UEditor/',
};

module.exports = {
  api: process.env.NODE_ENV === 'development' ? dev : pro
};
