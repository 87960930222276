import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/site/Login';
import BaseList from '@/views/base/list';
import BaseEdit from '@/views/base/edit';
import BaseDetail from '@/views/base/detail';
import BaseMain from '@/views/base/main';
import Home from '../views/Home.vue'
import changePass from "@/views/site/changePass";
import DashBoard from "@/views/site/DashBoard";
import BaseConfig from "@/BaseConfig";
import Utils from "@/script/utils";
import Choose from "../views/site/Choose";

const routerPush = VueRouter.prototype.push;
const routerReplace = VueRouter.prototype.replace;

VueRouter.prototype.push = function(location){
  return routerPush.call(this, location).catch(error=> error)
};

VueRouter.prototype.replace = function(location){
  return routerReplace.call(this, location).catch(error=> error)
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    redirect: "/choose",
    component: Home,
    meta:{title:'首页'},
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        component: DashBoard,
        meta:{title:'首页'}
      },
      {
        path: '/changePass',
        name: 'changePass',
        component: changePass,
        meta:{title:'修改密码'}
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/choose',
    name: 'choose',
    component: Choose
  }
]

let _paths = BaseConfig;

for(let i in _paths){

  let _default = Utils.isUndefined(_paths[i].default) ? 'list' : _paths[i].default;
  let _name = Utils.isUndefined(_paths[i].name) ? '页面' : _paths[i].name;

  let _router = '';

  if(_default === 'add'){
    _router = Utils.isUndefined(_paths[i].editRouter) ? '' : _paths[i].editRouter;
  }else if(_default === 'detail'){
    _router = Utils.isUndefined(_paths[i].detailRouter) ? '' : _paths[i].detailRouter;
  }else if(_default === 'edit'){
    _router = Utils.isUndefined(_paths[i].editRouter) ? '' : _paths[i].editRouter;
  }else if(_default === 'list'){
    _router = Utils.isUndefined(_paths[i].listRouter) ? '' : _paths[i].listRouter;
  }else{
    _router = i;
  }

  if(_router) {
    let _path = {
      path: _router,
      name: i + '-' + _default,
      component: BaseMain,
      meta: {title: _name}
    }

    routes[0].children.push(_path);
  }

  if(_paths[i].list && _paths[i].list.extButtons && _paths[i].list.extButtons.length > 0){

    for(let i2 =0 ;i2 < _paths[i].list.extButtons.length;i2++){
      let _ext = _paths[i].list.extButtons[i2];

      //添加router
      if(_ext.type === 'url') {

        let _com = '';

        //默认list模块
        if(_ext.com === 'list'){
          _com = BaseList;
        }else if(_ext.com === 'edit'){
          _com = BaseEdit;
        }else if(_ext.com === 'add'){
          _com = BaseEdit;
        }else if(_ext.com === 'detail'){
          _com = BaseDetail;
        }else{
          if(_ext.com){
            _com = () => import('@/views' + _ext.com);
          }else{
            _com = BaseMain;
          }
        }

        let _extPath = {
          path: _ext.path,
          name: _ext.name,
          component: _com,
          meta: {title: _ext.title}
        }

        routes[0].children.push(_extPath);
      }
    }
  }
  
  if(_paths[i].topLeftButtons && _paths[i].topLeftButtons.length > 0){
    
    for(let i2 =0 ;i2 < _paths[i].topLeftButtons.length;i2++){
      let _ext = _paths[i].topLeftButtons[i2];
      
      //添加router
      if(_ext.type === 'url') {
        
        let _com = '';
        
        //默认list模块
        if(_ext.com === 'list'){
          _com = BaseList;
        }else if(_ext.com === 'edit'){
          _com = BaseEdit;
        }else if(_ext.com === 'add'){
          _com = BaseEdit;
        }else if(_ext.com === 'detail'){
          _com = BaseDetail;
        }else{
          if(_ext.com){
            _com = () => import('@/views' + _ext.com);
          }else{
            _com = BaseMain;
          }
        }
        
        let _extPath = {
          path: _ext.path,
          name: _ext.name,
          component: _com,
          meta: {title: _ext.title}
        }
        
        routes[0].children.push(_extPath);
      }
    }
  }
}

console.log(routes);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router
