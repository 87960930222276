<template>
  <el-row :gutter="10">
    <el-form :model="searchForm" size="small">
<!--      <el-col :xl="item.type === 'timeChoose' ? 12 : 6" :lg="item.type === 'timeChoose' ? 12 : 6" :md="item.type === 'timeChoose' ? 16 : 12" :sm="item.type === 'timeChoose' ? 24 : 12" :xs="24" v-for="(item,index) in searchParams">-->
      <el-col :span="item.col ? item.col : 6" v-for="(item,index) in searchParams">
        <el-form-item :label="item.name" :label-width="item.name ? '100px' : '0px'">
          <type-select v-if="item.type === 'select'" v-model="searchForm[item.value]"
                       @change="(e)=>$set(searchForm,item.value,e)" :options="item.options ? item.options : null"
                       :data="item.data ? item.data : null" :url="item.url" :canAdd="false" style="width: 100%;"
                       :method="item.method" :val-key="item.valKey" :val-label="item.valLabel" :list-key="item.listKey" :is-top="item.isTop" :pid="item.pidKey ? searchForm[item.pidKey] : ''" :pid-data-key="item.pidDataKey" :auto-load="item.autoLoad" :placeholder="item.placeholder" :filterable="item.filterable ? item.filterable : false"></type-select>
<!--            <member-level-select v-model="searchForm[item.value]" v-else-if="item.type === 'memberSelect'"></member-level-select>-->
          <base-tree-select :is-top="item.isTop ? item.isTop : false" :top-label="item.topLabel" :url="item.url" v-model="searchForm[item.value]" v-else-if="item.type === 'baseTreeSelect'" :val-key="item.valKey ? item.valKey : 'id'" :val-label="item.valLabel ? item.valLabel : 'label'" :val-child="item.valChild ? item.valChild : 'children'" style="max-width:500px;"></base-tree-select>
          <el-tree-select :is-top="item.isTop ? item.isTop : false" :top-label="item.topLabel" :url="item.url" v-model="searchForm[item.value]" v-else-if="item.type === 'elTreeSelect'" :val-key="item.valKey ? item.valKey : 'id'" :val-label="item.valLabel ? item.valLabel : 'label'" :val-child="item.valChild ? item.valChild : 'children'" style="max-width:500px;"></el-tree-select>
          <year-select v-model="searchForm.year" v-else-if="item.value === 'year'" style="width: 100%;"></year-select>
          <month-select v-model="searchForm.month" v-else-if="item.value === 'month'" style="width: 100%;"></month-select>
          <area-select v-model="searchForm[item.value]" v-else-if="item.type === 'area'" style="width: 100%;"></area-select>
          <range-time v-model="searchForm[item.value]" v-else-if="item.type ==='timeRange'" style="width: 100%;" @change="(e)=>changeRangeTime(e,item.columns,item.unix ? item.unix : '')" show-picker></range-time>
          <el-select v-model="searchForm[item.value]" :placeholder="item.placeholder ? item.placeholder : '-请选择-'" v-else-if="item.type === 'state'">
            <el-option label="启用" :value="1"></el-option>
            <el-option label="禁用" :value="0"></el-option>
          </el-select>
          <el-input clearable v-model="searchForm[item.value]" class="block" :placeholder="item.placeholder ? item.placeholder : '请输入'" v-else></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="4">
<!--      <el-col :span="searchOptions && searchOptions.cols ? (searchOptions.cols % 2 === 0 ? 4 : (24 / searchOptions.cols)) : 4">-->
        <el-row :gutter="10" v-if="$isMobile">
          <el-col :span="12">
            <el-button type="primary" class="w100" size="small" @click="toSearch">搜索</el-button>
          </el-col>
          <el-col :span="12">
            <el-button type="info" class="w100" size="small" @click="toReset">重置</el-button>
          </el-col>
        </el-row>
        <template v-else>
          <el-button type="primary" size="small" @click="toSearch">搜索</el-button>
          <el-button type="info" size="small" @click="toReset">重置</el-button>
        </template>
      </el-col>
    </el-form>
  </el-row>
</template>

<script>
export default {
  name: "TopSearch",
  data() {
    return {
      comPath: '',
      params: null,
      listParams: null,
      listOptions: null,
      searchParams:[],
      searchOptions:null,
      pk:'id',
      extButtonList:[],
      searchForm:{},
      pageSizeKey:'pageSize',
      pageKey:'page'
    }
  },
  props:{
    path:{
      type:String,
      default:''
    },
    comParams: null,
    comListParams: null,
    comListOptions: null,

    comTableList:[],
  },
  created() {
    this.init();
  },
  mounted() {
  },
  methods: {
    changeRangeTime(e, columns, unix = '') {
      let _times = e.split(',');

      if(columns && columns.length === 2){
        if(unix === 'sss'){
          this.$set(this.searchForm, columns[0], this.$moment(_times[0]).valueOf());
          this.$set(this.searchForm, columns[1], this.$moment(_times[1]).valueOf());
        }else if(unix === 'ss'){
          this.$set(this.searchForm, columns[0], this.$moment(_times[1]).unix());
          this.$set(this.searchForm, columns[1], this.$moment(_times[1]).unix());
        }else{
          this.$set(this.searchForm, columns[0], _times[0]);
          this.$set(this.searchForm, columns[1], _times[1]);
        }
      }
    },
    init(){
      this.comPath = this.path ? this.path : this.$route.path;

      //获取路径全部配置
      this.params = this.comParams ? this.comParams : (this.comPath ? this.$baseConfig[this.comPath] : null);

      //获取列表参数
      this.listParams = this.comListParams ? this.comListParams : (this.params ? this.params.list : null);
      //获取列表配置
      this.listOptions = this.comListOptions ? this.comListOptions : (this.params && this.params.list ? this.params.list.options : null);

      this.searchParams = this.params.search && this.params.search.params ? this.params.search.params : [];
      this.searchOptions = this.params.search && this.params.search.options ? this.params.search.options : null;

      this.pageSizeKey = this.listOptions.pageSizeKey ? this.listOptions.pageSizeKey : 'pageSize';
      this.pageKey = this.listOptions.pageKey ? this.listOptions.pageKey : 'page';

      //初始化搜索参数
      if (this.listOptions.showSearch) {
        let _edit = this.params['search']['params'];

        for (let i = 0; i < _edit.length; i++) {
          this.$set(this.searchForm, _edit[i].value, '');
        }

        let _tabs = this.$user.getTabByPath(this.$route.path);

        this.searchForm = _tabs.data ? _tabs.data : {};

        // this.$set(this.searchForm, this.pageSizeKey, this.searchForm[this.pageSizeKey] ? this.searchForm[this.pageSizeKey] : this.pageSize);
        // this.$set(this.searchForm, this.pageKey, this.searchForm[this.pageKey] ? this.searchForm[this.pageKey] : 1);
      }
    },
    toSearch(){
      // this.$user.setTabData(this.$route.path, this.searchForm);

      this.$emit('toSearch',this.$utils.deepCopy(this.searchForm));
    },
    toReset(){
      console.log(this.searchForm);
      console.log(this.listOptions);

      for (let i in this.searchForm) {
        if(i !== this.pageKey && i !== this.pageSizeKey) {
          this.$set(this.searchForm, i, '');
        }
      }

      this.$emit('toReset',this.$utils.deepCopy(this.searchForm));
    }
  }
}
</script>
