import Vue from 'vue'
let self = new Vue();

export default {
    // memberLogin(data,callback,errorback){
    //     self.$http.httpPost({
    //         url:'/Member/login',
    //         datas:data,
    //         // showLoad:true,
    //         success:callback,
    //         fail:errorback
    //     });
    // },
    //
    // ruleList(data,callback,errorback){
    //     self.$http.httpPost({
    //         url:'/AuthRule/listRule',
    //         datas:data,
    //         // showLoad:true,
    //         success:callback,
    //         fail:errorback
    //     });
    // },
    //
    // ruleAdd(data,callback,errorback){
    //     self.$http.httpPost({
    //         url:'/AuthRule/add',
    //         datas:data,
    //         // showLoad:true,
    //         success:callback,
    //         fail:errorback
    //     });
    // },
    //
    // ruleDel(data,callback,errorback){
    //     self.$http.httpPost({
    //         url:'/AuthRule/del',
    //         datas:data,
    //         // showLoad:true,
    //         success:callback,
    //         fail:errorback
    //     });
    // },
    //
    // ruleEdit(data,callback,errorback){
    //     self.$http.httpPost({
    //         url:'/AuthRule/edit',
    //         datas:data,
    //         // showLoad:true,
    //         success:callback,
    //         fail:errorback
    //     });
    // },
    //
    // ruleDetail(data,callback,errorback){
    //     self.$http.httpPost({
    //         url:'/AuthRule/detail',
    //         datas:data,
    //         // showLoad:true,
    //         success:callback,
    //         fail:errorback
    //     });
    // },
    //
    // ruleGroupList(data,callback,errorback){
    //     self.$http.httpPost({
    //         url:'/AuthGroup/listAll',
    //         datas:data,
    //         // showLoad:true,
    //         success:callback,
    //         fail:errorback
    //     });
    // },
    //
    // ruleGroupAdd(data,callback,errorback){
    //     self.$http.httpPost({
    //         url:'/AuthGroup/add',
    //         datas:data,
    //         // showLoad:true,
    //         success:callback,
    //         fail:errorback
    //     });
    // },
    //
    // ruleGroupDel(data,callback,errorback){
    //     self.$http.httpPost({
    //         url:'/AuthGroup/del',
    //         datas:data,
    //         // showLoad:true,
    //         success:callback,
    //         fail:errorback
    //     });
    // },
    //
    // ruleGroupEdit(data,callback,errorback){
    //     self.$http.httpPost({
    //         url:'/AuthGroup/edit',
    //         datas:data,
    //         // showLoad:true,
    //         success:callback,
    //         fail:errorback
    //     });
    // },
    //
    // ruleGroupDetail(data,callback,errorback){
    //     self.$http.httpPost({
    //         url:'/AuthGroup/detail',
    //         datas:data,
    //         // showLoad:true,
    //         success:callback,
    //         fail:errorback
    //     });
    // },
    //
    // memberList(data,callback,errorback){
    //     self.$http.httpPost({
    //         url:'/Member/listPage',
    //         datas:data,
    //         // showLoad:true,
    //         success:callback,
    //         fail:errorback
    //     });
    // },
    //
    // memberDetail(data,callback,errorback){
    //     self.$http.httpPost({
    //         url:'/Member/detail',
    //         datas:data,
    //         // showLoad:true,
    //         success:callback,
    //         fail:errorback
    //     });
    // },
    //
    // memberAdd(data,callback,errorback){
    //     self.$http.httpPost({
    //         url:'/Member/add',
    //         datas:data,
    //         // showLoad:true,
    //         success:callback,
    //         fail:errorback
    //     });
    // },
    //
    // memberDel(data,callback,errorback){
    //     self.$http.httpPost({
    //         url:'/Member/del',
    //         datas:data,
    //         // showLoad:true,
    //         success:callback,
    //         fail:errorback
    //     });
    // },
    //
    // memberEdit(data,callback,errorback){
    //     self.$http.httpPost({
    //         url:'/Member/edit',
    //         datas:data,
    //         // showLoad:true,
    //         success:callback,
    //         fail:errorback
    //     });
    // },
    //
    // goodsCategoryList(data,callback,errorback){
    //     self.$http.httpPost({
    //         url:'goodsCategory/listPage',
    //         datas:data,
    //         // showLoad:true,
    //         success:callback,
    //         fail:errorback
    //     });
    // },
    //
    // goodsCategoryAdd(data,callback,errorback){
    //     self.$http.httpPost({
    //         url:'goodsCategory/add',
    //         datas:data,
    //         // showLoad:true,
    //         success:callback,
    //         fail:errorback
    //     });
    // },
    //
    // goodsCategoryDel(data,callback,errorback){
    //     self.$http.httpPost({
    //         url:'goodsCategory/del',
    //         datas:data,
    //         // showLoad:true,
    //         success:callback,
    //         fail:errorback
    //     });
    // },
    //
    // goodsCategoryEdit(data,callback,errorback){
    //     self.$http.httpPost({
    //         url:'goodsCategory/edit',
    //         datas:data,
    //         // showLoad:true,
    //         success:callback,
    //         fail:errorback
    //     });
    // },
    //
    // goodsCategoryDetail(data,callback,errorback){
    //     self.$http.httpPost({
    //         url:'goodsCategory/detail',
    //         datas:data,
    //         // showLoad:true,
    //         success:callback,
    //         fail:errorback
    //     });
    // },
}
