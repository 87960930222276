<template>
  <div class="padding-top-20 padding-right-20">
		<el-input placeholder="输入关键字进行过滤" v-model="filterText" size="small" clearable></el-input>
    <el-tree :data="data" :current-node-key="id" node-key="dept_id" :expand-on-click-node="false" highlight-current default-expand-all :props="defaultProps" @node-click="handleNodeClick" ref="tree" :filter-node-method="filterNode" class="mg-t-10"></el-tree>
  </div>
</template>

<script>
export default {
  name: "DeptTreeSide",
  data() {
    return {
			filterText:'',
      data: [{
        children: [],
        id: '',
        name: "全部",
				dept_id:''
      }],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
    }
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    id: {
      type: [String, Number],
      default: ''
    }
  },
	created() {

	},
	mounted() {
    this.getList();
  },
  methods: {
		filterNode(value, data) {
			if (!value) return true;
			return data.name.indexOf(value) !== -1;
		},
    getList() {
      let _this = this;

      this.$http.request({
        url: '/Dept/listTree',
        method: 'post',
        datas: {orderBy:'sort',sortBy:'asc'},
        success(res) {
          _this.data[0].children = res;
					if(_this.id){
						setTimeout(()=>{
							_this.$nextTick(()=>{
								_this.$refs.tree.setCurrentKey(_this.id);
							})
						},1000)
					}else{
						setTimeout(()=>{
							_this.$nextTick(()=>{
								_this.$refs.tree.setCurrentKey('');
							})
						},1000)
					}
        }
      });
    },
    handleNodeClick(data) {
      console.log(data);
      this.$emit('change', data.dept_id);
    }
  },
  watch: {
    id(n, o) {
      if (n) {
        this.$refs.tree.setCurrentKey(n);
      } else {

      }
    },
		filterText(val) {
			this.$refs.tree.filter(val);
		}
  }
}
</script>
