<template>
		<el-upload
			:show-file-list="true"
			:action="$config.api.uploadUrl"
			:class="fileList.length >= limit ? 'miihe-file-done-22'  : ''"
			:data="{key:imgKey}"
			:headers="{Authorization:$user.getToken()}"
			:accept="accept"
			ref="upload"
			:on-change="onChange"
			:on-success="onSuccess"
			:on-exceed="onExceed"
			:on-preview="onPreview"
			:on-remove="onDel"
			:file-list="fileList"
			:limit="limit"
			:auto-upload="true">
			<div class="text-left mg-r-10">
				<el-button size="small" type="primary" :disabled="fileList.length >= limit">{{fileList.length >= limit ? '已达数量上限，请先删除' : '点击上传'}}</el-button>
			</div>
			<div slot="tip" style="color:#777;line-height: 18px !important;">
				<div>文件类型：{{accept}}</div>
				<div>最多可以上传 {{limit}} 个文件</div>
			</div>
		</el-upload>
</template>

<script>
import emitter from "element-ui/src/mixins/emitter";

export default {
	name: "OneFileUpload",
	mixins: [emitter],
	data() {
		return {
			bigImg: '',
			show: false,
			imgKey: '',
			fileList: [],
			values: ''
		};
	},
	model: {
		prop: 'value',
		event: 'change'
	},
	props: {
		accept:{
			type:String,
			default:'.doc,.docx,.ppt,.pptx,.pdf,.xls,.xlsx,.exe,.zip,.rar,.txt,.js,.tar.gz,.mp4'
		},
		value: {
			type: String,
			default: ''
		},
		limit: {
			type: Number,
			default: 1
		},
		size: {
			type: [Number, String],
			default: '148px'
		}
	},
	created() {
		this.fileList = [];
		this.values = this.value;

		if (this.values) {
			let _file = {
				name: this.values.replace(/\/.+\//, ''),
				uid: this.$utils.randomWords(),
				url: this.values
			}

			this.fileList.push(_file);
		}
	},
	watch: {
		value(n, o) {
			this.values = n;

			if (!n) {
				this.fileList = [];
			} else {
				this.fileList = [];
				let _file = {
					name: this.values.replace(/(http:|https:)\/.+\//, ''),
					uid: this.$utils.randomWords(),
					url: this.values
				}

				this.fileList.push(_file);
			}
		}
	},
	methods: {
		onChange(file, fileList) {
			// this.fileList = fileList.slice(-3);
		},
		onDel() {
			this.values = '';
			this.fileList = [];
			this.$refs.upload.clearFiles();
			this.$emit('change', '');
			// this.dispatch('ElFormItem', 'el.form.change', [''])
			// this.dispatch('ElFormItem', 'el.form.blur', [''])
		},
		onSuccess(response, file, fileList) {
			console.log(fileList);
			if (response.code === 0) {
				this.fileList = fileList;

				this.values = response.data.url

				this.$emit('change', response.data.url);
				this.dispatch('ElFormItem', 'el.form.change', [response.data.url])
				this.dispatch('ElFormItem', 'el.form.blur', [response.data.url])
			} else {
				this.$utils.error(response.msg);
				this.fileList = [];
			}
		},
		onPreview(file) {
			let _url = file.response && file.response.data && file.response.data.url ? (file.response.data.url.indexOf('http') >= 0 ? file.response.data.url : this.$config.api.assetsUrl + file.response.data.url) : (file.url ? (file.url.indexOf('http') >= 0 ? file.url : this.$config.api.assetsUrl + file.url) : '');

			if(_url) {
				window.open(_url, '_blank');
			}
		},
		onExceed(f, fl) {
			this.$alert('只能上传一个文件', '提示', {
				confirmButtonText: '确定',
				showClose: false,
				callback: action => {
				}
			});
		}
	},
	destroyed() {
		console.log('dis');
	}
}
</script>
