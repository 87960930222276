<template>
    <el-date-picker
        :editable="false"
        v-model="values"
        type="datetime"
        placeholder="选择日期时间" @change="change">
    </el-date-picker>
</template>

<script>
export default {
    name: "CreateTime",
    data(){
        return{
            values:'',
        }
    },
    model:{
        prop:'value',
        event:'change'
    },
    props: {
        value: {
            type:String,
            default: ''
        },
    },
    created() {
        this.values = this.value ? this.$moment(this.value).toDate() : this.$moment().toDate();
        // console.log(this.$moment(this.values).format('YYYY-MM-DD'));
        this.$emit('change',this.$moment(this.values).format('YYYY-MM-DD HH:mm:ss'));
    },
    watch:{
        value(n,o){
            this.values = n ? this.$moment(n).toDate() : this.$moment().toDate();
        },
    },
    methods:{
        change(e){
            console.log(this.$moment(e).format('YYYY-MM-DD HH:mm:ss'));
            let _year = e.getFullYear();
            let _month = e.getMonth() + 1;
            let _day = e.getDate();
            let _hour = e.getHours();
            let _min = e.getMinutes();
            let _second = e.getSeconds();

            // this.$emit('change',_year+'-'+_month+'-'+_day+' '+_hour+':'+_min+':'+_second);
            this.$emit('change',this.$moment(e).format('YYYY-MM-DD HH:mm:ss'));
        }
    }
}
</script>
