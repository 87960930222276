<template>
    <el-form
        v-if="!isDom"
        :model="colors"
        :rules="rules"
        ref="form"
        class="theme-form"
        label-width="100px"
        label-position="left">
        <el-form-item label="主题颜色" prop="primary">
            <div>
              <el-color-picker v-model="colors.primary"></el-color-picker>
            </div>
        </el-form-item>
        <el-form-item class="color-buttons" label-width="0">
            <el-button size="small" type="primary" @click="submitForm">切换</el-button>
            <el-button size="small" @click="resetForm">恢复默认</el-button>
        </el-form-item>
    </el-form>
</template>

<script>
import objectAssign from "object-assign";
import generateColors from "@/script/color";

export default {
    name: "ThemeColorChoose",
    data(){
        const colorValidator = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('必须选择一种颜色'))
            }
            // else if (!/^#[\dabcdef]{6}$/i.test(value)) {
            //     return callback(new Error('颜色数值格式不正确'))
            // }
            else {
                callback()
            }
        }
        return{
            colors: {
                primary: '#409eff'
            },
            rules: {
                primary: [
                    { validator: colorValidator, trigger: 'blur' }
                ]
            },
            originalStylesheetCount: -1,
            originalStyle: '',
            primaryColor: '#409eff',
            defaultColor:'#409eff',
            styleFiles: [],
            fontFiles: ['element-icons.ttf', 'element-icons.woff'],
            fonts: [],
        }
    },
    props:{
        isDom:{
            type:Boolean,
            default:false
        },
    },
    created() {
        // this.getSeparatedStyles();
        // this.getFontFiles();
        let _this = this;

        let _localColor = this.$utils.localGet('theme');
        this.colors.primary = _localColor ? _localColor : this.defaultColor;

        this.getIndexStyle(()=>{
                if(_this.colors.primary !== _this.defaultColor) {
                    _this.changeColor();
                }
            }
        );
    },
    mounted() {

    },
    methods:{
        getIndexStyle (success) {
            ////unpkg.com/element-ui/lib/theme-chalk/index.css
            this.getFile(process.env.BASE_URL+'static/css/theme.css').then(({ data }) => {
                    data += '.el-menu-item.is-active {background: primary !important;color: #fff !important;}.el-dialog__body::-webkit-scrollbar-thumb,.diy-scroll::-webkit-scrollbar-thumb,.el-table__body-wrapper::-webkit-scrollbar-thumb{background-color: primary !important;}.el-dialog__body,.diy-scroll,.el-table__body-wrapper{scrollbar-color: primary transparent;}.el-table__body tr.current-row > td.el-table__cell{background: line-op !important;}.el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content{background-color: primary;color:#fff !important; }';

                    this.originalStyle = this.getStyleTemplate(data);
                }).then(()=>{
                    if(success){
                        success();
                    }
                })
        },
        getSeparatedStyles () {
            this.getFile('//unpkg.com/element-ui/lib/theme-chalk/')
                .then(({ data }) => {
                    return data.match(/href="[\w-]+\.css"/g).map(str => str.split('"')[1])
                }).then(styleFiles => {
                    return Promise.all(styleFiles.map(file => {
                        return this.getFile(`//unpkg.com/element-ui/lib/theme-chalk/${file}`)
                    }))
                }).then(files => {
                    this.styleFiles = files.map(file => {
                        return {
                            name: file.url,
                            data: this.getStyleTemplate(file.data)
                        }
                    })
                })
        },
        getFontFiles () {
            Promise.all(this.fontFiles.map(font => {
                return this.getFile(`//unpkg.com/element-ui/lib/theme-chalk/fonts/${font}`, true)
            })).then(fonts => {
                    this.fonts = fonts
                })
        },
        getStyleTemplate (data) {
            const colorMap = {
                '#3a8ee6': 'shade-1',
                '#409eff': 'primary',
                '#53a8ff': 'light-1',
                '#66b1ff': 'light-2',
                '#79bbff': 'light-3',
                '#8cc5ff': 'light-4',
                '#a0cfff': 'light-5',
                '#b3d8ff': 'light-6',
                '#c6e2ff': 'light-7',
                '#d9ecff': 'light-8',
                '#ecf5ff': 'light-9'
            }
            Object.keys(colorMap).forEach(key => {
                const value = colorMap[key]
                data = data.replace(new RegExp(key, 'ig'), value)
            })
            return data
        },
        getFile (url, isBlob = false) {
            return new Promise((resolve, reject) => {
                const client = new XMLHttpRequest()
                client.responseType = isBlob ? 'blob' : ''
                client.onreadystatechange = () => {
                    if (client.readyState !== 4) {
                        return
                    }
                    if (client.status === 200) {
                        const urlArr = client.responseURL.split('/')
                        resolve({
                            data: client.response,
                            url: urlArr[urlArr.length - 1]
                        })
                    } else {
                        reject(new Error(client.statusText))
                    }
                }
                client.open('GET', url)
                client.send()
            })
        },
        writeNewStyle () {
            let cssText = this.originalStyle

            Object.keys(this.colors).forEach(key => {
                cssText = cssText.replace(new RegExp('(:|\\s+)' + key, 'g'), '$1' + this.colors[key])
            })

            let _style_dom = document.getElementById("custom-color");

            if(_style_dom){
                _style_dom.innerText = cssText;
            }else {
                const style = document.createElement('style');
                style.setAttribute('id', 'custom-color')
                style.innerText = cssText
                document.head.appendChild(style);
            }

            this.$utils.localSet('theme',this.colors.primary);

            this.$emit('success');
        },
        submitForm () {
            this.$refs.form.validate(valid => {
                if (valid) {
                    // this.primaryColor = this.colors.primary
                    this.changeColor();
                } else {
                    return false
                }
            })
        },
        resetForm () {
            this.$confirm('是否使用默认主题颜色', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.colors.primary = this.defaultColor;
              // console.log(this.colors);
                this.changeColor();
            }).catch(() => {
            });
            // this.$refs.form.resetFields()
        },
        changeColor(){
            this.colors = objectAssign({}, this.colors, generateColors(this.colors.primary));
            // console.log(this.colors);
            this.writeNewStyle();
        }
    }
}
</script>
