<template>
    <el-checkbox v-model="values" :true-label="1" :false-label="0" @change="change"><slot></slot></el-checkbox>
</template>

<script>
export default {
    name: "OneCheckBox",
    data(){
        return{
            values:0,
        }
    },
    model:{
        prop:'value',
        event:'change'
    },
    props:{
        value:{
            type:Number,
            default:0
        }
    },
    created() {
        this.values = this.value;
    },
    watch:{
        value(n,o){
            this.values = n;
        }
    },
    methods:{
        change(e){
            console.log(e);
            this.$emit("change", e);
        },
    }
}
</script>
