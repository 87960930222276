<template>
    <span class="inline-block">
        <el-button class="w100 pd-l-r-15" :icon="btnIcon" :size="btnSize" :type="btnType" @click="openDialog">{{label}}</el-button>
        <el-dialog title="人员选择" :visible.sync="visible" @close="onClose" width="60%" v-if="visible" custom-class="dialog-h-500" append-to-body>
          <div style="margin-bottom:20px;">
            <el-row :gutter="10">
              <el-col :span="8">
                <base-tree-select url="/api-admin/dept?queryMode=tree" v-model="searchForm.deptId" style=""></base-tree-select>
              </el-col>
              <el-col :span="8">
                <el-input placeholder="用户名称" class="mg-l-r-10" clearable v-model="searchForm.nickname" maxlength="100" show-word-limit style="width: 300px;"></el-input>
              </el-col>
              <el-col :span="8">
                <el-button type="primary" @click="toSearch">搜索</el-button>
                <el-button @click="toCancel">重置</el-button>
              </el-col>
            </el-row>
          </div>
          <el-table ref="list" :data="list" row-key="id" @selection-change="onSelectionChange">
            <el-table-column type="selection" width="55" reserve-selection></el-table-column>
            <el-table-column type="index" width="55"></el-table-column>
            <el-table-column label="账号" prop="username"></el-table-column>
            <el-table-column label="用户名称" prop="nickname"></el-table-column>
          </el-table>
          <div class="text-center mg-t-20" style="flex:1" v-if="list.length > 0">
            <el-pagination
              background
              layout="total,prev, pager, next,jumper"
              :page-size="searchForm.limit"
              :current-page.sync="searchForm.page"
              :total="total"
              @current-change="pageChange">
            </el-pagination>
          </div>
          <div slot="footer" class="dialog-footer">
            <el-button @click="visible = false">关闭</el-button>
            <el-button type="primary" @click="toChoose">确定</el-button>
          </div>
        </el-dialog>
    </span>
</template>

<script>
import emitter from "element-ui/src/mixins/emitter";
export default {
  name: "UserDialog",
  mixins: [emitter],
  data() {
    return {
      visible: false,
      dataList: [],
      list: [],
      searchForm:{
        page:1,
        limit:10,
        queryMode:'page',
        nickname:'',
        deptId:'',
        status:1
      },
      total:0,
      values:[],
      ids:[]
    }
  },
  model:{
    prop:'value',
    event:'change'
  },
  props: {
    value: {
      type: Array,
      default: function () {
        return [];
      }
    },
    label:{
      type:String,
      default:'新增'
    },
    toQuType:{
      type:[Number,String],
      default:''
    },
    btnType:{
      type:String,
      default:'primary'
    },
    btnSize:{
      type:String,
      default:'mini'
    },
    btnIcon:{
      type:String,
      default:''
    }
  },
  created() {
    this.values = this.value;

    this.searchForm.quType = this.toQuType ? this.toQuType : '';

    this.initIds();
  },
  watch:{
    value(n,o){
      this.values = n;

      // this.initIds();
    },
    toQuType(n,o){
      this.searchForm.quType = n ? n : '';

      this.getList();
    }
  },
  mounted() {
    // this.getList();
  },
  methods: {
    initIds(){
      this.ids = [];
      for(let i=0;i < this.values.length;i++){
        this.ids.push(this.values[i].id);
      }
    },
    initChecked() {
      if (this.ids.length > 0) {
        this.$nextTick(()=>{
          this.list.forEach(row => {
            if(this.ids.indexOf(row.id) >= 0) {
              this.$refs.list.toggleRowSelection(row, true);
            }
          });
        })
      }
    },
    toSearch() {
      this.searchForm.page = 1;
      this.getList();
    },
    toCancel(){
      this.searchForm.page = 1;
      this.searchForm.deptId = '';
      this.searchForm.nickname = '';
      this.getList();
    },
    toChoose() {
      console.log(this.values);

      this.$emit('change', this.values);
      this.dispatch('ElFormItem', 'el.form.change', [this.values])
      this.dispatch('ElFormItem', 'el.form.blur', [this.values])
      this.onClose();
    },
    openDialog() {
      this.visible = true;

      this.list = [];

      this.getList();
      // this.getList();
    },
    onClose() {
      this.visible = false;
      // this.imageList = [];
    },
    onExceed() {
      this.$message.error('数量最多不能超过' + this.limit + '个');
    },
    getList() {
      let _this = this;
      this.dataList = [];
      this.$http.request({
        url: '/api-admin/api.admin/v1/users',
        method:'get',
        datas: this.searchForm,
        success: (res) => {
          _this.list = res.records;

          _this.total = res.total;

          _this.initIds();
          _this.initChecked();
        }
      });
    },
    pageChange(page) {
      this.page = page;
      this.getList();
    },
    onSelectionChange(e){
      this.values = e;

      // this.initIds();
    },
  }
}
</script>
