<template>
    <el-select v-model="values" placeholder="请选择" :clearable="clearable" @change="change">
        <el-option
            v-for="item in list"
            :key="item.id"
            :label="item.name"
            :value="item.id"></el-option>
    </el-select>
</template>

<script>
export default {
    name: "GroupSelect",
    data(){
        return{
            list:[],
            values:'',
            name:''
        }
    },
    model:{
        prop:'value',
        event:'change'
    },
    props:{
        value:{
            type:[String,Number],
            default:''
        },
        data:{
            type:[Array,Object],
            default(){
                return null
            }
        },
        url:{
            type:String,
            default:''
        },
        options:{
            type:[Array,Object],
            default(){
                return null
            }
        },
        clearable:{
            type:Boolean,
            default:false
        }
    },
    created() {
        this.values = this.value;
    },
    watch:{
        value(n,o){
            this.values = n;
        }
    },
    mounted() {
        if(this.options) {
            this.list = this.options;
        }else{
            this.getList();
        }
    },
    methods:{
        getList() {
            let _this = this;

            this.$http.httpPost({
                url: '/AuthGroup/listAll',
                datas: {...this.data},
                success(res) {
                    _this.list = res;
                }
            });
        },
        change(e){
            this.$emit("change", e);
        },
    }
}
</script>
