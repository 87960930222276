<template>
	<el-dialog title="事项督查" :visible.sync="isShow" width="700px" append-to-body @close="onClose" @open="onOpen">
<!--		{{formData}}-->
		<el-form :model="formData" :rules="rules" ref="form" label-width="100px">
			<el-form-item label="督查状态" prop="state">
				<type-select :is-top="false" :options="[{id:1,name:'通过'},{id:2,name:'驳回'}]" v-model="formData.ducha_state" @change="onChange"></type-select>
			</el-form-item>
			<el-form-item label="备注" prop="ducha_remark" v-if="formData.ducha_state === 1">
				<el-input type="textarea" v-model="formData.ducha_remark"></el-input>
			</el-form-item>
			<el-form-item label="驳回理由" prop="ducha_remark" :rules="[{required:true,message:'驳回理由必填'}]" v-if="formData.ducha_state === 2">
				<el-input type="textarea" v-model="formData.ducha_remark"></el-input>
			</el-form-item>
<!--			<el-form-item label="异议原因" prop="remark" :rules="[{required:true,message:'延期理由必填'}]" v-if="formData.state === 7">-->
<!--				<el-input type="textarea" v-model="formData.remark"></el-input>-->
<!--			</el-form-item>-->
<!--			<el-form-item label="附件" prop="files_ids">-->
<!--				<file-upload v-model="formData.files_ids"></file-upload>-->
<!--			</el-form-item>-->
<!--			<el-form-item label="图片" prop="images_ids">-->
<!--				<image-upload v-model="formData.images_ids"></image-upload>-->
<!--			</el-form-item>-->
<!--			<el-form-item label="视频" prop="videos_ids">-->
<!--				<file-upload v-model="formData.videos_ids" accept=".mp4,.mov,.wmv"></file-upload>-->
<!--			</el-form-item>-->
		</el-form>
		<span slot="footer" class="dialog-footer">
			<el-button @click="isShow = false">关闭</el-button>
	    <el-button type="primary" @click="toSubmit">确定</el-button>
	  </span>
	</el-dialog>
</template>

<script>
export default {
	name: "LingquDialog",
	data() {
		return {
			isShow:false,
			formData:{
				id:'',
				pid:'',
				ducha_state:'',
				ducha_remark:'',
				// files_ids:[],
				// images_ids:[],
				// videos_ids:[]
			},
			rules:{
				ducha_state:[{required: true,message:'状态必选'}]
			}
		}
	},
	props:{
		visible:{
			type:Boolean,
			default:false
		},
		id:{
			type:String,
			default:''
		},
		bid:{
			type:String,
			default:''
		}
	},
	model:{
		prop:'visible',
		event:'change'
	},
	created() {
		this.isShow = this.visible;
		this.formData.pid = this.id ? this.id : ''
		this.formData.id = this.bid ? this.bid : ''
	},
	mounted() {
	
	},
	methods: {
		onChange(){
			this.formData.ducha_remark = '';
		},
		onOpen(){
			let _this = this;
			this.$nextTick(()=>{
				_this.$refs['form'].clearValidate()
			})
		},
		onClose(){
			this.formData.id = '';
			this.formData.pid = '';
			this.formData.ducha_state = '';
			this.formData.ducha_remark = '';
			// this.formData.files_ids = [];
			// this.formData.images_ids = [];
			// this.formData.videos_ids = [];
			this.$emit('update:visible', false);
			this.$emit('close');
		},
		toSubmit(){
			let _this = this;
			this.$refs['form'].validate((valid) => {
				if (valid) {
					_this.$http.request({
						url:'/ProgramDo/toDucha',
						datas:_this.formData,
						success(res){
							_this.$message.success('操作成功');
							
							_this.$emit('success');
							
							_this.isShow = false;
						}
					})
				} else {
					_this.$message.error('请填写完整表单')
					return false;
				}
			});
		}
	},
	watch:{
		visible(n,o){
			this.isShow = n;
			this.formData.pid = this.id ? this.id : ''
			this.formData.id = this.bid ? this.bid : ''
		}
	}
}
</script>
