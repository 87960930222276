import systemConfig from "./systemConfig";
import toolsConfig from "./toolsConfig";
import dictConfig from './dictConfig';
import settingConfig from './settingConfig';
import programConfig from './programConfig';
import deptConfig from './deptConfig';
import userConfig from './userConfig';
import specialConfig from './specialConfig';
import totalConfig from './totalConfig';
import ledgerConfig from './ledgerConfig';
import spaceConfig from './spaceConfig';
import jobConfig from './jobConfig';
import exportConfig from './exportConfig';
//示例
import testConfig from "./testConfig";

export default {
    ...systemConfig,
    ...toolsConfig,
    ...testConfig,
    ...dictConfig,
    ...settingConfig,
    ...programConfig,
    ...deptConfig,
    ...userConfig,
    ...specialConfig,
    ...totalConfig,
    ...ledgerConfig,
    ...spaceConfig,
    ...jobConfig,
    ...exportConfig,
}

// 示例，多个文件，
// export default {...systemConfig,...test}
