<template>
  <div class="mg-r-20">
    <el-tree :data="list" :current-node-key="currentNodekey" node-key="id" :expand-on-click-node="false" highlight-current default-expand-all :props="defaultProps" @node-click="handleNodeClick" ref="tree"></el-tree>
  </div>
</template>

<script>
export default {
  name: "BaseTreeSide",
  data() {
    return {
      list: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      currentNodekey:''
    }
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    id: {
      type: [String, Number],
      default: ''
    },
    topTitle: {
      type: String,
      default: '顶级'
    },
    showTop:{
      type:Boolean,
      default:true
    },
    showFirst:{
      type:Boolean,
      default:false
    },
    url:{
      type:String,
      default:''
    },
    method:{
      type:String,
      default:'get'
    },
    datas:{
      type:[Object,Array],
      default(){
        return {}
      }
    },
    valLabel:{
      type:String,
      default:'label'
    },
  },
  created() {
    this.defaultProps.label = this.valLabel;

    if(this.showTop && this.topTitle){
      this.list.push({
        children: [],
        id: '',
        [this.valLabel]: this.topTitle
      })
    }
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      let _this = this;

      if(this.url) {
        this.$http.request({
          url: this.url,
          method: this.method,
          datas: this.datas ? {...this.datas} : null,
          success(res) {
            let _typeof = Object.prototype.toString.call(res);

            if (_this.showTop) {
              _this.list[0].children = _typeof === '[object Object]' ? [res] : res;
            } else {
              _this.list = _typeof === '[object Object]' ? [res] : res;
            }

            if(_this.showFirst){
              _this.$emit('change', _this.list && _this.list[0] ? _this.list[0].id : '');

              _this.$nextTick(() => {
                _this.$refs.tree.setCurrentKey(_this.list && _this.list[0] ? _this.list[0].id : '');
              })
            }

            // if(_this.showFirst) {
            //   _this.$nextTick(() => {
            //     _this.$refs.tree.setCurrentKey(_this.list && _this.list[0] ? _this.list[0].id : '');
            //   })
            // }
          }
        });
      }
    },
    handleNodeClick(data) {
      // console.log(data);
      this.$emit('change', data.id);
    },

    replaceCurrent(){
      this.$nextTick(() => {
        this.$refs.tree.setCurrentKey(this.list && this.list[0] ? this.list[0].id : '');
      })
    }
  },
  watch: {
    id(n, o) {
      this.currentNodekey = n ? n : this.list[0].id;

      this.$refs.tree.setCurrentKey(this.currentNodekey);
    }
  }
}
</script>
