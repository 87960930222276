<template>
    <m-main-layout>
        <div class="bg-light pd-20">
            <el-form :model="formData" :rules="rules" ref="ruleForm" label-width="auto" class="bg-light" style="width: 300px;margin: 0 auto;">
                <el-form-item label="" prop="oldPassword" class="margin-top">
                    <el-input v-model="formData.oldPassword" type="password" placeholder="旧密码" maxlength="25" show-word-limit show-password></el-input>
                </el-form-item>
                <el-form-item label="" prop="newPassword" class="margin-top">
                    <el-input v-model="formData.newPassword" type="password" placeholder="新密码" maxlength="25" show-word-limit show-password></el-input>
                </el-form-item>
                <el-form-item label="" prop="re_pass" class="margin-top">
                    <el-input v-model="formData.againPassword" type="password" placeholder="重复新密码" maxlength="25" show-word-limit show-password></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitForm('ruleForm')">修改</el-button>
                    <el-button type="" @click="$router.back()">返回</el-button>
                </el-form-item>
            </el-form>
        </div>
    </m-main-layout>
</template>

<script>
export default {
name: "changePass",
    data() {
        var validatePass2 = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请再次输入密码'));
            } else if (value !== this.formData.newPassword) {
                callback(new Error('两次输入密码不一致!'));
            } else {
                callback();
            }
        };
        return {
            formData: {
              oldPassword: '',
              newPassword: '',
              againPassword:''
            },
            rules: {
                oldPassword: [
                    { required: true, message: '请输入旧密码', trigger: 'blur' }
                ],
                newPassword: [
                    { required: true, message: '请输入新密码', trigger: 'blur' },
                    { min: 6, max: 25, message: '请输入6-25位密码'},
                  { pattern:this.$regexp.password, message: '密码只能是6-25位字母、数字和!#$%^&*.~,_'}
                ],
              againPassword: [
                    { required: true, message: '请输入重复新密码'},
                    { min: 6, max: 25, message: '请输入6-25位密码'},
                  { pattern:this.$regexp.password, message: '密码只能是6-25位字母、数字和!#$%^&*.~,_'},
                    {validator: validatePass2}
                ]
            },
        };
    },
    methods:{
        submitForm(form){
            let _this = this;

            let _query = this.$utils.obj2str(this.formData);

            this.$refs[form].validate((valid) => {
                if (valid) {
                    _this.$http.request({
                        url: '/api-admin/api.admin/v1/users/updataPassByUsername?'+_query,
                        method:'put',
                        success: (res) => {
                          _this.$alert('密码修改成功，请重新登录', '提示', {
                            confirmButtonText: '重新登录',
                            showClose:false,
                            callback: action => {
                              if(action === 'confirm'){
                                // _this.$message.success('密码修改成功，请重新登录');
                                _this.$utils.sessionClear();
                                _this.$router.replace({path:'/login'});
                              }
                            }
                          });
                          // console.log(res);
                          //   _this.$message.success('密码修改成功，请重新登录');
                          //   _this.$utils.sessionClear();
                          //   _this.$router.replace({path:'/login'});
                        }
                    })
                }
            })
        }
    }
}
</script>
