<template>
    <div id="particles"></div>
</template>

<script>
import particles from 'particles.js'
import particlesconfig from '@/assets/json/particlesjs-config.json'

export default {
    name: "Particles",
    mounted() {
        this.logPartciles();
    },
    methods:{
        logPartciles(){
            window.particlesJS('particles',particlesconfig);
        }
    }
}
</script>
