<template>
  <m-main-layout ref="main">
<!--	  <el-alert-->
<!--		  v-if="mainParam && mainParam.headerTip"-->
<!--		  class="mg-b-10"-->
<!--		  type="warning"-->
<!--		  show-icon>-->
<!--		  <div slot="title">提示</div>-->
<!--		  <div class="f-14" v-html="mainParam.headerTip">{{mainParam.headerTip}}</div>-->
<!--	  </el-alert>-->
    <component ref="mainCom" :is="MainComponent" :path="mainPath" :param="mainParam" :title="mainTitle" @success="onMainSuccess" @toApi="toApi" @toEdit="toEdit" @toDetail="toDetail" @toList="toList" @toDiy="toDiy" @toProgram="toProgram" @toListExport="toListExport" @toListShenpi="toListShenpi" @toListReShenpi="toListReShenpi">
			<el-alert
				slot="tip"
				v-if="mainParam && mainParam.headerTip"
				class="mg-b-10"
				type="warning"
				show-icon>
				<div slot="title">提示</div>
				<div class="f-14" v-html="mainParam.headerTip">{{mainParam.headerTip}}</div>
			</el-alert>
		</component>
    <div class="text-center" v-if="mainParam.default === 'edit' || mainParam.default === 'add'">
      <el-button @click="$router.back()">返回</el-button>
      <el-button type="primary" @click="toSubmit('main')">保存</el-button>
    </div>
    <el-dialog class="drag-dialog" :custom-class="canDrag ? 'abs' : ''" :title="editTitle" :visible.sync="showEdit" v-if="showEdit" :width="editDialogWidth" :close-on-click-modal="false" append-to-body @closed="onClosed">
      <component ref="editCom" :is="EditComponent" :editInfo="editInfo" :path="editPath" :datas="editData" @success="onComSuccess('edit')"></component>
      <div slot="footer">
        <el-button @click="toClose('edit')">关闭</el-button>
        <el-button type="primary" @click="toSubmit('edit')">保存</el-button>
        <base-button-group class="mg-l-10" :buttons="editExtButtons" @toDiy="toDiy" @toApi="toApi" @toEdit="toEdit" @toDetail="toDetail" @toList="toList" @toListExport="toListExport" @toListShenpi="toListShenpi" @toListReShenpi="toListReShenpi"></base-button-group>
      </div>
    </el-dialog>
    <el-dialog class="drag-dialog" :title="listTitle" :visible.sync="showList" v-if="showList" :close-on-click-modal="false" append-to-body @closed="onClosed">
      <component ref="listCom" :is="ListComponent" :path="listPath" :datas="listData" @success="onComSuccess('list')" @toDiy="toDiy" @toApi="toApi" @toEdit="toEdit" @toDetail="toDetail" @toList="toList" @toListExport="toListExport" @toListShenpi="toListShenpi" @toListReShenpi="toListReShenpi"></component>
      <div slot="footer">
        <el-button @click="toClose('list')">关闭</el-button>
      </div>
    </el-dialog>
    <el-dialog class="drag-dialog" :title="detailTitle" :visible.sync="showDetail" v-if="showDetail" append-to-body @closed="onClosed">
      <component ref="detailCom" :is="DetailComponent" :path="detailPath" :datas="detailData" @success="onComSuccess('detail')"></component>
      <div slot="footer">
        <el-button @click="toClose('detail')">关闭</el-button>
      </div>
    </el-dialog>
    <el-dialog class="drag-dialog" :title="diyTitle" :visible.sync="showDiy" v-if="showDiy" :close-on-click-modal="false" append-to-body @closed="onClosed">
      <component ref="diyCom" :is="DiyComponent" :tableData="diyTableData" :option="diyOption" :row="diyRow" :path="diyPath" :datas="diyData" @success="onComSuccess('diy')"></component>
      <div slot="footer">
        <el-button @click="toClose('diy')" v-if="$utils.isUndefined(diyOption.showClose) ? true : diyOption.showClose">{{diyOption.cancelLabel ? diyOption.cancelLabel : '关闭'}}</el-button>
        <el-button type="primary" @click="toSubmit('diy')" v-if="$utils.isUndefined(diyOption.showSubmit) ? true : diyOption.showSubmit">{{diyOption.submitLabel ? diyOption.submitLabel : '保存'}}</el-button>
      </div>
    </el-dialog>
	  <shenpi-dialog :visible.sync="show_shenpi" :id="programInfo.id" @success="onSuccess" v-if="programInfo"></shenpi-dialog>
	  <list-shenpi-dialog :visible.sync="show_list_shenpi" :ids="list_ids" :url="list_url" @success="onSuccess"></list-shenpi-dialog>
	  <lingqu-dialog :visible.sync="show_lingqu" :id="programInfo.id" @success="onSuccess" v-if="programInfo"></lingqu-dialog>
	  <banli-dialog :visible.sync="show_banli" :id="programInfo.id" @success="onSuccess" v-if="programInfo"></banli-dialog>
	  <delay-dialog :visible.sync="show_delay" :info="programInfo" :id="programInfo.id" @success="onSuccess" v-if="programInfo"></delay-dialog>
	  <stop-dialog :visible.sync="show_stop" :id="programInfo.id" @success="onSuccess" v-if="programInfo"></stop-dialog>
		<abs-state-dialog :visible.sync="show_absstate" :id="programInfo.id" @success="onSuccess" v-if="programInfo"></abs-state-dialog>
  </m-main-layout>
</template>

<script>
export default {
  name: "pagemain",
  data() {
    return {
	    show_shenpi:false,
			show_list_shenpi:false,
	    show_lingqu:false,
	    show_banli:false,
	    show_stop:false,
	    show_delay:false,
			show_absstate:false,
	    programInfo:null,
			list_ids:[],
			list_url:'',

      //基础组件
      MainComponent:{},
      mainPath:'',
      mainParam:{},
      mainTitle:'',

      //编辑组件
      showEdit:false,
      editPath:'',
      editTitle:'',
      editData:{},
      editInfo:null,
      editExtButtons:[],
      EditComponent:{},
      editDialogWidth:null,

      //列表组件
      showList:false,
      listPath:'',
      listTitle:'',
      listData:{},
      ListComponent:{},

      //列表组件
      showDetail:false,
      detailPath:'',
      detailTitle:'',
      detailData:{},
      DetailComponent:{},

      //自定义组件
      showDiy:false,
      diyPath:'',
      diyTitle:'',
      diyData:{},
      diyRow:{},
      diyTableData:[],
      diyOption:{},
      DiyComponent:{},

      canDrag:false,
      draging:false,
      dialogTop:0,
      dialogLeft:0,

	    loadingObj:null
    }
  },
  created() {
    //获取配置
    let _config = this.$baseConfig

    //获取当前路径
    this.mainPath = this.$route.path;

    //获取路径全部配置
    this.mainParam = _config[this.mainPath];

	  let _baseCom = null
    //初始化组件
	  try {
		  _baseCom = this.$utils.isUndefined(this.mainParam.default) ? 'list' : this.mainParam.default;
	  }catch (e) {
			console.log(_config)
		  console.log(this.mainPath);
	  }

    if (['list','detail','edit','add'].indexOf(_baseCom) >= 0) {
      if(_baseCom === 'add'){
        _baseCom = 'edit';
      }
      this.MainComponent = () => import('@/views/base/'+_baseCom);
    } else {
      this.MainComponent = () => import('@/views'+_baseCom);
    }
  },
  mounted() {
  },
  methods: {
	  onSuccess(){
			// console.log('==onSuccess=onSuccess')
		  this.$refs.mainCom.success();
	  },
    onClosed(){
      this.canDrag = false;
    },

    onMainSuccess(e){
      console.log(e);

      if(!this.$utils.isUndefined(e.isDialog)){
        if(!e.isDialog){
          this.$user.removeTab();
          this.$router.back();
        }
      }
      // this.$router.back();
    },

    onComSuccess(type){
      console.log('====onComSuccess====');

      console.log(type);

      this.$refs.mainCom.success();
      this.toClose(type);
    },

    toApi(option, scopeRow, data) {
			// console.log(option, scopeRow, data);
      let _this = this;
      if (option.path) {
        let _showConfirm = this.$utils.isUndefined(option.showConfirm) ? false : option.showConfirm;
				console.log(_showConfirm);

        if(_showConfirm) {
          this.$confirm(option.confirmContent ? option.confirmContent : '是否确认要进行此操作?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            _this.doApi(option, scopeRow, data)
          }).catch(() => {});
        }else{
          this.doApi(option, scopeRow, data)
        }
      } else {
        this.$utils.warning(option.failContent ? option.failContent : '缺少参数，无法操作');
      }
    },

    doApi(option,scopeRow,data){
      // console.log(option);

      let _this = this;

      let _datas = {...data,...option.data};

      if(option) {
				if(option.showLoading){
					this.loading = this.$loading({
						lock: true,
						text: '加载中...',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
				}
        if (option.submitForm) {
          let _formData = this.$utils.deepCopy(this.$refs['editCom'].formData);

          _datas = {..._datas, ..._formData};
          // this.$refs['editCom'].formData = {...this.$refs['editCom'].formData, ...option.data};
          //
          // console.log(this.$refs['editCom'].formData);

          // this.$refs['editCom'].submit();
          this.$refs['editCom'].toCheck(()=>{
            _this.$http.request({
              url: option.path,
              method: option.method ? option.method : 'post',
              datas: _datas,
              success(res) {
                _this.$utils.success(option.successContent ? option.successContent : "操作成功！");
                _this.onComSuccess('edit');
								if(_this.loading) {
									_this.loading.close()
								}
              }
            });
          })
        } else {

          this.$http.request({
            url: option.path,
            method: option.method ? option.method : 'post',
            datas: _datas,
            success(res) {
              _this.$utils.success(option.successContent ? option.successContent : "操作成功！");

              _this.onComSuccess('edit');
	            if(_this.loading) {
		            _this.loading.close()
	            }
              // _this.$refs.mainCom.success();
              // _this.toClose(type);
            }
          });
        }
      }
    },

    //打开自定义弹框
    // toExt(data,options,list){
    //
    //   console.log(data,options,list);
    //
    //   if(options.com) {
    //     this.extDialog.id = data[this.listOptions.pk ? this.listOptions.pk : 'id'];
    //     this.extDialog.title = options.title ? options.title : '弹框';
    //     this.extDialog.info = data;
    //     this.extDialog.params = options.params;
    //
    //     this.tableList = list;
    //
    //     if (options.com) {
    //       this.ExtComponent = () => import('@/views' + options.com);
    //     }
    //
    //     this.showExtDialog = true;
    //   }else{
    //     this.$message.error('缺少组件');
    //   }
    // },

    toClose(type){
      let _upType = this.$utils.firstToUpper(type);
      this['show'+_upType] = false;

      this[type+'Path'] = '';
      this[type+'Title'] = '';
      this[type+'Data'] = {};

      this[_upType+'Component'] = {};
    },

	  toListExport(option, scopeRow, data,tableData,ids,listExportUrl){
			// console.log(option, scopeRow, data,tableData,ids,listExportUrl);
			// return;
			let _this = this;

			if(ids.length <= 0){
				this.$utils.error('请选择要导出的条目');
				return;
			}

			this.$http.request({
				url:listExportUrl,
				datas:{ids:ids},
				download:true,
				success(res){
					const blob = new Blob([res]) // 构造一个blob对象来处理数据
					let num = _this.$moment().format('YYYYMMDDHHmmss');

					const fileName = '事项导出_' + num + '.xlsx';
					const link = document.createElement('a') // 创建a标签
					link.download = fileName // a标签添加属性
					link.style.display = 'none'
					link.href = URL.createObjectURL(blob)
					document.body.appendChild(link)
					link.click();
					URL.revokeObjectURL(link.href);
					document.body.removeChild(link);
					_this.$utils.success("操作成功！");

					_this.$refs.mainCom.$refs.tableList.$refs.listtable.clearSelection();
				}
			})
	  },

		toListShenpi(option, scopeRow, data,tableData,ids,listShenpiUrl){
			let _this = this;

			if(ids.length <= 0){
				this.$utils.error('请选择要审核的条目');
				return;
			}

			this.list_ids = ids;
			this.list_url = listShenpiUrl;

			this.show_list_shenpi = true;
	  },

		toListReShenpi(option, scopeRow, data,tableData,ids,listReShenpiUrl){

			// console.log(option, scopeRow, data,tableData,ids,listReShenpiUrl)
			let _this = this;

			if(ids.length <= 0){
				this.$utils.error('请选择要审核的条目');
				return;
			}

			this.$confirm('重置状态，会清空所有汇报、督查记录、里程碑数据，是否继续？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				_this.$http.request({
					url:listReShenpiUrl,
					datas:{ids:ids},
					success(res){
						_this.$utils.success('操作成功');
						_this.onSuccess();
					}
				})
			}).catch(() => {});
		},

    //编辑
    toEdit(option, scopeRow, data,tableData) {
			// console.log(option, scopeRow, data,tableData)
      let _this = this;

      this.editPath = option && option.path ? option.path : this.mainPath;
      this.editTitle = option && option.title ? option.title : this.mainParam.name;

      this.editDialogWidth = this.mainParam.edit.options.editDialogWidth ? this.mainParam.edit.options.editDialogWidth : null;
      this.editData = data;
      this.editInfo = scopeRow;
      this.editExtButtons = this.mainParam.edit && this.mainParam.edit.extButtons ? this.mainParam.edit.extButtons : [];

      this.EditComponent = () => import('@/views/base/edit');

      this.showEdit = true;
    },

    onMouseMove(e){
      if(this.draging) {
        let _dialog = document.getElementsByClassName('drag-dialog')[0].getElementsByClassName('el-dialog')[0];
        // console.log('===');
        // console.log(e.movementX);
        // console.log(e.movementY);

        let _moveX = e.movementX;
        let _moveY = e.movementY;

        this.dialogLeft = (this.dialogLeft + _moveX <= 0) ? 0 : this.dialogLeft + _moveX;
        this.dialogTop = (this.dialogTop + _moveY <= 0) ? 0 : this.dialogTop + _moveY;

        _dialog.style.top = this.dialogTop + 'px';
        _dialog.style.left = this.dialogLeft + 'px';
      }
    },

    toList(option, scopeRow, data,tableData){
      this.listPath = option && option.path ? option.path : '';

      if(this.listPath) {
        this.listTitle = option && option.title ? option.title : '列表';
        this.listData = data;
        // console.log(data)

        this.ListComponent = () => import('@/views/base/list');

        this.showList = true;
      }

    },

	  toProgram(option, scopeRow, data,tableData){
			// console.log(option, scopeRow, data,tableData);
			let _this = this;
			if(option.path){
				if(option.path === 'banli'){
					// if(scopeRow.is_around === 1){
					// 	this.$confirm('该事项为周期性汇报，请到[办理情况]标签中进行汇报，是否跳转页面？', '提示', {
					// 		confirmButtonText: '确定',
					// 		cancelButtonText: '取消',
					// 		type: 'warning'
					// 	}).then(() => {
					// 		_this.$router.push({path:'/program/'+scopeRow.type+'/detail',query:{id:scopeRow.id,tab:'blqk'}})
					// 	}).catch(() => {});
					// }else{
						this.$http.request({
							url:'/Program/checkCanBanli',
							datas:{id:scopeRow.id},
							success(res){
								_this.programInfo = scopeRow;
								_this.$nextTick(()=>{
									_this['show_'+option.path] = true;
								})
							}
						})
					// }
				}else{
					this.programInfo = scopeRow;
					this['show_'+option.path] = true;
				}
			}
	  },

    toDiy(option, scopeRow, data,tableData){

      this.diyPath = option && option.path ? option.path : '';

      if(this.diyPath) {
        this.diyTitle = option && option.title ? option.title : '列表';
        this.diyData = data;
        this.diyOption = option;
        this.diyRow = scopeRow;
        this.diyTableData = tableData;

        this.DiyComponent = () => import('@/views'+option.path);

        this.showDiy = true;
      }
    },

    //查看详情
    toDetail(option, scopeRow, data,tableData) {
			// console.log(option, scopeRow, data,tableData);
      this.detailPath = option && option.path ? option.path : this.$route.path;

      if(this.detailPath) {
        let _detailParams = this.$baseConfig[this.detailPath].detail;

        this.detailTitle = option && option.title ? option.title : (_detailParams && _detailParams.options && _detailParams.options.title ? _detailParams.options.title : '详情');
        this.detailData = data;

        this.DetailComponent = () => import('@/views/base/detail');

        this.showDetail = true;
      }
    },

    toSubmit(type){
			console.log(this.$refs[type+'Com']);
      this.$refs[type+'Com'].submit();
    }
  },
  destroyed() {
    document.removeEventListener('mousemove', this.onMouseMove);
  }
}
</script>
