<template>
  <el-radio-group v-model="values" @change="change" :disabled="disabled">
    <el-radio :label="item.value" v-for="(item,index) in options">{{item.label}}</el-radio>
  </el-radio-group>
</template>

<script>
import emitter from "element-ui/src/mixins/emitter";
export default {
  name: "RadioGroup",
	mixins: [emitter],
  data() {
    return {
      values:''
    }
  },
  model:{
    prop:'value',
    event:'change'
  },
  props:{
    value:{
      type:[String,Number,Boolean,Object],
      default:''
    },
    options:{
      type:[Array,Object],
      default(){
        return []
      }
    },
    disabled:{
      type:Boolean,
      default:false
    }
  },
  created() {
    this.values = this.value;
  },
  mounted() {
  },
  methods: {
    change(e){
      this.$emit('change',e);
	    this.dispatch('ElFormItem', 'el.form.change', [e])
	    this.dispatch('ElFormItem', 'el.form.blur', [e])
    }
  },
  watch:{
    value(n,o){
      this.values = n;
    }
  }
}
</script>
