<template>
    <div class="margin-auto lee-time-line" v-if="list.length > 0">
        <div class="rel cl padding-top-bottom-20 time-line-box" v-for="(item,index) in list">
            <div class="abs line-view" :style="{backgroundColor:colors[index % 7]}"></div>
            <div class="abs f-12" style="top:50%;width: 50%;height: 40px;line-height: 40px;margin-top:-20px;" :style="index % 2 === 0 ? 'left:0;padding-right: 40px' : 'right:0;padding-left: 40px'" :class="index % 2 ? 'text-left' : 'text-right'">
	            {{item.create_time}}
<!--                <lee-state-tag :title="$moment(item.year).format('YYYY-MM')" :bg-color="colors[index % 7]"></lee-state-tag>-->
            </div>
            <div style="width: 50%;" :style="index % 2 === 0 ? 'padding-left: 40px' : 'padding-right: 40px'" :class="index % 2 ? 'z' : 'y'">
                <span class="block rel bg-light box-shadow-20" :class="index % 2 ? 'text-right time-line-body-left' : 'text-left time-line-body-right'">
                    {{item.content}}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "TimeLine",
    props:{
        list:{
            type:Array,
            default:()=>{
                return [];
            }
        }
    },
    data(){
        return{
            colors:[
                '#16ADA0',
                '#F38EDB',
                '#F6A640',
                '#E74594',
                '#8379BE',
                '#49B6F1',
                '#C8DA6A'
            ]
        }
    },
    created() {
        console.log(this.list);
    }
}
</script>
