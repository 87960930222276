<template>
    <span>
        <el-upload
	        :file-list="fileList"
	        :multiple="true"
	        :action="$config.api.uploadUrl"
	        list-type="picture-card"
	        :data="{key:imgKey,totype:totype}"
	        :headers="{Authorization:$user.getToken()}"
					:limit="limit"
	        :on-success="onSuccess"
	        :on-exceed="onExceed"
	        :on-preview="handlePictureCardPreview"
	        :accept="accept"
	        :on-remove="handleRemove">
            <i class="el-icon-plus"></i>
					<div slot="tip" class="mg-t-5" style="color:#777;line-height: 18px !important;">
						<div>文件类型：{{accept}}</div>
						<div>最多可以上传 {{limit}} 个文件</div>
					</div>
        </el-upload>
        <el-dialog :visible.sync="showBigImg" append-to-body>
            <img width="100%" :src="bigImg" alt="">
        </el-dialog>
    </span>
</template>

<script>
export default {
	name: "ImageUpload",
	data() {
		return {
			bigImg: '',
			showBigImg: false,
			fileList: [],
			values: [],
			ids: []
		};
	},
	model: {
		prop: 'value',
		event: 'change'
	},
	props: {
		accept:{
			type:String,
			default:'.jpg,.jpeg,.png,.gif'
		},
		imgKey: {
			type: String,
			default: 'blog'
		},
		totype: {
			type: String,
			default: 'oss'
		},
		value: {
			type: Array,
			default() {
				return []
			}
		},
		files: {
			type: Array,
			default() {
				return []
			}
		},
		limit:{
			type:Number,
			default:5
		}
	},
	created() {
		for (let i=0;i < this.files.length;i++){
			console.log(this.files[i].url.indexOf('http'));
			if(this.files[i].url.indexOf('http') < 0){
				this.files[i].url = this.$config.api.assetsUrl + this.files[i].url;
			}
		}
		this.fileList = this.files;
	},
	watch: {
		'value': {
			handler: function (n, o) {
				// console.log(n);
				this.values = n;
			},
			deep: true
		},
		files(n, o) {
			for (let i=0;i < n.length;i++){
				console.log(n[i].url.indexOf('http'));
				if(n[i].url.indexOf('http') < 0){
					n[i].url = this.$config.api.assetsUrl + n[i].url;
				}
			}
			this.fileList = n;
		}
	},
	methods: {
		handleRemove(file, fileList) {
			this.values = [];

			this.fileList = fileList;

			fileList.forEach(item => {
				this.values.push(item.id);
			})

			this.$emit('change', this.values);
		},
		handlePictureCardPreview(file) {
			this.bigImg = file.url;
			this.showBigImg = true;
		},
		onSuccess(response, file, fileList) {
			console.log(fileList);
			if (response.code !== 0) {
				this.$utils.error(response.msg);
				this.fileList = [];
			} else {
				// this.ids = [];

				this.fileList = fileList;

				// fileList.forEach(item=>{
				// 	console.log(item);
				this.values.push(response.data.id);
				// })

				this.$emit('change', this.values);
			}
		},
		onExceed(f, fl) {
			console.log(fl);
			this.$message.error('文件数量不能超过'+this.limit+'个')
		}
	}
}
</script>
