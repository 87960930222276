export default {
    '/total': {
        name: '数据统计',
        default:'/total/index',
        listRouter: '/dept',
        editRouter: '/dept/edit',
        addRouter: '/dept/add',
        detailRouter: '',
        editRole: 'setting:dept:edit',
        addRole: 'setting:dept:add',
        delRole: 'setting:dept:delete',
        list: {
            com: '',
        },
        edit: {
            com: '',
        },
        add: {
            com: '',
        },
        detail: {
            com: '',
            options: {
                method: 'get'
            },
        }
    },
}
