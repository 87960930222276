<template>
  <div>
    <el-tag
      :key="tag"
      v-for="tag in values"
      closable
      :disable-transitions="false"
      @close="handleClose(tag)" class="margin-right">
      {{tag}}
    </el-tag>
    <el-input
      class="input-new-tag"
      v-if="inputVisible"
      v-model="inputValue"
      ref="saveTagInput"
      size="small"
      @keyup.enter.native="handleInputConfirm"
      @blur="handleInputConfirm"
      style="width: 100px;"
    >
    </el-input>
    <el-button v-else class="button-new-tag" size="small" @click="showInput">+ 添加标签</el-button>
  </div>
</template>

<script>
export default {
  name: "TagsInput",
  data() {
    return {
      inputVisible: false,
      inputValue: '',
      values:[]
    }
  },
  props:{
    value:{
      type:Array,
      default(){
        return []
      }
    },
  },
  model:{
    prop:'value',
    event:'change'
  },
  created() {
    this.values = this.value;
  },
  mounted() {
  },
  methods: {
    handleClose(tag) {
      this.values.splice(this.values.indexOf(tag), 1);

      console.log(this.values);

      this.$emit('change',this.values);
    },

    showInput() {
      this.inputVisible = true;
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.values.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = '';

      console.log(this.values);

      this.$emit('change',this.values);
    },
  },
  watch:{
    value(n,o){
    //   console.log(n);
      this.values = n;
    }
  }
}
</script>
