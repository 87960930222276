<template>
	<div class="inline-block">
		<div :style="{width:width,height:height,lineHeight:height}" style="background-color: #eee;" class="text-center hand rel" @click="toShow">
			<img :src="img" class="abs" style="width: 100%;height: 100%;z-index: 1;left:0;top:0;" v-if="img"/>
			<i class="iconfont icon-bofang f-40 rel" style="z-index: 2;"></i>
		</div>
		<el-dialog :visible.sync="show" width="700px" v-if="show" append-to-body>
			<video width="100%" height="380" controls class="vm" autoplay>
				<source :src="values" type="video/mp4">
			</video>
			<span slot="footer" class="dialog-footer">
		    <el-button @click="show = false">关闭</el-button>
		  </span>
		</el-dialog>
	</div>
</template>

<script>
export default {
	name: "ZhbVideo",
	data() {
		return {
			values:'',
			show:false
		}
	},
	props:{
		value:{
			type:String,
			default:''
		},
		img:{
			type:String,
			default:''
		},
		width:{
			type:String,
			default:'200px'
		},
		height:{
			type:String,
			default:'100px'
		}
	},
	created() {
		this.values = this.value ? this.value : ''
	},
	mounted() {
	},
	methods: {
		toShow(){
			this.show = true;
		}
	},
	watch:{
		value(n,o){
			this.values = n ? n : ''
		}
	}
}
</script>
