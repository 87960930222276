<template>
	<el-dialog title="事项审批" :visible.sync="isShow" width="700px" append-to-body @close="onClose" @open="onOpen">
<!--		{{formData}}-->
		<el-form :model="formData" :rules="rules" ref="form" label-width="100px">
			<el-form-item label="审批状态" prop="shenpi_state">
				<type-select :is-top="false" :options="[{id:1,name:'通过'},{id:2,name:'驳回'}]" v-model="formData.shenpi_state" @change="onChangeShenpiState"></type-select>
			</el-form-item>
			<el-form-item label="驳回理由" prop="shenpi_remark" :rules="[{required:true,message:'驳回理由必填'}]" v-if="formData.shenpi_state === 2">
				<el-input type="textarea" v-model="formData.shenpi_remark"></el-input>
			</el-form-item>
			<el-form-item label="批示意见" prop="remark" v-if="formData.shenpi_state === 1">
				<el-input type="textarea" v-model="formData.shenpi_remark"></el-input>
			</el-form-item>
			<el-form-item label="修改办理情况"  v-if="formData.shenpi_state === 1">
				<one-checkbox v-model="editDo"></one-checkbox>
			</el-form-item>
			<template v-if="editDo === 1">
				<el-form-item label="办理状态" prop="state" :rules="[{required: true,message:'办理状态必选'}]">
					<type-select :is-top="false" :options="[{id:2,name:'正常推进中'},{id:1,name:'已办结'}]" v-model="formData.state"></type-select>
				</el-form-item>
				<el-form-item label="情况说明" prop="remark" :rules="[{required:true,message:'情况说明必填'}]">
					<el-input type="textarea" v-model="formData.remark" rows="6" maxlength="200" show-word-limit></el-input>
				</el-form-item>
				<el-form-item label="附件" prop="files_ids">
					<file-upload v-model="formData.files_ids" :files="files" img-key="program_file" totype="dingpan" :limit="5"></file-upload>
				</el-form-item>
				<el-form-item label="图片" prop="images_ids">
					<image-upload class="small-img-upload" v-model="formData.images_ids" :files="images" img-key="program_image"></image-upload>
				</el-form-item>
				<el-form-item label="视频" prop="videos_ids">
					<file-upload v-model="formData.videos_ids" :files="videos" accept=".mp4,.mov,.wmv" img-key="program_video"></file-upload>
				</el-form-item>
			</template>
		</el-form>
		<span slot="footer" class="dialog-footer">
			<el-button @click="isShow = false">关闭</el-button>
	    <el-button type="primary" @click="toSubmit">确定</el-button>
	  </span>
	</el-dialog>
</template>

<script>
export default {
	name: "ToDoShenpiDialog",
	data() {
		return {
			isShow:false,
			editDo:0,
			formData:{
				id:'',
				pid:'',
				shenpi_state:'',
				shenpi_remark:'',
				state:'',
				remark:'',
				files_ids:[],
				images_ids:[],
				videos_ids:[]
			},
			files:[],
			images:[],
			videos:[],
			rules:{
				shenpi_state:[{required: true,message:'审批状态必选'}]
			}
		}
	},
	props:{
		visible:{
			type:Boolean,
			default:false
		},
		id:{
			type:String,
			default:''
		},
		bid:{
			type:String,
			default:''
		}
	},
	model:{
		prop:'visible',
		event:'change'
	},
	created() {
		this.isShow = this.visible;
		this.formData.pid = this.id ? this.id : ''
		this.formData.id = this.bid ? this.bid : ''
	},
	mounted() {
		if(this.formData.id) {
			this.getDoDetail()
		}
	},
	methods: {
		onChangeShenpiState(e){
			// if(e === 2){
				this.editDo = 0;
			// }
		},
		getDoDetail(){
			let _this = this;
			_this.$http.request({
				url:'/ProgramDo/detail',
				datas:{id:this.formData.id},
				success(res){
					_this.formData.remark = res.remark;
					_this.formData.state = res.state;
					_this.formData.files_ids = res.files_ids;
					_this.formData.images_ids = res.images_ids;
					_this.formData.videos_ids = res.videos_ids;
					_this.videos = res.videos;
					_this.files = res.files;
					_this.images = res.images;
				}
			})
		},
		onOpen(){
			let _this = this;
			this.$nextTick(()=>{
				_this.$refs['form'].clearValidate()
			})
		},
		onClose(){
			this.formData.id = '';
			this.formData.pid = '';
			this.formData.shenpi_state = '';
			this.formData.shenpi_remark = '';
			this.formData.state = '';
			this.formData.remark = '';
			this.formData.files_ids = [];
			this.formData.images_ids = [];
			this.formData.videos_ids = [];
			this.videos = [];
			this.files = [];
			this.images = [];
			this.$emit('update:visible', false);
			this.$emit('close');
		},
		toSubmit(){
			let _this = this;
			this.$refs['form'].validate((valid) => {
				if (valid) {
					let _data = JSON.parse(JSON.stringify(_this.formData));

					if(_this.editDo === 0){
						delete _data.remark;
						delete _data.state;
						delete _data.files_ids;
						delete _data.images_ids;
						delete _data.videos_ids;
					}

					_this.$http.request({
						url:'/ProgramDo/toShenpi',
						datas:_data,
						success(res){
							_this.$message.success('操作成功');

							_this.$emit('success');

							_this.isShow = false;
						}
					})
				} else {
					_this.$message.error('请填写完整表单')
					return false;
				}
			});
		}
	},
	watch:{
		visible(n,o){
			this.isShow = n;
			this.formData.pid = this.id ? this.id : ''
			this.formData.id = this.bid ? this.bid : ''
			if(this.formData.id) {
				this.getDoDetail()
			}
		}
	}
}
</script>
