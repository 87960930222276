<template>
	<el-checkbox-group v-model="values" @change="change" :disabled="disabled">
		<el-checkbox  :label="item.value" v-for="(item,index) in options">{{item.label}}</el-checkbox>
	</el-checkbox-group>
</template>

<script>
import emitter from "element-ui/src/mixins/emitter";
export default {
  name: "CheckBoxGroup",
	mixins: [emitter],
  data() {
    return {
      values:''
    }
  },
  model:{
    prop:'value',
    event:'change'
  },
  props:{
    value:{
      type:Array,
      default(){
				return []
      }
    },
    options:{
      type:[Array,Object],
      default(){
        return []
      }
    },
    disabled:{
      type:Boolean,
      default:false
    }
  },
  created() {
    this.values = this.value;
  },
  mounted() {
  },
  methods: {
    change(e){
      this.$emit('change',e);
	    this.dispatch('ElFormItem', 'el.form.change', [e])
	    this.dispatch('ElFormItem', 'el.form.blur', [e])
    }
  },
  watch:{
    value(n,o){
      this.values = n;
    }
  }
}
</script>
