<template>
    <el-select v-model="values" placeholder="请选择" :clearable="clearable" @change="change" :multiple="multiple">
        <el-option
            v-for="item in list"
            :key="item.id"
            :label="item.name"
            :value="item.id"></el-option>
    </el-select>
</template>

<script>
export default {
    name: "RoleSelect",
    data(){
        return{
            list:[],
            values:'',
            name:''
        }
    },
    model:{
        prop:'value',
        event:'change'
    },
    props:{
        value:{
            type:[String,Number,Array],
            default:''
        },
        data:{
            type:[Array,Object],
            default(){
                return null
            }
        },
        options:{
            type:[Array,Object],
            default(){
                return null
            }
        },
        clearable:{
            type:Boolean,
            default:false
        },
      multiple:{
        type:Boolean,
        default:true
      }
    },
    created() {
        this.values = this.value;
    },
    watch:{
        value(n,o){
            this.values = n;
        }
    },
    mounted() {
        this.getList();
    },
    methods:{
        getList() {
            let _this = this;

            this.$http.request({
                url: '/SystemGroup/list',
                datas:null,
              method:'post',
                success(res) {
                    // console.log(res);
                    _this.list = res;
                }
            });
        },
        change(e){
            this.$emit("change", e);
        },
    }
}
</script>
