export default {
  '/program/ldjb': {
    name: '批示督办',
    listRouter: '/program/ldjb',
    editRouter: '',
    addRouter: '',
    delRouter: '',
    detailRouter: '',
    editRole: 'program:ldjb:edit',
    addRole: 'program:ldjb:add',
    delRole: 'program:ldjb:delete',
    detailRole: 'program:ldjb:detail',
    listExportRole: 'program:ldjb:listexport',
    listShenpiRole: 'program:ldjb:shenpi',
    addProp: {title: '新增事项'},
    editProp:{type:'url',path:'/program/ldjb/edit?type=ldjb',target:'_self'},
    // delDisabled:{rules:{key:'is_system',val:1}},
    editDisabled:{rules:{key:'state',val:[1,11],operator:'not in'}},
    delDisabled:{rules:{key:'state',val:[1,11],operator:'not in'}},
    topLeftButtons: [
      {label:'添加事项',role:'program:ldjb:add',icon:'el-icon-plus',type:'url',title:'编辑按钮权限',path:'/program/ldjb/add?type=ldjb',com:'add',size:'mini',btnType:'primary'},
      // {label:'添加事项',role:'program:ldjb:add',icon:'el-icon-plus',type:'url',title:'编辑按钮权限',path:'/program/ldjb/edit',com:'add',target:'_self',btnType:'primary'},
    ],
    list: {
      com: '',
      options: {
        listUrl: '/Program/listPage',
        statusUrl: '/Program/changeStatus',
        delUrl: '/Program/del',
        listExportUrl:'/Program/export',
        listShenpiUrl:'/Program/toListShenpi',
        listReShenpiUrl:'/Program/toListReShenpi',
        method: 'post',
        delMethod: 'post',
        data: {type: 'ldjb', orderBy: 'yldc_program.create_time', sortBy: 'desc'},
        showDept: false,
        showAdd: false,
        showDel: true,
        showEdit: true,
        showDetail: false,
        showListIndex: true,
        showSearch: true,
        showSideTree: false,
        showListExport:true,
        showListShenpi:true,
        showListReShenpi:true,
        listKey: 'list',
        listTree:false,
        optionsWidth:280
      },
      params: [
        {name:'关注',value:'is_favorite',type:'favorite',width: 50,props:{type:'api',path:'/UserLog/add',showConfirm:false,data:{akey:'program',type:'favorite'},pk:'tid'},tableKey:'is_favorite',onClass:'iconfont icon-guanzhu red-3 f-20',offClass:'iconfont dark-3 icon-a-wujiaoxingxingxing f-20'},
        {name: '事项类型', value: 'program_type.name',width:100},
        {name: '状态', value: 'state', type: 'programState',width: 120},
        {name: '事项内容', value: 'title', width: 250,type:'fun',callback:(row)=>{
            return '<a class="hand a-link f-u" href="/program/ldjb/detail?id='+row.id+'">'+row.title+'</a>'
          }
        },
        {name: '牵头单位', value: 'qtdws_str'},
        {name: '交办单位', value: 'dept.name'},
        {name: '事项期限', columns:['start_time','end_time'],width: 170,type:'inline'},
        {name: '交办时间', value: 'create_time', width: 150},
        {name: '发布人', value: 'user.name',width: 80},
        // {name: '最后修改时间', value: 'update_time', width: 150},
      ],
      extButtons: [
        //  label 按钮文字 ，role :权限标识  icon:图标，title：弹框的标题，type：按钮交互方式，可选值 fun(调用某个接口)，url(跳转路由)，dialog(已弹窗形式打开某一组件)，默认url，com：组件路径，path：组件路径,路由 或 接口地址
        {label:'详情',role:'program:ldjb:detail',icon:'el-icon-view',type:'url',title:'批示督办事项详情',path:'/program/ldjb/detail',target:'_self',btnType:'text'},
      ],
      dropExtButtons: [
        //  label 按钮文字 ，role :权限标识  icon:图标，title：弹框的标题，type：按钮交互方式，可选值 fun(调用某个接口)，url(跳转路由)，dialog(已弹窗形式打开某一组件)，默认url，com：组件路径，path：组件路径,路由 或 接口地址
        {label:'提醒设置',title:'提醒设置',role:'program:ldjb:tip',icon:'el-icon-video-pause',type:'diy',path:'/program/tip/list',target:'_self',btnType:'text',checkDisabled:true,rules:{key:'state',val:[2,3,6],operator:'in'},showSubmit:false},
        {label:'审核',role:'program:ldjb:shenpi',icon:'el-icon-circle-check',type:'program',path:'shenpi',target:'_self',btnType:'text',checkDisabled:true,rules:{key:'state',val:1}},
        {label:'反审核',role:'program:ldjb:reshenpi',icon:'el-icon-refresh',type:'api',path:'/Program/toReShenpi',target:'_self',btnType:'text',checkDisabled:true,rules:{key:'state',val:[2,6],operator:'in'},showConfirm:true,confirmContent:'重置状态，会清空所有汇报、督查记录、里程碑数据，是否继续？'},
        {label:'领办',role:'program:ldjb:pick',icon:'el-icon-upload2',type:'program',path:'lingqu',target:'_self',btnType:'text',checkDisabled:true,rules:[{key:'state',val:[2],operator:'in'},{key:'can_pick',val:1}]},
        // {label:'汇报',role:'program:ldjb:todo',icon:'el-icon-edit-outline',type:'program',path:'banli',target:'_self',btnType:'text',checkDisabled:true,rules:[{key:'state',val:[3],operator:'in'},{key:'is_around',val:1,operator:'==='},{key:'can_huibao',val:1}]},
        {label:'汇报',role:'program:ldjb:todo',icon:'el-icon-edit-outline',type:'program',path:'banli',target:'_self',btnType:'text',checkDisabled:true,rules:[{key:'state',val:[3],operator:'in'},{key:'can_huibao',val:1}]},
        {label:'催办',role:'program:ldjb:cuiban',icon:'el-icon-message-solid',type:'api',path:'/Program/toCuiban',target:'_self',btnType:'text',checkDisabled:true,rules:{key:'state',val:[3],operator:'in'},showConfirm:true,confirmContent:'是否要催办？'},
        {label:'催领',role:'program:ldjb:cuiling',icon:'el-icon-bell',type:'api',path:'/Program/toCuiling',target:'_self',btnType:'text',checkDisabled:true,rules:{key:'state',val:[2],operator:'in'},showConfirm:true,confirmContent:'是否要催领？'},
        {label:'延期',role:'program:ldjb:delay',icon:'el-icon-d-arrow-right',type:'program',path:'delay',target:'_self',btnType:'text',checkDisabled:true,rules:[{key:'state',val:[2,3],operator:'in'},{key:'is_long',val:0,operator:'==='}]},
        {label:'终止',role:'program:ldjb:stop',icon:'el-icon-video-pause',type:'program',path:'stop',target:'_self',btnType:'text',checkDisabled:true,rules:{key:'state',val:[2,3],operator:'in'}},
        {label:'修改状态',role:'program:ldjb:absstate',icon:'el-icon-video-pause',type:'program',path:'absstate',target:'_self',btnType:'text',checkDisabled:true,rules:{key:'state',val:[3,4],operator:'in'}},
      ]
    },
    edit: {
      com: '',
      options: {
        editUrl: '/Program/edit',
        detailUrl: '/Program/detail',
        addUrl: '/Program/add',
        editMethod: 'post',
        addMethod: 'post',
        detailMethod: 'post',
        pk: 'id',
        data: {type: 'ldjb'},
        copyData: false
      },
      params: [
        {name: '标题', value: 'title', edit: true, rq: true, max: 50},
        {name: '副标题', value: 'sub_title', edit: true, rq: false, max: 100},
        // {name: '所属产业', value: 'pid', edit: true, rq: true, type: 'select', url: '/SystemDict/list', data: {type: 'program', orderBy: 'sort', sortBy: 'asc'}, method: 'post'},
        {name: '封面图', value: 'head_img', edit: true, rq: false, type: 'image'},
        // {name: '排序', value: 'sort', def: '0', edit: true, rq: true, type: 'sort'},
        // {name: '状态', value: 'state', type: 'state', edit: true, rq: true},
      ]
    },
    add: {
      com: '',
    },
    detail: {
      com: '/program/detail',
    },
    search: {
      params: [
        {name: '事项内容', value: 'title'},
        // {name: '专题会议', value: 'special_id', type: 'baseTreeSelect',url:'/Special/listTree',isTop: true,valLabel:'title'},
        // {name: '事项类别', value: 'type', type: 'select',options:[{id:'ldjb',name:'批示督办'},{id:'zysx',name:'重要事项'},{id:'hysx',name:'会议督办'},{id:'qzfsx',name:'政务督办'}],isTop: true},
        // {name: '事项类别', value: 'type', type: 'select',options:[{id:'ldjb',name:'批示督办'},{id:'zysx',name:'日常督办'},{id:'hysx',name:'会议督办'}],isTop: true},
        {name: '事项类型', value: 'dict_program_ids', type: 'select',url:'/SystemDict/listAll',data:{type:'program',akey:'ldjb',orderBy:'sort',sortBy:'asc'},isTop: true},
        // {name: '事项类型', value: 'dict_program_ids', type: 'select',url:'/SystemDict/listAll',data:{type:'program',akey:'ldjb'},isTop:false,pidKey:'type',pidDataKey:'akey',autoLoad:false,placeholder:'请选择选择事项类别'},
        {name: '交办单位', value: 'dept_id', type: 'select',url:'/Dept/listAll',data:{state:1,orderBy:'sort',sortBy:'asc',is_qtdw:0,is_zrdw:0},valKey:'dept_id',filterable:true,isTop:false},
        {name: '牵头单位', value: 'qtdw', type: 'select',url:'/Dept/listAll',data:{state:1,orderBy:'sort',sortBy:'asc',is_qtdw:1},valKey:'dept_id',filterable:true,isTop:false},
        {name: '责任单位', value: 'zrdw', type: 'select',url:'/Dept/listAll',data:{state:1,orderBy:'sort',sortBy:'asc',is_zrdw:1},valKey:'dept_id',is_zrdw:1,filterable:true,isTop:false},
        {name: '状态', value: 'state', type: 'select', options: [{name: '汇报待我审批(领导审批)', id: 'lingdao_shenpi'},{name: '汇报待我督查', id: 'ducha'},{name: '已失效', id: 0}, {name: '待审批', id: 1}, {name: '待领办', id: 2}, {name: '办理中', id: 3}, {name: '已办结', id: 4}, {name: '已终止', id: 5}, {name: '已拒绝发布', id: 6}]},
      ]
    }
  },
  '/program/ldjb/edit':{
    name: '编辑批示督办',
    default:'/program/edit',
    listRouter: '/program',
    editRouter: '/program/edit',
    addRouter: '/program/add',
    detailRouter: '',
    editRole: 'system:zone:edit',
    addRole: 'system:zone:add',
    delRole: 'system:zone:delete',
  },
  '/program/ldjb/add':{
    name: '添加批示督办',
    default:'/program/edit',
    listRouter: '/program',
    editRouter: '/program/edit',
    addRouter: '/program/add',
    detailRouter: '',
    editRole: 'system:zone:edit',
    addRole: 'system:zone:add',
    delRole: 'system:zone:delete',
  },
  '/program/ldjb/detail':{
    name: '批示督办详情',
    default:'/program/detail',
    listRouter: '/program',
    editRouter: '/program/edit',
    addRouter: '/program/add',
    detailRouter: '/program/detail',
  },
  '/program/tip/list': {
    name: '提醒设置',
    default:'/program/tip/list',
    listRouter: '/program/tip',
    editRouter: '/program/tip/edit',
    addRouter: '/program/tip/add',
    delRouter: '/program/tip/del',
    detailRouter: '/program/tip/detail'
  },
  '/program/zysx': {
    name: '日常督办',
    listRouter: '/program/zysx',
    editRouter: '',
    addRouter: '',
    delRouter: '',
    detailRouter: '',
    editRole: 'program:zysx:edit',
    addRole: 'program:zysx:add',
    delRole: 'program:zysx:delete',
    listExportRole: 'program:zysx:listexport',
    addProp: {title: '新增事项'},
    editProp:{type:'url',path:'/program/zysx/edit?type=zysx',target:'_self'},
    topLeftButtons: [
      {label:'添加事项',role:'program:zysx:add',icon:'el-icon-plus',type:'url',title:'编辑按钮权限',path:'/program/zysx/add?type=zysx',com:'add',size:'mini',btnType:'primary'},
      // {label:'添加事项',role:'program:zysx:add',icon:'el-icon-plus',type:'url',title:'编辑按钮权限',path:'/program/zysx/edit',com:'add',target:'_self',btnType:'primary'},
    ],
    list: {
      com: '',
      options: {
        listUrl: '/Program/listPage',
        statusUrl: '/Program/changeStatus',
        delUrl: '/Program/del',
        listExportUrl:'/Program/export',
        listShenpiUrl:'/Program/toListShenpi',
        listReShenpiUrl:'/Program/toListReShenpi',
        method: 'post',
        delMethod: 'post',
        data: {type: 'zysx', orderBy: 'create_time', sortBy: 'desc'},
        showDept: false,
        showAdd: false,
        showDel: true,
        showEdit: true,
        showDetail: false,
        showListIndex: true,
        showListExport:true,
        showListShenpi:true,
        showListReShenpi:true,
        showSearch: true,
        showSideTree: false,
        listKey: 'list',
        listTree:false,
        optionsWidth:280
      },
      params: [
        {name:'关注',value:'is_favorite',type:'favorite',width: 50,props:{type:'api',path:'/UserLog/add',showConfirm:false,data:{akey:'program',type:'favorite'},pk:'tid'},tableKey:'is_favorite',onClass:'iconfont icon-guanzhu red-3 f-20',offClass:'iconfont dark-3 icon-a-wujiaoxingxingxing f-20'},
        {name: '事项类型', value: 'program_type.name',width:100},
        {name: '状态', value: 'state', type: 'programState',width: 120},
        {name: '事项内容', value: 'title', width: 250,type:'fun',callback:(row)=>{
            return '<a class="hand a-link f-u" href="/program/zysx/detail?id='+row.id+'">'+row.title+'</a>'
          }
        },
        {name: '牵头单位', value: 'qtdws_str'},
        {name: '交办单位', value: 'dept.name'},
        {name: '事项期限', columns:['start_time','end_time'],width: 170,type:'inline'},
        {name: '交办时间', value: 'create_time', width: 150},
        {name: '发布人', value: 'user.name',width: 80},
      ],
      extButtons: [
        //  label 按钮文字 ，role :权限标识  icon:图标，title：弹框的标题，type：按钮交互方式，可选值 fun(调用某个接口)，url(跳转路由)，dialog(已弹窗形式打开某一组件)，默认url，com：组件路径，path：组件路径,路由 或 接口地址
        {label:'详情',role:'program:zysx:detail',icon:'el-icon-view',type:'url',title:'日常督办事项详情',path:'/program/zysx/detail',target:'_self',btnType:'text'},
      ],
      dropExtButtons: [
        //  label 按钮文字 ，role :权限标识  icon:图标，title：弹框的标题，type：按钮交互方式，可选值 fun(调用某个接口)，url(跳转路由)，dialog(已弹窗形式打开某一组件)，默认url，com：组件路径，path：组件路径,路由 或 接口地址
        {label:'审核',role:'program:zysx:shenpi',icon:'el-icon-circle-check',type:'program',path:'shenpi',target:'_self',btnType:'text',checkDisabled:true,rules:{key:'state',val:1}},
        {label:'反审核',role:'program:zysx:reshenpi',icon:'el-icon-refresh',type:'api',path:'/Program/toReShenpi',target:'_self',btnType:'text',checkDisabled:true,rules:{key:'state',val:[2,6],operator:'in'},showConfirm:true,confirmContent:'重置状态，会清空所有汇报、督查记录、里程碑数据，是否继续？'},
        {label:'领办',role:'program:zysx:pick',icon:'el-icon-upload2',type:'program',path:'lingqu',target:'_self',btnType:'text',checkDisabled:true,rules:[{key:'state',val:[2],operator:'in'},{key:'can_pick',val:1}]},
        {label:'汇报',role:'program:zysx:todo',icon:'el-icon-edit-outline',type:'program',path:'banli',target:'_self',btnType:'text',checkDisabled:true,rules:[{key:'state',val:[3],operator:'in'},{key:'can_huibao',val:1}]},
        {label:'催办',role:'program:zysx:cuiban',icon:'el-icon-message-solid',type:'api',path:'/Program/toCuiban',target:'_self',btnType:'text',checkDisabled:true,rules:{key:'state',val:[3],operator:'in'},showConfirm:true,confirmContent:'是否要催办？'},
        {label:'催领',role:'program:zysx:cuiling',icon:'el-icon-bell',type:'api',path:'/Program/toCuiling',target:'_self',btnType:'text',checkDisabled:true,rules:{key:'state',val:[2],operator:'in'},showConfirm:true,confirmContent:'是否要催领？'},
        {label:'延期',role:'program:zysx:delay',icon:'el-icon-d-arrow-right',type:'program',path:'delay',target:'_self',btnType:'text',checkDisabled:true,rules:[{key:'state',val:[2,3],operator:'in'},{key:'is_long',val:0,operator:'==='}]},
        {label:'终止',role:'program:zysx:stop',icon:'el-icon-video-pause',type:'program',path:'stop',target:'_self',btnType:'text',checkDisabled:true,rules:{key:'state',val:[2,3],operator:'in'}},
      ]
    },
    edit: {
      com: '',
      options: {
        editUrl: '/Program/edit',
        detailUrl: '/Program/detail',
        addUrl: '/Program/add',
        editMethod: 'post',
        addMethod: 'post',
        detailMethod: 'post',
        pk: 'id',
        data: {type: 'zysx'},
        copyData: false
      },
      params: [
        {name: '标题', value: 'title', edit: true, rq: true, max: 50},
        {name: '副标题', value: 'sub_title', edit: true, rq: false, max: 100},
        // {name: '所属产业', value: 'pid', edit: true, rq: true, type: 'select', url: '/SystemDict/list', data: {type: 'program', orderBy: 'sort', sortBy: 'asc'}, method: 'post'},
        {name: '封面图', value: 'head_img', edit: true, rq: false, type: 'image'},
        // {name: '排序', value: 'sort', def: '0', edit: true, rq: true, type: 'sort'},
        // {name: '状态', value: 'state', type: 'state', edit: true, rq: true},
      ]
    },
    add: {
      com: '',
    },
    detail: {
      com: '',
    },
    search: {
      params: [
        {name: '事项内容', value: 'title'},
        // {name: '专题会议', value: 'special_id', type: 'baseTreeSelect',url:'/Special/listTree',isTop: true,valLabel:'title'},
        // {name: '事项类别', value: 'type', type: 'select',options:[{id:'ldjb',name:'批示督办'},{id:'zysx',name:'重要事项'},{id:'hysx',name:'会议督办'},{id:'qzfsx',name:'政务督办'}],isTop: true},
        // {name: '事项类别', value: 'type', type: 'select',options:[{id:'ldjb',name:'批示督办'},{id:'zysx',name:'日常督办'},{id:'hysx',name:'会议督办'}],isTop: true},
        {name: '事项类型', value: 'dict_program_ids', type: 'select',url:'/SystemDict/listAll',data:{type:'program',akey:'zysx',orderBy:'sort',sortBy:'asc'},isTop: true},
        // {name: '事项类型', value: 'dict_program_ids', type: 'select',url:'/SystemDict/listAll',data:{type:'program',akey:'ldjb'},isTop:false,pidKey:'type',pidDataKey:'akey',autoLoad:false,placeholder:'请选择选择事项类别'},
        {name: '交办单位', value: 'dept_id', type: 'select',url:'/Dept/listAll',data:{state:1,orderBy:'sort',sortBy:'asc',is_qtdw:0,is_zrdw:0},valKey:'dept_id',filterable:true,isTop:false},
        {name: '牵头单位', value: 'qtdw', type: 'select',url:'/Dept/listAll',data:{state:1,orderBy:'sort',sortBy:'asc',is_qtdw:1},valKey:'dept_id',filterable:true,isTop:false},
        {name: '责任单位', value: 'zrdw', type: 'select',url:'/Dept/listAll',data:{state:1,orderBy:'sort',sortBy:'asc',is_zrdw:1},valKey:'dept_id',is_zrdw:1,filterable:true,isTop:false},
        {name: '状态', value: 'state', type: 'select', options: [{name: '汇报待我审批(领导审批)', id: 'lingdao_shenpi'},{name: '汇报待我督查', id: 'ducha'},{name: '已失效', id: 0}, {name: '待审批', id: 1}, {name: '待领办', id: 2}, {name: '办理中', id: 3}, {name: '已办结', id: 4}, {name: '已终止', id: 5}, {name: '已拒绝发布', id: 6}]},
        // {name: '状态', value: 'state', type: 'select', options: [{name: '已失效', id: 0}, {name: '待审批', id: 1}, {name: '待领办', id: 2}, {name: '待领办(已逾期)', id: 3}, {name: '办理中', id: 4}, {name: '退回办理', id: 5}, {name: '延期办理', id: 6}, {name: '异议事项', id: 7}, {name: '逾期办结', id: 8}, {name: '已办结', id: 9}, {name: '已终止', id: 10}, {name: '已拒绝发布', id: 11}]},
      ]
    }
  },
  '/program/zysx/edit':{
    name: '编辑日常督办',
    default:'/program/edit',
    listRouter: '/program',
    editRouter: '/program/edit',
    addRouter: '/program/add',
    detailRouter: '',
    editRole: 'system:zone:edit',
    addRole: 'system:zone:add',
    delRole: 'system:zone:delete',
  },
  '/program/zysx/add':{
    name: '添加日常督办',
    default:'/program/edit',
    listRouter: '/program',
    editRouter: '/program/edit',
    addRouter: '/program/add',
    detailRouter: '',
    editRole: 'system:zone:edit',
    addRole: 'system:zone:add',
    delRole: 'system:zone:delete',
  },
  '/program/zysx/detail':{
    name: '日常督办详情',
    default:'/program/detail',
    listRouter: '/program',
    editRouter: '/program/edit',
    addRouter: '/program/add',
    detailRouter: '/program/detail',
    // editRole: 'system:zone:edit',
    // addRole: 'system:zone:add',
    // delRole: 'system:zone:delete',
  },
  
  '/program/qzfsx': {
    name: '政务督办',
    listRouter: '/program/qzfsx',
    editRouter: '',
    addRouter: '',
    delRouter: '',
    detailRouter: '',
    editRole: 'program:qzfsx:edit',
    addRole: 'program:qzfsx:add',
    delRole: 'program:qzfsx:delete',
    listExportRole: 'program:qzfsx:listexport',
    addProp: {title: '新增事项'},
    editProp:{type:'url',path:'/program/qzfsx/edit?type=qzfsx',target:'_self'},
    topLeftButtons: [
      {label:'添加事项',role:'program:qzfsx:add',icon:'el-icon-plus',type:'url',title:'编辑按钮权限',path:'/program/qzfsx/add?type=qzfsx',com:'add',size:'mini',btnType:'primary'},
      // {label:'添加事项',role:'program:qzfsx:add',icon:'el-icon-plus',type:'url',title:'编辑按钮权限',path:'/program/qzfsx/edit',com:'add',target:'_self',btnType:'primary'},
    ],
    list: {
      com: '',
      options: {
        listUrl: '/Program/listPage',
        statusUrl: '/Program/changeStatus',
        delUrl: '/Program/del',
        listExportUrl:'/Program/export',
        listShenpiUrl:'/Program/toListShenpi',
        listReShenpiUrl:'/Program/toListReShenpi',
        method: 'post',
        delMethod: 'post',
        data: {type: 'qzfsx', orderBy: 'create_time', sortBy: 'desc'},
        showDept: false,
        showAdd: false,
        showDel: true,
        showEdit: true,
        showDetail: false,
        showListIndex: true,
        showListExport:true,
        showListShenpi:true,
        showListReShenpi:true,
        showSearch: true,
        showSideTree: false,
        listKey: 'list',
        listTree:false,
        optionsWidth:280
      },
      params: [
        {name:'关注',value:'is_favorite',type:'favorite',width: 50,props:{type:'api',path:'/UserLog/add',showConfirm:false,data:{akey:'program',type:'favorite'},pk:'tid'},tableKey:'is_favorite',onClass:'iconfont icon-guanzhu red-3 f-20',offClass:'iconfont dark-3 icon-a-wujiaoxingxingxing f-20'},
        {name: '事项类型', value: 'program_type.name',width:100},
        {name: '状态', value: 'state', type: 'programState',width: 120},
        {name: '事项内容', value: 'title', width: 250,type:'fun',callback:(row)=>{
            return '<a class="hand a-link f-u" href="/program/qzfsx/detail?id='+row.id+'">'+row.title+'</a>'
          }
        },
        {name: '牵头单位', value: 'qtdws_str'},
        {name: '交办单位', value: 'dept.name'},
        {name: '事项期限', columns:['start_time','end_time'],width: 170,type:'inline'},
        {name: '交办时间', value: 'create_time', width: 150},
        {name: '发布人', value: 'user.name',width: 80},
      ],
      extButtons: [
        //  label 按钮文字 ，role :权限标识  icon:图标，title：弹框的标题，type：按钮交互方式，可选值 fun(调用某个接口)，url(跳转路由)，dialog(已弹窗形式打开某一组件)，默认url，com：组件路径，path：组件路径,路由 或 接口地址
        {label:'详情',role:'program:qzfsx:detail',icon:'el-icon-view',type:'url',title:'政务督办详情',path:'/program/qzfsx/detail',target:'_self',btnType:'text'},
      ],
      dropExtButtons: [
        //  label 按钮文字 ，role :权限标识  icon:图标，title：弹框的标题，type：按钮交互方式，可选值 fun(调用某个接口)，url(跳转路由)，dialog(已弹窗形式打开某一组件)，默认url，com：组件路径，path：组件路径,路由 或 接口地址
        {label:'审核',role:'program:qzfsx:shenpi',icon:'el-icon-circle-check',type:'program',path:'shenpi',target:'_self',btnType:'text',checkDisabled:true,rules:{key:'state',val:1}},
        {label:'反审核',role:'program:qzfsx:reshenpi',icon:'el-icon-refresh',type:'api',path:'/Program/toReShenpi',target:'_self',btnType:'text',checkDisabled:true,rules:{key:'state',val:[2,6],operator:'in'},showConfirm:true,confirmContent:'重置状态，会清空所有汇报、督查记录、里程碑数据，是否继续？'},
        {label:'领办',role:'program:qzfsx:pick',icon:'el-icon-upload2',type:'program',path:'lingqu',target:'_self',btnType:'text',checkDisabled:true,rules:[{key:'state',val:[2],operator:'in'},{key:'can_pick',val:1}]},
        {label:'汇报',role:'program:qzfsx:todo',icon:'el-icon-edit-outline',type:'program',path:'banli',target:'_self',btnType:'text',checkDisabled:true,rules:[{key:'state',val:[3],operator:'in'},{key:'can_huibao',val:1}]},
        {label:'催办',role:'program:qzfsx:cuiban',icon:'el-icon-message-solid',type:'api',path:'/Program/toCuiban',target:'_self',btnType:'text',checkDisabled:true,rules:{key:'state',val:[3],operator:'in'},showConfirm:true,confirmContent:'是否要催办？'},
        {label:'催领',role:'program:qzfsx:cuiling',icon:'el-icon-bell',type:'api',path:'/Program/toCuiling',target:'_self',btnType:'text',checkDisabled:true,rules:{key:'state',val:[2],operator:'in'},showConfirm:true,confirmContent:'是否要催领？'},
        {label:'延期',role:'program:qzfsx:delay',icon:'el-icon-d-arrow-right',type:'program',path:'delay',target:'_self',btnType:'text',checkDisabled:true,rules:[{key:'state',val:[2,3],operator:'in'},{key:'is_long',val:0,operator:'==='}]},
        {label:'终止',role:'program:qzfsx:stop',icon:'el-icon-video-pause',type:'program',path:'stop',target:'_self',btnType:'text',checkDisabled:true,rules:{key:'state',val:[2,3],operator:'in'}},
      ]
    },
    edit: {
      com: '',
      options: {
        editUrl: '/Program/edit',
        detailUrl: '/Program/detail',
        addUrl: '/Program/add',
        editMethod: 'post',
        addMethod: 'post',
        detailMethod: 'post',
        pk: 'id',
        data: {type: 'qzfsx'},
        copyData: false
      },
      params: [
        {name: '标题', value: 'title', edit: true, rq: true, max: 50},
        {name: '副标题', value: 'sub_title', edit: true, rq: false, max: 100},
        // {name: '所属产业', value: 'pid', edit: true, rq: true, type: 'select', url: '/SystemDict/list', data: {type: 'program', orderBy: 'sort', sortBy: 'asc'}, method: 'post'},
        {name: '封面图', value: 'head_img', edit: true, rq: false, type: 'image'},
        // {name: '排序', value: 'sort', def: '0', edit: true, rq: true, type: 'sort'},
        // {name: '状态', value: 'state', type: 'state', edit: true, rq: true},
      ]
    },
    add: {
      com: '',
    },
    detail: {
      com: '',
    },
    search: {
      params: [
        {name: '事项内容', value: 'title'},
        // {name: '专题会议', value: 'special_id', type: 'baseTreeSelect',url:'/Special/listTree',isTop: true,valLabel:'title'},
        // {name: '事项类别', value: 'type', type: 'select',options:[{id:'ldjb',name:'批示督办'},{id:'zysx',name:'重要事项'},{id:'hysx',name:'会议督办'},{id:'qzfsx',name:'政务督办'}],isTop: true},
        // {name: '事项类别', value: 'type', type: 'select',options:[{id:'ldjb',name:'批示督办'},{id:'zysx',name:'日常督办'},{id:'hysx',name:'会议督办'}],isTop: true},
        {name: '事项类型', value: 'dict_program_ids', type: 'select',url:'/SystemDict/listAll',data:{type:'program',akey:'qzfsx',orderBy:'sort',sortBy:'asc'},isTop: true},
        // {name: '事项类型', value: 'dict_program_ids', type: 'select',url:'/SystemDict/listAll',data:{type:'program',akey:'ldjb'},isTop:false,pidKey:'type',pidDataKey:'akey',autoLoad:false,placeholder:'请选择选择事项类别'},
        {name: '交办单位', value: 'dept_id', type: 'select',url:'/Dept/listAll',data:{state:1,orderBy:'sort',sortBy:'asc',is_qtdw:0,is_zrdw:0},valKey:'dept_id',filterable:true,isTop:false},
        {name: '牵头单位', value: 'qtdw', type: 'select',url:'/Dept/listAll',data:{state:1,orderBy:'sort',sortBy:'asc',is_qtdw:1},valKey:'dept_id',filterable:true,isTop:false},
        {name: '责任单位', value: 'zrdw', type: 'select',url:'/Dept/listAll',data:{state:1,orderBy:'sort',sortBy:'asc',is_zrdw:1},valKey:'dept_id',is_zrdw:1,filterable:true,isTop:false},
        {name: '状态', value: 'state', type: 'select', options: [{name: '汇报待我审批(领导审批)', id: 'lingdao_shenpi'},{name: '汇报待我督查', id: 'ducha'},{name: '已失效', id: 0}, {name: '待审批', id: 1}, {name: '待领办', id: 2}, {name: '办理中', id: 3}, {name: '已办结', id: 4}, {name: '已终止', id: 5}, {name: '已拒绝发布', id: 6}]},
        // {name: '状态', value: 'state', type: 'select', options: [{name: '已失效', id: 0}, {name: '待审批', id: 1}, {name: '待领办', id: 2}, {name: '待领办(已逾期)', id: 3}, {name: '办理中', id: 4}, {name: '退回办理', id: 5}, {name: '延期办理', id: 6}, {name: '异议事项', id: 7}, {name: '逾期办结', id: 8}, {name: '已办结', id: 9}, {name: '已终止', id: 10}, {name: '已拒绝发布', id: 11}]},
      ]
    }
  },
  '/program/qzfsx/edit':{
    name: '编辑政务督办',
    default:'/program/edit',
    listRouter: '/program',
    editRouter: '/program/edit',
    addRouter: '/program/add',
    detailRouter: '',
    editRole: 'system:zone:edit',
    addRole: 'system:zone:add',
    delRole: 'system:zone:delete',
  },
  '/program/qzfsx/add':{
    name: '添加政务督办',
    default:'/program/edit',
    listRouter: '/program',
    editRouter: '/program/edit',
    addRouter: '/program/add',
    detailRouter: '',
    editRole: 'system:zone:edit',
    addRole: 'system:zone:add',
    delRole: 'system:zone:delete',
  },
  '/program/qzfsx/detail':{
    name: '政务督办详情',
    default:'/program/detail',
    listRouter: '/program',
    editRouter: '/program/edit',
    addRouter: '/program/add',
    detailRouter: '/program/detail',
    // editRole: 'system:zone:edit',
    // addRole: 'system:zone:add',
    // delRole: 'system:zone:delete',
  },
  
  '/program/hysx': {
    name: '会议督办',
    listRouter: '/program/hysx',
    editRouter: '',
    addRouter: '',
    delRouter: '',
    detailRouter: '',
    editRole: 'program:hysx:edit',
    addRole: 'program:hysx:add',
    delRole: 'program:hysx:delete',
    listExportRole: 'program:hysx:listexport',
    addProp: {title: '新增事项'},
    editProp:{type:'url',path:'/program/hysx/edit?type=hysx',target:'_self'},
    topLeftButtons: [
      {label:'添加事项',role:'program:hysx:add',icon:'el-icon-plus',type:'url',title:'编辑按钮权限',path:'/program/hysx/add?type=hysx',com:'add',size:'mini',btnType:'primary'},
      // {label:'添加事项',role:'program:hysx:add',icon:'el-icon-plus',type:'url',title:'编辑按钮权限',path:'/program/hysx/edit',com:'add',target:'_self',btnType:'primary'},
    ],
    list: {
      com: '',
      options: {
        listUrl: '/Program/listPage',
        statusUrl: '/Program/changeStatus',
        delUrl: '/Program/del',
        listExportUrl:'/Program/export',
        listShenpiUrl:'/Program/toListShenpi',
        listReShenpiUrl:'/Program/toListReShenpi',
        method: 'post',
        delMethod: 'post',
        data: {type: 'hysx', orderBy: 'create_time', sortBy: 'desc'},
        showDept: false,
        showAdd: false,
        showDel: true,
        showEdit: true,
        showDetail: false,
        showListIndex: true,
        showListExport: true,
        showListShenpi: true,
        showListReShenpi: true,
        showSearch: true,
        showSideTree: false,
        listKey: 'list',
        listTree:false,
        optionsWidth:280
      },
      params: [
        {name:'关注',value:'is_favorite',type:'favorite',width: 50,props:{type:'api',path:'/UserLog/add',showConfirm:false,data:{akey:'program',type:'favorite'},pk:'tid'},tableKey:'is_favorite',onClass:'iconfont icon-guanzhu red-3 f-20',offClass:'iconfont dark-3 icon-a-wujiaoxingxingxing f-20'},
        {name: '事项类型', value: 'program_type.name',width:100},
        {name: '状态', value: 'state', type: 'programState',width: 120},
        {name: '事项内容', value: 'title', width: 250,type:'fun',callback:(row)=>{
            return '<a class="hand a-link f-u" href="/program/hysx/detail?id='+row.id+'">'+row.title+'</a>'
          }
        },
        {name: '牵头单位', value: 'qtdws_str'},
        {name: '交办单位', value: 'dept.name'},
        {name: '事项期限', columns:['start_time','end_time'],width: 170,type:'inline'},
        {name: '交办时间', value: 'create_time', width: 150},
        {name: '发布人', value: 'user.name',width: 80},
      ],
      extButtons: [
        //  label 按钮文字 ，role :权限标识  icon:图标，title：弹框的标题，type：按钮交互方式，可选值 fun(调用某个接口)，url(跳转路由)，dialog(已弹窗形式打开某一组件)，默认url，com：组件路径，path：组件路径,路由 或 接口地址
        {label:'详情',role:'program:hysx:detail',icon:'el-icon-view',type:'url',title:'会议督办详情',path:'/program/hysx/detail',target:'_self',btnType:'text'},
      ],
      dropExtButtons: [
        //  label 按钮文字 ，role :权限标识  icon:图标，title：弹框的标题，type：按钮交互方式，可选值 fun(调用某个接口)，url(跳转路由)，dialog(已弹窗形式打开某一组件)，默认url，com：组件路径，path：组件路径,路由 或 接口地址
        {label:'审核',role:'program:hysx:shenpi',icon:'el-icon-circle-check',type:'program',path:'shenpi',target:'_self',btnType:'text',checkDisabled:true,rules:{key:'state',val:1}},
        {label:'反审核',role:'program:hysx:reshenpi',icon:'el-icon-refresh',type:'api',path:'/Program/toReShenpi',target:'_self',btnType:'text',checkDisabled:true,rules:{key:'state',val:[2,6],operator:'in'},showConfirm:true,confirmContent:'重置状态，会清空所有汇报、督查记录、里程碑数据，是否继续？'},
        {label:'领办',role:'program:hysx:pick',icon:'el-icon-upload2',type:'program',path:'lingqu',target:'_self',btnType:'text',checkDisabled:true,rules:[{key:'state',val:[2],operator:'in'},{key:'can_pick',val:1}]},
        {label:'汇报',role:'program:hysx:todo',icon:'el-icon-edit-outline',type:'program',path:'banli',target:'_self',btnType:'text',checkDisabled:true,rules:[{key:'state',val:[3],operator:'in'},{key:'can_huibao',val:1}]},
        {label:'催办',role:'program:hysx:cuiban',icon:'el-icon-message-solid',type:'api',path:'/Program/toCuiban',target:'_self',btnType:'text',checkDisabled:true,rules:{key:'state',val:[3],operator:'in'},showConfirm:true,confirmContent:'是否要催办？'},
        {label:'催领',role:'program:hysx:cuiling',icon:'el-icon-bell',type:'api',path:'/Program/toCuiling',target:'_self',btnType:'text',checkDisabled:true,rules:{key:'state',val:[2],operator:'in'},showConfirm:true,confirmContent:'是否要催领？'},
        {label:'延期',role:'program:hysx:delay',icon:'el-icon-d-arrow-right',type:'program',path:'delay',target:'_self',btnType:'text',checkDisabled:true,rules:[{key:'state',val:[2,3],operator:'in'},{key:'is_long',val:0,operator:'==='}]},
        {label:'终止',role:'program:hysx:stop',icon:'el-icon-video-pause',type:'program',path:'stop',target:'_self',btnType:'text',checkDisabled:true,rules:{key:'state',val:[2,3],operator:'in'}},
      ]
    },
    edit: {
      com: '',
      options: {
        editUrl: '/Program/edit',
        detailUrl: '/Program/detail',
        addUrl: '/Program/add',
        editMethod: 'post',
        addMethod: 'post',
        detailMethod: 'post',
        pk: 'id',
        data: {type: 'hysx'},
        copyData: false
      },
      params: [
        {name: '标题', value: 'title', edit: true, rq: true, max: 50},
        {name: '副标题', value: 'sub_title', edit: true, rq: false, max: 100},
        // {name: '所属产业', value: 'pid', edit: true, rq: true, type: 'select', url: '/SystemDict/list', data: {type: 'program', orderBy: 'sort', sortBy: 'asc'}, method: 'post'},
        {name: '封面图', value: 'head_img', edit: true, rq: false, type: 'image'},
        // {name: '排序', value: 'sort', def: '0', edit: true, rq: true, type: 'sort'},
        // {name: '状态', value: 'state', type: 'state', edit: true, rq: true},
      ]
    },
    add: {
      com: '',
    },
    detail: {
      com: '',
    },
    search: {
      params: [
        {name: '事项内容', value: 'title'},
        {name: '专题会议', value: 'special_id', type: 'baseTreeSelect',url:'/Special/listTree',isTop: true,valLabel:'title'},
        // {name: '事项类别', value: 'type', type: 'select',options:[{id:'ldjb',name:'批示督办'},{id:'zysx',name:'重要事项'},{id:'hysx',name:'会议督办'},{id:'qzfsx',name:'政务督办'}],isTop: true},
        // {name: '事项类别', value: 'type', type: 'select',options:[{id:'ldjb',name:'批示督办'},{id:'zysx',name:'日常督办'},{id:'hysx',name:'会议督办'}],isTop: true},
        {name: '事项类型', value: 'dict_program_ids', type: 'select',url:'/SystemDict/listAll',data:{type:'program',akey:'hysx',orderBy:'sort',sortBy:'asc'},isTop: true},
        // {name: '事项类型', value: 'dict_program_ids', type: 'select',url:'/SystemDict/listAll',data:{type:'program',akey:'ldjb'},isTop:false,pidKey:'type',pidDataKey:'akey',autoLoad:false,placeholder:'请选择选择事项类别'},
        {name: '交办单位', value: 'dept_id', type: 'select',url:'/Dept/listAll',data:{state:1,orderBy:'sort',sortBy:'asc',is_qtdw:0,is_zrdw:0},valKey:'dept_id',filterable:true,isTop:false},
        {name: '牵头单位', value: 'qtdw', type: 'select',url:'/Dept/listAll',data:{state:1,orderBy:'sort',sortBy:'asc',is_qtdw:1},valKey:'dept_id',filterable:true,isTop:false},
        {name: '责任单位', value: 'zrdw', type: 'select',url:'/Dept/listAll',data:{state:1,orderBy:'sort',sortBy:'asc',is_zrdw:1},valKey:'dept_id',is_zrdw:1,filterable:true,isTop:false},
        {name: '状态', value: 'state', type: 'select', options: [{name: '汇报待我审批(领导审批)', id: 'lingdao_shenpi'},{name: '汇报待我督查', id: 'ducha'},{name: '已失效', id: 0}, {name: '待审批', id: 1}, {name: '待领办', id: 2}, {name: '办理中', id: 3}, {name: '已办结', id: 4}, {name: '已终止', id: 5}, {name: '已拒绝发布', id: 6}]},
        // {name: '状态', value: 'state', type: 'select', options: [{name: '已失效', id: 0}, {name: '待审批', id: 1}, {name: '待领办', id: 2}, {name: '待领办(已逾期)', id: 3}, {name: '办理中', id: 4}, {name: '退回办理', id: 5}, {name: '延期办理', id: 6}, {name: '异议事项', id: 7}, {name: '逾期办结', id: 8}, {name: '已办结', id: 9}, {name: '已终止', id: 10}, {name: '已拒绝发布', id: 11}]},
      ]
    }
  },
  '/program/hysx/edit':{
    name: '编辑会议督办',
    default:'/program/edit',
    listRouter: '/program',
    editRouter: '/program/edit',
    addRouter: '/program/add',
    detailRouter: '',
    editRole: 'system:zone:edit',
    addRole: 'system:zone:add',
    delRole: 'system:zone:delete',
  },
  '/program/hysx/add':{
    name: '添加会议督办',
    default:'/program/edit',
    listRouter: '/program',
    editRouter: '/program/edit',
    addRouter: '/program/add',
    detailRouter: '',
    editRole: 'system:zone:edit',
    addRole: 'system:zone:add',
    delRole: 'system:zone:delete',
  },
  '/program/hysx/detail':{
    name: '会议督办详情',
    default:'/program/detail',
    listRouter: '/program',
    editRouter: '/program/edit',
    addRouter: '/program/add',
    detailRouter: '/program/detail',
    // editRole: 'system:zone:edit',
    // addRole: 'system:zone:add',
    // delRole: 'system:zone:delete',
  },
  '/program/import':{
    name:'事项导入',
    default: '/program/import',
    listRouter:'/program/import'
  },
}
