<template>
	<el-dialog title="事项汇报" :visible.sync="isShow" width="700px" append-to-body @close="onClose" @open="onOpen">
<!--		{{formData}}-->
		<el-form :model="formData" :rules="rules" ref="form" label-width="100px">
			<el-form-item label="办理状态" prop="state">
				<type-select :is-top="false" :options="[{id:2,name:'正常推进中'},{id:1,name:'已办结'}]" v-model="formData.state"></type-select>
			</el-form-item>
			<el-form-item label="情况说明" prop="remark" :rules="[{required:true,message:'情况说明必填'}]">
				<el-input type="textarea" v-model="formData.remark" rows="6" maxlength="200" show-word-limit></el-input>
			</el-form-item>
			<el-form-item label="领导审批" prop="need_shenpi">
				<el-switch
					:disabled="disShenpi"
					v-model="formData.need_shenpi"
					active-color="#13ce66"
					:active-value="1"
					:inactive-value="0"
					active-text="是"
					inactive-text="否">
				</el-switch>
			</el-form-item>
			<el-form-item label="是否发送短信" prop="send_sms">
				<one-checkbox v-model="formData.send_sms" @change="onChangeSms">是</one-checkbox>
			</el-form-item>
			<el-form-item label="接收短信人员" prop="sms_ids" v-if="personList.length > 0">
				<el-table :data="personList" border style="width: 100%" size="mini" ref="list" @selection-change="onSelectionChange" row-key="id">
					<el-table-column type="selection" width="55px"></el-table-column>
					<el-table-column prop="name" label="姓名"></el-table-column>
					<el-table-column prop="dept_name" label="部门"></el-table-column>
					<el-table-column prop="mobile" label="手机号"></el-table-column>
					<el-table-column prop="type_str" label="职责单位"></el-table-column>
					<el-table-column prop="job_str" label="事项职责"></el-table-column>
				</el-table>
			</el-form-item>
			<el-form-item label="附件" prop="files_ids">
				<file-upload v-model="formData.files_ids" accept=".doc,.docx,.ppt,.pptx,.pdf,.xls,.xlsx,.txt" :files="files" img-key="program_file" totype="dingpan" :limit="5"></file-upload>
			</el-form-item>
			<el-form-item label="图片" prop="images_ids">
				<image-upload class="small-img-upload" v-model="formData.images_ids" :files="images" img-key="program_image"></image-upload>
			</el-form-item>
			<el-form-item label="视频" prop="videos_ids">
				<file-upload v-model="formData.videos_ids" accept=".mp4" :files="videos" img-key="program_video"></file-upload>
			</el-form-item>
		</el-form>
		<span slot="footer" class="dialog-footer">
			<el-button @click="isShow = false">关闭</el-button>
	    <el-button type="primary" @click="toSubmit">确定</el-button>
	  </span>
	</el-dialog>
</template>

<script>
export default {
	name: "BanliDialog",
	data() {
		return {
			isShow:false,
			formData:{
				id:'',
				pid:'',
				state:'',
				remark:'',
				files_ids:[],
				images_ids:[],
				videos_ids:[],
				send_sms:0,
				sms_ids:[],
				need_shenpi:1
			},
			disShenpi:false,
			files:[],
			images:[],
			videos:[],
			personList:[],
			rules:{
				state:[{required: true,message:'状态必选'}]
			}
		}
	},
	props:{
		visible:{
			type:Boolean,
			default:false
		},
		id:{
			type:String,
			default:''
		},
		bid:{
			type:String,
			default:''
		},
		info:{
			type:[Object,Array],
			default(){
				return null
			}
		},
	},
	model:{
		prop:'visible',
		event:'change'
	},
	created() {
		this.isShow = this.visible;
		this.formData.pid = this.id ? this.id : ''
		this.formData.id = this.bid ? this.bid : '';

		if(this.info.qtdw[0].lingdao_uid){
			this.formData.need_shenpi = 0;
			this.disShenpi = true;
		}
	},
	mounted() {
		console.log('====')
	},
	methods: {
		onSelectionChange(e){
			this.formData.sms_ids = e;
		},
		onChangeSms(e){
			if(e === 1){
				this.getPerson();
			}else{
				this.personList = [];
				this.formData.sms_ids = [];
			}
		},
		getPerson(){
			let _this = this;
			_this.$http.request({
				url:'/Program/getProgramPerson',
				datas:{id:this.formData.pid},
				success(res){
					_this.personList = res;

					_this.$nextTick(()=>{
						// _this.$refs.list.toggleAllSelection();
					})
				}
			})
		},
		onChange(){
			this.formData.remark = '';
		},
		onOpen(){
			let _this = this;
			this.$nextTick(()=>{
				_this.formData.pid = this.id ? this.id : '';
				_this.$refs['form'].clearValidate()
			})
		},
		onClose(){
			this.formData.pid = '';
			this.formData.id = '';
			this.formData.state = '';
			this.formData.remark = '';
			this.formData.files_ids = [];
			this.formData.images_ids = [];
			this.formData.videos_ids = [];
			this.formData.sms_ids = [];
			this.formData.send_sms = 0;
			this.videos = [];
			this.files = [];
			this.images = [];
			this.personList = [];
			this.$emit('update:visible', false);
			this.$emit('close');
		},
		getDetail(){
			let _this = this;
			_this.$http.request({
				url:'/ProgramDo/detail',
				datas:{id:this.formData.id},
				success(res){
					_this.formData.remark = res.remark;
					_this.formData.files_ids = res.files_ids;
					_this.formData.images_ids = res.images_ids;
					_this.formData.videos_ids = res.videos_ids;
					_this.videos = res.videos;
					_this.files = res.files;
					_this.images = res.images;
				}
			})
		},
		toSubmit(){
			let _this = this;
			this.$refs['form'].validate((valid) => {
				if (valid) {
					if(_this.formData.send_sms === 1 && _this.formData.sms_ids.length <= 0){
						_this.$message.error('请选择需要接受短信的人');
						return;
					}
					_this.$http.request({
						url:'/ProgramDo/add',
						datas:_this.formData,
						success(res){
							_this.$message.success('操作成功');

							_this.$emit('success');

							_this.isShow = false;
						}
					})
				} else {
					_this.$message.error('请填写完整表单')
					return false;
				}
			});
		}
	},
	watch:{
		visible(n,o){
			this.isShow = n;
			this.formData.pid = this.id ? this.id : ''
			this.formData.id = this.bid ? this.bid : ''

			if(this.formData.id){
				this.getDetail();
			}
		}
	}
}
</script>
