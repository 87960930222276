<template>
	<el-dialog title="事项审批" :visible.sync="isShow" width="700px" append-to-body @close="onClose" @open="onOpen">
<!--		{{formData}}-->
		<el-form :model="formData" :rules="rules" ref="form" label-width="100px">
			<el-form-item label="审批状态" prop="state">
				<type-select :is-top="false" :options="[{id:2,name:'通过'},{id:6,name:'拒绝'}]" v-model="formData.state"></type-select>
			</el-form-item>
			<el-form-item label="拒绝理由" prop="remark" :rules="[{required:true,message:'拒绝理由必填'}]" v-if="formData.state === 6">
				<el-input type="textarea" v-model="formData.remark"></el-input>
			</el-form-item>
			<el-form-item label="批示意见" prop="remark" v-if="formData.state === 2">
				<el-input type="textarea" v-model="formData.remark"></el-input>
			</el-form-item>
			<el-form-item label="是否发送短信" prop="send_sms">
				<one-checkbox v-model="formData.send_sms">是</one-checkbox>
				<div class="dark-6" style="line-height: 14px !important;">批量审核只能给牵头单位的联络员发送短信</div>
			</el-form-item>
		</el-form>
		<span slot="footer" class="dialog-footer">
			<el-button @click="isShow = false">关闭</el-button>
	    <el-button type="primary" @click="toSubmit">确定</el-button>
	  </span>
	</el-dialog>
</template>

<script>
export default {
	name: "ListShenpiDialog",
	data() {
		return {
			isShow:false,
			formData:{
				ids:'',
				state:'',
				remark:'',
				send_sms:0
			},
			rules:{
				state:[{required: true,message:'审批状态必选'}]
			}
		}
	},
	props:{
		visible:{
			type:Boolean,
			default:false
		},
		ids:{
			type:[Array],
			default:[]
		},
		url:{
			type:String,
			default:''
		}
	},
	model:{
		prop:'visible',
		event:'change'
	},
	created() {
		this.isShow = this.visible;
		this.formData.ids = this.ids ? this.ids : []
	},
	mounted() {

	},
	methods: {
		onOpen(){
			let _this = this;
			this.$nextTick(()=>{
				_this.formData.ids = this.ids ? this.ids : [];
				_this.$refs['form'].clearValidate()
			})
		},
		onClose(){
			this.formData.ids = [];
			this.formData.state = '';
			this.formData.remark = '';
			this.formData.send_sms = 0;
			this.$emit('update:visible', false);
		},
		toSubmit(){
			let _this = this;

			if(!this.url){
				return;
			}

			if(!this.formData.ids || this.formData.ids.length <= 0){
				return;
			}

			this.$refs['form'].validate((valid) => {
				if (valid) {
					_this.$http.request({
						url:_this.url,
						datas:_this.formData,
						success(res){
							_this.$message.success('操作成功');

							_this.$emit('success');

							_this.isShow = false;
						}
					})
				} else {
					_this.$message.error('请填写完整表单')
					return false;
				}
			});
		}
	},
	watch:{
		visible(n,o){
			this.isShow = n;
		}
	}
}
</script>
