<template>
  <el-row class="h100" id="main-list">
    <el-col :span="listOptions && listOptions.sideSpan ? listOptions.sideSpan : 6" v-if="listOptions && (listOptions.showDept || listOptions.showSideTree)" class="diy-scroll h100" style="overflow-y: auto;">
      <base-tree-side ref="side" @change="onChangeSideTree" v-if="listOptions.showSideTree && listOptions.sideTreeOptions && listOptions.sideTreeOptions.url" v-bind="listOptions.sideTreeOptions" :id="searchForm[listOptions.sideTreeOptions.searchKey]"></base-tree-side>
      <dept-tree-side @change="changeDept" :id="searchForm.dept_id" v-if="listOptions.showDept"></dept-tree-side>
    </el-col>
    <el-col :span="listOptions && listOptions.sideSpan ? parseInt(24 - listOptions.sideSpan) : (listOptions && (listOptions.showDept || listOptions.showSideTree) ? 18 : 24)" class="flex flex-col h100 hide">
			<slot name="search"></slot>
      <top-buttons @toApi="toApi" @toDiy="toDiy" @toEdit="toEdit" @toDetail="toDetail" @toList="toList" @success="getList" :path="comPath" :com-list-options="comListOptions" :com-params="comParams" :com-list-params="comListParams" v-if="listOptions && (listOptions.showAdd || listOptions.showExport || listOptions.showBack || (params.topLeftButtons && params.topLeftButtons.length > 0) || (params.topRightButtons && params.topRightButtons.length > 0))" :search-data="searchForm"></top-buttons>
<!--      <div v-if="list.length > 0">-->
      <div class="flex1 hide flex flex-col" v-if="listOptions && params">
				<div class="flex1 hide">
					<el-table
						@header-dragend="headerDragend"
						height="100%"
						border
						size="mini"
						@sort-change="sortChange"
							stripe
							highlight-current-row
							style="width: 100%;"
							:show-summary="listOptions.apiTotal || listOptions.total"
							:summary-method="(listOptions.apiTotal && listOptions.apiTotalUrl) ? summaryMethod : null"
							:data="list"
							:row-key="listOptions && listOptions.pk ? listOptions.pk : 'id'"
							:tree-props="{children: listOptions && listOptions.listTree ? 'children' : ''}" :indent="listOptions && listOptions.listTree ? 30 : 0"
							:default-expand-all="listOptions && listOptions.listTree && listOptions.expandAll"
							ref="listtable" :fit="true" @selection-change="selectionChange">
							<div slot="empty">
								<div v-if="loading">
									<i class="el-icon-loading f-20 vm mg-r-10"></i>加载中...
								</div>
								<div v-else>暂无数据</div>
							</div>
							<el-table-column fixed type="selection" width="40" reserve-selection v-if="listOptions && listOptions.showListExport">
							</el-table-column>
							<el-table-column
								fixed
								type="index"
								label="序号"
								width="50" v-if="listOptions && listOptions.showListIndex" align="center">
							</el-table-column>
							<template v-for="(item,index) in listParams['params']">
								<el-table-column v-if="item.tem" :label="item.name" :min-width="item.fixed ? '' : (item.width ? item.width : '')" :width="item.fixed ? (item.width ? item.width : '') : ''" :fixed="item.fixed" :sortable="$utils.isUndefined(item.sort) ? false : 'custom'" :show-overflow-tooltip="$utils.isUndefined(item.showOverflowTooltip) ? false : item.showOverflowTooltip">
									<template slot-scope="scope">
										<template v-for="(item2,index2) in item.tem">
											<div v-if="item2.type === 'copy'">
												{{ item2.name ? item2.name + '：' : '' }}{{ item2.pre ? item2.pre : '' }}{{ $utils.evalGet(scope.row, item2.val) }}
												<el-button size="mini" @click="copy((item2.pre ? item2.pre : '') + scope.row[item2.val])" class="margin-left">复制</el-button>
											</div>
											<div
												v-else-if="item2.val === 'title' && (scope.row.is_tuijian === 1 || scope.row.is_toutiao === 1 || scope.row.sort > 0)">
												<div class="margin-bottom">
													<el-tag size="mini" effect="dark" type="danger" class="margin-right"
																	v-if="scope.row.is_tuijian">推荐
													</el-tag>
													<el-tag size="mini" effect="dark" type="warning" v-if="scope.row.is_toutiao"
																	class="margin-right">头条
													</el-tag>
													<el-tag size="mini" effect="dark" type="success" v-if="scope.row.sort === 3">一级置顶</el-tag>
													<el-tag size="mini" effect="dark" type="success" v-if="scope.row.sort === 2">二级置顶</el-tag>
													<el-tag size="mini" effect="dark" type="success" v-if="scope.row.sort === 1">三级置顶</el-tag>
												</div>
												<div>
													{{ item2.name ? item2.name + '：' : '' }}{{ item2.pre ? item2.pre : '' }}{{ $utils.evalGet(scope.row, item2.val) }}
												</div>
											</div>
											<div v-else-if="item2.type === 'tag'">
												<el-tag v-for="(item,index) in scope.row[item2.val]" class="margin-right hand"
																@click="copy(item)">{{ item }}
												</el-tag>
											</div>
											<div v-else>
												{{ item2.name ? item2.name + '：' : '' }}{{ item2.pre ? item2.pre : '' }}{{ $utils.evalGet(scope.row, item2.val) }}
											</div>
										</template>
									</template>
								</el-table-column>
								<template v-else>
									<el-table-column :label="item.name" :prop="item.value" :min-width="item.width ? item.width : ''" :sortable="$utils.isUndefined(item.sort) ? false : 'custom'" v-if="$utils.checkButtonRules(true,info,item.rules ? item.rules : null)" :show-overflow-tooltip="$utils.isUndefined(item.showOverflowTooltip) ? false : item.showOverflowTooltip">
										<template slot-scope="scope">
											<el-tag :type="$utils.evalGet(scope.row,item.value) === 1 ? 'success' : 'danger'" disable-transitions v-if="item.type === 'state'">
												{{ $utils.evalGet(scope.row,item.value) === 1 ? '可用' : '禁用' }}
											</el-tag>
											<i :class="$utils.evalGet(scope.row,item.value)" v-else-if="item.type === 'icon'"></i>
											<el-tag :type="$utils.evalGet(scope.row,item.value) === 1 ? 'success' : 'danger'" disable-transitions v-else-if="item.type === 'bool'">{{ $utils.evalGet(scope.row,item.value) === 0 ? '否' : '是' }}</el-tag>
											<template v-else-if="item.type === 'custom-select'">
		<!--                    <template v-if="scope.row[item.value]">-->
													<el-tag size="mini" :class="{'hand':item.ext && item.ext.type === 'link' && item.ext.url}" v-for="(st,si) in item.options" :type="st.color" disable-transitions  v-if="scope.row[item.value] === st.val" @click="item.ext && item.ext.type === 'link' && item.ext.url && $utils.evalGet(scope.row,(item.ext.linkKey ? item.ext.linkKey : 'id')) ? $router.push({path:item.ext.url,query:{id:item.ext.linkKey ? $utils.evalGet(scope.row,item.ext.linkKey) : ''}}) : null">{{ st.label }}</el-tag>
		<!--                    </template>-->
		<!--                    <template v-else>-</template>-->
											</template>
											<template v-else-if="item.type === 'favorite'">
												<i :class="scope.row[item.tableKey] === 0 ? item.offClass : item.onClass" class="hand" @click="toApi(item.props,scope.row,{[item.props.pk]:scope.row.id,...item.data},list)"></i>
											</template>
											<template v-else-if="item.type === 'sex'">
												<span v-if="scope.row[item.value] === 0">未知</span>
												<span v-if="scope.row[item.value] === 1">男</span>
												<span v-if="scope.row[item.value] === 2">女</span>
											</template>
											<template v-else-if="item.type === 'percent'">
												{{ (scope.row[item.value] * 100).toFixed(2) }}%
											</template>
											<div v-else-if="item.type === 'copy'">
												{{ item.name ? item.name + '：' : '' }}{{ item.root ? $utils.evalGet(scope.row, item.value) : scope.row[item.value] }}
												<el-button size="mini" @click="copy(item.root ? $utils.evalGet(scope.row,item.value) : scope.row[item.value])" class="margin-left">复制
												</el-button>
											</div>
											<template v-else-if="item.type === 'image'">
												<el-image style="width: 50px; height: 50px" fit="cover"
																	:src="$utils.evalGet(scope.row,item.value).indexOf('http')>= 0 ? $utils.evalGet(scope.row,item.value) : $config.api.assetsUrl + $utils.evalGet(scope.row,item.value)"
																	:preview-src-list="$utils.evalGet(scope.row,item.value).indexOf('http')>= 0 ? [$utils.evalGet(scope.row,item.value)] : [$config.api.assetsUrl + $utils.evalGet(scope.row,item.value)]"
																	v-if="$utils.evalGet(scope.row,item.value)"></el-image>
											</template>
											<template v-else-if="item.type === 'base64image'">
												<el-image style="height: 60px"
																	:src="'data:image/jpeg;base64,'+$utils.evalGet(scope.row,item.value)"
																	:preview-src-list="['data:image/jpeg;base64,'+$utils.evalGet(scope.row,item.value)]"
																	v-if="$utils.evalGet(scope.row,item.value)"></el-image>
											</template>
											<template v-else-if="item.type === 'programState'">
												<el-tag type="" size="small" v-if="$utils.evalGet(scope.row,item.value) === 0">{{scope.row.state_str}}</el-tag>
												<el-tag type="primary" size="small" v-if="$utils.evalGet(scope.row,item.value) === 1">{{scope.row.state_str}}</el-tag>
												<el-tag type="primary" size="small" effect="dark" v-if="$utils.evalGet(scope.row,item.value) === 2">{{scope.row.state_str}}</el-tag>
		<!--		                <el-tag type="warning" size="small" v-if="$utils.evalGet(scope.row,item.value) === 3">待领办(已逾期)</el-tag>-->
												<el-tag type="warning" size="small" effect="dark" v-if="$utils.evalGet(scope.row,item.value) === 3 && $utils.evalGet(scope.row,'pstate') === 0">{{scope.row.state_str}}</el-tag>
												<el-tag type="danger" size="small" effect="dark" v-if="$utils.evalGet(scope.row,item.value) === 3 && $utils.evalGet(scope.row,'pstate') === 2">{{scope.row.state_str}}</el-tag>
												<el-tag type="warning" size="small" v-if="$utils.evalGet(scope.row,item.value) === 3 && $utils.evalGet(scope.row,'pstate') === 7">{{scope.row.state_str}}</el-tag>
												<el-tag type="success" size="small" v-if="$utils.evalGet(scope.row,item.value) === 4">{{scope.row.state_str}}</el-tag>
												<el-tag type="info" size="small" v-if="$utils.evalGet(scope.row,item.value) === 5">{{scope.row.state_str}}</el-tag>
												<el-tag type="info" size="small" effect="dark" v-if="$utils.evalGet(scope.row,item.value) === 6">{{scope.row.state_str}}</el-tag>

		<!--		                <el-tag type="" v-if="$utils.evalGet(scope.row,item.value) === 0">已失效</el-tag>-->
		<!--		                <el-tag type="primary" size="small" v-if="$utils.evalGet(scope.row,item.value) === 1">待审批</el-tag>-->
		<!--		                <el-tag type="primary" size="small" effect="dark" v-if="$utils.evalGet(scope.row,item.value) === 2">待领办</el-tag>-->
		<!--		                <el-tag type="warning" size="small" v-if="$utils.evalGet(scope.row,item.value) === 3">待领办(已逾期)</el-tag>-->
		<!--		                <el-tag type="warning" size="small" effect="dark" v-if="$utils.evalGet(scope.row,item.value) === 4">办理中</el-tag>-->
		<!--		                <el-tag type="danger" size="small" v-if="$utils.evalGet(scope.row,item.value) === 5">退回办理</el-tag>-->
		<!--		                <el-tag type="danger" size="small" v-if="$utils.evalGet(scope.row,item.value) === 6">延期办理</el-tag>-->
		<!--		                <el-tag type="danger" size="small" v-if="$utils.evalGet(scope.row,item.value) === 7">异议事项</el-tag>-->
		<!--		                <el-tag type="success" size="small" effect="dark" v-if="$utils.evalGet(scope.row,item.value) === 8">逾期办结</el-tag>-->
		<!--		                <el-tag type="success" size="small" v-if="$utils.evalGet(scope.row,item.value) === 9">已办结</el-tag>-->
		<!--		                <el-tag type="info" size="small" v-if="$utils.evalGet(scope.row,item.value) === 10">已终止</el-tag>-->
		<!--		                <el-tag type="info" size="small" effect="dark" v-if="$utils.evalGet(scope.row,item.value) === 11">已拒绝发布</el-tag>-->

		<!--		                <el-tag v-if="$utils.evalGet(scope.row,item.value) === 0">已失效</el-tag>-->
		<!--		                <el-tag v-if="$utils.evalGet(scope.row,item.value) === 1">待审批</el-tag>-->
		<!--		                <template v-if="$utils.evalGet(scope.row,item.value) === 2">-->
		<!--			                <el-tag v-if="$utils.evalGet(scope.row,'pstate') === 2">待领办(已逾期)</el-tag>-->
		<!--			                <el-tag v-if="$utils.evalGet(scope.row,'pstate') === 1">待领办</el-tag>-->
		<!--		                </template>-->
		<!--		                <template v-if="$utils.evalGet(scope.row,item.value) === 3">-->
		<!--			                <el-tag v-if="$utils.evalGet(scope.row,'pstate') === 2">已汇报</el-tag>-->
		<!--			                <el-tag v-if="$utils.evalGet(scope.row,'pstate') === 3">进行中</el-tag>-->
		<!--			                <el-tag v-if="$utils.evalGet(scope.row,'pstate') === 4">退回办理</el-tag>-->
		<!--			                <el-tag v-if="$utils.evalGet(scope.row,'pstate') === 5">延期办理</el-tag>-->
		<!--			                <el-tag v-if="$utils.evalGet(scope.row,'pstate') === 6">异议事项</el-tag>-->
		<!--			                <el-tag v-if="$utils.evalGet(scope.row,'pstate') === 1">办理中</el-tag>-->
		<!--		                </template>-->
		<!--		                <template v-if="$utils.evalGet(scope.row,item.value) === 4">-->
		<!--			                <el-tag v-if="$utils.evalGet(scope.row,'pstate') === 2">逾期办结</el-tag>-->
		<!--			                <el-tag v-if="$utils.evalGet(scope.row,'pstate') === 1">已办结</el-tag>-->
		<!--		                </template>-->
		<!--		                <el-tag v-if="$utils.evalGet(scope.row,item.value) === 5">已终止</el-tag>-->
		<!--		                <el-tag v-if="$utils.evalGet(scope.row,item.value) === 6">已拒绝发布</el-tag>-->
											</template>
											<template v-else-if="item.type === 'time'">
												{{ $utils.evalGet(scope.row,item.value) > 0 ? $moment.unix($utils.evalGet(scope.row,item.value)).format('YYYY-MM-DD HH:mm:ss') : '-' }}
											</template>
											<template v-else-if="item.type === 'unixtime'">
												{{ $utils.evalGet(scope.row,item.value) > 0 ? $moment($utils.evalGet(scope.row,item.value)).format('YYYY-MM-DD HH:mm:ss.SSS') : '-' }}
											</template>
											<span class="hand a-link f-u" v-else-if="item.type === 'link'" @click="item.url && $utils.evalGet(scope.row,item.linkKey ? item.linkKey : 'id') ? $router.push({path:item.url,query:{id:$utils.evalGet(scope.row,item.linkKey ? item.linkKey : 'id')}}) : null">{{ item.linkLabel ? item.linkLabel : $utils.evalGet(scope.row,item.value) }}</span>
											<template v-else-if="item.type === 'download'">
												<a class="hand a-link f-u" :href="$utils.formatUrl($utils.evalGet(scope.row,item.linkKey ? item.linkKey : item.value))" target="_blank">{{ item.linkLabel ? item.linkLabel : $utils.evalGet(scope.row,item.value) }}</a>
											</template>
											<template v-else-if="item.type === 'file'">
												<template v-if="$utils.evalGet(scope.row,item.linkKey ? item.linkKey : item.value).length > 0">
													<a class="hand a-link f-u" :href="ft.url" target="_blank" v-for="(ft,fi) in $utils.evalGet(scope.row,item.linkKey ? item.linkKey : item.value)" :key="ft.url">{{ft.name}}</a>
												</template>
											</template>
											<div class="cl rel" v-else-if="item.type === 'html'">
												<div class="z" :class="item.more ? (item.line ? 'text-line-'+item.line : 'text-line-1') : ''" :style="item.more ? 'margin-left:25px;' : ''" v-html="item.root ? $utils.evalGet(scope.row,'scope.row',item.value) : scope.row[item.value]"></div>
												<el-button type="info" icon="el-icon-view" circle @click="showMore(scope.row[item.value])" v-if="item.more && scope.row[item.value]" size="mini" class="abs" style="top:0;left:0;padding:2px !important;"></el-button>
											</div>
											<el-switch
												v-else-if="item.type === 'changeStatus' && (item.checkDisabled ? true : $utils.checkButtonRules(true,scope.row,item.rules ? item.rules : null))"
												:disabled="item.checkDisabled ? !$utils.checkButtonRules(true,scope.row,item.rules ? item.rules : null) : false"
												v-model="scope.row[item.value]"
												active-color="#13ce66"
												:active-value="1"
												:inactive-value="0"
												@change="changeStatus(scope.row[listOptions.pk ? listOptions.pk : 'id'],scope.row,item)">
											</el-switch>
											<template v-else-if="item.type === 'price'">
												{{ (parseFloat($utils.evalGet(scope.row,item.value)) / 100).toFixed(2) }}
											</template>
											<template v-else-if="item.type === 'province'">
												{{$utils.areaCodeToName($utils.evalGet(scope.row,item.value))}}
											</template>
											<template v-for="(t,i) in item.options" v-else-if="item.type === 'options'">
												<span v-if="$utils.evalGet(scope.row,item.value) === t.value">{{ t.label }}</span>
											</template>
											<template v-else-if="item.type === 'inline'">
												<template v-if="item.callback">
													{{item.callback(scope.row)}}
												</template>
												<template v-else>
													{{ $utils.scopeArrToStr(scope.row, item.columns) }}
												</template>
											</template>
											<template v-else-if="item.type === 'text-line'">
												<div class="text-line-1">{{ $utils.evalGet(scope.row,item.value) }}</div>
											</template>
											<template v-else-if="item.type === 'todo'">
												{{$utils.evalGet(scope.row,item.value) ? $utils.evalGet(scope.row,item.value).remark : ''}}
											</template>
											<div v-else-if="item.type === 'fun'" v-html="item.callback ? item.callback(scope.row) : null">
												{{item.callback ? item.callback(scope.row) : null}}
											</div>
											<template v-else>
												<template v-if="item.prepend">{{item.prepend}}</template>
												{{$utils.evalGet(scope.row,item.value) ? $utils.evalGet(scope.row,item.value) : '-'}}
												<template v-if="item.append">{{item.append}}</template>
		<!--                    {{scope.row[item.value] || scope.row[item.value] === 0 ? scope.row[item.value] : '-'}}-->
											</template>
										</template>
									</el-table-column>
								</template>
							</template>
							<el-table-column fixed="right" align="right" :label="$utils.isUndefined(listOptions.optionsLabel) ? '操作' : listOptions.optionsLabel" :width="listOptions.optionsWidth ? listOptions.optionsWidth : (listOptions.showDetail ? 300 : 170)" v-if="listOptions.showAdd || listOptions.showEdit || listOptions.showDel || listOptions.showDetail || (listParams.extButtons && listParams.extButtons.length > 0) || (listParams.dropExtButton && listParams.dropExtButton.length > 0)">
								<template slot-scope="scope">
									<option-button-group :path="comPath" :com-list-options="comListOptions" :com-params="comParams" :com-list-params="comListParams" :scope-row="scope.row" :table-data="list" @toApi="toApi" @toEdit="toEdit" @toDetail="toDetail" @toList="toList" @toDiy="toDiy" @success="getList" @toDel="toDel" @toProgram="toProgram" :permission="scope.row.permission ? scope.row.permission : null"></option-button-group>
								</template>
							</el-table-column>
						</el-table>
				</div>
	      <div class="cl padding-tb-20">
		      <div class="z" v-if="listOptions.showListExport || listOptions.showListShenpi || listOptions.showListReShenpi">
			      <base-button @click.native="selectAll" :rule="params.delRouter">全选</base-button>
			      <base-button :role="'program:'+listOptions.data.type+':listexport'" type="primary" size="mini" @click.native="toListExport">批量导出</base-button>
						<base-button :role="'program:'+listOptions.data.type+':shenpi'" type="success" size="mini" @click.native="toListShenpi">批量审核</base-button>
						<base-button :role="'program:'+listOptions.data.type+':reshenpi'" type="warning" size="mini" @click.native="toListReShenpi">批量反审核</base-button>
		      </div>
		      <div class="y" v-if="showPage && list.length > 0">
			      <el-pagination
				      background
				      layout="total,sizes,prev, pager, next,jumper"
				      :page-sizes="[5,10,15,20, 50, 100, 200]"
				      @size-change="changeSize"
				      :page-size="searchForm[listOptions.pageSizeKey ? listOptions.pageSizeKey : 'pageSize']"
				      :current-page.sync="searchForm[listOptions.pageKey ? listOptions.pageKey : 'page']"
				      :total="totalCount"
				      @current-change="pageChange">
			      </el-pagination>
		      </div>
	      </div>
      </div>
<!--      <div class="padding-tb-40 border-top text-center dark-2">-->
<!--        暂无数据-->
<!--      </div>-->
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "MainTableList",
  data() {
    return {
      showPage:true,

      comPath: '',
      params: null,
      listParams: null,
      listOptions: null,

      totalCount: 0,
      list: [],

      ids:[],

      pk:'',

      searchForm:{},

      pageKey:'',
      pageSizeKey:'',

      sideData:null,

      loading:false,
	    query:{}
    }
  },
  props:{
    datas:{
      type:[Object,Array],
      default(){
        return {}
      }
    },
    info:{
      type:[Object,Array],
      default(){
        return {}
      }
    },
    path:{
      type:String,
      default:''
    },
    comTableList:{
      type:Array,
      default(){
        return []
      }
    },
    comParams: null,
    comListParams: null,
    comListOptions: null,
  },
  created() {
    // console.log(this.comParams);
    // console.log(this.comListParams);
    // console.log(this.comListOptions);
    // console.log(this.comTableList);

    // this.searchForm

	  this.query = this.$route.query ? JSON.parse(JSON.stringify(this.$route.query)) : {}
  },
  mounted() {
    this.init();

    this.getList();
  },
  methods: {
		headerDragend(){
			this.$refs.listtable.doLayout();
		},
    getSide(){
      if(this.listOptions.showSideTree && this.listOptions.sideTreeOptions){
        this.$refs.side.getList();
      }
    },

    //统计事件
    summaryMethod(param) {
      const {columns, data} = param;

      let sums = [];

      columns.forEach((column, index) => {
        for (let i = 0; i < this.listParams.total.length; i++) {
          if (this.listParams.total[i].prop === column.property) {
            sums[index] = this.totalData[this.listParams.total[i].value] + (this.listParams.total[i].dw ? this.listParams.total[i].dw : '');
          }
        }
      });

      return sums;
    },

    //选择机构
    changeDept(id) {
      this.$set(this.searchForm, 'dept_id', id);
	    this.searchForm[this.pageKey] = 1;
	    this.$user.setTabData(this.$route.path, this.searchForm);

      this.getList();
    },

    //选择侧边菜单
    onChangeSideTree(e){
			console.log(e);
      if(this.listOptions.showSideTree && this.listOptions.sideTreeOptions){
        let _sideTree = this.listOptions.sideTreeOptions;

        this.$set(this.searchForm,_sideTree.searchKey,e);

        if(this.listOptions.showSideTree && _sideTree && _sideTree.formKey) {
          this.sideData = {};
          this.$set(this.sideData,_sideTree.formKey,e);
        }else{
          this.sideData = null
        }
        this.getList();
      }
    },

    showMore(e) {
      let _this = this;
      this.$confirm(e, '详细内容', {
        customClass: 'alert-message',
        confirmButtonText: '关闭',
        cancelButtonText: '复制',
        closeOnClickModal: true,
        showCancelButton: false,
        closeOnPressEscape: true,
      }).then(() => {})
    },

    //改变分页数
    changeSize(e) {
      this.searchForm[this.pageKey] = 1;
      this.searchForm[this.pageSizeKey] = e;

      this.$user.setPage(this.$route.path, e);

      this.getList();
    },

    selectAll() {
      this.$refs.listtable.toggleAllSelection();
    },
		cleanSelect() {
			this.$refs.listtable.clearSelection();
		},
    selectionChange(val) {
      let _check = [];
      if (val.length > 0) {
        for (let i = 0; i < val.length; i++) {
          _check.push(val[i].id);
        }
      }

      this.ids = _check;
    },

    //复制
    copy(txt) {
      let _this = this;
      this.$copyText(txt).then(function (e) {
        _this.$utils.success('复制成功');
      }, function (e) {

      })
    },

    sortChange(column, prop, order){
      // console.log(column,prop, order);

      if(this.listOptions.sortFun){
        this.listOptions.sortFun(this,column);
      }
    },

    //获取列表
    getList() {
      let _this = this;

      if(this.comTableList && this.comTableList.length > 0){
        this.list = this.comTableList;
        this.showPage = false;

        return;
      }

      let _url = '';

      let _listData = {};

      if (this.listOptions) {

        _url = this.listOptions.listUrl ? this.listOptions.listUrl : '';

        if(!_url){
          return false;
        }

        _url = _url.replace('{id}',this.searchForm[this.pk]);

        _listData = this.listOptions.data ? this.listOptions.data : {};
        if (this.listOptions.showDept) {
          _listData.deptId = this.searchForm.deptId;
        }

        // if(this.listOptions.showSideTree && this.listOptions.sideTreeOptions){
        //   let _sideTree = this.listOptions.sideTreeOptions;
        //
        //   _listData[_sideTree.searchKey] = this.searchForm[_sideTree.searchKey];
        // }

        this.loading = true;

	      // console.log(_listData);
        // console.log(_this.searchForm);

        this.$http.request({
          method: this.listOptions.method ? this.listOptions.method : 'post',
          url: _url,
          datas: { ..._listData,..._this.searchForm},
          success(res) {
            // if(res.data){
            //   for(let i=0; i<res.data.length; i++){//工单列表获取数据
            //     if(res.data && res.data[i].workOrderRepairVO){
            //       res.data[i].repairUserName = res.data[i].workOrderRepairVO.repairUserName;
            //     }
            //     if(res.data && res.data[i].workOrderRepairVO){
            //       res.data[i].repairTime = res.data[i].workOrderRepairVO.repairTime;
            //     }
            //     if(res.data && res.data[i].workOrderRepairVO){
            //       res.data[i].repairAmount = res.data[i].workOrderRepairVO.repairAmount;
            //     }
            //   }
            //   _this.list = res.data;
            //
            // }else{
              _this.list = res[_this.listOptions.listKey] ? res[_this.listOptions.listKey] : (res ? res : []);
            // }

            if (_this.showPage) {
              _this.totalCount = res.totalCount;
            }

            _this.loading = false;

						_this.$nextTick(() => {
								window.setTimeout(() => {
									_this.$refs.listtable.doLayout()
								}, 200)
							}
						)
          }
        });
      }
    },

    //统计接口
    getTotal() {
      let _this = this;

      let _datas = JSON.parse(JSON.stringify(this.searchForm));

      delete _datas.pageSize;
      // delete _datas.pageNum;

      if (this.listOptions.apiTotalUrl) {
        this.$http.request({
          method: 'post',
          url: this.listOptions.apiTotalUrl,
          datas: {..._datas},
          success(res) {

            _this.totalData = res;
            console.log(res);
          }
        });
      }
    },

    //改变状态
    changeStatus(index, row,option) {

      // console.log(option);
      // console.log(row.state);

      let _this = this;
      let _text = '';
      let _status = '';
      let flag = option.comProp && !this.$utils.isUndefined(option.comProp.key) ? row[option.comProp.key] : row.state;

      if (row[option.comProp && option.comProp.key ? option.comProp.key : 'state'] === 0 || row[option.comProp && option.comProp.key ? option.comProp.key : 'state'] === 1) {
        if (row[option.comProp && option.comProp.key ? option.comProp.key : 'state'] === 1) {
          _text = option && option.comProp && option.comProp.successTitle ? option.comProp.successTitle : '是否【启用】该条数据？';
          _status = 1;
        } else if (row[option.comProp && option.comProp.key ? option.comProp.key : 'state'] === 0) {
          // console.log('===f=ds');
          // console.log(option.comProp);
          _text = option && option.comProp && option.comProp.cancelTitle ? option.comProp.cancelTitle : '是否【禁用】该条数据？';
          _status = 0;
        }
        this.$confirm(_text, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            if (_status === 0 || _status === 1) {
              if ((option.comProp && option.comProp.statusUrl) || _this.listOptions.statusUrl) {
                let _id = row[_this.listOptions.pk ? _this.listOptions.pk : 'id'];

                this.$http.request({
                  url: (option.comProp && option.comProp.statusUrl) ? option.comProp.statusUrl.replace('{id}', _id) : _this.params.list.options.statusUrl.replace('{id}', _id),
                  datas: {
                    [_this.listOptions.pk ? _this.listOptions.pk : 'id']: _id,
                    [option.comProp && option.comProp.key ? option.comProp.key : 'state']: _status
                  },
                  method:option.comProp && option.comProp.method ? option.comProp.method : 'post',
                  success(res) {
                    _this.$utils.success("操作成功！");
                    _this.getList();
                  },
                  fail(err){
                    if (flag === 1) {
                      row[option.comProp && option.comProp.key ? option.comProp.key : 'state'] = 0;
                    } else {
                      row[option.comProp && option.comProp.key ? option.comProp.key : 'state'] = 1;
                    }
                    _this.$utils.error(err.msg);
                  }
                });
              }
            }
          })
          .catch((err) => {
            // console.log(err);
            if (flag === 1) {
              row[option.comProp && option.comProp.key ? option.comProp.key : 'state'] = 0;
            } else {
              row[option.comProp && option.comProp.key ? option.comProp.key : 'state'] = 1;
            }
          });
      } else {
        this.$utils.warning('操作异常');
      }
    },

    //翻页
    pageChange(page) {
      this.searchForm[this.pageKey] = page;

      this.$user.setTabData(this.$route.path, this.searchForm);
      this.getList();
    },

    //删除事件
    toDel(option,scopeRow,data,tableData){
      if(this.listOptions.showSideTree && this.listOptions.sideTreeOptions && this.listOptions.sideTreeOptions.url){
        this.$refs.side.getList();
      }
    },

    //自定义请求接口
    toApi(option, scopeRow, data,tableData) {
      this.$emit('toApi',option,scopeRow, data,tableData)
    },

	  toListExport(option, scopeRow, data,tableData){
		  this.$emit('toListExport',option, scopeRow, data,tableData,this.ids,this.listOptions.listExportUrl)
	  },

		toListShenpi(option, scopeRow, data,tableData){
		  this.$emit('toListShenpi',option, scopeRow, data,tableData,this.ids,this.listOptions.listShenpiUrl)
	  },

		toListReShenpi(option, scopeRow, data,tableData){
		  this.$emit('toListReShenpi',option, scopeRow, data,tableData,this.ids,this.listOptions.listReShenpiUrl)
	  },

    //查看详情
    toDetail(option, scopeRow, data,tableData) {
      this.$emit('toDetail',option, scopeRow, data,tableData)
    },

	  toProgram(option, scopeRow, data,tableData) {
      this.$emit('toProgram',option, scopeRow, data,tableData)
    },

    //自定义组件
    toDiy(option, scopeRow, data,tableData){
      this.$emit('toDiy',option, scopeRow, data,tableData)
    },

    toList(option, scopeRow, data,tableData){
      this.$emit('toList',option, scopeRow, data,tableData)
    },

    //编辑
    toEdit(option, scopeRow, data,tableData) {
      // console.log(option, scopeRow, data,tableData);

      this.$emit('toEdit',option, scopeRow, this.$utils.isUndefined(data) ? (this.sideData ? this.sideData : undefined) : {...data,...this.sideData},tableData);
      // this.$emit('toEdit',option, scopeRow, data,tableData);
    },

    init(){
      //获取配置
      let _config = this.$baseConfig

      //获取当前路径
      this.comPath = this.path ? this.path : this.$route.path;

      //获取路径全部配置
      this.params = this.comParams ? this.comParams : (this.comPath ? _config[this.comPath] : null);

      //获取列表参数
      this.listParams = this.comListParams ? this.comListParams : (this.comPath && this.params ? this.params.list : null);
      //获取列表配置
      this.listOptions = this.comListOptions ? this.comListOptions : (this.comPath && this.params && this.params.list ? this.params.list.options : null);

      this.pk = this.listOptions.pk ? this.listOptions.pk : '';


      //获取分页参数
      this.pageSizeKey = this.listOptions.pageSizeKey ? this.listOptions.pageSizeKey : 'pageSize';
      this.pageSize = this.listOptions.pageSize ? this.listOptions.pageSize : 10;
      this.pageKey = this.listOptions.pageKey ? this.listOptions.pageKey : 'page';
      this.showPage = this.$utils.isUndefined(this.listOptions.showPage) ? true : this.listOptions.showPage;

      let _tabs = this.$user.getTabByPath(this.$route.path);

      this.searchForm = _tabs.data ? _tabs.data : {};

			console.log(this.listOptions.showDept)

      // this.$set(this.searchForm, this.pageSizeKey, this.searchForm[this.pageSizeKey] ? this.searchForm[this.pageSizeKey] : this.pageSize);
      // this.$set(this.searchForm, this.pageKey, this.searchForm[this.pageKey] ? this.searchForm[this.pageKey] : 1);

      //获取页数
      this.searchForm[this.pageKey] = this.searchForm[this.pageKey] ? parseInt(this.searchForm[this.pageKey]) : 1;

      //获取分页数
      this.searchForm[this.pageSizeKey] = this.$route.query[this.pageSizeKey] ? parseInt(this.$route.query[this.pageSizeKey]) : (this.$user.getPage(this.comPath) ? parseInt(this.$user.getPage(this.comPath)) : this.pageSize);

      if(this.datas && !this.$utils.isNullObject(this.datas) && this.pk) {
        this.$set(this.searchForm,this.pk,this.datas[this.pk]);
      }

			if(this.listOptions.copyQueryData && this.listOptions.copyQueryData.length > 0){
				for(let i=0;i < this.listOptions.copyQueryData.length;i++){
					if(this.query[this.listOptions.copyQueryData[i].from]){
						this.$set(this.searchForm,this.listOptions.copyQueryData[i].to,this.query[this.listOptions.copyQueryData[i].from]);
					}
				}
			}
    }
  },
  watch:{
    // datas(n,o){
    //   console.log(n);
    // }
    datas:{
      handler(n,o){
        let _datas = n ? n : {}

        for (let i in this.searchForm){
          if(i !== this.pageKey && i !== this.pageSizeKey) {
            this.$set(this.searchForm, i, '');
          }
        }

        this.searchForm = {...this.searchForm,..._datas};
        this.searchForm[this.pageKey] = 1;

        this.$user.setTabData(this.$route.path, this.searchForm);

        this.getList();
      },
      deep:true
    }
  }
}
</script>
