import Vue from 'vue';
let vm = new Vue();
import axios from 'axios'
import qs from 'qs';

import Config from './config'
import Utils from './utils'
import User from './user'
import Router from '../router'
const JSONbig = require('json-bigint')

import Moment from 'moment';

let _quitCount = 0;

// export default {
const http = {
    request:function(obj){
        let _datas = Utils.isUndefined(obj.datas) ? null : obj.datas;

        let _download = Utils.isUndefined(obj.download) ? false : obj.download;
        let _mode = obj.mode ? obj.mode : '';
        let _showLoad = Utils.isUndefined(obj.showLoad) ? false : obj.showLoad;
        let _requestHeaders = obj.headers ? obj.headers : {};
        // let _headers = {'Content-Type':'application/x-www-form-urlencoded','Accept': 'application/json'};
        let _headers = {'Content-Type':'application/json','Accept': 'application/json'};

        if(_download){
            // _headers = {'Content-Type':'application/x-www-form-urlencoded','Accept': 'application/json'};
        }
        let loadingInstance = null;
        if(_showLoad) {
            loadingInstance = vm.$loading({lock:true});
        }

        if(_download){
            _headers['response-Type'] = 'blob';
        }

        if(obj.url !== '/Member/login'){
            _headers.Authorization = User.getToken();
        }

        axios.defaults.timeout = 3000000;

        let _config = {};

        _config.headers = {..._headers,..._requestHeaders};
        _config.method = obj.method ? obj.method.toLowerCase() : 'post';
        _config.url = obj.url.indexOf('http') >= 0 ? obj.url : Config.api.apiUrl+obj.url;
        _config.responseType = obj.download ? 'arraybuffer' : 'application/json';

        //过滤空数组
        for(let i in _datas){
            if(_datas[i] instanceof Array && _datas[i].length <= 0){
                _datas[i] = ''
            }
        }

        // console.log(_datas);
        
        //处理精度丢失
        if(!_download) {
            _config.transformResponse = [
                function (data) {
                    const json = JSONbig({
                        storeAsString: true,
                    });
                    const res = json.parse(data);
                    return res;
                }
            ]
        }

        if(_config.method === 'post') {
            if(_datas) {
                // _config.data = qs.stringify(_datas);
                _config.data = JSON.stringify(_datas);
            }
        } else if(_config.method === 'put' || _config.method === 'patch'|| _config.method === 'delete'){
            if(_datas) {
                _config.data = qs.stringify(_datas);
            }
        }else if(_config.method === 'json'){
            if(_datas) {
                _config.data = JSON.stringify(_datas);
            }
            _config.headers['Content-Type'] = 'application/json';
        }else{
            _config.params = _datas ? _datas : null;
        }
        
        // console.log(_config);

        // else if(_config.method === 'delete' || _mode === 'detail'){
        //     let _path = [];
        //
        //     for(let i in _datas){
        //         _path.push(_datas[i]);
        //     }
        //
        //     let _pathStr = _path.length > 0 ? _path.join('/') : '';
        //
        //     _config.url += _pathStr ? '/'+_pathStr : '';
        // }

        // console.log(_config);


        axios(_config).then(function(res){
            if(loadingInstance){
                vm.$nextTick(() => {
                    loadingInstance.close();
                });
            }

            if(_download){
                if (obj.success) {
                    obj.success(res.data);
                }
            }else {
                if(res.data.code === 0){
                    if(obj.success){
                        obj.success(res.data.data);
                    }
                }else{
                    if(res.data.code === -1002){
                        if(_quitCount <= 0) {
                            _quitCount += 1;
                            vm.$mAlert.error(res.data.msg,'重新登录',()=>{
                                _quitCount = 0;
                                Utils.sessionClear();
                                Router.replace({path: '/login'});
                            })
                        }
                        
                        // vm.$message.error({message: '登录信息已失效，请重新登录', duration: 20000});
                        // Utils.sessionClear();
                        // Router.replace({path:'/'});
                    }else {
                        if (obj.fail) {
                            obj.fail(res.data);
                        } else {
                            vm.$message.error({message: res.data.msg, duration: 2000});
                        }
                    }
                }
            }
        }).catch(function (error) {
            if(error.response && error.response.data.code && error.response.data.code === '401 UNAUTHORIZED'){
                if(_quitCount <= 0) {
                    _quitCount += 1;
                    vm.$mAlert.error(res.data.msg,'重新登录',()=>{
                        _quitCount = 0;
                        Utils.sessionClear();
                        Router.replace({path: '/login'});
                    })
                    // vm.$alert('登陆已过期请重新登陆', '确认退出', {
                    //     confirmButtonText: '重新登录',
                    //     cancelButtonText: '取消',
                    //     type: 'warning'
                    // }).then(() => {
                    //     _quitCount = 0;
                    //     Utils.sessionClear();
                    //     Router.replace({path: '/login'});
                    // }).catch(() => {
                    //     _quitCount = 0;
                    // });
                }
            }else {
                if (obj.fail) {
                    obj.fail(error.response);
                } else {
                    if(error.response && error.response.data && error.response.data.msg){
                        vm.$message.error(error.response.data.msg);
                    }else {
                        vm.$message.error(error.toString());
                    }
                }
            }

            if(loadingInstance){
                vm.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
                    loadingInstance.close();
                });
            }
        })

        //     ,function(error, config, code, request, response){
        //     console.log(code);
        //     vm.$message.error(error);
        // }
    },
    get:function(obj){

        // let _datas = Utils.isUndefined(obj.datas) ? null : qs.stringify(obj.datas);
        // let _datas = Utils.isUndefined(obj.datas) ? null : obj.datas;
        let _showLoad = Utils.isUndefined(obj.showLoad) ? false : obj.showLoad;
        // let _heads = {'Content-Type':'application/x-www-form-urlencoded','Accept': 'application/json'};
        // let _heads = {'Content-Type':'application/json','Accept': 'application/json'};

        let _heads = {};

        let loadingInstance = null;
        if(_showLoad) {
            loadingInstance = vm.$loading({lock:true});
        }

        if(obj.url !== 'Member/login'){
            _heads.Authorization = User.getToken();
        }

        // let _time = Moment().unix();

        // let _secret = md5(Config.secret + _time.toString());
        //
        // _heads.secret = _secret;
        // _heads.timestamp = _time;

        axios.defaults.timeout = 30000;

        // console.log(obj);
        axios({
            headers:_heads,
            method: 'get',
            url: obj.url.indexOf('http') >= 0 ? obj.url : Config.apiUrl+obj.url,
            // data:null
        }).then(function(res){
            // console.log(res);
            if(loadingInstance){
                vm.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
                    loadingInstance.close();
                });
            }
            if(res.data.code === 0){
                if(obj.success){
                    obj.success(res.data.data);
                }
            }else{
                if(res.data.code === -1002){
                    vm.$message.error({message: '登录信息已失效，请重新登录', duration: 2000});
                    Utils.sessionClear();
                    Router.replace({path:'/'});
                }else {
                    if (obj.fail) {
                        obj.fail(res.data);
                    } else {
                        vm.$message.error({message: res.data.msg, duration: 2000});
                    }
                }
            }
        },function(error){
            vm.$message.error(error);
        }).catch(function (error) {
            // console.log(error);
            vm.$message.error(error.toString());
            if(loadingInstance){
                vm.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
                    loadingInstance.close();
                });
            }
        })
    },
    upLoad:function(file){
        let config = {
            //添加请求头
            headers: { "Content-Type": "multipart/form-data" },
            //添加上传进度监听事件
            onUploadProgress: e => {
                var completeProgress = ((e.loaded / e.total * 100) | 0) + "%";
                this.progress = completeProgress;
            }
        };

        let param = new FormData();
        param.append("imageFile", file);
        // console.log(param.get("imageFile"));

        axios.post('http://miiheapi/user/up', param, config).then(
            function (response) {
                // console.log(response);
            })
            .catch(function (error) {
                // console.log(error);
            });
    }
};

export default http;
