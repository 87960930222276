<template>
  <clean-main>
    <div class="cl"></div>
    <div class="mg-center bg-light pd-20 hide bd-radius-6" style="z-index: 2;margin-top:200px;" :style="{width:$isMobile ? 'auto' : '400px'}">
	    <div>
		    <img src="/images/logo.png" style="height: 50px;" class="vm"/>
		    <b class="f-n f-20 mg-l-10">宜昌市夷陵区委督查平台</b>
	    </div>
<!--      <div class="padding-bottom" style="color:#70a7e2;"><b class="f-24 f-n" style="color:#3f9fff;">用户登录</b> / USER LOGIN</div>-->
	    <el-tabs v-model="activeName">
		    <el-tab-pane label="用户登录" name="user">
			    <div class="cl text-center mg-t-b-30">
				    <img src="/images/logo.png" style="height: 50px;" class="vm"/>
				    <i class="iconfont icon-zuoyoujiantou f-30 mg-l-r-10 vm dark-6"></i>
				    <img src="/images/dd_logo.png" style="height: 50px;" class="vm"/>
			    </div>
			    <div class="mg-t-20 text-center">
			      <el-button type="primary" @click="toDDLogin">钉钉授权登录</el-button>
			    </div>
		    </el-tab-pane>
		    <el-tab-pane label="管理员登录" name="admin">
			    <el-form :model="loginForm" :rules="rules" ref="ruleForm" label-width="auto" class="margin-top">
				    <el-form-item label="" prop="username" >
					    <el-input class="" v-model="loginForm.username" placeholder="用户名" clearable @input="onInput">
						    <i slot="prefix" class="el-input__icon el-icon-user-solid blue-1 f-20"></i>
					    </el-input>
				    </el-form-item>
				    <el-form-item label="" prop="password" >
					    <el-input autocomplete="new-password" class="" v-model="loginForm.password" show-password clearable placeholder="密码" maxlength="20" @input="onInput">
						    <i slot="prefix" class="el-input__icon icon-mima iconfont blue-1 f-22 text-center"></i>
					    </el-input>
				    </el-form-item>
				    <el-form-item>
					    <el-button type="primary" @click="submitForm('ruleForm')" class="block">登录</el-button>
				    </el-form-item>
				    <!--        <div class="text-center f-14" style="color:#35eeff;" v-if="showError">-->
				    <!--          <i class="el-icon-error mg-r-5"></i>{{errorMessage}}-->
				    <!--        </div>-->
			    </el-form>
		    </el-tab-pane>
	    </el-tabs>
    </div>
  </clean-main>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      showError:false,
      errorMessage:'账号密码输入有误，请重新输入',
      loginForm: {
        username: '',
        password: '',
      },
	    activeName:'user',
      rules: {
        username: [
          {required: true, message: '账号输入有误，请重新输入', trigger: 'blur'}
        ],
        password: [
          {required: true, message: '密码输入有误，请重新输入', trigger: 'blur'},
          {min: 1, max: 20, message: '请输入4-20位密码', trigger: 'blur'}
        ]
      },
	    authCode:''
    };
  },
  created() {
    // document.querySelector('body').style.backgroundImage = 'url("/images/bg.jpg")';
    // document.querySelector('body').style.backgroundRepeat = 'no-repeat';
    // document.querySelector('body').style.backgroundSize = '100% 100%';
	  this.authCode = this.$route.query.authCode ? this.$route.query.authCode : '';
  },
  mounted() {
		let _this = this;
		// console.log(this.authCode);
		if(this.authCode){
			this.loginByCode();
		}
    // this.getCode();
	  // this.$utils.loadJs('https://g.alicdn.com/dingding/h5-dingtalk-login/0.21.0/ddlogin.js').then(()=>{
		//   _this.initDDQrCode();
	  // });
	  // this.$utils.loadJs('https://g.alicdn.com/dingding/dinglogin/0.0.5/ddLogin.js').then(()=>{
		// 	console.log(window.DTFrameLogin);
		// 	_this.$nextTick(()=>{
		// 		_this.initDDQrCode();
		// 	})
	  // });
  },
  methods: {
		toDDLogin(){
			console.log(window.location.href);
			let _page = encodeURIComponent(window.location.href)
			let _url = 'https://login.dingtalk.com/oauth2/auth?' +
				'redirect_uri=' +
				_page +
				'&response_type=code' +
				// '&client_id=ding27frhvsweylcvvta' +
				'&client_id=dingfox4bisppb2zpvby' +
				'&scope=openid' +
				'&state=dddd' +
				'&prompt=consent'
			console.log(_url);
			window.location.replace(_url);
		},
		initDDQrCode(){
			window.DTFrameLogin(
				{
					id: 'self_defined_element',
					width: 300,
					height: 300,
				},
				{
					redirect_uri: encodeURIComponent('http://127.0.0.1/choose'),
					client_id: 'dingfox4bisppb2zpvby',
					scope: 'openid',
					response_type: 'code',
					prompt: 'consent',
				},
				(loginResult) => {
					const {redirectUrl, authCode, state} = loginResult;
					// 这里可以直接进行重定向
					window.location.href = redirectUrl;
					// 也可以在不跳转页面的情况下，使用code进行授权
					console.log(authCode);
					console.log(state);
				},
				(errorMsg) => {
					// 这里一般需要展示登录失败的具体原因
					alert(`Login Error: ${errorMsg}`);
				},
			);
		},
    onInput(e){
      this.showError = false;
    },
    getCode() {
      this.$http.request({
        url: '/Common/aaa',
        datas: {a:'aaa',b:'bbb'},
        success(res) {
          console.log(res);
        }
      });
    },

    submitForm(formName) {
      let _this = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
					let _url = '';
					if(_this.activeName === 'user'){
						_url = '/Member/userLogin'
					}else{
						_url = '/Member/login'
					}
          this.$http.request({
            url: _url,
            datas: _this.loginForm,
            success(res) {
              console.log(res);
              _this.$user.setToken(res.token);
              _this.$user.setUserName(res.username);
              _this.$user.setUid(res.id);
	            _this.$user.setJob(res.job);

	            _this.$user.setTab({name: '首页', path: '/dashboard', data: ''});
              _this.$router.replace({path:'/'});
            },
            // fail(err){
            //   console.log(err);
            //   _this.showError = true;
            // }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
	  loginByCode(){
			let _this = this;
		  this.$http.request({
			  url: '/Member/userLoginByCode',
			  datas: {auth_code:this.authCode},
			  success(res) {
				  console.log(res);
				  _this.$user.setToken(res.token);
				  _this.$user.setUserName(res.username);
				  _this.$user.setUid(res.id);
				  _this.$user.setJob(res.job);

				  _this.$user.setTab({name: '首页', path: '/dashboard', data: ''});
					_this.getMenu();
			  }
		  })
	  },
		getMenu(){
			let _this = this;
			this.$http.request({
				url: '/SystemRole/menu',
				datas: null,
				success(res) {
					_this.$user.setPermissions(res.rule);
					_this.$user.setDepartmentId(res.dept_id);
					_this.$router.replace({path:'/'});
				}
			});
		}
  }
}
</script>
<style>
.blue-input{}
.blue-input input{border:1px solid #3f9eff !important;height: 50px !important;line-height: 50px !important;}
.blue-input input,.blue-input input:-internal-autofill-selected,.blue-input input:-internal-autofill-selected{color:#fff;background-color: rgba(255,255,255,.05) !important;transition: background-color 5000s ease-out 0.5s;}
.blue-input input::-webkit-input-placeholder,.blue-input input::-ms-input-placeholder,.blue-input input::-moz-placeholder{color:#888;}
.blue-input input:focus {
  box-shadow: 0 0 5px rgb(0,153,184) inset,0 0 5px rgb(0,153,184);;
}

.blue-button{
  color: #3f9eff !important;
  background-color:rgba(63,158,255,.2) !important;
  border-color: #3f9eff !important;
  font-size:16px !important;
  padding:17px 20px !important;
}

/*input:-internal-autofill-previewed,*/
/*input:-internal-autofill-selected {*/
/*  -webkit-text-fill-color: #807c7c;*/
/*  transition: background-color 5000s ease-out 0.5s;*/
/*}*/
</style>
