<template>
    <span>
<!--      <slot @click="openDialog">{{label}}</slot>-->
        <el-button class="w100 pd-l-r-15" :icon="btnIcon" :size="btnSize" :type="btnType" @click="openDialog">{{label}}</el-button>
        <el-dialog title="选择试题" :visible.sync="visible" @close="onClose" width="1000px" v-if="visible" custom-class="dialog-h-500" append-to-body>
          <div style="margin-bottom:20px;">
            <type-select url="/api-train/trainType?queryMode=list" v-model="searchForm.teType" method="get" val-label="typeName" :is-top="false" placeholder="请选择培训类型"></type-select>
            <el-input placeholder="题目名称模糊搜索" class="mg-l-r-10" clearable v-model="searchForm.content" style="width: 300px;"></el-input>
            <el-button type="primary" @click="toSearch">搜索</el-button>
          </div>
          <el-table ref="list" :data="list" row-key="id" @selection-change="onSelectionChange">
            <el-table-column type="selection" width="55" reserve-selection></el-table-column>
            <el-table-column label="序号" type="index" width="55"></el-table-column>
<!--            <el-table-column label="id" prop="id"></el-table-column>-->
            <el-table-column label="题目名称" prop="content" width="300"></el-table-column>
            <el-table-column label="题型" prop="quType" width="100">
              <template slot-scope="scope">
                <el-tag type="success" v-if="scope.row.quType === 1">单选</el-tag>
                <el-tag type="danger" v-if="scope.row.quType === 2">多选</el-tag>
                <el-tag type="warning" v-if="scope.row.quType === 3">判断</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="所属培训类型" prop="typeName"></el-table-column>
            <el-table-column label="创建人" prop="createBy"></el-table-column>
            <el-table-column label="创建时间" prop="createTime" width="160"></el-table-column>
          </el-table>
          <div class="text-center mg-t-20" style="flex:1" v-if="list.length > 0">
            <el-pagination
              background
              layout="total,prev, pager, next,jumper"
              :page-size="searchForm.pageSize"
              :current-page.sync="searchForm.pageNum"
              :total="total"
              @current-change="pageChange">
            </el-pagination>
          </div>
          <div slot="footer" class="dialog-footer">
            <el-button @click="visible = false">关闭</el-button>
            <el-button type="primary" @click="toChoose">确定</el-button>
          </div>
        </el-dialog>
    </span>
</template>

<script>
export default {
  name: "ExamDialog",
  data() {
    return {
      visible: false,
      dataList: [],
      list: [],
      searchForm:{
        pageNum:1,
        pageSize:10,
        quType:'',
        teType:'',
        content:''
      },
      total:0,
      values:[],
      ids:[]
    }
  },
  model:{
    prop:'value',
    event:'change'
  },
  props: {
    value: {
      type: Array,
      default: function () {
        return [];
      }
    },
    label:{
      type:String,
      default:'新增'
    },
    toQuType:{
      type:[Number,String],
      default:''
    },
    btnType:{
      type:String,
      default:'text'
    },
    btnSize:{
      type:String,
      default:''
    },
    btnIcon:{
      type:String,
      default:''
    }
  },
  created() {
    this.values = this.value;

    this.searchForm.quType = this.toQuType ? this.toQuType : '';

    this.initIds();
  },
  watch:{
    value(n,o){
      this.values = n;

      // this.initIds();
    },
    toQuType(n,o){
      this.searchForm.quType = n ? n : '';

      this.getList();
    }
  },
  mounted() {
    // this.getList();
  },
  methods: {
    initIds(){
      this.ids = [];
      for(let i=0;i < this.values.length;i++){
        this.ids.push(this.values[i].id);
      }
    },
    initChecked() {
      if (this.ids.length > 0) {
        this.$nextTick(()=>{
          this.list.forEach(row => {
            if(this.ids.indexOf(row.id) >= 0) {
              this.$refs.list.toggleRowSelection(row, true);
            }
          });
        })
      }
    },
    toSearch() {
      this.searchForm.page = 1;
      this.getList();
    },
    toChoose() {
      this.$emit('change', this.values);
      this.onClose();
    },
    openDialog() {
      this.visible = true;

      this.list = [];

      this.getList();
      // this.getList();
    },
    onClose() {
      this.visible = false;
      // this.imageList = [];
    },
    onExceed() {
      this.$message.error('数量最多不能超过' + this.limit + '个');
    },
    getList() {
      let _this = this;
      this.dataList = [];
      this.$http.request({
        url: '/api-train/elQu/page',
        method:'get',
        datas: this.searchForm,
        success: (res) => {
          _this.list = res.data;

          _this.total = res.total;

          _this.initIds();
          _this.initChecked();
        }
      });
    },
    pageChange(page) {
      this.page = page;
      this.getList();
    },
    onSelectionChange(e){
      // console.log(e);
      this.values = e;

      // this.initIds();
    },
  }
}
</script>
