<template>
	<el-date-picker
		:editable="false"
		type="date"
		placeholder="选择日期"
		v-model="values"
		style="width: 100%;"
		:format="format"
		:picker-options="pickerOptions"
		@change="change"></el-date-picker>
</template>

<script>
import emitter from "element-ui/src/mixins/emitter";

export default {
	name: "PushTime",
	mixins: [emitter],
	data() {
		return {
			values: '',
			pickerOptions: {
				disabledDate(time) {
					return false
				}
			}
		}
	},
	model: {
		prop: 'value',
		event: 'change'
	},
	props: {
		value: {
			type: String,
			default: ''
		},
		now: {
			type: Boolean,
			default: false
		},
		startTime: {
			type: String,
			default: ''
		},
		format: {
			type: String,
			default: 'yyyy-MM-dd'
		}
	},
	created() {

		if (this.now) {
			this.values = this.value ? this.$moment(this.value).toDate() : this.$moment().toDate();
			this.$emit('change', this.$moment(this.values).format('YYYY-MM-DD'));
		} else {
			this.values = this.value ? this.$moment(this.value).toDate() : '';
		}

		if (this.startTime) {
			this.pickerOptions.disabledDate = (time) => {
				return time.getTime() < this.$moment(this.startTime).format('x')
			}
		}
	},
	watch: {
		value(n, o) {
			this.values = n ? this.$moment(n).toDate() : '';
		},
		startTime(n, o) {
			if (n) {
				this.pickerOptions.disabledDate = (time) => {
					return time.getTime() < this.$moment(n).format('x')
				}
			}
		}
	},
	methods: {
		change(e) {
			let _val = e ? this.$moment(e).format('YYYY-MM-DD') : '';
			this.$emit('change', _val);
			this.dispatch('ElFormItem', 'el.form.change', [_val])
			this.dispatch('ElFormItem', 'el.form.blur', [_val])
		}
	}
}
</script>
