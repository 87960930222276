<template>
  <span class="inline-block">
    <template v-for="(item,index) in buttons">
      <base-button :class="item.color ? 'text-button-'+item.color : ''" :type="item.btnType" :role="item.role ? item.role : ''" :size="item.size" :icon="item.icon" @click.native="toClick(item.type,item,null)">{{ item.label }}</base-button>
    </template>
  </span>
</template>

<script>
export default {
  name: "BaseButtonGroup",
  data() {
    return {}
  },
  props:{
    buttons:{
      type:Array,
      default(){
        return []
      }
    },
  },
  created() {
  },
  mounted() {
  },
  methods: {
    toClick(type,option,scopeRow){
      // let _target = ['_blank','_self'].indexOf(option.target) >= 0 ? option.target : '_blank';
      let _scopeRow = scopeRow ? this.$utils.deepCopy(scopeRow) : null;
      let _target = option ? (['_blank','_self'].indexOf(option.target) >= 0 ? option.target : '_self') : '_self';

      if(this.$utils.isUndefined(type) || type === 'url'){
        if(option.path) {
          if(_target === '_self') {
            this.$router.push({path:option.path});
          }else{
            const { href } = this.$router.resolve({
              path: option.path,
            });

            window.open(href, _target);
          }
        }
      }else if(type === 'web'){
        if(option.path) {
          window.open(option.path, _target);
        }
      }else {
        if(this.$utils.firstToUpper(type) === 'Add'){
          this.$emit('toEdit', option, _scopeRow, null);
        }else {
          this.$emit('to' + this.$utils.firstToUpper(type), option, _scopeRow, null);
        }
      }
    },
  }
}
</script>
