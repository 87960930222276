import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
import './element-variables.scss'
// import { Dialog } from 'vant';
// import 'vant/lib/index.css';


import '@/assets/css/main.css'
import '@/assets/css/all.scss'
import '@/assets/css/icon.css'
import '@/assets/css/icon2.css'

import particles from 'particles.js'
import Moment from 'moment';
import VueClipboard from 'vue-clipboard2';

Vue.use(ElementUI);
Vue.use(VueClipboard);
Vue.use(particles);

import TreeSelect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

import Config from './script/config';
import HTTPs from './script/http';
import Utils from './script/utils'
import User from './script/user';
import Api from './script/api';
import Rule from './script/rule';
import sideMenu from './script/sideMenu';
import RegExp from './script/RegExp';
import Alert from './script/Alert';
import EchartsJs from './echartScript/echartsJs';
// import AMapLoader from '@amap/amap-jsapi-loader';

import BaseConfig from "./BaseConfig";
let echarts = require('echarts');

Vue.prototype.$config = Config;
Vue.prototype.$http = HTTPs;
Vue.prototype.$utils = Utils;
Vue.prototype.$api = Api;
Vue.prototype.$user = User;
Vue.prototype.$rule = Rule;
Vue.prototype.$moment = Moment;
Vue.prototype.$sideMenu = sideMenu;
Vue.prototype.$regexp = RegExp;
Vue.prototype.$particles = particles;
Vue.prototype.$store = store;
Vue.prototype.$echartsJs = EchartsJs;
Vue.prototype.$echarts = echarts;
Vue.prototype.$baseConfig = BaseConfig;
Vue.prototype.$mAlert = Alert;
// Vue.prototype.$AMapLoader = AMapLoader;

// window._AMapSecurityConfig = {
//   securityJsCode: 'b1bde1c73a918f7c6ad8af9a5458a156' // 输入你的jscode
// }

let browser = Utils.isMobile();
Vue.prototype.$isMobile = browser.versions.mobile;

const confirm = ElementUI.MessageBox.confirm;
// const dialog = Dialog.confirm;

Vue.prototype.$confirm = function(message,title,options){
  // console.log(message,title,options);
  if(browser.versions.mobile){
    return dialog.call(this,{title:title,message:message,...options});
  }else {
    return confirm.call(this, message, title, options);
  }
};

window.onresize = () => {
  // let browser = Utils.isMobile();
  // console.log(browser.versions.mobile);
  // Vue.prototype.$isMobile = browser.versions.mobile;
}

import VueUeditorWrap from 'vue-ueditor-wrap'
import UserNavMain from './components/common/UserNavMain';
import MainLayout from './components/common/MainLayout'
import SideMenu from './components/common/SideMenu';
import TypeSelect from "./components/widget/TypeSelect";
import YearSelect from "./components/widget/YearSelect";
import MonthSelect from "./components/widget/MonthSelect";
import EditButton from "./components/button/EditButton";
import DelButton from "./components/button/DelButton";
import AddButton from "./components/button/AddButton";
import BaseButton from "./components/button/BaseButton";
import MainBreadcrumb from "./components/common/MainBreadcrumb";
import HeadImgUpload from "./components/widget/HeadImgUpload";
import PushTime from "./components/widget/PushTime";
import EvaluationInformation from "./components/widget/EvaluationInformation";
import CompanySelect from "./components/widget/CompanySelect";
import FileUpload from "./components/widget/FileUpload";
import AreaSelect from "./components/widget/AreaSelect";
import SortSelect from "./components/widget/SortSelect";
import IconDialog from "./components/dialog/IconDialog";
import ZdSelect from "./components/widget/ZdSelect";
import OneCheckBox from "./components/widget/OneCheckBox";
import PriceTimeSelect from "./components/widget/PriceTimeSelect";
import OrderTypeSelect from "./components/widget/OrderTypeSelect";
import OrderStateSelect from "./components/widget/OrderStateSelect";
import ColorSelect from "./components/widget/ColorSelect";
import ProvinceSelect from "./components/widget/ProvinceSelect";
import RangeTime from "./components/widget/RangeTime";
import GroupSelect from "./components/widget/GroupSelect";
import CreateTime from "./components/widget/CreateTime";
import BarEcharts from "./components/common/BarEcharts";
import RoleTreeSelect from "./components/widget/RoleTreeSelect";
import DeptTreeSide from "./components/widget/DeptTreeSide";
import DeptTreeSelect from "./components/widget/DeptTreeSelect";
import RoleSelect from "./components/widget/RoleSelect";
import ParticlesView from "./components/common/Particles";
import BaseTreeSelect from "./components/widget/BaseTreeSelect";
import BaseDropItem from "./components/button/BaseDropItem";
import EditForm from "@/components/dialog/EditForm";
import MemberLevelSelect from "@/components/widget/MemberLevelSelect";
import ChooseTime from "@/components/widget/ChooseTime";
import ThemeColorChoose from "@/components/widget/ThemeColorChoose";
import YwlxSelect from "./components/widget/YwlxSelect";
import MenuTreeSelect from "./components/widget/MenuTreeSelect";
import ButtonPermChoose from "./components/widget/ButtonPermChoose";
import OptionButtonGroup from "./components/layout/OptionButtonGroup";
import TopButtons from "./components/layout/TopButtons";
import TopSearch from "./components/layout/TopSearch";
import MainTableList from "./components/layout/MainTableList";
import BaseButtonGroup from "./components/layout/BaseButtonGroup";
import RadioGroup from "./components/widget/RadioGroup";
import BaseTreeSide from "./components/widget/BaseTreeSide";
import GoodsDialog from "./components/dialog/GoodsDialog";
import TopicTypeForm from "./components/widget/TopicTypeForm";
import ExamDialog from "./components/dialog/ExamDialog";
import DateTimeRange from "./components/widget/DateTimeRange";
import KnowledgeDialog from "./components/dialog/KnowledgeDialog";
import UserDialog from "./components/dialog/UserDialog";
import ElTreeSelect from "./components/widget/ElTreeSelect";
import ZeroCheckbox from "./components/widget/ZeroCheckbox";
import RandomQuDialog from "./components/dialog/RandomQuDialog";
import TimeDown from "./components/widget/TimeDown";
import OnlyShowRadio from "./components/widget/OnlyShowRadio";
import CleanMain from "./components/layout/CleanMain";
import ImageUpload from "@/components/widget/ImageUpload";
import UEditor from "@/components/widget/UEditor";
import TagsInput from "@/components/widget/TagsInput";
import Simulation from "@/components/common/Simulation";
import ZhbTable from './components/widget/ZhbTable';
import ZhbTh from './components/widget/ZhbTh';
import ZhbTd from './components/widget/ZhbTd';
import ZhbTr from './components/widget/ZhbTr';
import ImagePreView from './components/widget/ImagePreView'
import ZhbVideo from './components/widget/ZhbVideo'
import ShenpiDialog from './views/program/components/ShenpiDialog'
import LingquDialog from './views/program/components/LingquDialog'
import BanliDialog from './views/program/components/BanliDialog'
import TimeLine from './components/widget/TimeLine'
import DuchaDialog from './views/program/components/DuchaDialog'
import DelayDialog from './views/program/components/DelayDialog'
import StopDialog from './views/program/components/StopDialog'
import ReplyDialog from './views/program/components/ReplyDialog'
import CheckBoxGroup from './components/widget/CheckBoxGroup'
import PieEcharts from './components/common/PieEcharts'
import ToDoShenpiDialog from './views/program/components/ToDoShenpiDialog'
import OneFileUpload from './components/widget/OneFileUpload'
import ListShenpiDialog from './views/program/components/ListShenpiDialog'
import AbsstateDialog from './views/program/components/AbsstateDialog'

Vue.component("edit-button", EditButton);
Vue.component("del-button", DelButton);
Vue.component("add-button", AddButton);
Vue.component("base-button", BaseButton);
Vue.component('lee-bar-echarts', BarEcharts);
Vue.component('lee-pie-echarts', PieEcharts);
Vue.component('m-main-layout',MainLayout);
Vue.component('m-side-menu',SideMenu);
Vue.component('m-user-nav-main',UserNavMain);
Vue.component('type-select',TypeSelect);
Vue.component('company-select',CompanySelect);
Vue.component('year-select',YearSelect);
Vue.component('month-select',MonthSelect);
Vue.component('m-breadcrumb',MainBreadcrumb);
Vue.component('head-img-upload',HeadImgUpload);
Vue.component('push-time',PushTime);
Vue.component('file-upload',FileUpload);
Vue.component('Evaluation-ionInformation',EvaluationInformation);
Vue.component('area-select',AreaSelect);
Vue.component('sort-select',SortSelect);
Vue.component('zd-select',ZdSelect);
Vue.component('one-checkbox',OneCheckBox);
Vue.component('icon-dialog',IconDialog);
Vue.component('goods-dialog',GoodsDialog);
Vue.component('price-time-select',PriceTimeSelect);
Vue.component('order-type-select',OrderTypeSelect);
Vue.component('order-state-select',OrderStateSelect);
Vue.component('color-select',ColorSelect);
Vue.component('province-select',ProvinceSelect);
Vue.component('range-time',RangeTime);
Vue.component('group-select',GroupSelect);
Vue.component('create-time',CreateTime);
Vue.component('role-tree-select',RoleTreeSelect);
Vue.component('role-select',RoleSelect);
Vue.component('dept-tree-side',DeptTreeSide);
Vue.component('dept-tree-select',DeptTreeSelect);
Vue.component('tree-select',TreeSelect);
Vue.component('particles-view',ParticlesView);
Vue.component('base-tree-select',BaseTreeSelect);
Vue.component('base-drop-item',BaseDropItem);
Vue.component('member-level-select',MemberLevelSelect);
Vue.component('edit-form',EditForm);
Vue.component('choose-time',ChooseTime);
Vue.component('theme-color-choose',ThemeColorChoose);
Vue.component('ywlx-select',YwlxSelect);
Vue.component('menu-tree-select',MenuTreeSelect);
Vue.component('button-perm-choose',ButtonPermChoose);
Vue.component('option-button-group',OptionButtonGroup);
Vue.component('top-buttons',TopButtons);
Vue.component('top-search',TopSearch);
Vue.component('main-table-list',MainTableList);
Vue.component('base-button-group',BaseButtonGroup);
Vue.component('zhb-radio-group',RadioGroup);
Vue.component('base-tree-side',BaseTreeSide);
Vue.component('editor',UEditor);
Vue.component('topic-type-form',TopicTypeForm);
Vue.component('exam-dialog',ExamDialog);
Vue.component('knowledge-dialog',KnowledgeDialog);
Vue.component('user-dialog',UserDialog);
Vue.component('el-tree-select',ElTreeSelect);
Vue.component('date-time-range',DateTimeRange);
Vue.component('zero-checkbox',ZeroCheckbox);
Vue.component('random-qu-dialog',RandomQuDialog);
Vue.component('time-down',TimeDown);
Vue.component('only-show-radio',OnlyShowRadio);
Vue.component('clean-main',CleanMain);
Vue.component('radio-group',RadioGroup);
Vue.component('image-upload',ImageUpload);
Vue.component('vue-ueditor-wrap',VueUeditorWrap);
Vue.component('tags-input',TagsInput);
Vue.component('Simulation',Simulation);
Vue.component('zhb-table',ZhbTable);
Vue.component('zhb-th',ZhbTh);
Vue.component('zhb-tr',ZhbTr);
Vue.component('zhb-td',ZhbTd);
Vue.component('image-pre-view',ImagePreView);
Vue.component('zhb-video',ZhbVideo);
Vue.component('shenpi-dialog',ShenpiDialog);
Vue.component('list-shenpi-dialog',ListShenpiDialog);
Vue.component('lingqu-dialog',LingquDialog);
Vue.component('banli-dialog',BanliDialog);
Vue.component('ducha-dialog',DuchaDialog);
Vue.component('delay-dialog',DelayDialog);
Vue.component('stop-dialog',StopDialog);
Vue.component('reply-dialog',ReplyDialog);
Vue.component('todo-shenpi-dialog',ToDoShenpiDialog);
Vue.component('abs-state-dialog',AbsstateDialog);
Vue.component('zhb-time-line',TimeLine);
Vue.component('zhb-checkbox-group',CheckBoxGroup);
Vue.component('one-file-upload',OneFileUpload);

router.beforeEach((to, from, next) => {

  let _crossList = ['/login'];
  if(_crossList.indexOf(to.path) === -1){

    if(to.path !== '/' && to.path !== '/choose') {
      User.setTab({path: to.path, name: to.meta.title, data: '', query: to.query});
    }

    User.setLastTab(from.path);

    console.log(to.path)
    if(!User.getToken()){
      next({ path: '/login' })
    }else{
      if(to.path === '/'){
        next({path:'/choose'});
      }else{
        next();
      }
    }
  }else{
    next();
  }
});

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
