<template>
  <div>
    <span class="form-icon margin-right" v-show="icon"><i :class="icon"></i></span>
    <el-button type="primary" @click="dialogVisible = true" native-type="button">选择图标</el-button>
    <el-button type="danger" @click="toClean" native-type="button">清空</el-button>
    <el-dialog append-to-body title="图标" :visible.sync="dialogVisible" @close="onClose" width="30%" custom-class="dialog-h-300">
      <ul class="icon-list">
        <li v-for="item in iconList" @click="checkIcon(item)"><span><i :class="item"></i></span></li>
      </ul>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "IconDialog",
  data(){
    return{
      dialogVisible: false,
      iconList:[
        'el-icon-ice-cream-round',
        'el-icon-ice-cream-square',
        'el-icon-lollipop',
        'el-icon-potato-strips',
        'el-icon-milk-tea',
        'el-icon-ice-drink',
        'el-icon-ice-tea',
        'el-icon-coffee',
        'el-icon-orange',
        'el-icon-pear',
        'el-icon-apple',
        'el-icon-cherry',
        'el-icon-watermelon',
        'el-icon-grape',
        'el-icon-refrigerator',
        'el-icon-goblet-square-full',
        'el-icon-goblet-square',
        'el-icon-goblet-full',
        'el-icon-goblet',
        'el-icon-cold-drink',
        'el-icon-coffee-cup',
        'el-icon-water-cup',
        'el-icon-hot-water',
        'el-icon-ice-cream',
        'el-icon-dessert',
        'el-icon-sugar',
        'el-icon-tableware',
        'el-icon-burger',
        'el-icon-knife-fork',
        'el-icon-fork-spoon',
        'el-icon-chicken',
        'el-icon-food',
        'el-icon-dish-1',
        'el-icon-dish',
        'el-icon-moon-night',
        'el-icon-moon',
        'el-icon-cloudy-and-sunny',
        'el-icon-partly-cloudy',
        'el-icon-cloudy',
        'el-icon-sunny',
        'el-icon-sunset',
        'el-icon-sunrise-1',
        'el-icon-sunrise',
        'el-icon-heavy-rain',
        'el-icon-lightning',
        'el-icon-light-rain',
        'el-icon-wind-power',
        'el-icon-baseball',
        'el-icon-soccer',
        'el-icon-football',
        'el-icon-basketball',
        'el-icon-ship',
        'el-icon-truck',
        'el-icon-bicycle',
        'el-icon-mobile-phone',
        'el-icon-service',
        'el-icon-key',
        'el-icon-unlock',
        'el-icon-lock',
        'el-icon-watch',
        'el-icon-watch-1',
        'el-icon-timer',
        'el-icon-alarm-clock',
        'el-icon-map-location',
        'el-icon-delete-location',
        'el-icon-add-location',
        'el-icon-location-information',
        'el-icon-location-outline',
        'el-icon-location',
        'el-icon-place',
        'el-icon-discover',
        'el-icon-first-aid-kit',
        'el-icon-trophy-1',
        'el-icon-trophy',
        'el-icon-medal',
        'el-icon-medal-1',
        'el-icon-stopwatch',
        'el-icon-mic',
        'el-icon-copy-document',
        'el-icon-full-screen',
        'el-icon-switch-button',
        'el-icon-aim',
        'el-icon-crop',
        'el-icon-odometer',
        'el-icon-time',
        'el-icon-bangzhu',
        'el-icon-close-notification',
        'el-icon-microphone',
        'el-icon-turn-off-microphone',
        'el-icon-position',
        'el-icon-postcard',
        'el-icon-message',
        'el-icon-chat-line-square',
        'el-icon-chat-dot-square',
        'el-icon-chat-dot-round',
        'el-icon-chat-square',
        'el-icon-chat-line-round',
        'el-icon-chat-round',
        'el-icon-set-up',
        'el-icon-turn-off',
        'el-icon-open',
        'el-icon-connection',
        'el-icon-link',
        'el-icon-cpu',
        'el-icon-thumb',
        'el-icon-female',
        'el-icon-male',
        'el-icon-guide',
        'el-icon-news',
        'el-icon-price-tag',
        'el-icon-discount',
        'el-icon-wallet',
        'el-icon-coin',
        'el-icon-money',
        'el-icon-bank-card',
        'el-icon-box',
        'el-icon-present',
        'el-icon-sell',
        'el-icon-sold-out',
        'el-icon-shopping-bag-2',
        'el-icon-shopping-bag-1',
        'el-icon-shopping-cart-2',
        'el-icon-shopping-cart-1',
        'el-icon-shopping-cart-full',
        'el-icon-smoking',
        'el-icon-no-smoking',
        'el-icon-house',
        'el-icon-table-lamp',
        'el-icon-school',
        'el-icon-office-building',
        'el-icon-toilet-paper',
        'el-icon-notebook-2',
        'el-icon-notebook-1',
        'el-icon-files',
        'el-icon-collection',
        'el-icon-receiving',
        'el-icon-suitcase-1',
        'el-icon-suitcase',
        'el-icon-film',
        'el-icon-collection-tag',
        'el-icon-data-analysis',
        'el-icon-pie-chart',
        'el-icon-data-board',
        'el-icon-data-line',
        'el-icon-reading',
        'el-icon-magic-stick',
        'el-icon-coordinate',
        'el-icon-mouse',
        'el-icon-brush',
        'el-icon-headset',
        'el-icon-umbrella',
        'el-icon-scissors',
        'el-icon-mobile',
        'el-icon-attract',
        'el-icon-monitor',
        'el-icon-search',
        'el-icon-takeaway-box',
        'el-icon-paperclip',
        'el-icon-printer',
        'el-icon-document-add',
        'el-icon-document',
        'el-icon-document-checked',
        'el-icon-document-copy',
        'el-icon-document-delete',
        'el-icon-document-remove',
        'el-icon-tickets',
        'el-icon-folder-checked',
        'el-icon-folder-delete',
        'el-icon-folder-remove',
        'el-icon-folder-add',
        'el-icon-folder-opened',
        'el-icon-folder',
        'el-icon-edit-outline',
        'el-icon-edit',
        'el-icon-date',
        'el-icon-c-scale-to-original',
        'el-icon-view',
        'el-icon-loading',
        'el-icon-rank',
        'el-icon-sort-down',
        'el-icon-sort-up',
        'el-icon-sort',
        'el-icon-finished',
        'el-icon-refresh-left',
        'el-icon-refresh-right',
        'el-icon-refresh',
        'el-icon-video-play',
        'el-icon-video-pause',
        'el-icon-d-arrow-right',
        'el-icon-d-arrow-left',
        'el-icon-arrow-up',
        'el-icon-arrow-down',
        'el-icon-arrow-right',
        'el-icon-arrow-left',
        'el-icon-top-right',
        'el-icon-top-left',
        'el-icon-top',
        'el-icon-bottom',
        'el-icon-right',
        'el-icon-back',
        'el-icon-bottom-right',
        'el-icon-bottom-left',
        'el-icon-caret-top',
        'el-icon-caret-bottom',
        'el-icon-caret-right',
        'el-icon-caret-left',
        'el-icon-d-caret',
        'el-icon-share',
        'el-icon-menu',
        'el-icon-s-grid',
        'el-icon-s-check',
        'el-icon-s-data',
        'el-icon-s-opportunity',
        'el-icon-s-custom',
        'el-icon-s-claim',
        'el-icon-s-finance',
        'el-icon-s-comment',
        'el-icon-s-flag',
        'el-icon-s-marketing',
        'el-icon-s-shop',
        'el-icon-s-open',
        'el-icon-s-management',
        'el-icon-s-ticket',
        'el-icon-s-release',
        'el-icon-s-home',
        'el-icon-s-promotion',
        'el-icon-s-operation',
        'el-icon-s-unfold',
        'el-icon-s-fold',
        'el-icon-s-platform',
        'el-icon-s-order',
        'el-icon-s-cooperation',
        'el-icon-bell',
        'el-icon-message-solid',
        'el-icon-video-camera',
        'el-icon-video-camera-solid',
        'el-icon-camera',
        'el-icon-camera-solid',
        'el-icon-download',
        'el-icon-upload2',
        'el-icon-upload',
        'el-icon-picture-outline-round',
        'el-icon-picture-outline',
        'el-icon-picture',
        'el-icon-close',
        'el-icon-check',
        'el-icon-plus',
        'el-icon-minus',
        'el-icon-help',
        'el-icon-s-help',
        'el-icon-circle-close',
        'el-icon-circle-check',
        'el-icon-circle-plus-outline',
        'el-icon-remove-outline',
        'el-icon-zoom-out',
        'el-icon-zoom-in',
        'el-icon-error',
        'el-icon-success',
        'el-icon-circle-plus',
        'el-icon-remove',
        'el-icon-info',
        'el-icon-question',
        'el-icon-warning-outline',
        'el-icon-warning',
        'el-icon-goods',
        'el-icon-s-goods',
        'el-icon-star-off',
        'el-icon-star-on',
        'el-icon-more-outline',
        'el-icon-more',
        'el-icon-phone-outline',
        'el-icon-phone',
        'el-icon-user',
        'el-icon-user-solid',
        'el-icon-setting',
        'el-icon-s-tools',
        'el-icon-delete',
        'el-icon-delete-solid',
        'el-icon-eleme',
        'el-icon-platform-eleme',
      ],
      icon:''
    }
  },
  model:{
    prop:'value',
    event:'change'
  },
  props:{
    value:{
      type:String,
      default:''
    },
    visible:{
      type:Boolean,
      default:false
    }
  },
  created() {
    this.icon = this.value ? this.value : '';
  },
  watch:{
    visible(n,o){
      this.dialogVisible = n;
    },
    value(n,o){
      this.icon = n ? n : '';
    }
  },
  methods:{
    checkIcon(icon){
      // console.log(icon);
      this.icon = icon;
      this.dialogVisible = false;
      this.$emit('checkIcon',icon);
      this.$emit('change',icon);
    },
    onClose(){
      this.$emit('onClose');
    },
    toClean(){
      this.icon = '';
      this.$emit('checkIcon','');
      this.$emit('change','');
    }
  }
}
</script>
