<template>
    <el-color-picker v-model="values" :predefine="predefine" @change="change" style="vertical-align: middle;" ></el-color-picker>
</template>

<script>
export default {
    name: "ColorSelect",
    data(){
        return{
            values:'',
            predefine: [
                '#c71585',
                '#ff0000',
                '#d51217',
                '#ff4500',
                '#e64508',
                '#ff8c00',
                '#fec20c',
                '#f5dc02',
                '#a5da1b',
                '#90ee90',
                '#70ce2a',
                '#4abf4d',
                '#00ced1',
                '#2e93b1',
                '#1e90ff',
                '#3178e4',
                '#2d3ff8',
                '#541acc',
                '#4b15c5',
                '#620279',
            ]
        }
    },
    model:{
        prop:'value',
        event:'change'
    },
    props:{
        value:{
            type:String,
            default:''
        }
    },
    created() {
        this.values = this.value ? this.value : '';
    },
    watch:{
        value(n,o){
            this.values = n ? n : '';
        }
    },
    mounted() {

    },
    methods:{
        change(e){
            this.$emit("change", e);
        }
    }
}
</script>
