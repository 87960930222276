<template>
	<m-main-layout body-in-class="h100" out-class="h100" body-out-class="h100 hide">
<!--		<div class="adminLogin">欢迎登录夷陵区委督查平台</div>-->
		<el-card shadow="never" class="diy-card" :body-style="{padding:'10px'}">
			<div slot="header" class="cl">
				<span class="f-14">我的关注</span>
			</div>
			<el-table :data="favoriteList" size="mini">
				<el-table-column fixed type="index" label="序号" width="50" align="center">
				</el-table-column>
				<el-table-column label="事项内容" prop="title" width="400">
					<template slot-scope="scope">
						<div><span class="f-u a-link hand" @click="$router.push({path:'/program/'+scope.row.slug.type+'/detail',query:{id:scope.row.slug.id}})">{{scope.row.slug.title}}</span></div>
						<div v-if="scope.row.slug.special && scope.row.slug.special.title">专题会议：{{scope.row.slug.special.title}}</div>
					</template>
				</el-table-column>
				<el-table-column label="事项类型" prop="title">
					<template slot-scope="scope">
<!--						<template v-if="scope.row.slug.type === 'ldjb'">I.批示督办</template>-->
<!--						<template v-if="scope.row.slug.type === 'zysx'">I.日常督办</template>-->
<!--						<template v-if="scope.row.slug.type === 'hysx'">I.会议督办</template>-->
<!--						<template v-if="scope.row.slug.type === 'qzfsx'">I.政务督办</template><br/>-->
						<template>I.{{scope.row.slug.program_type_str}}</template><br/>
						<template>II.{{scope.row.slug.program_type.name}}</template>
					</template>
				</el-table-column>
				<el-table-column label="状态" prop="title">
					<template slot-scope="scope">
						<el-tag type="" v-if="scope.row.slug.state === 0">已失效</el-tag>
						<el-tag type="primary" size="small" v-if="scope.row.slug.state === 1">待审批</el-tag>
						<el-tag type="primary" size="small" effect="dark" v-if="scope.row.slug.state === 2">待领办</el-tag>
						<el-tag type="warning" size="small" v-if="scope.row.slug.state === 3">办理中</el-tag>
						<el-tag type="warning" size="small" effect="dark" v-if="scope.row.slug.state === 4">已办结</el-tag>
						<el-tag type="danger" size="small" v-if="scope.row.slug.state === 5">已终止</el-tag>
						<el-tag type="danger" size="small" v-if="scope.row.slug.state === 6">拒绝发布</el-tag>
<!--						<el-tag type="danger" size="small" v-if="scope.row.slug.state === 7">异议事项</el-tag>-->
<!--						<el-tag type="success" size="small" effect="dark" v-if="scope.row.slug.state === 8">逾期办结</el-tag>-->
<!--						<el-tag type="success" size="small" v-if="scope.row.slug.state === 9">已办结</el-tag>-->
<!--						<el-tag type="info" size="small" v-if="scope.row.slug.state === 10">已终止</el-tag>-->
<!--						<el-tag type="info" size="small" effect="dark" v-if="scope.row.slug.state === 11">已拒绝发布</el-tag>-->
					</template>
				</el-table-column>
				<el-table-column label="交办单位" prop="title">
					<template slot-scope="scope">
						{{scope.row.slug.dept.name}}
					</template>
				</el-table-column>
				<el-table-column label="牵头单位" prop="title">
					<template slot-scope="scope">
						{{scope.row.slug.qtdws_str}}
					</template>
				</el-table-column>
				<el-table-column label="责任单位" prop="title">
					<template slot-scope="scope">
						{{scope.row.slug.zrdws_str}}
					</template>
				</el-table-column>
			</el-table>
			<div class="pd-t-10 text-right" v-if="favoriteList.length > 0">
				<el-pagination
					background
					layout="total,sizes,prev, pager, next,jumper"
					:page-sizes="[5,10,15,20, 50, 100, 200]"
					@size-change="changeFavoriteSize"
					:page-size="favoriteSearch.pageSize"
					:current-page.sync="favoriteSearch.page"
					:total="favoriteTotal"
					@current-change="pageFavoriteChange">
				</el-pagination>
			</div>
		</el-card>
		<el-row :gutter="20" class="mg-t-10">
			<el-col :span="12" v-if="$user.getJob() === 'bsy'">
				<el-card shadow="never" class="diy-card" :body-style="{padding:'10px',maxHeight:'300px',overflowY:'auto'}">
					<div slot="header" class="cl">
						<span class="f-14">我领办的事项</span>
					</div>
					<el-table :data="BsyList" size="mini">
						<el-table-column fixed type="index" label="序号" width="50" align="center"></el-table-column>
						<el-table-column label="事项内容" prop="title" width="200">
							<template slot-scope="scope">
								<div><span class="f-u a-link hand" @click="$router.push({path:'/program/'+scope.row.type +'/detail',query:{id:scope.row.id}})">{{scope.row.title}}</span></div>
								<div v-if="scope.row.special && scope.row.special.title">专题会议：{{scope.row.special.title}}</div>
							</template>
						</el-table-column>
						<el-table-column label="事项类型" prop="title" width="150">
							<template slot-scope="scope">
<!--								<template v-if="scope.row.type === 'ldjb'">I.批示督办</template>-->
<!--								<template v-if="scope.row.type === 'zysx'">I.日常督办</template>-->
<!--								<template v-if="scope.row.type === 'hysx'">I.会议督办</template>-->
<!--								<template v-if="scope.row.type === 'qzfsx'">I.政务督办</template><br/>-->
								<template>I.{{scope.row.type_str}}</template><br/>
								<template>II.{{scope.row.program_type.name}}</template>
							</template>
						</el-table-column>
						<el-table-column label="状态" prop="title">
							<template slot-scope="scope">
								<el-tag type="" v-if="scope.row.state === 0">已失效</el-tag>
								<el-tag type="primary" size="small" v-if="scope.row.state === 1">待审批</el-tag>
								<el-tag type="primary" size="small" effect="dark" v-if="scope.row.state === 2">待领办</el-tag>
								<el-tag type="warning" size="small" v-if="scope.row.state === 3">办理中</el-tag>
								<el-tag type="warning" size="small" effect="dark" v-if="scope.row.state === 4">已办结</el-tag>
								<el-tag type="danger" size="small" v-if="scope.row.state === 5">已终止</el-tag>
								<el-tag type="danger" size="small" v-if="scope.row.state === 6">拒绝发布</el-tag>
							</template>
						</el-table-column>
						<el-table-column label="交办单位" prop="title">
							<template slot-scope="scope">
								{{scope.row.dept.name}}
							</template>
						</el-table-column>
					</el-table>
				</el-card>
			</el-col>
			<el-col :span="12" v-if="$user.getJob() === 'dby'">
				<el-card shadow="never" class="diy-card" :body-style="{padding:'10px',maxHeight:'300px',overflowY:'auto'}">
					<div slot="header" class="cl">
						<span class="f-14">我督办的事项</span>
					</div>
					<el-table :data="dubanList" size="mini">
						<el-table-column fixed type="index" label="序号" width="50" align="center"></el-table-column>
						<el-table-column label="事项内容" prop="title" width="200">
							<template slot-scope="scope">
								<div><span class="f-u a-link hand" @click="$router.push({path:'/program/'+scope.row.type +'/detail',query:{id:scope.row.id}})">{{scope.row.title}}</span></div>
								<div v-if="scope.row.special && scope.row.special.title">专题会议：{{scope.row.special.title}}</div>
							</template>
						</el-table-column>
						<el-table-column label="事项类型" prop="title" width="150">
							<template slot-scope="scope">
<!--								<template v-if="scope.row.type === 'ldjb'">I.批示督办</template>-->
<!--								<template v-if="scope.row.type === 'zysx'">I.日常督办</template>-->
<!--								<template v-if="scope.row.type === 'hysx'">I.会议督办</template>-->
<!--								<template v-if="scope.row.type === 'qzfsx'">I.政务督办</template><br/>-->
								<template>I.{{scope.row.type_str}}</template><br/>
								<template>II.{{scope.row.program_type.name}}</template>
							</template>
						</el-table-column>
						<el-table-column label="状态" prop="title">
							<template slot-scope="scope">
								<el-tag type="" v-if="scope.row.state === 0">已失效</el-tag>
								<el-tag type="primary" size="small" v-if="scope.row.state === 1">待审批</el-tag>
								<el-tag type="primary" size="small" effect="dark" v-if="scope.row.state === 2">待领办</el-tag>
								<el-tag type="warning" size="small" v-if="scope.row.state === 3">办理中</el-tag>
								<el-tag type="warning" size="small" effect="dark" v-if="scope.row.state === 4">已办结</el-tag>
								<el-tag type="danger" size="small" v-if="scope.row.state === 5">已终止</el-tag>
								<el-tag type="danger" size="small" v-if="scope.row.state === 6">拒绝发布</el-tag>
							</template>
						</el-table-column>
						<el-table-column label="交办单位" prop="title">
							<template slot-scope="scope">
								{{scope.row.dept.name}}
							</template>
						</el-table-column>
					</el-table>
				</el-card>
			</el-col>
			<el-col :span="12">
				<el-card shadow="never" class="diy-card" :body-style="{padding:'10px',maxHeight:'300px',overflowY:'auto'}">
					<div slot="header" class="cl">
						<span class="f-14">部门事项</span>
					</div>
					<el-table :data="DeptList" size="mini">
						<el-table-column fixed type="index" label="序号" width="50" align="center"></el-table-column>
						<el-table-column label="标题/专题会议" prop="title" width="200">
							<template slot-scope="scope">
								<div>标题：<span class="f-u a-link hand" @click="$router.push({path:'/program/'+scope.row.type +'/detail',query:{id:scope.row.id}})">{{scope.row.title}}</span></div>
								<div v-if="scope.row.special && scope.row.special.title">专题会议：{{scope.row.special.title}}</div>
							</template>
						</el-table-column>
						<el-table-column label="事项类型" prop="title" width="150">
							<template slot-scope="scope">
								<template v-if="scope.row.type === 'ldjb'">I.批示督办</template>
								<template v-if="scope.row.type === 'zysx'">I.日常督办</template>
								<template v-if="scope.row.type === 'hysx'">I.会议督办</template>
								<template v-if="scope.row.type === 'qzfsx'">I.政务督办</template><br/>
								<template>II.{{scope.row.program_type.name}}</template>
							</template>
						</el-table-column>
						<el-table-column label="状态" prop="title">
							<template slot-scope="scope">
								<el-tag type="" v-if="scope.row.state === 0">已失效</el-tag>
								<el-tag type="primary" size="small" v-if="scope.row.state === 1">待审批</el-tag>
								<el-tag type="primary" size="small" effect="dark" v-if="scope.row.state === 2">待领办</el-tag>
								<el-tag type="warning" size="small" v-if="scope.row.state === 3">办理中</el-tag>
								<el-tag type="warning" size="small" effect="dark" v-if="scope.row.state === 4">已办结</el-tag>
								<el-tag type="danger" size="small" v-if="scope.row.state === 5">已终止</el-tag>
								<el-tag type="danger" size="small" v-if="scope.row.state === 6">拒绝发布</el-tag>
							</template>
						</el-table-column>
						<el-table-column label="交办单位" prop="title">
							<template slot-scope="scope">
								{{scope.row.dept.name}}
							</template>
						</el-table-column>
					</el-table>
				</el-card>
			</el-col>
		</el-row>
	</m-main-layout>
</template>

<script>
export default {
	name: "DashBoard",
	data() {
		return {
			username: this.$user.getUserName(),
			today: '',

			user_total_time: '',
			start_time: '',
			end_time: '',

			favoriteList:[],
			favoriteSearch:{
				akey:'program',
				type:'favorite',
				uid:this.$user.getUid(),
				page:1,
				pageSize:10
			},
			favoriteTotal:0,

			BsyList:[],
			DeptList:[],
			dubanList:[]
		}
	},
	created() {

	},
	mounted() {
		this.getMyFavorite();
		this.getMyDeptList();

		if(this.$user.getJob() === 'bsy'){
			this.getMyBsyList();
		}else if(this.$user.getJob() === 'dby'){
			this.getMyDubanList();
		}
	},
	methods: {
		getMyDubanList(){
			let _this = this;
			this.$http.request({
				url:'/Program/listPage',
				datas:{dby_ids:this.$user.getUid()},
				success(res){
					_this.dubanList = res.list;
				}
			})
		},
		getMyDeptList(){
			let _this = this;
			this.$http.request({
				url:'/Program/listPage',
				datas:{dept_id:this.$user.getDepartmentId()},
				success(res){
					_this.DeptList = res.list;
				}
			})
		},
		getMyBsyList(){
			let _this = this;
			this.$http.request({
				url:'/Program/listPage',
				datas:{bsy_ids:[this.$user.getUid(),'find_in_set']},
				success(res){
					_this.BsyList = res.list;
				}
			})
		},
		getMyFavorite(){
			let _this = this;
			this.$http.request({
				url:'/UserLog/listPage',
				datas:this.favoriteSearch,
				success(res){
					console.log(res);
					_this.favoriteList = res.list;
					_this.favoriteTotal = res.totalCount;
				}
			})
		},
		pageFavoriteChange(e){
			console.log(e);
			this.favoriteSearch.page = e;
			this.getMyFavorite();
		},
		changeFavoriteSize(e){
			console.log(e);
			this.favoriteSearch.pageSize = e;
			this.getMyFavorite();
		}
	}
}
</script>
<style>
.adminLogin {
	width: 100%;
	text-align: center;
	font-size: 24px;
	margin-top: 200px;
	font-weight: 300;
}
</style>
