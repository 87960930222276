<template>
  <span class="el-radio__input" :class="{'is-checked':isChecked,'is-disabled':disabled}">
    <span class="el-radio__inner"></span>
    <input type="radio" aria-hidden="true" tabindex="-1" autocomplete="off" class="el-radio__original" value="">
  </span>
</template>

<script>
export default {
  name: "OnlyShowRadio",
  data() {
    return {
      isChecked:false
    }
  },
  props:{
    checked:{
      type:Boolean,
      default:false
    },
    disabled:{
      type:Boolean,
      default:false
    }
  },
  created() {
    this.isChecked = !!this.checked;
  },
  mounted() {
  },
  methods: {}
}
</script>
