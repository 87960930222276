export default {
    // 页面url，必须和listRouter一致
    '/test/test': {
        //页面标题
        name: 'test',
        default:'list', //默认页地址，list代表默认读取list配置
        //列表页router
        listRouter: '/test/test',
        //编辑页router，弹窗形式不需要
        editRouter: '/system/menu/edit',
        //新增页router，弹窗形式不需要
        addRouter: '/system/menu/add',
        //详情页router，弹窗形式不需要
        detailRouter: '',
        //编辑权限标识
        editRole: 'system:menu:update',
        //新增权限标识
        addRole: 'system:menu:add',
        //删除权限标识
        delRole: 'system:menu:delete',
        //顶部左侧按钮，参数与列表的extButtons类似，没有com，增加type 默认 primary，size 默认 mini
        topLeftButtons: [
            {label:'按钮权限',role:'system:menu:delete',icon:'el-icon-refresh',type:'_self',title:'编辑按钮权限',path:'/system/menu/edit',size:'medium',btnType:'success'},
            {label:'本窗口打开路由',role:'system:menu:delete',icon:'el-icon-refresh',type:'url',title:'编辑按钮权限',path:'/system/menu/edit',com:'add',target:'_self',btnType:'danger'},
        ],
        //顶部右侧按钮
        topRightButtons: [
            {label:'按钮权限',role:'system:menu:delete',icon:'el-icon-refresh',type:'url',title:'编辑按钮权限',path:'/system/menu/editDialog',size:'mini',btnType:'danger'},
        ],

        //列表对象
        list: {
            //自定义页面路径
            com: '',
            //列表配置
            options: {
                //列表接口url
                listUrl: '/SystemRole/listPage',
                //是否为tree结构，如果true，则不显示翻页
                listTree:false,
                //修改状态接口url
                statusUrl: '',
                //删除接口url，{id}占位符会被主键id替换
                delUrl: '/api-admin/api.admin/v1/menus/{id}',
                //导出接口url
                exportUrl:'',
                //导出文件名
                exportFileName:'',
                //列表接口请求方式
                method: 'post',
                //删除接口请求方式
                delMethod: 'post',
                //主键
                pk:'id',
                //列表数据 返回数据中的对象 键名，
                listKey:'list',
                //分页page 参数，默认 pageNum
                pageKey:'page',
                //分页pagesize 参数，默认 pageSize
                pageSizeKey:'pageSize',
                //列表页固定传入参数
                data: {queryMode:'page'},
                //是否显示机构侧边栏，true时则显示，同时列表查询参数会增加deptId
                showDept: false,
                //是否显示新增按钮，权重高于权限标识
                showAdd: true,
                //是否显示删除按钮，权重高于权限标识
                showDel: true,
                //是否显示修改按钮，权重高于权限标识
                showEdit: true,
                //是否显示详情按钮，权重高于权限标识
                showDetail: true,
                //是否显示导出按钮
                showExport:false,
                //是否显示搜索栏
                showSearch:false,
                //是否显示分页
                showPage:true,
                optionsWidth:500
            },

            //列表配置
            params: [
                //name:字段名称，value：字段参数名，type：字段处理方式，width：列宽度，单位px，int

                /**
                 * type 参数详解
                 *
                 * state：状态显示 1：可用，0：禁用
                 *
                 * icon：显示图标，值应为css样式名
                 *
                 * bool：状态显示，1：是，0：否
                 *
                 * custom-select：自定义类别显示，具体呈现为 tag 组件
                 *      示例1：{name:'机构',value:'deptId',type:'custom-select',options:[{label:'机构1',val:2,color:'#f00'}]}
                 *
                 * sex：性别，0：未知，1：男，2：女
                 *
                 * percent：显示为百分比，自动添加 %
                 *
                 * copy：添加复制按钮
                 *
                 * image：显示图片，固定高度60px，可放大
                 *
                 * base64image：显示base64图片，固定高度60px，可放大
                 *
                 * time：解析时间戳为 YYYY-MM-DD HH:mm:ss
                 *
                 * unixtime：解析时间戳为 YYYY-MM-DD HH:mm:ss.SSS
                 *
                 * link：添加链接，新窗口打开，链接地址为 参数值
                 *
                 * html：以html解析
                 *
                 * changeStatus：状态切换 switch 组件，接口url在 list.options 中配置
                 *
                 * mobile：自动 脱敏中间4位
                 *
                 * idCard：自动 脱敏中间8位
                 *
                 * price：分 转 元，显示2位小数
                 */
                {name: 'ID', value: 'id'},
                {name: '菜单名称', value: 'name',width:200},
                {name: '路径', value: 'path'},
                {name: '图标', value:'icon',type:'icon'},
                // {name: '排序', value: 'sort'},
                // {name: '状态', value: 'visible',type:'state'},
                // {name: '时间', value: 'gmtModified'},
            ],

            // 扩展按钮
            extButtons: [
                //  label 按钮文字 ，role :权限标识  icon:图标，title：弹框的标题，type：按钮交互方式，可选值 fun(调用某个接口)，url(跳转路由)，dialog(已弹窗形式打开某一组件)，默认url，com：组件路径，path：组件路径,路由 或 接口地址
                {label:'本窗口打开路由',role:'system:menu:delete',icon:'el-icon-refresh',type:'url',title:'编辑按钮权限',path:'/system/menu/edit',com:'add',target:'_self',btnType:'danger'},
                {label:'新窗口打开路由',role:'system:menu:delete',icon:'el-icon-refresh',type:'url',title:'编辑按钮权限',path:'/system/menu/editDialog',target:'_blank',btnType:'success'},
                {label:'新窗口外部链接',role:'system:menu:delete',icon:'el-icon-refresh',type:'web',title:'编辑按钮权限',path:'https://www.baidu.com',target:'_blank',btnType:'warning'},
                {label:'弹框打开列表组件',role:'system:menu:delete',icon:'el-icon-refresh',type:'list',title:'编辑按钮权限',path:'/system/dept',target:'_blank',btnType:'primary'},
                {label:'弹框打开新增组件',role:'system:menu:delete',icon:'el-icon-refresh',type:'add',title:'编辑按钮权限',path:'/system/dept',target:'_blank',btnType:'success'},
                {label:'弹框打开详情组件',role:'system:menu:delete',icon:'el-icon-refresh',type:'detail',title:'编辑按钮权限',path:'/system/dept',target:'_blank',btnType:'danger'},
            ]
        },
        //编辑配置
        edit: {
            //自定义组件路径
            com: '',
            options: {
                //编辑接口url，{id}占位符
                editUrl: '/api-admin/api.admin/v1/menus/{id}',
                //详情接口url，{id}占位符
                detailUrl: '/api-admin/api.admin/v1/menus/{id}',
                //新增接口url，{id}占位符
                addUrl: '/api-admin/api.admin/v1/menus',
                //编辑请求方式
                editMethod:'put',
                //新增请求方式
                addMethod:'post',
                //详情请求方式
                detailMethod:'get',
                //主键
                pk: 'id',
                //编辑或新增时的固定传入参数
                data: null,
                //编辑弹窗表单列数，默认1列表
                cols:1
            },
            //编辑传入参数配置
            params: [
                //name：参数名，
                //value：字段名，
                //def：默认值（默认空字符串''），
                //edit：是否可以编辑，
                //rq：是否必填，
                //rule：验证规则，详见regexp文件，
                //type：组件类型，默认单行input框
                /**
                 * type 参数详解
                 * select：下拉框，
                 * 示例1 {name:'类型',value:'type',type:'select',url:'xxxxx url',method:'post',data:{key:1,mode:'list'},valKey:'tid',valLabel:'tname'}
                 *
                 * 示例2 {name:'类型',value:'type',type:'select',options:[{tid:22,tname:'222'},{tid:23,tname:'333'}],valKey:'tid',valLabel:'tname'}
                 *
                 *    额外参数：url，method，data 需搭配使用，如果不传url，则options必传
                 *    url：下拉框接口url,
                 *    method：接口请求方式，默认 get
                 *    data：接口请求参数，Object
                 *
                 *    options：自定义选项，array 示例 [{id:1,name:'示例1'},{id:2,name:'示例2'}]
                 *
                 *    valKey：下拉选项主键，默认 id
                 *    valLabel：下拉选项文字，默认 name
                 *
                 * textarea：文本域
                 *
                 * image：图片上传，仅支持上传单张图片
                 *
                 * time：时间选择，返回格式 YYYY-MM-DD
                 *
                 * year：年份选择，显示近30年，返回int，如 2019
                 *
                 * file：文件上传，最大上传1个，暂时这样，后续会修改支持多个，要根据接口而定
                 *
                 * state：状态switch组件，int，1：启用，0：禁用
                 *
                 * deptSelect：机构tree选择，返回机构id
                 *
                 * roleSelect：角色选择，返回角色id
                 *
                 * baseTreeSelect：基础tree选择组件，返回主键值
                 *      额外参数
                 *      url：接口url，必填
                 *      isTop:true  是否显示顶级选项，默认值 0
                 *      topLabel: 顶级选项 文字，默认 '顶级'
                 *      valKey: 选项主键，默认 id
                 *      valLabel：选项文字，默认 label
                 *      valChild：子级 字段名，默认  children
                 *
                 * password：密码输入框
                 *
                 * iconChoose：图标选择弹框，返回图标css名
                 *
                 *
                 * 特殊参数1：
                 * 当没有 type 参数时(即单行input)，以下参数可生效
                 * prepend：input 前置内容
                 * append：input 后置内容
                 * max：input maxlength，当 max 参数 非空 非0 时，input显示word-limit
                 *
                 * --------------------------------------------
                 * 特殊参数2：
                 * tip：表单后的说明文字，任何 type 均可使用
                 *
                 * -------------------------------------------
                 * 特殊情况
                 * 当以上参数  value 即 字段名 为 sort 是，表单后会自动增加 '排序数字越小越靠前'文字
                 *
                 * 以上组件后续会逐步增加
                 */

                {name: '上级', value:'parentId',def:0,edit:true,rq:true,type:'baseTreeSelect',url:'/api-admin/api.admin/v1/menus?queryMode=tree',isTop:true},
                {name: '菜单名称', value: 'name', edit: true, rq: true},
                {name: '路径', value: 'path', edit: true, rq: true},
                {name: '日期', value: 'time', edit: true, rq: true,type:'time'},
                {name: '整体评分', value: 'start1',def:0,comProp:{showText:true}, edit: true, rq: true,type:'star'},
                {name: '满意程度', value: 'start2',def:0, edit: true, rq: true,type:'star'},
                {name: '解决能力', value: 'start3',def:0, edit: true, rq: true,type:'star'},
                {name: '有无遗留', value: 'start4',def:0, edit: true, rq: true,type:'star'},
                {name: '职业程度', value: 'start5',def:0, edit: true, rq: true,type:'star'},
                {name: '服务态度', value: 'start6',def:0, edit: true, rq: true,type:'star'},
                {name: '排序', value: 'sort', def: '0', edit: true, rq: true, type: 'sort',rule:{patt:'positiveInteger'}},
                {name: '图标', value: 'icon', edit: true, rq: true, type: 'iconChoose'},
                {name: '图片上传', value: 'avatar', edit: true, rq: true, type: 'image'},
                {name: '文件上传', value: 'files', edit: true, rq: true, type: 'file'},
                {name: '省市区', value: 'areas', type: 'provinceSelect',comProp:{outData:'province,city,area',type:'pca'}, edit: true, rq: true},
                {name: '省市', value: 'areas2', type: 'provinceSelect',comProp:{outData:'province,city',type:'pc'}, edit: true, rq: true},
                {name: '省市区(只返回区code)', value: 'areas3', type: 'provinceSelect',comProp:{outData:'area',type:'pca'}, edit: true, rq: true},
                {name: 'radio2', value: 'radio2', type: 'radio',def:0, edit: true, rq: true,options:[{label:'是',value:1},{label:'否',value:0}]},
                {name: '状态', value: 'visible', type: 'state', edit: true, rq: true},
                {name: '状态', value: 'radio', type: 'radio',def:0, edit: true, rq: true,options:[{label:'是',value:1},{label:'否',value:0}]},
                {name: '单位1',value:'dw1',type:'select',edit:true,rq:true,options:[{name:'双',id:1},{name:'盒',id:2}],rules:{key:'radio',val:1},lastInput:{name:'喂喂喂111',value:'dw11',rq:true}},
                {name: '单位2',value:'dw2',type:'select',edit:true,rq:true,options:[{name:'只',id:1},{name:'件',id:2}],rules:{key:'radio',val:1},lastInput:{name:'喂喂喂2',value:'dw22',rq:true}},
            ],
        },

        //添加配置
        /**
         * 如果
         */
        add: {
            com: '',
        },
        detail: {
            com: '',
            options: {
                editUrl: '/api-admin/api.admin/v1/roles/{id}',
                detailUrl: '/api-admin/api.admin/v1/menus/{id}',
                addUrl: '/api-admin/api.admin/v1/roles',
                editMethod:'put',
                addMethod:'post',
                detailMethod:'get',
                pk: 'id',
                data: {code:'role'},
            },
            params: [
                {
                    type:'info',
                    title:'学习信息',
                    cols:1,
                    params:[
                        {name:'菜单名称',value:'name'},
                        {name:'排序',value:'sort'},
                        {name:'创建时间',value:'createTime'},
                    ]
                },
            ]
        },

        //搜索栏配置
        search:{
            //name：参数名，
            //value：字段名，
            //type：组件类型，默认单行input框
            //placeholder: 仅输入框有效
            /**
             * type 参数详解
             * select：下拉框，
             * timeRange：时间范围选择
             */
            params:[
                {name: '姓名', value: 'name'},
                {name: '机构',value:'deptId',type:'select',url:'xxxxx',valLabel:'label',valKey:'id',data:{aaa:'111'}},
                {name: '机构2',value:'deptId2',type:'select',options:[{label:'1212',id:'3232'},{label:'1212',id:'3232'}]},
                {name: '注册时间',value:'createTime',type:'timeRange',unix:'ss',columns:['registerTimeFrom','registerTimeTo']},
                {name: '手机号', value: 'phone'},
            ]
        }
    },
    '/test/css': {
        //页面标题
        name: 'CSS样式示例页',
        default:'/test/css',
        //列表页router
        listRouter: '/test/css',
        //编辑页router，弹窗形式不需要
        editRouter: '/system/menu/edit',
        //新增页router，弹窗形式不需要
        addRouter: '/system/menu/add',
        //详情页router，弹窗形式不需要
        detailRouter: '',
        //编辑权限标识
        editRole: 'system:menu:update',
        //新增权限标识
        addRole: 'system:menu:add',
        //删除权限标识
        delRole: 'system:menu:delete',

        //列表对象
        list: {
            //自定义页面路径
            com: '/test/css',
            //扩展操作按钮
            extButtons:[]
        },
        //编辑配置
        edit: {
            //自定义组件路径
            com: '',
        },

        //添加配置
        /**
         * 如果
         */
        add: {
            com: '',
        },
        detail: {
            com: '',
        },

        //搜索栏配置
        search:{}
    },
    '/test/com': {
        //页面标题
        name: '组件示例',
        default:'/test/com',
        //列表页router
        listRouter: '/test/com',
        //编辑页router，弹窗形式不需要
        editRouter: '/system/menu/edit',
        //新增页router，弹窗形式不需要
        addRouter: '/system/menu/add',
        //详情页router，弹窗形式不需要
        detailRouter: '',
        //编辑权限标识
        editRole: 'system:menu:update',
        //新增权限标识
        addRole: 'system:menu:add',
        //删除权限标识
        delRole: 'system:menu:delete',

        //列表对象
        list: {
            //自定义页面路径
            com: '/test/com',
            //扩展操作按钮
            extButtons:[]
        },
        //编辑配置
        edit: {
            //自定义组件路径
            com: '',
        },

        //添加配置
        /**
         * 如果
         */
        add: {
            com: '',
        },
        detail: {
            com: '',
        },

        //搜索栏配置
        search:{}
    },
}
