<template>
<!--    <el-select v-model="values" placeholder="请选择" @change="change" clearable style="width: 100%;">-->
<!--        <el-option-->
<!--            v-for="item in list"-->
<!--            :key="item.id"-->
<!--            :label="item.name"-->
<!--            :value="item.id"></el-option>-->
<!--    </el-select>-->
    <el-date-picker
            v-model="values"
            type="month"
            @change="change"
            placeholder="选择月">
    </el-date-picker>
</template>

<script>
export default {
    name: "MonthSelect",
    data(){
        return{
            list:[],
            values:''
        }
    },
    model:{
        prop:'value',
        event:'change'
    },
    props:{
        value:{
            type:[],
            default:''
        }
    },
    created() {
        // this.values = this.value ? parseInt(this.value) : '';
    },
    watch:{
        value(n,o){
            this.values = n ? n : '';
        }
    },
    mounted() {
        // let _now = new Date();
        // let _year = _now.getFullYear();
        let yy = new Date(this.values).getFullYear();
        let mm = new Date(this.values).getMonth()+1;
        // let dd = new Date(this.values).getDate();
        // let hh = new Date(this.values).getHours();
        // let mf = new Date(this.values).getMinutes();
        // let ss = new Date(this.values).getSeconds();

        // for(let i = 1;i <=12;i++){
        //     this.list.push({id:i,name:i+'月'});
        // }
    },
    methods:{
        change(e){
            let yy = this.values.getFullYear();
            // let mm = this.values.getMonth()+1;
            let mm = this.values.getMonth()+ 1 < 10 ? '0' + (this.values.getMonth() + 1)  : this.values.getMonth() + 1 ;
            // let dd = this.values.getDate();
            // console.log(yy+"-"+mm);
            e = yy+"-"+mm

            this.$emit("change", e);
        }
    }
}
</script>
