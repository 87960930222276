<template>
  <div>
		<template v-for="(item,index) in extButtonList" v-if="extButtonList && extButtonList.length > 0">
			<base-button :permission="permission ? permission : null" :circle="!!item.circle" :class="item.class ? item.class : ''" :type="item.btnType" :role="item.role ? item.role : ''" v-if="item && item.checkDisabled ? true : $utils.checkButtonRules(true,scopeRow,item ? item.rules : null)" :disabled="item.checkDisabled ? !$utils.checkButtonRules(true,scopeRow,item ? item.rules : null) : false" :size="item.size" :icon="item.icon" @click.native="toClick(item.type,item,scopeRow)">{{ item.label }}</base-button>
		</template>
    <base-button
      class="blue"
      type="text"
      @click.native="toClick(params.detailProp && params.detailProp.type ? params.detailProp.type : 'detail',params.detailProp,scopeRow)"
      :role="params.detailRole"
      :permission="permission ? permission : null"
      icon="el-icon-view"
      v-if="listOptions.showDetail ? $utils.checkButtonRules(listOptions.showDetail,scopeRow,params.detailProp ? params.detailProp.rules : null) : false"
      :disabled="listOptions.showDetail ? (params.detailDisabled && params.detailDisabled.rules ? $utils.callOperator(scopeRow[params.detailDisabled.rules.key],params.detailDisabled.rules.val,params.detailDisabled.rules.operator) : false) : false"
    >{{ listOptions.detailText ? listOptions.detailText : '详情' }}
    </base-button>
    <edit-button
      type="text"
      @click.native="toClick(params.editProp && params.editProp.type ? params.editProp.type : 'edit',params.editProp,scopeRow)"
      :role="params.editRole"
      :permission="permission ? permission : null"
      v-if="listOptions.showEdit ? $utils.checkButtonRules(listOptions.showEdit,scopeRow,params.editProp ? params.editProp.rules : null) : false"
      :disabled="listOptions.showEdit ? (params.editDisabled && params.editDisabled.rules ? $utils.callOperator(scopeRow[params.editDisabled.rules.key],params.editDisabled.rules.val,params.editDisabled.rules.operator) : false) : false"
      v-bind="params.editProp"
    ></edit-button>
    <del-button
      type="text"
      @click.native="del(scopeRow[listOptions.delPk ? listOptions.delPk : pk])"
      v-if="listOptions.showDel ? $utils.checkButtonRules(listOptions.showDel,scopeRow,params.delProp ? params.delProp.rules : null) : false"
      :role="params.delRole"
      :permission="permission ? permission : null"
      :disabled="listOptions.showDel ? (params.delDisabled && params.delDisabled.rules ? $utils.callOperator(scopeRow[params.delDisabled.rules.key],params.delDisabled.rules.val,params.delDisabled.rules.operator) : false) : false"
      v-bind="params.delProp"
    ></del-button>
	  <template v-if="dropExtButtonList && dropExtButtonList.length > 0">
		  <el-dropdown class="margin-left hand" v-if="checkDrop()">
			  <span class="el-dropdown-link">更多操作<i class="el-icon-arrow-down el-icon--right"></i></span>
			  <el-dropdown-menu slot="dropdown">
				  <template v-for="(item,index) in dropExtButtonList">
<!--					  {{dropCount += 1}}-->
					  <base-drop-item :permission="permission ? permission : null" :class="item.class ? item.class : ''" :role="item.role ? item.role : ''" v-if="item && item.checkDisabled ? true : $utils.checkButtonRules(true,scopeRow,item ? item.rules : null)" :disabled="item.checkDisabled ? !$utils.checkButtonRules(true,scopeRow,item ? item.rules : null) : false" :icon="item.icon" @click.native="toClick(item.type,item,scopeRow)">{{ item.label }}</base-drop-item>
				  </template>
			  </el-dropdown-menu>
		  </el-dropdown>
	  </template>
  </div>
</template>

<script>
export default {
  name: "OptionButtonGroup",
  data() {
    return {
      comPath: '',
      params: null,
      listParams: null,
      listOptions: null,
      pk:'id',
      extButtonList:[],
	    dropExtButtonList:[],
	    dropCount:0
    }
  },
  props:{
    scopeRow:{
      type:[Array,Object],
      default(){
        return {};
      }
    },
	  permission: {
		  type:Array,
		  default(){
			  return null
		  },
	  },
    buttonType:{
      type:String,
      default:'text'
    },
    path:{
      type:String,
      default:''
    },
    tableData:{
      type:Array,
      default(){
        return []
      }
    },
    comParams: null,
    comListParams: null,
    comListOptions: null,
  },
  created() {
    this.init();
  },
  mounted() {
  },
  methods: {
    init(){
      this.comPath = this.path ? this.path : this.$route.path;

      //获取路径全部配置
      this.params = this.comParams ? this.comParams : (this.comPath ? this.$baseConfig[this.comPath] : null);

      //获取列表参数
      this.listParams = this.comListParams ? this.comListParams : (this.params ? this.params.list : null);
      //获取列表配置
      this.listOptions = this.comListOptions ? this.comListOptions : (this.params && this.params.list ? this.params.list.options : null);
      //获取操作扩展按钮
      this.extButtonList = this.$utils.isUndefined(this.listParams.extButtons) ? [] : this.listParams.extButtons;
      this.dropExtButtonList = this.$utils.isUndefined(this.listParams.dropExtButtons) ? [] : this.listParams.dropExtButtons;

      this.pk = this.listOptions.pk ? this.listOptions.pk : 'id';
    },
	  checkDrop(){
			let _count = 0;

		  if(this.dropExtButtonList && this.dropExtButtonList.length > 0){
			  for(let i=0;i < this.dropExtButtonList.length;i++){
				  let _item = this.dropExtButtonList[i];
				  let _a = _item && _item.checkDisabled ? true : this.$utils.checkButtonRules(true,this.scopeRow,_item ? _item.rules : null)
				  let _b = this.$rule.checkRule(_item.role,this.permission && this.permission.length > 0 ? this.permission : null);

					if(_a && _b){
						_count += 1;
					}
			  }
		  }

			return _count > 0;
	  },
    toClick(type,option,scopeRow){
      console.log(type,option,scopeRow);

      let _scopeRow = scopeRow ? this.$utils.deepCopy(scopeRow) : null;
      let _tableData = this.$utils.deepCopy(this.tableData);
      let _target = option ? (['_blank','_self'].indexOf(option.target) >= 0 ? option.target : '_self') : '_self';

      if(this.$utils.isUndefined(type) || type === 'url'){
        if(option.path) {
					if(option.com){
						let _query = null;
						if(option.com === 'edit'){
							_query = {[this.pk]: _scopeRow[this.pk]}
						}
						if(_target === '_self') {
							this.$router.push({path:option.path,query:_query});
						}else{
							const { href } = this.$router.resolve({
								path: option.path,
								query: _query
							});

							window.open(href, _target);
						}
					}else{
						if(_target === '_self') {
							this.$router.push({path:option.path,query:{[this.pk]: _scopeRow[this.pk]}});
						}else{
							const { href } = this.$router.resolve({
								path: option.path,
								query: {[this.pk]: _scopeRow[this.pk]}
							});

							window.open(href, _target);
						}
					}
        }
      }else if(type === 'web'){
        if(option.path) {
          window.open(option.path, _target);
        }
      }else {
				console.log(this.$utils.firstToUpper(type));
        if(this.$utils.firstToUpper(type) === 'Add'){
          if(option.isPid){
            this.$emit('toEdit', option, _scopeRow, {pid: _scopeRow[option.pidKey ? option.pidKey : this.pk]},_tableData);
          }else {
            this.$emit('toEdit', option, _scopeRow, null, _tableData);
          }
        }else {
          this.$emit('to' + this.$utils.firstToUpper(type), option, _scopeRow, {[this.pk]: _scopeRow[this.pk]},_tableData);
        }
      }
    },
    // toCall(path,data){
    //   this.$emit('toCall',path,data);
    // },
    // toExt(scopeRow,option){
    //   this.$emit('toExt',scopeRow,option);
    // },
    // toEdit(scopeRow){
    //   this.$emit('toEdit',scopeRow);
    // },
    // toDetail(scopeRow){
    //   this.$emit('toDetail',scopeRow);
    // },
    del(id) {
      let _this = this;
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
          this.$http.request({
            url: _this.listOptions.delUrl.replace('{id}', id),
            datas: {[_this.listOptions.delPk ? _this.listOptions.delPk : 'id']: id},
            method: _this.listOptions.delMethod ? _this.listOptions.delMethod : 'post',
            success(res) {
              _this.$utils.success("删除成功！");
              _this.$emit('success');
              _this.$emit('toDel');
            }
          });
        }).catch(() => {});
    }
  }
}
</script>
