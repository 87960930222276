export default {
    '/special': {
        name: '专题/议题',
        // default:'/system/menu/list'
        listRouter: '/special',
        editRouter: '/special/edit',
        addRouter: '/special/add',
        detailRouter: '',
        editRole: 'special:list:edit',
        addRole: 'special:list:add',
        delRole: 'special:list:delete',
        delDisabled:null,
        editDisabled:null,
        list: {
            com: '',
            options: {
                listUrl: '/Special/listTree',
                statusUrl: '/Special/changeStatus',
                delUrl: '/Special/del',
                method: 'post',
                delMethod: 'post',
                pk:'id',
                data: {orderBy:'sort',sortBy:'asc'},
                showDept: false,
                showAdd: true,
                showDel: true,
                showEdit: true,
                showDetail: false,
                pageKey:'page',
                pageSizeKey:'pageSize',
                listKey:null,
                listTree:true,
                expandAll:true,
                showPage:false,
                optionsWidth:300
            },
            params: [
                {name: '标题', value: 'title',width:300},
                // {name: '图标', value: 'icon',type:'icon',width:60},
                // {name: '路径', value: 'path',width:200},
                // {name: '权限标识', value:'rule',width:200},
                // {name: '类型', value: 'type',type:'custom-select',options:[{label:'菜单',val:'page',color:'success'},{label:'按钮',val:'button',color:'warning'}]},
                {name: 'ID路径(仅开发用)', value: 'id_path',width: 250},
                {name: '排序', value: 'sort'},
                {name: '状态', value: 'state', type: 'state',},
            ],
            extButtons: [
                //  label 按钮文字 ，role :权限标识  icon:图标，title：弹框的标题，type：按钮交互方式，可选值 fun(调用某个接口)，url(跳转路由)，dialog(已弹窗形式打开某一组件)，默认url，com：组件路径，path：组件路径,路由 或 接口地址
                {label:'添加子级',role:'special:list:child',icon:'el-icon-key',type:'add',isPid:true,title:'添加子级',path:'/special',color:'orange-1',btnType:'text',rules:null},
            ]
        },
        edit: {
            com: '',
            options: {
                editUrl: '/Special/edit',
                detailUrl: '/Special/detail',
                addUrl: '/Special/add',
                editMethod:'post',
                addMethod:'post',
                detailMethod:'post',
                pk: 'id',
                data: null,
                copyData:false
            },
            params: [
                {name: '上级', value: 'pid', edit: true, rq: true,type:'baseTreeSelect',url:'/Special/listTree',method:'post',valLabel:'title',isTop:true,def:0,datas:{orderBy:'sort',sortBy:'asc'}},
                {name: '名称', value: 'title', edit: true, rq: true,max:25},
                {name: '描述', value: 'content',type:'textarea', edit: true, rq: false,max:100},
                // {name: '类型', value: 'type', edit: true, rq: true,type:'radio',options:[{label:'菜单',value:'page'},{label:'按钮',value:'button'}]},
                // {name: '图标', value: 'icon',edit: true,rq:true,type:'iconChoose',rules:{key:'type',val:'page'}},
                // {name: '路径', value: 'path', edit: true, rq: true,max:50,rules:{key:'type',val:'page'}},
                // {name: '权限标识', value: 'rule', edit: true, rq: false,max:30},
                {name: '排序', value: 'sort', def: '0', edit: true, rq: true, type: 'sort'},
                {name: '状态', value: 'state', type: 'state', edit: true, rq: true},
            ]
        },
        add: {
            com: '',
        },
        detail: {
            com: '',
            options: {
                method: 'get'
            },
        }
    },
}
