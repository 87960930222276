<template>
    <div class="border-1 border-radius-10 hide" style="width: 500px;">
        <el-tree
            :data="data"
            show-checkbox
            node-key="id"
            ref="tree"
            :default-checked-keys="ids"
            highlight-current
            :props="defaultProps"
            @check-change="checkChange">
        </el-tree>
    </div>
</template>

<script>
export default {
    name: "RoleTreeSelect",

    data(){
        return{
            data:[],
            defaultProps: {
                children: 'children',
                label: 'label'
            },
            ids:[]
        }
    },
    model:{
        prop:'value',
        event:'change'
    },
    props: {
        value: {
            type:Array,
            default(){
                return [];
            }
        },
        id:{
            type:[Number,String,Array],
            default:''
        }
    },
    created() {
    },
    mounted() {
        this.getList();
    },
    methods:{
        getList(){
            let _this = this;

            this.$http.post({
                url:'/system/menu/treeselect',
              // method:'get',
                success(res){
                    // console.log(res);
                    _this.data = res;

                    if(_this.id) {
                        _this.getDetail();
                    }
                }
            });
        },
        getDetail(){
            let _this = this;

            this.$http.get({
                url:'/system/menu/roleMenuTreeselect/'+this.id,
                success(res){
                    _this.$nextTick(()=>{
                        _this.ids = res.checkedKeys;
                    })

                    // _this.data = res;
                }
            });
        },
        checkChange() {
            let _leaf_ids = this.$refs.tree.getCheckedNodes(false,true);
            // console.log(_leaf_ids);
            // this.ids = [];

            let _ids = [];

            for (let i=0;i < _leaf_ids.length;i++){
                _ids.push(_leaf_ids[i].id);
            }

            // console.log(_ids);

            this.$emit('change',_ids);

            // console.log(this.$refs.tree.getHalfCheckedKeys());
            // let _halfChecked_ids = this.$refs.tree.getHalfCheckedKeys();


            // console.log(this.formData.rule_ids);
        },
    },
    watch:{
        id:function (n,o){
            this.getList();
        }
    }
}
</script>
