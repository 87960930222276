<template>
    <tree-select :normalizer="normalizer" v-model="val" :options="options" :searchable="false" placeholder="-请选择-" @select="select"></tree-select>
</template>

<script>
export default {
    name: "DeptTreeSelect",
    data(){
        return{
            options: [
              // {
              //   children: [],
              //   id: 0,
              //   label: "全部"
              // }
            ],
            val:null,
            normalizer(node){
              return {
                id: node.id,
                label: node.name,
                children: node.children
              }
            }
        }
    },
    model:{
        prop:'value',
        event:'change'
    },
    props: {
        value: {
            type:[String,Number],
            default:''
        },
        id: {
            type:[String,Number],
            default:null
        }
    },
    created() {
        this.val = this.value ? this.value : null;
    },
    mounted() {
        this.getList();
    },
    methods:{
        getList(){
            let _this = this;

            this.$http.request({
                url:'/api-admin/dept',
                datas:{queryMode:'list',parentId:'0'},
              method:'get',
                success(res){
                  _this.options = res;
                  // for(let i = 0;i < res.length;i++) {
                  //   let _sub = res[i];
                    // if(_sub.children.length <= 0){
                    //   delete _sub.children;
                    // }
                    // _this.options[0].children.push(_sub);
                  // }
                }
            });
        },
        select(e){
            this.$emit('change',e.id);
        }
    },
    watch:{
        value(n,o){
            this.val = n || n === 0 ? n : null;
        }
    }
}
</script>
