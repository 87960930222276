import Vue from 'vue'
import router from '../router'
let self = new Vue();

export default {
    setPage(path,data){
        if(!data || data < 0){
            return;
        }

        if(typeof data !== 'number' && typeof data !== 'string'){
            new Error('data must be string or number');
            return;
        }

        let _res = self.$utils.localGet('page');

        _res = _res ? JSON.parse(_res) : {};

        _res[path] = data ? data : 10;

        _res = JSON.stringify(_res);

        self.$utils.localSet('page',_res);
    },
    getPage(path){
        let _res = self.$utils.localGet('page');

        _res = _res ? JSON.parse(_res) : {};

        return _res[path] ? _res[path] : '';
    },
    setTabData(path,data){
        let _tabs = self.$utils.sessionGet('tab');

        _tabs = _tabs ? JSON.parse(_tabs) : {};

        if(_tabs[path]){
            _tabs[path].data = data ? data : '';
        }

        _tabs = JSON.stringify(_tabs);

        self.$utils.sessionSet('tab',_tabs);
    },
    setTabQuery(path,data){
        let _tabs = self.$utils.sessionGet('tab');

        _tabs = _tabs ? JSON.parse(_tabs) : {};

        if(_tabs[path]){
            _tabs[path].query = data ? data : '';
        }

        _tabs = JSON.stringify(_tabs);

        self.$utils.sessionSet('tab',_tabs);
    },
    setLastTab(path){
        let _tab = self.$user.getTabByPath(path);

        if(_tab) {
            self.$utils.sessionSet('lastTab', JSON.stringify(_tab));
        }else{
            let _tabs = self.$utils.sessionGet('tab');

            _tabs = _tabs ? JSON.parse(_tabs) : {};

            let _tabsKeys = Object.keys(_tabs);

            let _lastPath = _tabsKeys[_tabsKeys.length - 1];

            let _lastTab = _tabs[_lastPath];

            self.$utils.sessionSet('lastTab', JSON.stringify(_lastTab));

        }
    },
    getLastTab(){
        let _tab = self.$utils.sessionGet('lastTab');

        return _tab ? JSON.parse(_tab).path : null;
    },
    setTab(v){
        let _tabs = self.$utils.sessionGet('tab');

        _tabs = _tabs ? JSON.parse(_tabs) : {};

        if(!_tabs[v.path]) {
            _tabs[v.path] = {};
            _tabs[v.path].name = v.name;
            _tabs[v.path].path = v.path;
            _tabs[v.path].data = '';
            _tabs[v.path].query = v.query;
        }else{
            _tabs[v.path].name = v.name;
            _tabs[v.path].path = v.path;
            _tabs[v.path].query = v.query;
            // _tabs[v.path].data = '';
        }

        let _tabsArr = Object.keys(_tabs);
        let _index = _tabsArr[1];
        let _len = _tabsArr.length;

        if(_len > 13 && !self.$utils.isUndefined(_index)){
            delete _tabs[_index];
        }

        _tabs = JSON.stringify(_tabs);

        self.$utils.sessionSet('tab',_tabs);
    },

    getTab(){
        let _tabs = self.$utils.sessionGet('tab');

        _tabs = _tabs ? JSON.parse(_tabs) : {};

        return _tabs;
    },

    getTabByPath(path){
        let _tabs = self.$utils.sessionGet('tab');

        _tabs = _tabs ? JSON.parse(_tabs) : {};

        return _tabs[path] ? _tabs[path] : null;
    },

    delTab(path = ''){
        path = path ? path : router.currentRoute.path;

        let _tabs = self.$utils.sessionGet('tab');

        _tabs = _tabs ? JSON.parse(_tabs) : {};

        if(_tabs[path]){
            delete _tabs[path];
        }
        // let _index = _tabs[v.path];

        // _tabs.splice(_index,1);

        self.$utils.sessionSet('tab',JSON.stringify(_tabs));

        _tabs = self.$utils.sessionGet('tab');
        _tabs = _tabs ? JSON.parse(_tabs) : {};

        // if(_last === path){
        // if(path === router.currentRoute.path){

        let _last = self.$user.getLastTab();

        let _tabsKeys = Object.keys(_tabs);

        if(_tabsKeys.indexOf(_last) < 0){
            _last = _tabsKeys[_tabsKeys.length - 1];
        }
        // }else{
        //
        // }

        return _last ? _last : '/dashboard';
    },

    removeTab(path){
        path = path ? path : router.currentRoute.path;

        let _last = this.delTab(path);

        let _newPath = this.getTabByPath(_last);

        if(path === router.currentRoute.path) {
            router.replace({path: _newPath.path, query: _newPath.query});
        }

        // let _tabs = this.getTab();
        //
        // let _tabsKeys = Object.keys(_tabs);
        //
        // if(_tabsKeys.length > 1){
        //     router.replace({path:_last});
        // }else{
        //     router.replace({path:'/dashboard'});
        // }
    },

    getUserInfo(k){
        if(!k){
            return '';
        }

        return self.$utils.sessionGet(k);
    },

    setToken(v){
        self.$utils.sessionSet('token',v);
    },

    getToken(){
        return self.$utils.sessionGet('token');
    },

    setRefreshToken(v){
        self.$utils.sessionSet('refresh-token',v);
    },

    getRefreshToken(){
        return self.$utils.sessionGet('refresh-token');
    },

    setDept(v){
        self.$utils.sessionSet('dept',v ? JSON.stringify(v) : '');
    },

    getDept(){
        let _res = self.$utils.sessionGet('dept');
        return _res ? JSON.parse(_res) : null;
    },

    setCompanyInfo(v){
        self.$utils.sessionSet('company',v ? JSON.stringify(v) : '');
    },

    getCompanyInfo(){
        let _res = self.$utils.sessionGet('company');

        return _res ? JSON.parse(_res) : null;
    },

    setUserName(v){
        self.$utils.sessionSet('username',v);
    },

    getUserName(){
        return self.$utils.sessionGet('username');
    },

    setNickName(v){
        self.$utils.sessionSet('nickname',v);
    },

    getNickName(){
        return self.$utils.sessionGet('nickname');
    },
    
    setJob(v){
        self.$utils.sessionSet('job',v);
    },
    
    getJob(){
        return self.$utils.sessionGet('job');
    },

    setUid(v){
        self.$utils.sessionSet('uid',v);
    },

    getUid(){
        return self.$utils.sessionGet('uid');
    },

    setMenu(v){
        self.$utils.sessionSet('menu',v);
    },

    getMenu(){
        return self.$utils.sessionGet('menu');
    },

    setRole(v){
        self.$utils.sessionSet('role',v && v.length > 0 ? JSON.stringify(v) : '');
    },

    getRole(){
        let _res = self.$utils.sessionGet('role');
        return _res ? JSON.parse(_res) : [];
    },

    setPermissions(v){
        self.$utils.sessionSet('permissions',v && v.length > 0 ? JSON.stringify(v) : '');
    },

    getPermissions(){
        let _res = self.$utils.sessionGet('permissions');
        return _res ? JSON.parse(_res) : [];
    },

    setAvatar(v){
        self.$utils.sessionSet('avatar',v);
    },

    getAvatar(){
        return self.$utils.sessionGet('avatar');
    },

    setIsAdmin(v){
        self.$utils.sessionSet('is_admin',v);
    },

    getIsAdmin(){
        return self.$utils.sessionGet('is_admin');
    },
    setDepartment(v){
        self.$utils.sessionSet('department',v);
    },

    getDepartment(){
        return self.$utils.sessionGet('department');
    },
    setDepartmentId(v){
        self.$utils.sessionSet('departmentId',v);
    },

    getDepartmentId(){
        return self.$utils.sessionGet('departmentId');
    },

}
