<template>
    <el-date-picker
        v-model="values"
        type="datetimerange"
        align="right"
        unlink-panels
        :clearable="false"
        :editable="false"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :picker-options="showPicker ? pickerOptions : null"
        :popper-class="onlyHour ? 'date-picker-only-hour' : ''"
        @change="change">
    </el-date-picker>
<!--    <div>-->
<!--        <template v-if="$isMobile">-->
<!--            <div @click="show = true" style="height: 32px;" class="border-radius-3 border-1 padding-lr-5">{{values && values.length > 0 ? $moment(values[0]).format('YYYY-MM-DD')+' 至 '+$moment(values[1]).format('YYYY-MM-DD') : '选择时间'}}</div>-->
<!--            <van-calendar :min-date="minDate" type="range" v-model="show" @confirm="change" />-->
<!--        </template>-->
<!--        <template v-else>-->
<!--            <el-date-picker-->
<!--                v-model="values"-->
<!--                type="daterange"-->
<!--                align="right"-->
<!--                unlink-panels-->
<!--                :clearable="false"-->
<!--                :editable="false"-->
<!--                range-separator="至"-->
<!--                start-placeholder="开始日期"-->
<!--                end-placeholder="结束日期"-->
<!--                :picker-options="pickerOptions"-->
<!--                @change="change">-->
<!--            </el-date-picker>-->
<!--        </template>-->
<!--    </div>-->
</template>

<script>
export default {
    name: "DateTimeRange",
    data(){
        return{
            minDate:this.$moment('1970-1-1').toDate(),
            // show:false,
            values:'',
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
        }
    },
    model:{
        prop:'value',
        event:'change'
    },
    props: {
      value: {
        type:String,
        default: ''
      },
      onlyHour:{
        type:Boolean,
        default:false
      },
      showPicker: {
        type:Boolean,
        default: false
      },
    },
    created() {
        if(this.value){
            let _times = this.value.split(',');
            let _timeArr = [];
            _timeArr.push(this.$moment(_times[0]).toDate());
            _timeArr.push(this.$moment(_times[1]).toDate());

            this.values = _timeArr;

        }else{
            this.values = ''
        }
    },
    watch:{
        value(n,o){
            if(n){
                let _times = n.split(',');
                let _timeArr = [];
                _timeArr.push(this.$moment(_times[0]).toDate());
                _timeArr.push(this.$moment(_times[1]).toDate());

                this.values = _timeArr;

            }else{
                this.values = '';

                this.$emit('change','');
            }
        },
    },
    methods:{
        change(e) {
          console.log(e);
          let _start = '';
          let _end = '';

          if (e && e.length > 0){
            _start = this.$moment(e[0]).format('YYYY-MM-DD HH:mm:ss');
            _end = this.$moment(e[1]).format('YYYY-MM-DD HH:mm:ss');

            this.$emit('change', _start + ',' + _end);
            // if(this.$isMobile){
            //     this.show = false;
            // }
          }else{
            this.$emit('change', '');
          }
        },
        onConfirm(e){
            console.log(e);
        }
    }
}
</script>
