<template>
    <el-button :type="type" :size="bSize" v-if="isShow" icon="el-icon-edit" :disabled="disabled" :class="customClass">{{sLabel}}</el-button>
</template>

<script>
export default {
    name: "EditButton",
    data() {
        return {
            bSize: '',
            sLabel:'编辑'
        }
    },
    created() {
        this.sLabel = this.label;
        this.bSize = this.size;
    },
    props: {
      role: '',
        click: '',
        disabled: {
            type: Boolean,
            default: false
        },
        label:{
            type:String,
            default:'编辑'
        },
        type: {
            type: String,
            default: 'success'
        },
        size: {
            type: String,
            default: ''
        },
	    customClass: {
            type: String,
            default: 'green'
        },
	    permission: {
		    type:Array,
		    default(){
			    return null
		    },
	    },
    },
    watch: {
        label(n, o) {
            this.sLabel = n;
        },
        size(n, o) {
            this.bSize = n;
        }
    },
    computed: {
        isShow() {
	        if(this.role) {
		        return this.$rule.checkRule(this.role,this.permission ? this.permission : null);
	        }else{
		        return true;
	        }
        }
    }
}
</script>
