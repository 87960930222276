<template>
    <span>
        <el-button size="mini" type="primary" @click="openDialog">{{label}}</el-button>
        <el-dialog title="物资选择" :visible.sync="visible" @close="onClose" width="60%" custom-class="dialog-h-500" append-to-body>
          <div style="margin-bottom:20px;">
            <el-input placeholder="请输入物资名称" class="mg-r-10" v-model="searchForm.name" maxlength="100" show-word-limit style="width: 300px;"></el-input>
            <el-button type="primary" @click="toSearch">搜索</el-button>
            <el-button @click="toReset">重置</el-button>
          </div>
          <el-row :gutter="10">
            <el-col :span="4">
              <base-tree-side ref="side" @change="onChangeSideTree" url="/api-oil/mateCategory" :datas="{queryMode:'list'}" val-label="name" :show-top="true" top-title="全部"></base-tree-side>
            </el-col>
            <el-col :span="20">
              <el-table ref="list" :data="list" row-key="id" @selection-change="onSelectionChange">
                <el-table-column type="selection" width="55" reserve-selection></el-table-column>
                <el-table-column label="物资名称" prop="goodsName"></el-table-column>
                <el-table-column label="型号" prop="model"></el-table-column>
                <el-table-column label="品牌" prop="brandName"></el-table-column>
                <el-table-column label="单位" prop="unitName"></el-table-column>
                <el-table-column label="剩余库存数量" prop="surplusCount"></el-table-column>
              </el-table>
<!--              <div class="text-center mg-t-20" style="flex:1" v-if="list.length > 0">-->
<!--                <el-pagination-->
<!--                  background-->
<!--                  layout="total,prev, pager, next"-->
<!--                  :page-size="searchForm.limit"-->
<!--                  :current-page.sync="searchForm.page"-->
<!--                  :total="total"-->
<!--                  @current-change="pageChange">-->
<!--                </el-pagination>-->
<!--                </div>-->
            </el-col>
          </el-row>
            <div slot="footer" class="dialog-footer">
                <el-button @click="visible = false">关闭</el-button>
                <el-button type="primary" @click="toChoose">确定</el-button>
            </div>
        </el-dialog>
    </span>
</template>

<script>
export default {
  name: "ImageDialog",
  data() {
    return {
      visible: false,
      dataList: [],
      list: [],
      searchForm:{
        page:1,
        limit:10,
        categoryId:'',
        name:'',
        queryMode:'list'
      },
      total:0,
      values:[],
      ids:[]
    }
  },
  model:{
    prop:'value',
    event:'change'
  },
  props: {
    value: {
      type: Array,
      default: function () {
        return [];
      }
    },
    label:{
      type:String,
      default:'新增'
    }
  },
  created() {
    this.values = this.value;
    this.initIds();
  },
  watch:{
    value(n,o){
      this.values = n;

      this.initIds();
      // this.initChecked();
    }
  },
  mounted() {
    this.getList();
  },
  methods: {
    toReset(){
      this.searchForm.categoryId = '';
      this.searchForm.page = 1;
      this.searchForm.name = '';

      this.getList();

      this.$refs.side.replaceCurrent();
    },
    initIds(){
      this.ids = [];
      for(let i=0;i < this.values.length;i++){
        this.ids.push(this.values[i].id);
      }
    },
    initChecked() {
      if (this.ids.length > 0) {
        this.$nextTick(()=>{
          this.list.forEach(row => {
            if(this.ids.indexOf(row.id) >= 0) {
              this.$refs.list.toggleRowSelection(row, true);
            }
          });
        })
      }
    },
    toSearch() {
      this.searchForm.page = 1;
      this.getList();
    },
    toChoose() {
      this.$emit('change', this.values);
      this.onClose();
    },
    openDialog() {
      this.visible = true;
      // this.getList();
    },
    onClose() {
      this.visible = false;
      // this.imageList = [];
    },
    onExceed() {
      this.$message.error('数量最多不能超过' + this.limit + '个');
    },
    getList() {
      let _this = this;
      this.dataList = [];
      this.$http.request({
        url: '/api-oil/mateGoods/goodsList',
        method:'get',
        datas: this.searchForm,
        success: (res) => {
          _this.list = res ? res : [];

          _this.total = res.total ? res.total : 0;

          _this.initChecked();
        }
      });
    },
    pageChange(page) {
      this.page = page;
      this.getList();
    },
    onChangeSideTree(e){
      this.searchForm.categoryId = e;
      this.searchForm.page = 1;

      this.getList();
    },
    onSelectionChange(e){
      // console.log(e);
      this.values = e;

      this.initIds();
    },
  }
}
</script>
<style scoped>
.img-item {
  width: 140px;
  height: 140px !important;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  overflow: hidden;
  margin-left: 0 !important;
  margin-bottom: 10px !important;
}

/*.img-item .el-checkbox__label{width: 100px !important;}*/
</style>
