<template>
	<div>
		<el-image :class="customClass" :style="{width:width,height:width}" fit="cover" :src="$utils.formatUrl(item.url)" :preview-src-list="list" v-for="(item,index) in values"></el-image>
	</div>
</template>

<script>
export default {
	name: "ImagePreView",
	data() {
		return {
			values:[],
			list:[]
		}
	},
	props:{
		value:{
			type:Array,
			default(){
				return []
			}
		},
		width:{
			type:String,
			default:'50px'
		},
		customClass:{
			type:String,
			default:''
		}
	},
	created() {
		this.list = [];
		this.values = this.value ? this.value : [];
		
		for(let i=0;i < this.values.length;i++){
			this.list.push(this.$utils.formatUrl(this.values[i].url));
		}
	},
	mounted() {
	},
	methods: {},
	watch:{
		value:{
			handler(n,o){
				this.values = n ? n : [];
				for(let i=0;i < this.values.length;i++){
					this.list.push(this.$utils.formatUrl(this.values[i].url));
				}
			},
			deep:true,
			immediate:true
		}
	}
}
</script>
