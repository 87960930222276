<template>
    <div :class="outClass" class="h100">
        <div v-if="$isMobile && $slots.search" class="margin-bottom">
            <el-button @click="openSearch" size="small" icon="el-icon-search" class="w100">搜索</el-button>
            <el-drawer
                size="auto;max-height:60%;"
                :visible.sync="showSearch"
                direction="ttb"
                :with-header="false">
                <div class="padding-20">
                    <slot name="search"></slot>
                </div>
            </el-drawer>
        </div>
        <div class="bg-light margin-bottom-20" v-else-if="$slots.search">
            <slot name="search"></slot>
        </div>
        <div id="main-body" :class="bodyOutClass" class="h100">
          <slot name="top-button"></slot>
<!--            <div class="cl">-->
<!--              <div class="z">fd</div>-->
<!--              <div class="y">-->
<!--                <slot name="right-btn"></slot>-->
<!--              </div>-->
<!--            </div>-->
          <div class="bg-light pd-20 bd-radius-6 h100 diy-scroll" style="overflow-y: auto;" :class="bodyInClass" :style="bodyInStyle">
            <slot></slot>
          </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "MainLayout",
    data() {
        return {
            breadText: [],
            showSearch:false
        }
    },
    props:{
      hasBg:{
        type:Boolean,
        default:true
      },
      bodyInClass:{
        type:String,
        default:''
      },
	    bodyInStyle:{
        type:[String,Object],
        default:''
      },
      outClass:{
        type:String,
        default:''
      },
      bodyOutClass:{
        type:String,
        default:''
      }
    },
    methods: {
        bread() {
            this.breadText = [];
            for (let i = 0; i < this.$route.meta.length; i++) {
                this.breadText.push(this.$route.meta[i]);
            }
        },
        openSearch(){
            this.showSearch = true;
        },
        closeSearch(){
            this.showSearch = false;
        },
    },
    mounted() {
        this.breadText = [];

        for (let i = 0; i < this.$route.meta.length; i++) {
            this.breadText.push(this.$route.meta[i]);
        }
    }
}
</script>
