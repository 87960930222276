<template>
  <span class="inline-block">
    <el-button class="w100 pd-l-r-15" :icon="btnIcon" :size="btnSize" :type="btnType" @click="openDialog">{{label}}</el-button>
    <el-dialog title="添加题目" :visible.sync="visible" @close="onClose" width="60%" v-if="visible" custom-class="dialog-h-300" append-to-body>
      <div style="margin-bottom:20px;">
        <el-form :model="formData" label-width="130px">
          <el-row :gutter="20" v-for="(item,index) in countsShow">
            <el-col :span="12">
              <el-form-item :label="labels[index]">
                <el-input-number :min="0" :max="countsShow[index]" v-model="formData[index+'Number']" :step="1" style="width: 100%;" @change="(e)=>onChange(index,e)"></el-input-number>
              </el-form-item>
            </el-col>
            <el-col :span="12" class="lh-40">题，可随机剩余试题 <b class="blue-1 f-18 f-b">{{countsShow[index]}}</b> 题</el-col>
          </el-row>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer text-center">
        <el-button @click="visible = false">关闭</el-button>
        <el-button type="primary" @click="toCreate">确定</el-button>
      </div>
    </el-dialog>
  </span>
</template>

<script>
export default {
  name: "RandomQuDialog",
  data() {
    return {
      visible: false,
      formData:{
        radio:[],
        radioNumber:0,
        multi:[],
        multiNumber:0,
        judge:[],
        judgeNumber:0
      },
      labels:{
        judge:'判断题随机抽取',
        multi:'多选题随机抽取',
        radio:'单选题随机抽取'
      },
      countsShow:{
        judge:0,
        multi:0,
        radio:0
      },
      counts:{
        judge:0,
        multi:0,
        radio:0
      },
      values: {
        radio: [],
        multi: [],
        judge: []
      },
      ids: {
        radio: [],
        multi: [],
        judge: []
      },
    }
  },
  model:{
    prop:'value',
    event:'input'
  },
  props: {
    label:{
      type:String,
      default:'新增'
    },
    btnType:{
      type:String,
      default:'primary'
    },
    btnSize:{
      type:String,
      default:'mini'
    },
    btnIcon:{
      type:String,
      default:''
    },
    quIds:{
      type:[Object,Array],
      default(){
        return {}
      }
    }
  },
  created() {
    // this.values = this.value;

    // this.searchForm.quType = this.toQuType ? this.toQuType : '';

    // this.initIds();

    console.log(this.quIds);
  },
  mounted() {
    this.getCount();
  },
  watch:{
    quIds:{
      handler(n,o){
        this.formData.radio = n.radio.length > 0 ? n.radio : [];
        this.formData.multi = n.multi.length > 0 ? n.multi : [];
        this.formData.judge = n.judge.length > 0 ? n.judge : [];

        this.ids.radio = n.radio.length > 0 ? n.radio : [];
        this.ids.multi = n.multi.length > 0 ? n.multi : [];
        this.ids.judge = n.judge.length > 0 ? n.judge : [];

        this.getCount();

      },
      deep:true
    }
  },
  methods: {
    openDialog() {
      this.visible = true;
      this.getCount();
    },
    onClose() {
      this.formData = {
        radio:[],
        radioNumber:0,
        multi:[],
        multiNumber:0,
        judge:[],
        judgeNumber:0
      };

      this.countsShow.judge = 0;
      this.countsShow.multi = 0;
      this.countsShow.radio = 0;

      this.counts.judge = 0;
      this.counts.multi = 0;
      this.counts.radio = 0;

      this.values.judge = [];
      this.values.radio = [];
      this.values.multi = [];

      this.visible = false;
    },
    getCount(){
      let _this = this;

      this.$http.request({
        url: '/api-train/elExam/random/show',
        method: 'post',
        datas:this.formData,
        success(res) {
          console.log(res);
          _this.countsShow.judge = res.judge;
          _this.countsShow.multi = res.multi;
          _this.countsShow.radio = res.radio;
        }
      });
    },
    toCreate(){
      let _this = this;

      let _check = false;

      for (let i in this.formData){
        if(this.formData[i+'Number'] > 0){
          _check = true;

          break;
        }
      }

      if(!_check){
        this.$message.error('至少要抽取1道题目');
        return;
      }


      this.$http.request({
        url: '/api-train/elExam/random/create',
        method: 'post',
        datas: this.formData,
        success(res) {
          for (let i = 0;i < res.length;i++){
            if(res[i].quType === 1){
              _this.values.radio.push(res[i]);

              console.log(res[i]);

              _this.ids.radio.push(res[i].id)
            }else if(res[i].quType === 2){
              _this.values.multi.push(res[i]);
              _this.ids.multi.push(res[i].id)
            }else{
              _this.values.judge.push(res[i]);
              _this.ids.judge.push(res[i].id)
            }
          }

          _this.counts.radio = _this.formData.radioNumber;
          _this.counts.multi = _this.formData.multiNumber;
          _this.counts.judge = _this.formData.judgeNumber;

          _this.$emit('input',_this.$utils.deepCopy(_this.values));
          _this.$emit('change',{counts:_this.$utils.deepCopy(_this.counts),ids:_this.$utils.deepCopy(_this.ids)});

          _this.onClose();
        }
      });
    },
    onChange(type,e){
      this.formData[type+'Number'] = e;
    }
  }
}
</script>
