export default {
    '/dept/move': {
        name: '工作移交',
        // default:'/system/menu/list'
        listRouter: '/dept/move',
        editRouter: '/dept/move/edit',
        addRouter: '/dept/move/add',
        detailRouter: '',
        editRole: 'system:user:edit',
        addRole: 'system:user:add',
        delRole: 'system:user:delete',
        list: {
            com: '',
            options: {
                // sideSpan:4,
                listUrl: '/Dept/listPage',
                statusUrl: '/Dept/changeStatus',
                delUrl: '/Dept/del',
                method: 'post',
                delMethod: 'post',
                pk:'dept_id',
                data: {orderBy:'sort',sortBy:'asc'},
                showDept: false,
                showAdd: false,
                showDel: false,
                showEdit: false,
                showDetail: false,
                showSearch: true,
                pageKey:'page',
                pageSizeKey:'pageSize',
                pageSize:20,
                listKey:'list',
                listTree:false,
                optionsWidth:150
            },
            params: [
                {name: '名称', value: 'name',width:200},
                {name: '是否牵头单位', value: 'is_qtdw',type:'bool',width:100},
                {name: '是否责任单位', value: 'is_zrdw',type:'bool',width:100},
                {name: '排序', value: 'sort'},
                {name: '状态', value: 'state', type: 'state'},
            ],
            extButtons: [
                {label:'移交工作',icon:'el-icon-key',type:'diy',title:'移交工作',path:'/dept/move/detail',color:'orange-1',btnType:'text'},
            ]
        },
        edit: {
            com: '',
        },
        add: {
            com: '',
        },
        detail: {
            com: '',
        },
        search: {
            params: [
                {name: '部门名称', value: 'name',}
            ]
        }
    },
    '/dept/move/detail': {
        name: '工作移交',
        default:'/dept/move/detail',
        listRouter: '/dept/move',
        editRouter: '/dept/move/edit',
        addRouter: '/dept/move/add',
        detailRouter: '',
        editRole: 'system:user:edit',
        addRole: 'system:user:add',
        delRole: 'system:user:delete',
        list: {
            com: '',
        },
        edit: {
            com: '/dept/move/detail',
            options: {
            },
        },
        add: {
            com: '/dept/move/detail',
            options: {
            },
        },
        detail: {
            com: '',
        },
        search: {
            params: [
                {name: '部门名称', value: 'name',}
            ]
        }
    },
}
