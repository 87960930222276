<template>
  <el-menu
    :router="true"
    :collapse="isCollapse"
    :default-active="getDefaultActive"
    background-color="transparent"
    text-color="#333"
    active-text-color="#ffd04b"
    class="el-menu-vertical diy-scroll"
    style="height:100%;overflow-y: auto;padding:0 15px;"
  >
    <template v-for="(item, index) in sideMenu">
      <template v-if="item.children && item.children.length > 0">
        <el-submenu :index="item.path" :key="'t-'+index">
          <template slot="title">
            <i :class="item.icon" v-if="item.icon" style="color:#333;"></i>
            <span>{{ item.title }}</span>
          </template>
          <template v-for="(subitem, subindex) in item.children">
            <el-submenu :index="subitem.path" :key="'s-'+subindex" v-if="subitem && subitem.children && subitem.children.length > 0">
              <template slot="title">{{ subitem.title }}</template>
              <el-menu-item v-for="(subitem2,subindex2) in subitem.children" :index="subitem2.path" :key="'t2-'+subindex2" :route="subitem2.path">
                {{ subitem2.title }}
              </el-menu-item>
            </el-submenu>
            <el-menu-item :index="subitem.path" :key="'s2-'+subindex" :route="subitem.path" v-else>
              {{ subitem.title }}
            </el-menu-item>
          </template>
        </el-submenu>
      </template>
      <template v-else>
        <el-menu-item
          :index="item.path"
          :key="'t-' + index"
          :route="item.path">
          <i :class="item.icon" v-if="item.icon" style="color:#333;"></i>
          <span slot="title">{{ item.title }}</span>
        </el-menu-item>
      </template>
    </template>
<!--    <el-menu-item index="/changePass" key="/changePass" route="/changePass" class="hover-bg-blue-0" v-if="$isMobile">-->
<!--      修改密码-->
<!--    </el-menu-item>-->
<!--    <el-menu-item class="hover-bg-blue-0" @click="quit" v-if="$isMobile">退出登录</el-menu-item>-->
  </el-menu>
</template>

<script>
export default {
  name: "SideMenu",
  data() {
    return {
      sideMenu: [
        {
          children: [],
          icon: "el-icon-data-analysis",
          title:'首页',
          link: null,
          noCache: false,
          name: "System",
          path: "/dashboard",
        },
        // {
        //   children: [],
        //   meta: {title: "菜单管理(有问题,先不管)", icon: "el-icon-data-analysis", noCache: false, link: null},
        //   icon: "el-icon-data-analysis",
        //   link: null,
        //   noCache: false,
        //   name: "test",
        //   path: "/test/test",
        // }
      ],
      defaultActive: this.$router.currentRoute.path,
      isCollapse: false,
	    menuActiveArr:[
				'/program/ldjb',
		    '/program/zysx',
		    '/program/qzfsx',
		    '/program/hysx',
		    '/system/group/admin',
		    '/system/group/user',
		    '/system/group/program'
	    ]
    };
  },
  props: {
    sideCollapse: {
      type: Boolean,
      default: false
    }
  },
  created() {
    this.isCollapse = this.sideCollapse;
  },
  watch: {
    sideCollapse(n, o) {
      this.isCollapse = n;
    },
    '$route': 'freshMenu'
  },
	computed:{
		getDefaultActive(){
			for(let i in this.menuActiveArr){
				if(this.defaultActive.indexOf(this.menuActiveArr[i]) === 0){
					return this.menuActiveArr[i];
				}
			}
			return this.defaultActive;
		}
	},
  mounted() {
    this.getRouters();
  },
  methods: {
    quit() {
      this.$confirm('是否退出登录？', '提示', {
        confirmButtonText: '立即退出',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$utils.sessionClear();
        this.$router.replace({path: "/login"});
      }).catch(() => {

      });
    },
    freshMenu() {
      this.defaultActive = this.$router.currentRoute.path;
      // console.log(this.$router.currentRoute.path);
    },
    getRouters() {
      let _this = this;

      let _uid = this.$user.getUid();

      this.$http.request({
        url: '/SystemRole/menu',
        datas: null,
        success(res) {
          // console.log(res);
          let _menu = res.menu;
          for (let i = 0; i < _menu.length; i++) {
            _this.sideMenu.push(_menu[i]);
          }

          _this.$user.setPermissions(res.rule);
          _this.$user.setDepartmentId(res.dept_id)

	        _this.$emit('success')
        }
      });
    },
  }
};
</script>
<style>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 220px;
  min-height: 400px;
}
</style>
