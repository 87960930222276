<template>
	<el-dialog title="修改事项状态" :visible.sync="isShow" width="700px" append-to-body @close="onClose" @open="onOpen">
<!--		{{formData}}-->
		<el-form :model="formData" :rules="rules" ref="form" label-width="100px">
			<el-form-item label="事项状态" prop="state">
				<type-select :is-top="false" :options="[{id:1,name:'待审批'},{id:3,name:'办理中'},{id:4,name:'已办结'}]" v-model="formData.state" @change="onChange"></type-select>
			</el-form-item>
		</el-form>
		<span slot="footer" class="dialog-footer">
			<el-button @click="isShow = false">关闭</el-button>
	    <el-button type="primary" @click="toSubmit">确定</el-button>
	  </span>
	</el-dialog>
</template>

<script>
export default {
	name: "LingquDialog",
	data() {
		return {
			isShow:false,
			formData:{
				id:'',
				state:'',
			},
			rules:{
				state:[{required: true,message:'状态必选'}]
			}
		}
	},
	props:{
		visible:{
			type:Boolean,
			default:false
		},
		id:{
			type:String,
			default:''
		},
		bid:{
			type:String,
			default:''
		}
	},
	model:{
		prop:'visible',
		event:'change'
	},
	created() {
		this.isShow = this.visible;
		this.formData.id = this.id ? this.id : ''
	},
	mounted() {

	},
	methods: {
		onChange(){
			this.formData.ducha_remark = '';
		},
		onOpen(){
			let _this = this;
			this.$nextTick(()=>{
				_this.$refs['form'].clearValidate()
			})
		},
		onClose(){
			this.formData.id = '';
			this.formData.state = '';
			this.$emit('update:visible', false);
			this.$emit('close');
		},
		toSubmit(){
			let _this = this;
			if(!this.formData.id){
				this.$message.error('缺少id');
				return;
			}
			this.$refs['form'].validate((valid) => {
				if (valid) {
					_this.$http.request({
						url:'/Program/changeAbsStatus',
						datas:_this.formData,
						success(res){
							_this.$message.success('操作成功');

							_this.$emit('success');

							_this.isShow = false;
						}
					})
				} else {
					_this.$message.error('请填写完整表单')
					return false;
				}
			});
		}
	},
	watch:{
		visible(n,o){
			this.isShow = n;
			this.formData.id = this.id ? this.id : ''
		}
	}
}
</script>
