<template>
	<tr class="el-descriptions-row">
		<slot></slot>
	</tr>
</template>

<script>
export default {
	name: "ZhbTr",
	data() {
		return {}
	},
	created() {
	},
	mounted() {
	},
	methods: {}
}
</script>
