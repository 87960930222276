<template>
  <el-card class="box-card">
    <div slot="header" class="cl">
      <span>按钮配置</span>
      <add-button label="保存" size="mini" class="y" rule="system:role:add" @click.native="toSave(null)"></add-button>
    </div>
    <el-table ref="table" :data="list" style="width: 100%" @selection-change="onChoose">
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="name" label="按钮名称" min-width="100"></el-table-column>
      <el-table-column prop="perm" label="按钮标识" min-width="200"></el-table-column>
    </el-table>
  </el-card>
</template>

<script>
export default {
  name: "ButtonPermChoose",
  data() {
    return {
      list:[],
      params:{
        page:1,
        limit:9999,
        queryMode:'page',
        moduleId:'',
        type:2
      },
      ids:[]
    }
  },
  props:{
    id:{
      type:[String,Number],
      default:''
    },
    roleId:{
      type:[String,Number],
      default:''
    }
  },
  created() {
    this.params.moduleId = this.id ? this.id : '';
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList(){
      let _this = this;

      if(this.params.moduleId) {
        this.$http.request({
          url: '/api-admin/api.admin/v1/permissions',
          method: 'get',
          datas: this.params,
          success(res) {
            _this.list = res.records;

            if(_this.list.length > 0){
              _this.getPermIds();
            }
          }
        })
      }
    },

    toSave(){
      let _this = this;

      if(!this.roleId){
        this.$message.error('请选择角色');
        return;
      }

      if(!this.params.moduleId){
        this.$message.error('请选择菜单');
        return;
      }

      if(this.roleId && this.params.moduleId) {
        this.$http.request({
          url: '/api-admin/api.admin/v1/roles/'+this.roleId+'/permission_ids',
          method: 'put',
          datas: {moduleId: this.params.moduleId,permissionIds: this.ids, type: "2"},
          success(res) {
            _this.$message.success('保存成功');
          }
        })
      }
    },

    getPermIds(){
      let _this = this;

      if(this.id) {
        this.$http.request({
          url: '/api-admin/api.admin/v1/roles/'+this.roleId+'/permission_ids',
          method: 'get',
          datas:{type:2},
          success(res) {
            let _check = [];

            _this.list.forEach((row,index) => {
              if(res.indexOf(row.id) >= 0){
                _check.push(_this.list[index]);
              }
            })

            _this.toggleSelection(_check);
          }
        })
      }
    },

    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.table.toggleRowSelection(row);
        });
      } else {
        this.$refs.table.clearSelection();
      }
    },

    onChoose(rows){
      this.ids = [];
      rows.forEach(row => {
        this.ids.push(row.id);
      });
    }
  },
  watch:{
    id(n,o){
      this.params.moduleId = n ? n : '';
      this.ids = [];
      this.list = [];

      this.getList();
    },
    roleId(n,o){
      this.params.moduleId = '';
      this.ids = [];
      this.list = [];

      this.getList();
    }
  }
}
</script>
