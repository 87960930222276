<template>
    <el-select
        v-model="values"
        multiple
        filterable
        default-first-option
        placeholder="请选择文章标签"
        @change="change">
        <div style="flex-direction: row;display: flex;padding:0 5px 5px 5px;">
            <el-input placeholder="请输入内容" v-model="title"></el-input>
            <add-button @click.native="ajaxAdd" label="添加企业" style="margin-left:5px;"></add-button>
        </div>
        <el-option
            v-for="(item,index) in list"
            :key="item.id"
            :label="item.title"
            :value="item.id">
        </el-option>
    </el-select>
</template>

<script>
export default {
    name: "CompanySelect",
    data(){
        return{
            list:[],
            values:[],
            title:''
        }
    },
    model:{
        prop:'value',
        event:'change'
    },
    props:{
        value:{
            type:Array,
            default(){
                return []
            }
        },
    },
    created() {
        this.values = this.value;
    },
    watch:{
        value(n,o){
            this.values = n;
        }
    },
    mounted() {
        this.getList();
    },
    methods:{
        getList() {
            let _this = this;

            this.$http.httpPost({
                url: '/Company/listAll',
                datas: {state:1},
                success(res) {
                    _this.list = res;
                }
            });
        },
        change(e){
            console.log(e);
            this.$emit("change", e);
        },
        ajaxAdd(){
            let _this = this;

            this.$http.httpPost({
                url: '/Company/add',
                datas: {title:this.title},
                success(res) {
                    console.log(res);
                    _this.getList();
                    _this.title = '';
                }
            });
        }
    }
}
</script>
