export default {
  '/ledger': {
    name: '事项台账',
    listRouter: '/ledger',
    editRouter: '',
    addRouter: '',
    delRouter: '',
    detailRouter: '',
    editRole: 'program:ledger:edit',
    addRole: 'program:ledger:add',
    delRole: 'program:ledger:delete',
    detailRole: 'program:ledger:detail',
    list: {
      com: '',
      options: {
        listUrl: '/Program/listPage',
        statusUrl: '/Program/changeStatus',
        delUrl: '/Program/del',
        method: 'post',
        delMethod: 'post',
        data: {orderBy: 'yldc_program.create_time', sortBy: 'desc'},
        showDept: false,
        showAdd: false,
        showDel: false,
        showEdit: false,
        showDetail: false,
        showListIndex: true,
        showSearch: true,
        showSideTree: false,
        listKey: 'list',
        listTree:false,
        optionsWidth:70
      },
      params: [
        // {name: '标题', value: 'title', width: 300},
        // {name: '所属专题/议题', value: 'special.title', width: 200},
        {name: '标题/专题会议', value: 'title', width: 300,type:'fun',callback:(row)=>{
            return '标题：<a class="hand a-link f-u" href="/program/detail?id='+row.id+'">'+row.title+'</a>'+(row.special && row.special.title ? '<br/>专题会议：'+row.special.title : '')
          }
        },
        {name: '事项类型', value: 'type',width:200,type:'fun',callback:(row)=>{
            let _str1 = '';
            if(row.type === 'ldjb'){
              _str1 = '领导交办';
            }else if(row.type === 'hysx'){
              _str1 = '会议事项';
            }else if(row.type === 'zysx'){
              _str1 = '重要事项';
            }else if(row.type === 'qzfsx'){
              _str1 = '区政府事项';
            }
            return 'I.'+_str1+'<br/>II.'+row.program_type.name;
          }
        },
        {name: '状态', value: 'state', type: 'programState',width: 120},
        // {name: '事项类别', value: 'type',type:'custom-select',options:[{val:'ldjb',label:'领导交办',color:'primary'},{val:'zysx',label:'重要事项',color:'warning'},{val:'hysx',label:'会议事项',color:'success'},{val:'qzfsx',label:'区政府事项',color:'danger'}], width: 100},
        // {name: '事项类型', value: 'program_type.name', width: 150,showOverflowTooltip:true},
        // {name: '副标题', value: 'sub_title', width: 100},
        // {name: '封面图', value: 'head_img', type: 'image'},
        // {name: '批示意见', value: 'remark',width: 100,showOverflowTooltip:true},
        // {name: '落实情况', value: 'last_todo', type: 'todo',width: 200},
        {name: '交办单位', value: 'dept.name',width: 100},
        {name: '发布人', value: 'user.name',width: 60},
        {name: '牵头单位', value: 'qtdws_str',width: 100},
        {name: '责任单位', value: 'zrdws_str',width: 100},
        {name: '责任领导', value: 'lingdaos_str',width: 80},
        {name: '责任办事员', value: 'bsys_str',width: 100},
        {name: '期限', columns:['start_time','end_time'],width: 160,type:'inline'},
        {name: '办结时间', value: 'done_time',width: 120},
        {name: '交办时间', value: 'create_time', width: 140},
        // {name: '最后修改时间', value: 'update_time', width: 140},
      ],
      // extButtons: [
      //   //  label 按钮文字 ，role :权限标识  icon:图标，title：弹框的标题，type：按钮交互方式，可选值 fun(调用某个接口)，url(跳转路由)，dialog(已弹窗形式打开某一组件)，默认url，com：组件路径，path：组件路径,路由 或 接口地址
      //   {label:'详情',role:'program:ledger:detail',icon:'el-icon-view',type:'url',title:'重要事项详情',path:'/program/ledger/detail',target:'_self',btnType:'text'},
      // ],
    },
    edit: {
      com: '',
    },
    add: {
      com: '',
    },
    detail: {
      com: '/program/detail',
    },
    search: {
      params: [
        {name: '标题', value: 'title'},
        {name: '专题/议题', value: 'special_id', type: 'baseTreeSelect',url:'/Special/listTree',isTop: true,valLabel:'title'},
        {name: '事项类别', value: 'type', type: 'select',options:[{id:'ldjb',name:'领导交办'},{id:'zysx',name:'重要事项'},{id:'hysx',name:'会议事项'},{id:'qzfsx',name:'区政府事项'}],isTop: true},
        {name: '事项类型', value: 'dict_program_ids', type: 'select',url:'/SystemDict/listAll',data:{type:'program'},isTop:false,pidKey:'type',pidDataKey:'akey',autoLoad:false,placeholder:'请选择选择事项类别'},
        {name: '交办单位', value: 'dept_id', type: 'select',url:'/Dept/listAll',data:{state:1,orderBy:'pid',sortBy:'asc'},valKey:'dept_id'},
        {name: '责任单位', value: 'zrdw', type: 'select',url:'/Dept/listAll',data:{state:1,orderBy:'pid',sortBy:'asc'},valKey:'dept_id'},
        {name: '状态', value: 'state', type: 'select', options: [{name: '已失效', id: 0}, {name: '待审批', id: 1}, {name: '待领办', id: 2}, {name: '待领办(已逾期)', id: 3}, {name: '办理中', id: 4}, {name: '退回办理', id: 5}, {name: '延期办理', id: 6}, {name: '异议事项', id: 7}, {name: '逾期办结', id: 8}, {name: '已办结', id: 9}, {name: '已终止', id: 10}, {name: '已拒绝发布', id: 11}]},
      ]
    }
  },
  '/program/ledger/detail':{
    name: '事项详情',
    default:'/program/detail',
    listRouter: '/program',
    editRouter: '/program/edit',
    addRouter: '/program/add',
    detailRouter: '/program/detail',
    // editRole: 'system:zone:edit',
    // addRole: 'system:zone:add',
    // delRole: 'system:zone:delete',
  },
}
