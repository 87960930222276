<template>
<!--	<div>-->
<!--		{{pid}}-{{value}}-{{list.length}}-->
		<el-select v-model="values" :filterable="filterable" :value-key="isObj ? valueKey : ''" :placeholder="placeholder" :clearable="clearable" @change="change" :remote="remote" :remote-method="remote ? onRemote : null" :disabled="disabled" :multiple="multiple">
			<div style="flex-direction: row;display: flex;padding:0 5px 5px 5px;" v-if="canAdd && data && data.akey">
				<el-input placeholder="请输入内容" v-model="name"></el-input>
				<add-button @click.native="ajaxAdd" label="添加" style="margin-left:5px;"></add-button>
			</div>
			<el-option
				v-for="item in list"
				:key="$utils.randomWords()+'_'+item[valueKey]"
				:label="item[valueLabel]"
				:disabled="disabledList && disabledList.indexOf(item[valueKey]) >= 0"
				:value="isObj ? item : item[valueKey]">
				<span style="float: left">{{ item[valueLabel] }}</span>
				<span style="float: right; color: #8492a6; font-size: 13px" v-if="rightKey">{{
						$utils.evalGet(item, rightKey)
					}}</span>
			</el-option>
		</el-select>
<!--	</div>-->
</template>

<script>
import emitter from "element-ui/src/mixins/emitter";

export default {
	name: "TypeSelect",
	mixins: [emitter],
	data() {
		return {
			list: [],
			values: '',
			name: '',
			valueKey: 'id',
			valueLabel: 'name',
			searchVal: '',
			done:false,
			pids:''
		}
	},
	model: {
		prop: 'value',
		event: 'input'
	},
	props: {
		value: {
			type: [String, Number, Boolean, Object, Array],
			default: ''
		},
		data: {
			type: [Array, Object],
			default() {
				return null
			}
		},
		disabledList: {
			type: Array,
			default() {
				return []
			}
		},
		multiple: {
			type: Boolean,
			default: false
		},
		rightKey: {
			type: String,
			default: ''
		},
		url: {
			type: String,
			default: ''
		},
		options: {
			type: [Array, Object],
			default() {
				return null
			}
		},
		canAdd: {
			type: Boolean,
			default: false
		},
		clearable: {
			type: Boolean,
			default: false
		},
		filterable: {
			type: Boolean,
			default: false
		},
		searchKey: {
			type: String,
			default: ''
		},
		valKey: {
			type: String,
			default: 'id'
		},
		valLabel: {
			type: String,
			default: 'name'
		},
		isObj: {
			type: Boolean,
			default: false
		},
		isTop: {
			type: Boolean,
			default: true
		},
		topText: {
			type: String,
			default: '全部'
		},
		method: {
			type: String,
			default: 'post'
		},
		listKey: {
			type: String,
			default: ''
		},
		placeholder: {
			type: String,
			default: '请选择'
		},
		autoLoad: {
			type: Boolean,
			default: true
		},
		pid: {
			type: [String, Number],
			default: ''
		},
		pidKey: {
			type: String,
			default: ''
		},
		pidDataKey: {
			type: String,
			default: ''
		},
		remote: {
			type: Boolean,
			default: false
		},
		disabled: {
			type: Boolean,
			default: false
		},
		autoFirst: {
			type: Boolean,
			default: false
		}
	},
	created() {
		this.values = this.value;

		this.valueKey = this.valKey ? this.valKey : 'id';
		this.valueLabel = this.valLabel ? this.valLabel : 'name';
	},
	watch: {
		value(n, o) {
			// console.log(n,'===d=d==d')
			this.values = n;
			// this.$emit('change',this.values);
		},
		// value:{
		// 	handler(n,o){
		// 		console.log(n,'===d=d==d')
		// 		this.values = n;
		// 		this.$emit('change',this.values);
		// 	},
		// 	deep:true,
		// 	immediate:true
		// }
		pid:{
			handler(n,o){
				// console.log(n);
				if (this.url && this.pidDataKey) {
					// console.log(this.value + '====' + n+'===='+o);

					// if(n !== this.pids) {
						this.list = [];

						if (n) {
							this.getList();
						} else {
							this.list = [];
						}
						if (this.done) {
							this.values = '';
							this.$emit('input', '');
						}
						// this.pids = n;
					// }
				}
			},
			deep:false,
			immediate:true
		}
		// pid(n, o) {
		// 	console.log(n);
		// 	if (this.url && this.pidDataKey) {
		// 		// console.log(this.value+'===='+n);
		//
		// 		this.list = [];
		//
		// 		if(this.done) {
		// 			this.values = '';
		// 			this.$emit('input', '');
		// 		}
		//
		// 		this.getList();
		// 	}
		// }
	},
	mounted() {
		if (this.options) {
			let _list = []
			if (this.isTop) {
				_list.push({[this.valueKey]: '', [this.valueLabel]: this.topText});
			}

			_list.push(...this.options);
			this.list = _list;
			// this.list.push.apply(this.list, this.options);
		} else {
			if (this.url && this.autoLoad) {
				this.getList();
			}
		}
	},
	methods: {
		refresh(){
			this.list = []
			this.values = '';
			this.$emit('change','');
			this.getList();
		},
		onRemote(e) {
			this.searchVal = e;

			if (this.remote && this.filterable && this.searchKey) {
				this.list = [];
				this.getList();
			}
		},
		getList() {
			// console.log('=======================ddd======')
			let _this = this;

			let _datas = {};

			_datas = {...this.data};

			if (this.searchKey) {
				_datas[this.searchKey] = this.searchVal;
			}

			if (this.pidDataKey) {
				_datas[this.pidDataKey] = this.pid;
			}

			this.$http.request({
				method: this.method ? this.method : 'post',
				url: this.url,
				datas: _datas,
				success(res) {
					// if(res.rows){
					//     _this.list.push.apply(_this.list,res.rows);
					// }else{
					//     _this.list.push.apply(_this.list,res);
					// }
					_this.list = [];
					let _res = _this.listKey ? res[_this.listKey] : res;
					let _typeof = Object.prototype.toString.call(_res);

					let _list = [];
					if (_this.isTop) {
						_list.push({[_this.valueKey]: '', [_this.valueLabel]: _this.topText});
					}

					_list.push(..._res);
					_this.list = _list;

					// console.log(_this.list.length)
					// console.log(_this.autoFirst)
					// console.log(!_this.value);
					// console.log(_this.list.length === 1 && _this.autoFirst && !_this.values)
					// if(_this.done === false) {
						if (_this.list.length === 1 && _this.autoFirst) {
							_this.$nextTick(() => {
								_this.values = _this.list[0][_this.valueKey];
								_this.$emit("input", _this.values);
								_this.$emit('change', _this.values);
								_this.$emit("label", _this.list[0]);

								_this.dispatch('ElFormItem', 'el.form.change', [_this.values])
								_this.dispatch('ElFormItem', 'el.form.blur', [_this.values])
							})
						}
					// }
					_this.done = true
				}
			});
		},
		change(e) {
			this.$emit("input", e);

			let _data = null;

			for (let i = 0; i < this.list.length; i++) {
				if (this.list[i][this.valKey] === e) {
					_data = this.list[i];
				}
			}
			this.$emit("label", _data);
			this.$emit("change", e);
			this.dispatch('ElFormItem', 'el.form.change', [e])
			this.dispatch('ElFormItem', 'el.form.blur', [e])
		},
		ajaxAdd() {
			let _this = this;

			this.$http.post({
				url: '/Type/add',
				datas: {name: this.name, akey: this.data.akey},
				success(res) {
					// console.log(res);
					_this.getList();
					_this.dictLabel = '';
				}
			});
		}
	}
}
</script>
