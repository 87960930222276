<template>
  <el-select v-model="values" :value-key="isObj ? valueKey : ''" placeholder="请选择" :clearable="clearable" @change="change">
    <el-option
        v-for="item in list"
        :key="item[valueKey]"
        :label="item[valueLabel]"
        :value="isObj ? item : item[valueKey]"></el-option>
  </el-select>
</template>

<script>
export default {
  name: "YwlxSelect",
  data(){
    return{
      list:[],
      values:'',
      name:'',
      valueKey:'value',
      valueLabel:'label'
    }
  },
  model:{
    prop:'value',
    event:'change'
  },
  props:{
    value:{
      type:[String,Number,Boolean,Object],
      default:''
    },
    data:{
      type:[Array,Object],
      default(){
        return null
      }
    },
    url:{
      type:String,
      default:''
    },
    options:{
      type:[Array,Object],
      default(){
        return [
          {label:'补领号牌',value:'1002'},
          {label:'补领行驶证',value:'1004'},
          {label:'补检验合格标志',value:'1005'},
          {label:'申领六年免检核发检验合格标志',value:'1018'},
          {label:'异地申领六年免检核发检验合格标志',value:'1029'},
          {label:'机动车联系方式变更备案',value:'10A9'},
          {label:'绑定非本人机动车',value:'99B3'},
          {label:'解绑非本人机动车',value:'99B4'},
          {label:'期满换证',value:'2001'},
          {label:'超龄换证',value:'2002'},
          {label:'遗失补证',value:'2003'},
          {label:'损毁换证',value:'2013'},
          {label:'转入换证',value:'2027'},
          {label:'自愿降低准驾车型换证',value:'2025'},
          {label:'因身体条件变化降级换证',value:'2026'},
          {label:'驾驶人联系方式变更备案',value:'20A9'},
          {label:'提交身体条件证明',value:'20C3'}
        ]
      }
    },
    clearable:{
      type:Boolean,
      default:false
    },
    isObj:{
      type:Boolean,
      default:false
    },
    isTop:{
      type:Boolean,
      default:true
    },
  },
  created() {
    this.values = this.value;

    if(this.isTop){
      this.list.push({value:'',label:'全部'});
    }
  },
  watch:{
    value(n,o){
      this.values = n;
    }
  },
  mounted() {
    this.list.push.apply(this.list,this.options);
  },
  methods:{
    change(e){
      this.$emit("change", e);
    }
  }
}
</script>
