<template>
	<el-dialog title="点评" :visible.sync="isShow" width="700px" append-to-body @close="onClose" @open="onOpen">
<!--		{{formData}}-->
		<el-form :model="formData" :rules="rules" ref="form" label-width="100px">
			<el-form-item label="点评内容" prop="content">
				<el-input type="textarea" v-model="formData.content"></el-input>
			</el-form-item>
		</el-form>
		<span slot="footer" class="dialog-footer">
			<el-button @click="isShow = false">关闭</el-button>
	    <el-button type="primary" @click="toSubmit">确定</el-button>
	  </span>
	</el-dialog>
</template>

<script>
export default {
	name: "ReplyDialog",
	data() {
		return {
			isShow:false,
			formData:{
				tid:'',
				type:'',
				extid:'',
				pid:'',
				content:''
			},
			rules:{
				content:[{required: true,message:'点评内容不能为空'}]
			}
		}
	},
	props:{
		visible:{
			type:Boolean,
			default:false
		},
		id:{
			type:String,
			default:''
		},
		replyType:{
			type:String,
			default:''
		},
		replyExtid:{
			type:String,
			default:''
		},
		pid:{
			type:String,
			default:''
		},
		targetUid:{
			type:String,
			default:''
		}
	},
	model:{
		prop:'visible',
		event:'change'
	},
	created() {
		this.isShow = this.visible;
		this.formData.tid = this.id ? this.id : ''
		this.formData.extid = this.replyExtid ? this.replyExtid : ''
		this.formData.type = this.replyType ? this.replyType : ''
		this.formData.pid = this.pid ? this.pid : ''
		this.formData.target_uid = this.targetUid ? this.targetUid : ''
	},
	mounted() {
	
	},
	methods: {
		onOpen(){
			let _this = this;
			this.$nextTick(()=>{
				_this.formData.tid = _this.id ? _this.id : '';
				_this.formData.pid = _this.pid ? _this.pid : '';
				_this.formData.extid = _this.replyExtid ? _this.replyExtid : ''
				_this.formData.type = _this.replyType ? _this.replyType : ''
				_this.formData.target_uid = _this.targetUid ? _this.targetUid : ''
				_this.$refs['form'].clearValidate()
			})
		},
		onClose(){
			this.formData.tid = '';
			this.formData.pid = '';
			this.formData.extid = '';
			this.formData.type = '';
			this.formData.content = '';
			this.formData.target_uid = '';
			this.$emit('update:visible', false);
		},
		toSubmit(){
			let _this = this;
			this.$refs['form'].validate((valid) => {
				if (valid) {
					_this.$http.request({
						url:'/ReplyLog/add',
						datas:_this.formData,
						success(res){
							_this.$message.success('操作成功');
							
							_this.$emit('success');
							
							_this.isShow = false;
						}
					})
				} else {
					_this.$message.error('请填写完整表单')
					return false;
				}
			});
		}
	},
	watch:{
		visible(n,o){
			this.isShow = n;
		}
	}
}
</script>
