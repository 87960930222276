<template>
    <el-button :circle="circle" :type="type" :plain="plain" :size="bSize" v-if="isShow" :icon="icon" :disabled="disabled">
        <slot></slot>
    </el-button>
</template>

<script>
export default {
    name: "BaseButton",
    data() {
        return {
            bSize: ''
        }
    },
    props: {
      role: '',
      icon: '',
      type: {
        type:String,
        default:'text',
      },
	    permission: {
        type:Array,
        default(){
					return null
        },
      },
      size: {
          type: String,
          default: 'mini'
      },
      disabled: {
          type: Boolean,
          default: false
      },
      plain: {
          type: Boolean,
          default: false
      },
      circle: {
        type: Boolean,
        default: false
      },
    },
    created() {
        this.bSize = this.type === 'text' ? '' : this.size;
    },
    watch: {
        size(n, o) {
            this.bSize = n;
        }
    },
    computed: {
        isShow() {
            if(this.role) {
	            return this.$rule.checkRule(this.role,this.permission ? this.permission : null);
            }else{
                return true;
            }
        }
    }
}
</script>
