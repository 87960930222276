<template>
    <el-date-picker
        v-model="values"
        type="daterange"
        align="right"
        unlink-panels
        :clearable="false"
        :editable="false"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :picker-options="showPicker ? pickerOptions : null"
        @change="change">
    </el-date-picker>
<!--    <div>-->
<!--        <template v-if="$isMobile">-->
<!--            <div @click="show = true" style="height: 32px;" class="border-radius-3 border-1 padding-lr-5">{{values && values.length > 0 ? $moment(values[0]).format('YYYY-MM-DD')+' 至 '+$moment(values[1]).format('YYYY-MM-DD') : '选择时间'}}</div>-->
<!--            <van-calendar :min-date="minDate" type="range" v-model="show" @confirm="change" />-->
<!--        </template>-->
<!--        <template v-else>-->
<!--            <el-date-picker-->
<!--                v-model="values"-->
<!--                type="daterange"-->
<!--                align="right"-->
<!--                unlink-panels-->
<!--                :clearable="false"-->
<!--                :editable="false"-->
<!--                range-separator="至"-->
<!--                start-placeholder="开始日期"-->
<!--                end-placeholder="结束日期"-->
<!--                :picker-options="pickerOptions"-->
<!--                @change="change">-->
<!--            </el-date-picker>-->
<!--        </template>-->
<!--    </div>-->
</template>

<script>
export default {
    name: "RangeTime",
    data(){
        return{
	          millisecondOfDay:1 * 24 * 60 * 60 * 1000,
	          pickMinDate:0,
            minDate:this.$moment('1970-1-1').toDate(),
            show:false,
            values:'',
            pickerOptions: {
	            firstDayOfWeek:1,
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
        }
    },
    model:{
        prop:'value',
        event:'change'
    },
    props: {
        value: {
            type:String,
            default: ''
        },
      showPicker: {
        type:Boolean,
        default: true
      },
	    maxPickDays:{
		    type:Number,
		    default:null
	    }
    },
    created() {
        console.log(this.$moment('1970-1-1').toDate());
				console.log(typeof this.value);
        if(this.value){
            let _times = typeof this.value === 'object' ? this.value : this.value.split(',');
            let _timeArr = [];
            _timeArr.push(this.$moment(_times[0]).toDate());
            _timeArr.push(this.$moment(_times[1]).toDate());

            this.values = _timeArr;

        }else{
            this.values = ''
        }
				
				if(this.maxPickDays){
					this.pickerOptions.disabledDate = (time)=>{
						if (this.pickMinDate > 0) {
							return time.getTime() > this.pickMinDate + this.maxPickDays * this.millisecondOfDay || time.getTime() < this.pickMinDate - this.maxPickDays * this.millisecondOfDay
						}
					}
					this.pickerOptions.onPick = ({ minDate, maxDate })=>{
						this.pickMinDate = minDate.getTime()
					}
				}
        // this.values = this.value ? this.$moment(this.value).toDate() : '';
    },
    watch:{
        value(n,o){
            console.log(n);
            // this.values = n ? this.$moment(n).toDate() : '';
            if(n){
                let _times = typeof n === 'object' ? n : n.split(',');
                let _timeArr = [];
                _timeArr.push(this.$moment(_times[0]).toDate());
                _timeArr.push(this.$moment(_times[1]).toDate());

                this.values = _timeArr;

            }else{
                this.values = '';

                this.$emit('change','');
            }
        },
    },
    methods:{
        change(e){
            // console.log(e);
            // console.log(this.$moment(e[0]).format('YYYY-MM-DD HH:mm:ss'));
            // let _year = e[0].getFullYear();
            // let _month = e[0].getMonth() + 1;
            // let _day = e[0].getDate();
            //
            // let _year2 = e[1].getFullYear();
            // let _month2 = e[1].getMonth() + 1;
            // let _day2 = e[1].getDate();

            let _start = this.$moment(e[0]).format('YYYY-MM-DD');
            let _end = this.$moment(e[1]).format('YYYY-MM-DD');

            this.$emit('change',_start+','+_end);

            if(this.$isMobile){
                this.show = false;
            }
        },
        onConfirm(e){
            console.log(e);
        }
    }
}
</script>
