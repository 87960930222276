<template>
	<div :id="id" :style="{height:height,width:width}"></div>
</template>

<script>
export default {
	name: "PidEcharts",
	data() {
		return {
			id: 'echarts',
			maxIndex:0,
			option: {
				title: {
					text: '',
					left: 'center',
					top: '50%',
					show: true,
					textStyle: {
						fontWeight: 'normal',
						fontSize: 18
					}
				},
				tooltip: {
					trigger: 'item',
					// formatter: '{b} : {c} ({d}%)'
				},
				grid: {
					top: 20,
					containLabel: true
				},
				legend: null,
				color: ["#7ED321", "#5C87F6", "#FBC531", "#E74218", "#02C5C6", "#9980F9", "#E056FD"],
				series: [
					{
						avoidLabelOverlap: false,
						name: '访问来源',
						type: 'pie',
						radius: ['0%', '70%'],
						data: [],
						label: {
							show: false,
							formatter: '{c}{d}%',
							position: 'center',
							fontSize: 16,
							// alignTo:'labelLine'
						},
						itemStyle: {
							borderRadius: 10,
							borderColor: '#fff',
							borderWidth: 2
						},
						emphasis: {
							label: {
								show: true,
								fontSize: 20,
								fontWeight: 'bold',
								formatter: '{b}\n{d}%',
							},
							itemStyle: {
								shadowBlur: 10,
								shadowOffsetX: 0,
								shadowColor: 'rgba(0, 0, 0, 0.5)'
							}
						}
					}
				]
			}
		}
	},
	props: {
		width: String,
		height: String,
		title: String,
		radius: {
			type: Array,
			default() {
				return ['0%', '70%']
			}
		},
		showLegend: {
			type: Boolean,
			default: true
		},
		legendOrient: {
			type: String,
			default: 'horizontal'
		},
		labelPos: {
			type: String,
			default: 'inside'
		},
		labelFormat: {
			type: String,
			default: '{d}%'
		},
		titlePos: {
			type: String,
			default: 'top'
		},
		titleSize: {
			type: Number,
			default: 18
		},
		labelSize: {
			type: Number,
			default: 14
		},
		showTitle: {
			type: Boolean,
			default: true
		},
		data: {
			type: Array,
			default() {
				return []
			}
		},
		legendPos: {
			type: String,
			default: 'bottom'
		},
		gridBottom: {
			type: Number,
			default: 30
		}
	},
	created() {
		this.$echarts.set
		
		this.id = this.$utils.randomWords(8);
		
		if (this.titlePos === 'top') {
			this.option.title.top = 0;
		} else if (this.titlePos === 'bottom') {
			this.option.title.bottom = 0;
		}
		
		this.option.series[0].label.formatter = this.labelFormat;
		this.option.series[0].radius = this.radius;
		this.option.series[0].label.position = this.labelPos;
		
		this.option.title.textStyle.fontSize = this.titleSize;
		
		this.option.grid.bottom = this.gridBottom;
		
		if (this.title) {
			this.option.title.text = this.title;
			this.option.grid.top = 40;
		}
		
		this.option.title.show = this.showTitle;
		this.option.series[0].label.fontSize = this.labelSize
		this.option.series[0].data = this.data;
		
		// this.option.tooltip.formatter = (params)=>{
		//   let relVal = params[0].name;
		//   for (let i = 0, l = params.length; i < l; i++) {
		//     relVal += '<div style="display:flex;flex-direction: row;align-items: center;justify-content: space-between;"><span style="flex:1;margin-right:10px;">' + params[i].marker + (params[i].seriesName ? params[i].seriesName : params[i].name) + ' : </span><span style="flex:1;text-align: right;">' + params[i].value + '</span>'+ (data[i] && data[i].dw ? data[i].dw : '') +'</div>'
		//   }
		//   return relVal;
		// }
		
	},
	mounted() {
		this.echarts = this.$echarts.init(document.getElementById(this.id));
		this.echarts.setOption(this.option, true);
		window.addEventListener("resize", this.resize);
	},
	beforeDestroy() {
		window.removeEventListener("resize", this.resize);
	},
	methods: {
		resize(){
			this.echarts.resize();
		},
		initData(data, dw) {
			// console.log(data);
			if (data.length > 0) {
				this.maxIndex = 0
				let _max = 0;
				let _legend = [];
				let _all_index = [];
				for (let i = 0; i < data.length; i++) {
					let _data = data[i];
					_all_index.push(i);
					
					if(_data.value > _max){
						_max = _data.value;
						this.maxIndex = i;
					}
					_legend.push(_data.name);
				}
				
				console.log(this.maxIndex);
				
				if (this.showLegend) {
					this.option.legend = this.$echartsJs.getLegend(_legend, this.legendPos, this.legendOrient);
				}
				
				this.option.series[0].data = data;
				
				this.option.tooltip.formatter = '{b} : {c} ' + (dw ? dw : '') + ' ({d}%)'
				
				this.echarts.setOption(this.option, true);
				
				this.echarts.dispatchAction({
					type: 'downplay',
					seriesIndex: 0,
					dataIndex: _all_index
				});
				
				this.echarts.dispatchAction({
					type: 'hideTip',
					seriesIndex: 0,
					dataIndex: _all_index
				});
				
				this.echarts.dispatchAction({
					type: 'highlight',
					seriesIndex: 0,
					dataIndex: this.maxIndex
				});
				
				this.echarts.on('mouseover', (v) => {
					if (v.dataIndex !== this.maxIndex) {
						// 高亮显示悬停的那块
						this.echarts.dispatchAction({
							type: 'hideTip',
							seriesIndex: 0,
							dataIndex: this.maxIndex
						});
						// 当检测到鼠标悬停事件，取消所有选中高亮
						this.echarts.dispatchAction({
							type: 'downplay',
							seriesIndex: 0,
							dataIndex: this.maxIndex
						});
					}
				});

// 检测鼠标移出后显示之前默认高亮的那块
				this.echarts.on('mouseout', (v) => {
					this.echarts.dispatchAction({
						type: 'showTip',
						seriesIndex: 0,
						dataIndex: this.maxIndex
					});
					this.echarts.dispatchAction({
						type: 'highlight',
						seriesIndex: 0,
						dataIndex: this.maxIndex
					});
				});
			}
		}
	}
}
</script>
