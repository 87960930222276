<template>
  <div class="h100">
    <div class="rel h100 bg-main">
<!--      <div class="main-header cl pd-l-r-40 abs block">-->
<!--        <div class="text-center f-30 lh-70 f-b" style="color: #5fdaff;">-->
<!--          <img src="/static/image/logo.png" height="70" class="vm mg-r-10"/>夷陵督查平台-->
<!--        </div>-->
<!--&lt;!&ndash;        <div class="text-center f-30 lh-70 f-b" style="color: #5fdaff;">智慧化油库</div>&ndash;&gt;-->
<!--&lt;!&ndash;        <el-button icon="icon-exit iconfont mg-r-5" type="text" class="y mg-t-30 blue-5" v-if="$user.getToken()" @click="quit">退出</el-button>&ndash;&gt;-->
<!--      </div>-->
<!--      <img src="@/assets/images/login_title.png" style="width: 100%;"/>-->
      <slot></slot>
      <div class="abs block text-center light f-12 hide" style="line-height:80px;height:62px;bottom: 0;left:0;">版权所有：宜昌市夷陵区委督查室<span class="pd-l-r-10">|</span>技术支持：武汉米核网络科技有限公司</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CleanMain",
  data() {
    return {}
  },
  created() {
  },
  mounted() {
	  // this.$utils.loadJs('https://g.alicdn.com/dingding/h5-dingtalk-login/0.21.0/ddlogin.js').then(()=>{});
		// this.$utils.loadJs('https://g.alicdn.com/dingding/h5-dingtalk-login/0.21.0/ddlogin.js');
  },
  methods: {
    quit() {
      this.$confirm('您是否要退出本系统？', '提示', {
        confirmButtonText: '退出',
        cancelButtonText: '取消',
        type: '',
        // customClass:'sb-dialog',
        showClose:false,
        center: true
      }).then((action) => {

        console.log(action);
        this.$utils.sessionClear();
        this.$router.replace({ path: "/login" });
      }).catch((action) => {
        console.log(action);
      });
    },
  }
}
</script>
<style>
.bg-main{background-image: url("~@/assets/images/login_bg.jpg");background-repeat: no-repeat;background-size: 100% 100%;}
.main-footer{background-image: url("~@/assets/images/login_footer.png");background-repeat: no-repeat;background-size: 100% 100%;}
.main-header{height:102px;background-image: url("~@/assets/images/login_title.png");background-repeat: no-repeat;background-size: auto 100%;background-position: center center;}

/*@media (max-width: 768px){ !*0~768*!*/
/*  .main-header{height:40px !important;}*/
/*}*/
/*@media (min-width: 1000px){ !*0~768*!*/
/*  .main-header{height:60px !important;}*/
/*}*/
/*@media (min-width: 1400px){ !*0~768*!*/
/*  .main-header{height:102px !important;}*/
/*}*/
</style>
