<template>
  <el-checkbox v-model="values" @change="onChange"><slot></slot></el-checkbox>
</template>

<script>
export default {
  name: "ZeroCheckbox",
  data() {
    return {
      values:false
    }
  },
  props:{
    value:{
      type:[String,Number,Boolean],
      default:0
    }
  },
  model:{
    prop:'value',
    event:'change'
  },
  created() {
    this.values = this.value === 1;
  },
  mounted() {
  },
  methods: {
    onChange(e){
      console.log(e);
      this.$emit('change', e ? 1 : 0);
    }
  },
  watch:{
    value(n,o){
      this.values = n === 1;
    }
  }
}
</script>
