<template>
  <clean-main>
	  <div class="h100" :style="{backgroundImage:'url(\''+pc_home_page_head_img+'\')'}" style='background-repeat: no-repeat;background-size: 100% 100%;'>
		  <div class="cl"></div>
		  <div class="cl" style="width: 1500px;margin:50px auto 0 auto;">
			  <div class="z"><img src="/static/image/logo.png" class="vm" style="height: 100px;"/></div>
			  <div class="y hide" style="line-height: 100px;height: 100px;">
				  <a class="hand f-30" style="color: #01376d;" @click="$router.push({path:'/dashboard'})"><i class="iconfont icon-shouye mg-r-5 f-28"></i>首页</a>
				  <a class="hand f-30 mg-l-20" style="color: #01376d;" @click="$router.push({path:'/total'})"><i class="iconfont icon-shuju mg-r-5 f-30"></i>数据统计</a>
				  <a class="hand f-30 mg-l-20" style="color: #01376d;" @click="$router.push({path:'/ledger'})"><i class="iconfont icon-taizhang mg-r-5 f-30"></i>事项台账</a>
				  <a class="hand f-30 mg-l-20" style="color: #01376d;" @click="quit"><i class="iconfont icon-tuichu mg-r-5 f-28"></i>退出</a>
			  </div>
		  </div>
		  <div class="cl" style="margin-top:100px;"></div>
<!--		  <div class="text-center" style="color: #fff;font-weight: bold;line-height: 90px;font-size: 90px;">夷陵区在线督查平台</div>-->
<!--		  <div class="text-center" style="color: #01376d;font-weight: bold;height: 90px;font-size:90px;line-height: 90px;margin-top:-89px;margin-right: 2px;">夷陵区在线督查平台</div>-->
			<div class="text-center">
				<img src="/images/title.png" style="height: 107px;"/>
			</div>
		  <div style="width:1500px;margin:230px auto 0 auto;" v-if="total">
			  <el-row :gutter="30">
					<template v-for="(item,index) in total">
						<el-col :span="6" v-if="permission.indexOf('program:'+item.type+':list') >= 0">
							<div style="background-repeat: no-repeat;background-size: 100% 100%;width: 100%;height: 180px;padding:30px 30px 50px 60px;" class="hand" :style="{backgroundImage: 'url(\'/images/'+item.type+'.png?v='+time+'\')'}" @click="$router.push({path:'/program/'+item.type})">
								<div class="cl light f-36">
									<div class="z">{{item.type_str}}</div>
									<div class="y"><b class="mg-l-5">{{item.all}}</b></div>
								</div>
								<div class="cl light f-20 mg-t-20">
									<div class="z">已办结<b class="mg-l-5">{{item.state_4}}</b></div>
									<div class="y">办理中<b class="mg-l-5">{{item.state_3}}</b></div>
								</div>
							</div>
						</el-col>
					</template>
			  </el-row>
		  </div>
	  </div>
  </clean-main>
</template>

<script>
export default {
  name: "Choose",
  data() {
    return {
	    total:null,
			time:0,
			pc_home_page_head_img:'',
			permission:[]
    }
  },
  created() {
		this.time = this.$moment().unix();
  },
  mounted() {
	  this.getTotal();
		this.getBg();
		this.getMenu();
  },
  methods: {
    toIndex(){
      this.$router.replace({path:'/dashboard'})
    },
	  quit() {
		  this.$confirm('是否退出登录？', '提示', {
			  confirmButtonText: '立即退出',
			  cancelButtonText: '取消',
			  type: 'warning'
		  }).then(() => {
			  this.$utils.sessionClear();
			  this.$router.replace({ path: "/login" });
		  }).catch(() => {

		  });
	  },
	  getTotal() {
		  let _this = this;
		  _this.$http.request({
			  url: "/Program/getCount",
			  datas: {state:[[2,3,4,5],'in']},
			  success(res) {
				  _this.total = res;
			  }
		  })
	  },
		getMenu(){
			let _this = this;
			this.$http.request({
				url: '/SystemRole/menu',
				datas: null,
				success(res) {
					_this.permission = res.rule;
					_this.$user.setPermissions(res.rule);
					_this.$user.setDepartmentId(res.dept_id);
					_this.$router.replace({path:'/'});
				}
			});
		},
		getBg() {
			let _this = this;
			_this.$http.request({
				url: "/SystemSetting/getSystem",
				datas: {skey:'pc_home_page_head_img'},
				success(res) {
					_this.pc_home_page_head_img = res.pc_home_page_head_img;
				}
			})
		},
  }
}
</script>
<style scoped>
.sb-btn-box{margin:auto;height: 100%;}
.sb-btn-box:before{content:'';display: inline-block;vertical-align: middle;height: 100%;}
.sb-btn{width:369px;height:201px;background-position: center center;background-repeat: no-repeat;display: inline-block;vertical-align: middle;padding:80px 0 0 150px;}
.sb-btn1{background-image: url("~@/assets/images/sb_btn_1.png");}
.sb-btn2{background-image: url("~@/assets/images/sb_btn_2.png");}
</style>
