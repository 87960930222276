import Vue from 'vue'
let self = new Vue();
import changePass from "@/views/site/changePass";

export default {
    checkRule(path,permission = null){
        let ruleStr = self.$utils.sessionGet('permissions');
        if(ruleStr){
            let ruleArr = JSON.parse(ruleStr);

            if(permission && permission.length > 0){
                ruleArr.push(...permission)
            }
            if(ruleArr.indexOf(path) > -1){
                return true;
            }else{
                return false;
            }
        }

        return false;
    },

    getRoutes(){
        let _rule = JSON.parse(self.$utils.sessionGet('rule'));

        if(_rule) {
            let _routes = [
                {
                    path: '/',
                    name: 'DashBoard',
                    component: () => import('@/views/Home'),
                    redirect: '/dashboard',
                    children:[
                        {
                            path: '/changePass',
                            name: 'changePass',
                            component: changePass
                        },
                    ]
                },
                // {
                //     path: '/home',
                //     name: 'home',
                //     component: () => import('@/views/Home'),
                // }
            ];


            for (let path in _rule) {
                if (path && path !== '#') {
                    let _url = '';

                    let _path = path.split("/");

                    let _pathStr = '';

                    if(_path[1] === 'bids'){
                        _pathStr = '/'+_path[1]+ '/:type/'+_path[_path.length - 1];
                    }else if(_path[1] === 'type'){
                        _pathStr = '/'+_path[1]+ '/:type/'+_path[_path.length - 1];
                    }else if(_path[1] === 'news'){
                        _pathStr = '/'+_path[1]+ '/:type/'+_path[_path.length - 1];
                    } else{
                        // else {
                            _pathStr = path;
                        // }
                    }

                    if(_path[_path.length - 1] === 'edit' || _path[_path.length - 1] === 'detail' || _path[_path.length - 1] === 'rule'){
                        _pathStr = _pathStr + '/:id';
                    }

                    ///type/industry/list

                    if(_path[1] === 'bids'){
                        _url = '/'+_path[1] + '/' + _path[_path.length - 1];
                    }else if(_path[1] === 'type'){
                        _url = '/'+_path[1] + '/' + _path[_path.length - 1];
                    }else if(_path[1] === 'news') {
                        _url = '/' + _path[1] + '/' + _path[_path.length - 1];
                    }else{
                        _url = path;
                    }

                    // console.log(_rule[path]);

                    let _route = {
                        path: _pathStr,
                        component: () => import('@/views' + _url),
                        meta: [_rule[path]]
                    };
                    _routes[0].children.push(_route);
                    // _routes.push(_route);
                }
            }

            console.log(_routes[0]);

            return _routes;
        }else{
            return false;
        }
    }
}
