<template>
  <el-cascader :options="options" v-model="val" :show-all-levels="false" v-bind="comProp" @change="change"></el-cascader>
</template>

<script>
export default {
    name: "ElTreeSelect",
    data(){
        return{
            options: [],
            val:null,
            props:{
              value:'value',
              label:'label',
              children:'children',
              expandTrigger:'hover'
            }
        }
    },
    model:{
        prop:'value',
        event:'input'
    },
    props: {
      comProp:{
        type:[Object,Array],
        default(){
          return {}
        }
      },
        value: {
            type:[String,Number],
            default:''
        },
        url: {
            type:String,
            default:null
        },
        isTop: {
            type:Boolean,
            default:false
        },
      topLabel: {
        type:String,
        default:'顶级'
      },
        multiple:{
            type:Boolean,
            default:false
        },
        placeholder:{
            type:String,
            default:'-请选择-'
        },
        valKey:{
            type:String,
            default:'id'
        },
        valLabel:{
            type:String,
            default:'label'
        },
        valChild:{
            type:String,
            default:'children'
        },
    },
    created() {
      console.log(this.$props);

      this.val = this.value ? this.value : (this.value === 0 || this.value === '0' ? this.value : null);

      this.props.value = this.valKey;
      this.props.label = this.valLabel;
      this.props.children = this.valChild;


        if(this.isTop){
            let _top = {[this.valKey]:0,[this.valLabel]:this.topLabel};

            this.options.push(_top);
        }
    },
    mounted() {
        if(this.url) {
            this.getList();
        }
    },
    methods:{
        getList(){
            let _this = this;

            this.$http.request({
                url:this.url,
              method:'get',
                success(res){
                  let _res = _this.listKey ? res[_this.listKey] : res;
                  let _typeof = Object.prototype.toString.call(_res);

                  _this.options.push.apply(_this.options,_typeof === '[object Object]' ? [_res] : _res);
                }
            });
        },
      change(e){
          console.log(e);
          this.$emit('input',e);
      }
    },
    watch:{
        value(n,o){
            this.val = n ? n : (n === 0 ? n : null);
        },
        multiple(n,o){
            this.multiple = n;
        }
    }
}
</script>
