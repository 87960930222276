export default [
  {
    title:'首页',
    path: '/home',
    icon:'el-icon-tickets',
  },
  {title:'物流招标管理',path:'#1',query:null,icon:'el-icon-present',
    child:[
      {title:'仓储供给',path:'/bids/storage',query:null},
      {title:'设备采购',path:'/bids/device',query:null},
      {title:'招标公告',path:'/bids/notice',query:null},
      {title:'散单平台',path:'/bids/odd',query:null},
    ]
  },
  {title:'新闻资讯',path:'#2',query:null,icon:'el-icon-present',
    child:[
      {title:'物流资讯',path:'/news/logistics',query:null},
      {title:'政策法规',path:'/news/policy',query:null},
    ]
  },
  {
    title:'字典管理',
    path: '#',
    icon:'el-icon-tickets',
    child:[
      {title:'行业类别',path:'/type/industry',query:null},
    ]
  },
  {
    title:'用户管理',
    path: '/user',
    icon:'el-icon-tickets',
  },
];
