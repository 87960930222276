<template>
  <el-upload
    style="width: 500px;"
    class="upload-demo"
    :class="fileList.length >= limit ? 'miihe-file-done-22'  : ''"
    :action="$config.api.uploadUrl"
    :data="{key:imgKey,totype:totype}"
    :headers="{Authorization:$user.getToken()}"
    :on-preview="onPreview"
    :on-remove="onRemove"
		:on-exceed="onExceed"
    :on-success="onSuccess"
    :before-remove="beforeRemove"
    :accept="accept"
    :multiple="multiple"
    :limit="limit"
    :file-list="fileList">
<!--    <div class="text-left mg-r-10" v-if="fileList.length < limit">-->
		<div class="text-left mg-r-10">
      <el-button size="small" type="primary" :disabled="fileList.length >= limit">{{fileList.length >= limit ? '已达数量上限，请先删除' : '点击上传'}}</el-button>
    </div>
		<div slot="tip" style="color:#777;line-height: 18px !important;">
			<div>文件类型：{{accept}}</div>
			<div>最多可以上传 {{limit}} 个文件</div>
		</div>
  </el-upload>
</template>

<script>
export default {
  name: "FileUpload",
  data() {
    return {
      fileList:[],
      values:'',
      ids:[]
    };
  },
  model:{
    prop:'value',
    event:'change'
  },
  props:{
	  accept:{
			type:String,
		  default:'.doc,.docx,.ppt,.pptx,.pdf,.xls,.xlsx,.exe,.zip,.rar,.txt,.js,.tar.gz,.mp4'
	  },
    multiple:{
      type:Boolean,
      default:false
    },
	  totype:{
      type:String,
      default:'oss'
    },
	  imgKey:{
		  type:String,
		  default:'file'
	  },
    files:{
      type:Array,
      default(){
        return []
      }
    },
    value:{
      type:[Array,String],
      default(){
        return []
      }
    },
    limit:{
      type:Number,
      default:1
    }
  },
  created() {
    // this.values = this.files;
    this.fileList = this.files;
    this.ids = this.value ? this.value : [];
  },
  watch:{
    files(n,o){
      // console.log(n);
      // this.values = n;
      this.fileList = n ? n : [];

      // if(!n){
      //     this.fileList = [];
      // }
    },
    value(n,o){
      console.log(n);
      this.ids = n ? n : [];
    }
  },
  methods: {
		onExceed(f, fl) {
			this.$message.error('文件数量不能超过'+this.limit+'个')
		},
    onRemove(file, fileList) {
      // this.values = fileList;
      this.fileList = fileList;

      this.ids = [];

      if(fileList.length > 0){
        for(let i=0;i < fileList.length;i++){
          if(fileList[i].id){
            this.ids.push(fileList[i].id);
          }else if(fileList[i].response && fileList[i].response.data.id){
            this.ids.push(fileList[i].response.data.id);
          }
        }
      }
      this.$emit('change',this.ids);
    },
    onPreview(file) {
      let _url = file.response && file.response.data && file.response.data.url ? (file.response.data.url.indexOf('http') >= 0 ? file.response.data.url : this.$config.api.assetsUrl + file.response.data.url) : (file.url ? (file.url.indexOf('http') >= 0 ? file.url : this.$config.api.assetsUrl + file.url) : '');

			if(_url) {
				window.open(_url, '_blank');
			}
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${ file.name }？`);
    },
    onSuccess(response, file, fileList) {

      if(response.code !== 0){
        this.$utils.error('上传失败');
        this.fileList = [];
      }else{

				this.fileList = fileList;
        this.ids.push(response.data.id);

        this.$emit('change',this.ids);
      }
    },
  }
}
</script>
<style>
.miihe-file-done .el-upload--text{display: none;}
</style>
