<template>
    <el-dropdown-item :disabled="disabled" :icon="icon" v-if="isShow"><slot></slot></el-dropdown-item>
</template>

<script>
export default {
    name: "BaseDropItem",
    props: {
      role: '',
      icon: '',
	    disabled:false,
	    permission: {
		    type:Array,
		    default(){
			    return null
		    },
	    },
    },
	mounted() {
		// console.log(this.role)
	},
	created() {
		// console.log(this.role)
	},
	computed: {
        isShow() {
	        if(this.role) {
		        return this.$rule.checkRule(this.role,this.permission ? this.permission : null);
	        }else{
		        return true;
	        }
        }
    }
}
</script>
