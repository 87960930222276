<template>
    <div>
      <template v-if="values">
        <template v-if="values.indexOf('.mp4') >= 0">
          <video width="210" height="148" controls class="vm">
            <source :src="values.indexOf('http') >= 0 ? values : $config.api.assetsUrl + values" type="video/mp4">
            <!--              <object :data="values.indexOf('http') >= 0 ? values : $config.api.assetsUrl + values" width="210" height="148">-->
            <!--                <embed width="210" height="148" src="movie.swf">-->
            <!--              </object>-->
          </video>
          <el-button type="text" class="vm" @click="delImg">删除</el-button>
        </template>
        <div class="el-upload el-upload--picture-card margin-right rel hide margin-bottom" :style="{height:size,width:size,lineHeight:size}" v-else>
          <el-image class="el-upload-list__item-thumbnail" :src="values.indexOf('http') >= 0 ? values : $config.api.assetsUrl + values" alt="" :style="{width:size,height:size}" fit="cover"></el-image>
          <span class="miihe-upload-item">
            <span @click="showBigImg">
              <i class="el-icon-zoom-in"></i>
            </span>
            <span @click="delImg">
              <i class="el-icon-delete"></i>
            </span>
          </span>
        </div>
      </template>
        <el-upload
            :show-file-list="false"
            :action="$config.api.uploadUrl"
            :data="{key:imgKey}"
            :headers="{Authorization:$user.getToken()}"
            accept=".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.GIF,.BMP,.mp4"
            ref="upload"
            :on-success="onSuccess"
            :on-exceed="onExceed"
            :file-list="fileList"
            :limit="limit"
            :auto-upload="true" :style="{height: values ? 0 : ''}">
          <div class="el-upload el-upload--picture-card" v-if="!values" :style="{height:size,width:size,lineHeight:size}">
            <i class="el-icon-plus"></i>
          </div>
<!--					<div slot="tip" class="el-upload__tip" v-if="tip">{{tip}}</div>-->
<!--            <div class="text-left" v-if="!values">-->
<!--              <el-button size="small" type="primary">点击上传</el-button>-->
<!--            </div>-->
        </el-upload>
        <el-dialog :visible.sync="show" append-to-body>
            <img width="100%" :src="bigImg" alt="">
        </el-dialog>
    </div>
</template>

<script>
import emitter from "element-ui/src/mixins/emitter";

export default {
    name: "HeadImgUpload",
  mixins: [emitter],
    data() {
        return {
            bigImg: '',
            show: false,
            imgKey:'',
            fileList:[],
            values:''
        };
    },
    model:{
        prop:'value',
        event:'change'
    },
    props:{
        value:{
            type:String,
            default:''
        },
			tip:{
            type:String,
            default:''
        },
        limit:{
            type:Number,
            default:1
        },
	    size:{
		    type:[Number,String],
		    default:'148px'
	    }
    },
    created() {
        this.values = this.value;
    },
    watch:{
        value(n,o){
            this.values = n;

            if(!n){
                this.fileList = [];
            }
        }
    },
    methods: {
        delImg(){
            this.values = '';
            this.$refs.upload.clearFiles();
            this.$emit('change','');
          this.dispatch('ElFormItem', 'el.form.change', [''])
          this.dispatch('ElFormItem', 'el.form.blur', [''])
        },
        showBigImg() {
            this.bigImg = this.values.indexOf('http') >= 0 ? this.values : this.$config.api.assetsUrl + this.values;
            this.show = true;
        },
        onSuccess(response, file, fileList) {
          console.log(response);
          if(response.code === 0){
            this.fileList = fileList;

            this.values = response.data.url

            this.$emit('change',response.data.url);
            this.dispatch('ElFormItem', 'el.form.change', [response.data.url])
            this.dispatch('ElFormItem', 'el.form.blur', [response.data.url])
          }else{
            this.$utils.error(response.msg);
            this.fileList = [];
          }
        },
        onExceed(f,fl){
            console.log('323');
        }
    },
    destroyed() {
        console.log('dis');
    }
}
</script>
