<template>
  <div>
    <el-form :model="formData" :rules="rules" ref="form" label-width="150px" v-if="!loading">
<!--      <el-form-item label="ID" v-if="formData.id">-->
<!--        {{ formData.id }}-->
<!--      </el-form-item>-->
<!--      {{formData}}-->
      <el-row :gutter="10">
        <el-col :span="24 / (item.cols ? item.cols : (editOptions.cols ? editOptions.cols : 1))" v-for="(item,index) in editParams">
	        <div style="border-bottom:1px solid #eee;" class="pd-t-b-10 mg-b-10" v-if="item.type === 'driver'">
		        <div style="width: 150px;padding-right: 12px;" class="f-14 text-right">{{item.name}}</div>
	        </div>
	        <template v-else>
	          <el-form-item :label="item.name" :prop="item.value" :class="item.type ==='editor' ? 'no-line-height' : ''" v-if="item.rules ? checkRules(item.rules.key,item.rules.val,item.rules.operator ? item.rules.operator : '===',item.value) : true">
	            <template v-if="item.edit">
	              <type-select v-if="item.type === 'select'" v-model="formData[item.value]"
	                           @input="(e)=>$set(formData,item.value,e)" @label="e=>onChangeLabel(e,item)" :is-top="item.isTop ? item.isTop : false"
	                           :options="item.options ? item.options : null" :data="item.data ? item.data : null"
	                           style="max-width: 500px;" :url="item.url ? item.url : null"
	                           :method="item.method ? item.method : 'post'" :val-key="item.valKey ? item.valKey : 'id'"
	                           :val-label="item.valLabel ? item.valLabel : 'name'" :list-key="item.listKey" v-bind="item.comProp" :pid="item.pidKey ? formData[item.pidKey] : ''" :pid-data-key="item.pidDataKey"></type-select>
	              <company-select v-else-if="item.type === 'company'" v-model="formData[item.value]"
	                              @change="(e)=>$set(formData,item.value,e)" style="max-width: 500px;"></company-select>
	              <el-input type="textarea" :rows="4" v-bind="item.comProp" placeholder="请输入内容" v-model="formData[item.value]" v-else-if="item.type === 'textarea'" style="max-width: 500px;" :maxlength="item.max ? item.max : null" :show-word-limit="!!item.max"></el-input>
	              <head-img-upload v-model="formData.head_img"
	                               :img="formData.head_img ? $config.assetsUrl + formData.head_img : ''"
	                               v-else-if="item.value === 'head_img'"
	                               @change="(e)=>$set(formData,'head_img',e ? e : '')"></head-img-upload>
	              <head-img-upload v-model="formData[item.value]"
	                               :img="formData[item.value] ? $config.assetsUrl + formData[item.value] : ''"
	                               v-else-if="item.type === 'image'"
	                               @change="(e)=>$set(formData,item.value,e ? e : '')"></head-img-upload>
	              <push-time v-model="formData.todo_time" style="max-width: 500px;"
	                         v-else-if="item.value === 'todo_time'"></push-time>
	              <push-time v-model="formData.push_time" style="max-width: 500px;"
	                         v-else-if="item.value === 'push_time'"></push-time>
	              <push-time v-model="formData[item.value]" style="max-width: 500px;" v-else-if="item.type === 'time'"></push-time>
	              <color-select v-model="formData[item.value]" v-else-if="item.type === 'colorSelect'"></color-select>
	              <range-time v-model="formData[item.value]" v-else-if="item.type === 'timeRange'" @change="(e)=>changeRangeTime(e,item.columns,item.unix ? item.unix : '')"></range-time>
	              <date-time-range v-model="formData[item.value]" v-else-if="item.type === 'dateTimeRange'" @change="(e)=>changeRangeTime(e,item.columns,item.unix ? item.unix : '')"></date-time-range>
	              <year-select v-model="formData.year" style="max-width: 500px;" v-else-if="item.value === 'year'"></year-select>
	              <file-upload v-model="formData[item.value]" v-else-if="item.type === 'file'" :files="formData['files']" :accept="item.comProp && item.comProp.accept ? item.comProp.accept : undefined"></file-upload>
	              <one-file-upload v-model="formData[item.value]" v-else-if="item.type === 'oneFile'" :files="formData['files']" :accept="item.comProp && item.comProp.accept ? item.comProp.accept : undefined"></one-file-upload>
	              <el-switch
	                v-model="formData[item.value]"
	                active-color="#13ce66"
	                :active-value="1"
	                :inactive-value="0"
	                active-text="启用"
	                inactive-text="禁用" v-else-if="item.type === 'state'">
	              </el-switch>
	              <el-switch
	                v-model="formData[item.value]"
	                active-color="#13ce66"
	                :active-value="1"
	                :inactive-value="0"
	                active-text="是"
	                inactive-text="否" v-else-if="item.type === 'bool'">
	              </el-switch>
	              <template v-else-if="item.type === 'totime'">
	                <el-input v-model="formData[item.value]" style="max-width: 400px;"></el-input>
	                <price-time-select class="margin-left" v-model="formData.time_type" style="width: 90px;"></price-time-select>
	              </template>
	              <tags-input v-model="formData[item.value]" v-else-if="item.type === 'tags'"></tags-input>
	              <group-select style="max-width: 500px;" v-else-if="item.type === 'group'" v-model="formData[item.value]"></group-select>
	              <dept-tree-select style="max-width: 500px;" v-else-if="item.type === 'deptSelect'" v-model="formData[item.value]" :id="formData[item.value]"></dept-tree-select>
	              <role-tree-select v-else-if="item.type === 'roleTreeSelect'" v-model="formData[item.value]" :id="formData[pk]"></role-tree-select>
	              <role-select v-else-if="item.type === 'roleSelect'" v-model="formData[item.value]" :multiple="false"></role-select>
	              <base-tree-select :is-top="item.isTop ? item.isTop : false" :top-value="item.topValue ? item.topValue : 0" :top-label="item.topLabel" :url="item.url" v-model="formData[item.value]" v-else-if="item.type === 'baseTreeSelect'" :val-key="item.valKey ? item.valKey : 'id'" :val-label="item.valLabel ? item.valLabel : 'label'" :val-child="item.valChild ? item.valChild : 'children'" style="max-width:500px;" :datas="item.datas ? item.datas : null"></base-tree-select>
	              <template v-else-if="item.type === 'password'">
	                <el-input v-model="formData[item.value]" type="password" show-password style="width: 500px;"></el-input>
	              </template>
	              <template v-else-if="item.type === 'topicType'">
	                <topic-type-form ref="topic" v-model="formData[item.value]" :pid="formData[item.comProp.pidKey]"></topic-type-form>
	              </template>
	              <template v-else-if="item.type === 'provinceSelect'">
	                <province-select v-model="formData[item.value]" @label="e=>onChangeLabel(e,item)" v-bind="item.comProp" style="min-width: 300px;"></province-select>
	              </template>
	              <icon-dialog v-model="formData[item.value]" v-else-if="item.type === 'iconChoose'"></icon-dialog>
	              <el-rate v-model="formData[item.value]" v-bind="item.comProp" allow-half v-else-if="item.type === 'star'"></el-rate>
	              <zhb-radio-group v-model="formData[item.value]" v-else-if="item.type === 'radio'" :options="item.options"></zhb-radio-group>
	              <zhb-checkbox-group v-model="formData[item.value]" v-else-if="item.type === 'checkGroup'" :options="item.options" style="width: 500px;"></zhb-checkbox-group>
	              <editor v-model="formData[item.value]" v-else-if="item.type === 'editor'"></editor>
	              <template v-else-if="item.type === 'userInfo'">
	                {{$user.getUserInfo(item.value)}}
	              </template>
	              <el-input v-model="formData[item.value]" v-bind="item.comProp" style="max-width: 500px;" :maxlength="item.max ? item.max : null" :class="item.max ? 'input-has-suffix' : ''" :show-word-limit="!!item.max" :readonly="!!item.readonly" v-else>
	                <template slot="prepend" v-if="item.prepend">{{ item.prepend }}</template>
	                <template slot="append" v-if="item.append">{{ item.append }}</template>
	              </el-input>
	              <span class="margin-left dark-7" v-if="item.type === 'sort'">排序数字越小越靠前</span>
	              <span class="margin-left dark-7" v-else-if="item.tip">{{ item.tip }}</span>
	              <div class="dark-7" v-else-if="item.lineTip">{{ item.lineTip }}</div>
	            </template>
	            <template v-else>
	              <template v-if="item.type === 'userInfo'">
	                {{$user.getUserInfo(item.value)}}
	              </template>
	              <template v-else>
	                {{ formData[item.value] }}
	              </template>
	            </template>
	            <el-form-item :rules="item.lastInput.rq ? [{required:true,message:item.lastInput.name+'必填'}] : null" :prop="item.lastInput.value" v-if="item.lastInput" class="inline-block">
	              <el-input v-model="formData[item.lastInput.value]" v-bind="item.comProp" style="max-width: 200px" class="mg-l-10"></el-input>
	            </el-form-item>
	          </el-form-item>
	        </template>
        </el-col>
      </el-row>
	    <el-form-item v-if="!path">
		    <el-button @click="toClose">关闭</el-button>
		    <el-button type="primary" @click="toSubmit">保存</el-button>
	    </el-form-item>
    </el-form>
    <div class="text-center" v-else>
      <i class="el-icon-loading f-20 vm mg-r-10"></i>加载中...
    </div>
  </div>
</template>

<script>
export default {
  name: "EditForm",
  data() {
    return {
      formData: {},

      rules: {},

      pk: 'id',
      mode:'add',
      method:'post',

      editParams: [],
      editOptions: {},
      detailOptions: {},
      editData: {},
      config: null,

      loading:false,

      isDialog:true,

	    pagePath:''
    }
  },
  props: {
    datas: {
      type: [Array,Object],
      default(){
        return {}
      }
    },
    path: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    editInfo:{
      type:[Array,Object],
      default(){
        return null
      }
    }
  },
  created() {
    //初始化配置参数
    this.loading = true;

		this.pagePath = this.path ? this.path : this.$route.path

    this.initSetting();
  },
  mounted() {
		console.log(this.pagePath);
    //如果有主键id，则获取详情
    if(this.editOptions.copyData && this.editInfo){
      this.formData = {...this.formData,...this.editInfo};

      this.loading = false;
    }else {
      console.log(this.formData[this.pk]);

      if (this.formData[this.pk]) {
        this.getDetail();
      }else {
        //初始化表单
        this.initForm();
      }
    }

    //初始化验证规则
    this.initRules();
  },
  methods: {
    checkRules(key,val,operator,column){
      let _out = this.$utils.callOperator(this.formData[key],val,operator ? operator : '===');

      if(_out){
        // console.log(column);
        // this.$set(this.formData,column,'');
      }else{
        // console.log(_out);
        delete this.formData[column];

        // console.log(this.formData);
      }

      return _out;
    },
    initSetting() {
      let _config = this.$baseConfig

	    if(this.pagePath && this.pagePath.indexOf('/edit') >= 0){
				this.pagePath = this.pagePath.replace('/edit','')
	    }

	    if(this.pagePath && this.pagePath.indexOf('/add') >= 0){
		    this.pagePath = this.pagePath.replace('/add','')
	    }

      this.config = _config[this.pagePath];

      this.pk = this.config && this.config.edit && this.config.edit.options && this.config.edit.options.pk ? this.config.edit.options.pk : 'id';

      this.editParams = this.datas[this.pk] ? this.config.edit.params : (this.config.add.params ? this.config.add.params : this.config.edit.params);

      this.editOptions = this.datas[this.pk] ? this.config.edit.options : (this.config.add.options ? this.config.add.options : this.config.edit.options);

      this.detailOptions = this.datas[this.pk] ? this.config.detail.options : (this.config.add.options ? this.config.add.options : this.config.detail.options);

      if(this.datas){
        for(let i in this.datas){
          this.$set(this.formData,i,this.datas[i]);
        }
      }

      if(!this.formData[this.pk]) {
        this.formData[this.pk] = this.$route.query[this.pk] ? this.$route.query[this.pk] : '';

        this.isDialog = false;
      }

      this.editData = this.editOptions.data ? this.editOptions.data : {};

      //获取表单提交url和method
      if (this.formData[this.pk]) {
        this.url = this.editOptions.editUrl.replace('{id}',this.formData[this.pk]);
        this.method = this.editOptions.editMethod;
      } else {
        this.url = this.editOptions.addUrl;
        this.method = this.editOptions.addMethod;
      }
    },
    closeDialog() {
      this.isShow = false;
      this.$emit('change', this.isShow);
    },
    //初始化校验规则
    initRules() {
      let _rules = {};

      for (let i = 0; i < this.editParams.length; i++) {
        if(!_rules[this.editParams[i].value]){
          _rules[this.editParams[i].value] = [];
        }

        if (this.editParams[i].type === 'price') {
          _rules[this.editParams[i].value].push({
            message: '请填写正确的' + this.editParams[i].name,
            pattern: this.$regexp.money
          });
        } else if (this.editParams[i].type === 'totime') {
          _rules[this.editParams[i].value].push({
            message: '请填写正确的' + this.editParams[i].name,
            pattern: this.$regexp.integer
          });
        } else if (this.editParams[i].type === 'sort') {
          _rules[this.editParams[i].value].push({
            message: '请填写正确的' + this.editParams[i].name,
            pattern: this.$regexp.positiveInteger
          });
        } else if (this.editParams[i].type === 'mobile') {
          _rules[this.editParams[i].value].push({
            message: '请填写正确的' + this.editParams[i].name,
            pattern: this.$regexp.mobile
          });
        } else if (this.editParams[i].type === 'email') {
          _rules[this.editParams[i].value].push({
            message: '请填写正确的' + this.editParams[i].name,
            pattern: this.$regexp.email
          });
        }else{
          if(this.editParams[i].rule && this.editParams[i].rule.patt) {
            _rules[this.editParams[i].value].push({
              message: this.editParams[i].rule.message ? this.editParams[i].rule.message : ('请填写正确的' + this.editParams[i].name),
              pattern: this.$regexp[this.editParams[i].rule.patt]
            });
          }
        }

        if (this.editParams[i].rq) {
          _rules[this.editParams[i].value].push({required: true, message: this.editParams[i].errorMessage ? this.editParams[i].errorMessage : (this.editParams[i].name + '必填'),trigger:['change','input']});
        }
      }

      this.$set(this,'rules',_rules);
    },
    //初始化表单数据
    initForm() {
      // console.log('==============')
      // console.log(this.datas);
      for (let i = 0; i < this.editParams.length; i++) {
        // console.log(this.editParams[i].name+'=='+this.editParams[i].def);
        // this.rules[this.editParams[i].value] = [];

        if (this.editParams[i].type === 'state') {
          this.$set(this.formData, this.editParams[i].value, 1);
        }else {
          //def 为默认值参数
          if (this.editParams[i].edit) {
            // if(this.$utils.isUndefined(this.formData[this.editParams[i].value])) {
            if(this.datas[this.editParams[i].value] || this.datas[this.editParams[i].value] === 0){

              // console.log(this.datas[this.editParams[i].value]);
              this.$set(this.formData, this.editParams[i].value,this.datas[this.editParams[i].value]);
            }else {
              console.log(this.editParams[i].def);
              this.$set(this.formData, this.editParams[i].value, (this.editParams[i].def === 0 || this.editParams[i].def ? this.editParams[i].def : ''));
            }
            // }
          }
        }
      }

      if(this.datas){
        for(let i in this.datas){
          this.$set(this.formData,i,this.datas[i]);
        }
      }

      this.loading = false;

      // console.log(this.formData);
    },
    //表单保存
    create() {
      let _this = this;

      let _tempdata = this.$utils.deepCopy(this.formData);

			let _data = {};

	    for (let i = 0;i < this.editParams.length;i++){
		    if(_tempdata[this.editParams[i].value] || _tempdata[this.editParams[i].value] === 0){
			    _data[this.editParams[i].value] = _tempdata[this.editParams[i].value]
		    }
	    }

	    _data[this.pk] = _tempdata[this.pk];

      if(!this.formData[this.pk]){
        delete _data[this.pk];
      }

      if(!this.$utils.isUndefined(this.editOptions.notSubmit) && this.editOptions.notSubmit.length > 0){
        for(let i=0;i < this.editOptions.notSubmit.length;i++){
          delete _data[this.editOptions.notSubmit[i]]
        }
      }

			for (let i = 0;i < this.editParams.length;i++){
				if(!this.editParams[i].edit){
					delete _data[this.editParams[i].value]
				}
			}

      this.$http.request({
        url: this.url,
        datas: {..._data, ...this.editData},
        method:this.method,
        success(res) {
	        if(!_this.path){
		        _this.$alert('保存成功！', '提示', {
			        confirmButtonText: '确定',
			        showClose:false,
			        callback: action => {
				        if(action === 'confirm'){
					        _this.$user.removeTab();
					        _this.$router.back();
				        }
			        }
		        });
	        }else {
		        _this.$utils.success('保存成功！');
	        }

          _this.$emit('success',{isDialog:_this.isDialog});
        }
      });
    },

    toCheck(callback){
      this.$refs['form'].validate(valid => {
        if (valid) {
          if(callback){
            callback();
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
	  toClose(){
		  this.$user.removeTab();
		  this.$router.back();
	  },

	  toSubmit() {
			this.submit();
	  },
	  //表单提交
    submit() {
      let _this = this;
      // if(this.starList){  //starGradeArr 传值
      //   this.formData.starList = this.starGradeArr;
      // }

      this.$refs['form'].validate(valid => {
        if (valid) {
          if(_this.$refs["topic"] && _this.$refs["topic"][0]){
            _this.$refs["topic"][0].validate(topicValid=>{
              if(topicValid){
                _this.create();
              }
            })
          }else{
            _this.create();
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //获取详情
    getDetail() {
      let _this = this;

      console.log('defdsfs');

      this.$http.request({
        url: this.editOptions.detailUrl.replace('{id}',this.formData[this.pk]),
        method: this.editOptions.detailMethod ? this.editOptions.detailMethod : 'get',
        datas: {[this.pk]: this.formData[this.pk]},
        success(res) {
          // console.log(res);
          _this.formData = res ? JSON.parse(JSON.stringify(res)) : null;

          // console.log(_this.formData);

          for (let i = 0; i < _this.editParams.length; i++) {
            if (_this.editParams[i].isNone) {
              _this.$set(_this.formData, _this.editParams[i].value, '');
            } else if (_this.editParams[i].isNull) {
              _this.$set(_this.formData, _this.editParams[i].value, null);
            }

            if(_this.editParams[i].type === 'dateTimeRange'){
              let _start = _this.formData[_this.editParams[i].columns[0]];
              let _end = _this.formData[_this.editParams[i].columns[1]];

              _this.$set(_this.formData, _this.editParams[i].value, _start+','+_end);
            }
          }

          _this.loading = false;
        }
      });
    },
    onChangeLabel(e,prop){
      if(prop.comProp && prop.comProp.labelInput){
        if(e) {
          this.$set(this.formData, prop.comProp.key, prop.comProp.val ? e[prop.comProp.val] : e);
        }else{
          this.$set(this.formData, prop.comProp.key, '');
        }
      }
    },

    changeRangeTime(e, columns, unix = '') {
      let _times = e.split(',');

      if(columns && columns.length === 2){
        if(unix === 'sss'){
          this.$set(this.formData, columns[0], this.$moment(_times[0]).valueOf());
          this.$set(this.formData, columns[1], this.$moment(_times[1]).valueOf());
        }else if(unix === 'ss'){
          this.$set(this.formData, columns[0], this.$moment(_times[1]).unix());
          this.$set(this.formData, columns[1], this.$moment(_times[1]).unix());
        }else{
          this.$set(this.formData, columns[0], _times[0]);
          this.$set(this.formData, columns[1], _times[1]);
        }
      }
    },
  },
	watch:{
		path(n,o){
			console.log(n,o);
		}
	}
}
</script>
