<template>
    <el-select v-model="values" :value-key="isObj ? valueKey : ''" placeholder="请选择" :clearable="clearable" @change="change" style="width: 100%;">
        <el-option
            v-for="item in list"
            :key="item[valueKey]"
            :label="item[valueLabel]"
            :value="isObj ? item : item[valueKey]"></el-option>
    </el-select>
</template>

<script>
export default {
    name: "BusinessSelect",
    data(){
        return{
            list:[],
            values:'',
            name:'',
            valueKey:'id',
            valueLabel:'name'
        }
    },
    model:{
        prop:'value',
        event:'change'
    },
    props:{
        value:{
            type:[String,Number,Boolean,Object],
            default:''
        },
        data:{
            type:[Array,Object],
            default(){
                return null
            }
        },
        url:{
            type:String,
            default:''
        },
        options:{
            type:[Array,Object],
            default(){
                return null
            }
        },
        canAdd:{
            type:Boolean,
            default:true
        },
        clearable:{
            type:Boolean,
            default:false
        },
        valKey:{
            type:String,
            default:'id'
        },
        valLabel:{
            type:String,
            default:'desc'
        },
        isObj:{
            type:Boolean,
            default:false
        },
        isTop:{
            type:Boolean,
            default:true
        },
    },
    created() {
        this.values = this.value;

        this.valueKey = this.valKey ? this.valKey : 'id';
        this.valueLabel = this.valLabel ? this.valLabel : 'desc';

        if(this.isTop){
            this.list.push({[this.valueKey]:'',[this.valueLabel]:'全部'});
        }
    },
    watch:{
        value(n,o){
            this.values = n;
        }
    },
    mounted() {
        if(this.options) {
            this.list.push.apply(this.list,this.options);
        }else{
            this.getList();
        }
    },
    methods:{
        getList() {
            let _this = this;

            let _url = '';

            this.$http.post({
                url: this.$config.apiUrl+'/member/meta',
                success(res) {
                    _this.list.push.apply(_this.list,res.level);
                }
            });
        },
        change(e){
            this.$emit("change", e);
        },
    }
}
</script>
