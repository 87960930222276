<template>
  <div class="cl margin-bottom" v-if="topLeftButtonList.length > 0 || topRightButtonList.length > 0 || listOptions.showAdd || listOptions.showExport || listOptions.showBack">
    <div class="z">
			<el-button icon="el-icon-arrow-left" size="mini" @click="goBack" v-if="listOptions.showBack">返回</el-button>
      <add-button class="mg-r-10" size="mini" @click.native="toEdit(params.addProp ? params.addProp : null,null,null)" :role="params.addRole" v-if="listOptions.showAdd"></add-button>
      <base-button class="mg-r-10" @click.native="toExport(listOptions.exportUrl)" size="mini" type="warning" v-if="listOptions.showExport">导出报表</base-button>
      <base-button-group :buttons="topLeftButtonList" @toDiy="toDiy" @toApi="toApi" @toEdit="toEdit" @toDetail="toDetail" @toList="toList"></base-button-group>
    </div>
    <div class="y">
      <base-button-group :buttons="topRightButtonList" @toDiy="toDiy" @toApi="toApi" @toEdit="toEdit" @toDetail="toDetail" @toList="toList"></base-button-group>
    </div>
  </div>
</template>

<script>
export default {
  name: "TopButtons",
  data() {
    return {
      comPath: '',
      params: null,
      listParams: null,
      listOptions: null,
      topLeftButtonList:[],
      topRightButtonList:[],
      searchForm:{}
    }
  },
  props:{
    path:{
      type:String,
      default:''
    },
    searchData:{
      type:[Array,Object],
      default(){
        return {}
      }
    },
    comParams: null,
    comListParams: null,
    comListOptions: null,
  },
  created() {
    this.init();
  },
  mounted() {
  },
  methods: {
		goBack(){
			this.$user.removeTab();
			this.$router.back();
		},
    init(){
      this.comPath = this.path ? this.path : this.$route.path;

      //获取路径全部配置
      this.params = this.comParams ? this.comParams : (this.comPath ? this.$baseConfig[this.comPath] : null);

      console.log(this.params);

      //获取列表参数
      this.listParams = this.comListParams ? this.comListParams : (this.params ? this.params.list : null);
      //获取列表配置
      this.listOptions = this.comListOptions ? this.comListOptions : (this.params && this.params.list ? this.params.list.options : null);
      //获取顶部左侧按钮
      this.topLeftButtonList = this.$utils.isUndefined(this.params.topLeftButtons) ? [] : this.params.topLeftButtons;
      //获取顶部右侧按钮
      this.topRightButtonList = this.$utils.isUndefined(this.params.topRightButtons) ? [] : this.params.topRightButtons;

      this.pk = this.listOptions.pk ? this.listOptions.pk : 'id';
    },
    //自定义请求接口
    toApi(option, scopeRow, data) {
      this.$emit('toApi',option,scopeRow, data)
    },

    //查看详情
    toDetail(option, scopeRow, data) {
      this.$emit('toDetail',option, scopeRow, data)
    },

    toList(option, scopeRow, data){
      this.$emit('toList',option, scopeRow, data)
    },

    //自定义组件
    toDiy(option, scopeRow, data){
      this.$emit('toDiy',option, scopeRow, data)
    },

    //编辑
    toEdit(option, scopeRow, data) {
      console.log(option, scopeRow, data);
      console.log('=f=f=d=f=sd');
      this.$emit('toEdit',option, scopeRow, data);
    },

    toExport(url){
      let _this = this;

      if (url) {
        let _datas = this.searchForm ? JSON.parse(JSON.stringify(this.searchForm)) : {};
        delete _datas.pageSize;
        // delete _datas.pageNum;

        this.$http.request({
          url: url,
          datas: _datas,
          method: 'get',
          download: true,
          success(res) {
            const blob = new Blob([res]) // 构造一个blob对象来处理数据
            const fileName = (_this.listOptions.exportFileName ? _this.listOptions.exportFileName : '导出数据') + '_' + _this.$moment().format('YYYYMMDDHHmmss') + '.xlsx';

            console.log(fileName);

            const link = document.createElement('a') // 创建a标签
            link.download = fileName // a标签添加属性
            link.style.display = 'none'
            link.href = URL.createObjectURL(blob)
            document.body.appendChild(link)
            link.click();
            URL.revokeObjectURL(link.href);
            document.body.removeChild(link);
          }
        });
      }
    }
  },
  watch:{
    searchData(n,o){
      this.searchForm = n;
    }
  }
}
</script>
