<template>
    <el-select v-model="values" placeholder="请选择" clearable @change="change" style="width: 100%;">
        <el-option
            v-for="item in list"
            :key="item.id"
            :label="item.name"
            :value="item.id"></el-option>
    </el-select>
</template>

<script>
export default {
    name: "OrderStateSelect",
    data(){
        return{
            list:[
                {name:'待支付',id:'1'},
                {name:'已付款',id:'2'},
            ],
            values:''
        }
    },
    model:{
        prop:'value',
        event:'change'
    },
    props:{
        value:{
            type:[String,Number],
            default:''
        }
    },
    created() {
        this.values = this.value ? this.value : '';
    },
    watch:{
        value(n,o){
            this.values = n ? n : '';
        }
    },
    mounted() {
    },
    methods:{
        change(e){
            this.$emit("change", e);
        }
    }
}
</script>
