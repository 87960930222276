<template>
	<div class="el-descriptions">
		<div class="el-descriptions__header" v-if="title">
			<div class="el-descriptions__title">{{title}}</div>
		</div>
		<div class="el-descriptions__body">
			<table class="el-descriptions__table is-bordered el-descriptions--normal">
				<tbody>
					<slot></slot>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
export default {
	name: "ZhbTable",
	data() {
		return {}
	},
	props:{
		title:String
	},
	created() {
	},
	mounted() {
	},
	methods: {}
}
</script>
