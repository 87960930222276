<template>
	<tree-select :multiple="multiple" v-model="val" :options="options" :searchable="false" :placeholder="placeholder" :normalizer="normalizer" :appendToBody="appendToBody" zIndex="9999" @select="select" :clearable="clearable" :default-expand-level="defaultExpandLevel"></tree-select>
</template>

<script>
import emitter from 'element-ui/src/mixins/emitter';

export default {
	name: "BaseTreeSelect",
	mixins: [emitter],
	data() {
		return {
			options: [],
			val: null,
			normalizer: null
		}
	},
	model: {
		prop: 'value',
		event: 'change'
	},
	props: {
		value: {
			type: [String, Number],
			default: ''
		},
		url: {
			type: String,
			default: null
		},
		isTop: {
			type: Boolean,
			default: false
		},
		clearable: {
			type: Boolean,
			default: false
		},
		topLabel: {
			type: String,
			default: '顶级'
		},
		topValue: {
			type: [Number,String],
			default: 0
		},
		multiple: {
			type: Boolean,
			default: false
		},
		appendToBody: {
			type: Boolean,
			default: true
		},
		placeholder: {
			type: String,
			default: '-请选择-'
		},
		valKey: {
			type: String,
			default: 'id'
		},
		valLabel: {
			type: String,
			default: 'label'
		},
		valChild: {
			type: String,
			default: 'children'
		},
		defaultExpandLevel:{
			type:Number,
			default:5
		},
		datas: {
			type: [Object, Array],
			default() {
				return {}
			}
		}
	},
	created() {
		this.val = this.value ? this.value : (this.value === 0 || this.value === '0' ? this.value : null);

		this.normalizer = (node) => {
			if (!node[this.valChild] || node[this.valChild].length <= 0) {
				delete node[this.valChild];
			}

			return {
				id: node[this.valKey],
				label: node[this.valLabel],
				children: node[this.valChild],
			}
		};

		// if (this.isTop) {
		// 	let _top = {id:'1',[this.valKey]: this.topValue, [this.valLabel]: this.topLabel,[this.valChild]:[]};
		//
		// 	this.options.push(_top);
		// }
	},
	mounted() {
		if (this.url) {
			this.getList();
		}
	},
	methods: {
		getList() {
			let _this = this;

			this.$http.request({
				url: this.url,
				method: 'post',
				datas: this.datas ? {...this.datas} : null,
				success(res) {
					let _res = _this.listKey ? res[_this.listKey] : res;
					let _typeof = Object.prototype.toString.call(_res);
					//
					// if(_this.isTop && _this.topLabel){
					// 	_this.options[0][_this.valChild] = [];
					// 	_this.options[0][_this.valChild].push.apply(_this.options[0][_this.valChild], _typeof === '[object Object]' ? [_res] : _res);
					// }else {
					// 	_this.options.push.apply(_this.options, _typeof === '[object Object]' ? [_res] : _res);
					// }
					if(_this.isTop && _this.topLabel){
						let _top = {id:'1',[_this.valKey]: _this.topValue, [_this.valLabel]: _this.topLabel,[_this.valChild]:[]};
						_top[_this.valChild] = _res;

						_this.options.push.apply(_this.options, Object.prototype.toString.call(_top) === '[object Object]' ? [_top] : _top);
					}else {
						_this.options.push.apply(_this.options, _typeof === '[object Object]' ? [_res] : _res);
					}

					// _this.options = _res;

					// console.log(_this.options);
				}
			});
		},
		select(e) {
			// console.log(e);
			// console.log('====');
			this.$emit('change', e[this.valKey]);
			this.$emit('choose', e);
			this.dispatch('ElFormItem', 'el.form.change', e[this.valKey])
			this.dispatch('ElFormItem', 'el.form.blur', e[this.valKey])
		}
	},
	watch: {
		value(n, o) {
			let _this = this;
			this.$nextTick(() => {
				_this.val = n ? n : (n === 0 ? n : null);
			})
		},
		multiple(n, o) {
			this.multiple = n;
		}
	}
}
</script>
